import axios, { AxiosResponse, AxiosError } from 'axios';
import { refreshToken } from '../api/Aws/authApi';
import { classifyAndThrowError } from './ApiError';
const axiosApiInstance = axios.create({ timeout: 50000 });

let isRefreshing = false;
let failedQueue: Array<() => void> = [];

const processQueue = (error: unknown, token: string | null = null) => {
  failedQueue.forEach(() => {
    classifyAndThrowError(error);
  });
  failedQueue = [];
};

/**
 * Interceptor to add the jwt to the authorization header for each request
 */
axiosApiInstance.interceptors.request.use(
  async (config) => {
    const accessToken = JSON.stringify({ token: localStorage.getItem('token') });
    config.headers.Authorization = accessToken;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosApiInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: any) => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 403 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise<string>((resolve) => {
          failedQueue.push(() => {
            originalRequest.headers['Authorization'] = `${localStorage.getItem('token')}`;
            resolve(axiosApiInstance(originalRequest));
          });
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;
      const fetchRefreshToken = localStorage.getItem('refreshToken');

      if (!fetchRefreshToken) {
        return Promise.reject('Refresh token not found in session');
      }
      const refreshTokenPayload = {
        userData: {
          refreshToken: fetchRefreshToken
        }
      };

      return refreshToken(refreshTokenPayload)
        .then((response) => {
          const token = response.data;
          localStorage.setItem('token', token);
          isRefreshing = false;
          originalRequest.headers['Authorization'] = `${token}`;
          processQueue(null, token);
          return axiosApiInstance(originalRequest);
        })
        .catch((err) => {
          localStorage.removeItem('token');
          localStorage.removeItem('refreshToken');
          isRefreshing = false;
          processQueue(err, null);
          return Promise.reject(err);
        });
    }

    if (error instanceof AxiosError) {
      return Promise.reject(error);
    }
  }
);

export default axiosApiInstance;
