import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';
import { IDevice } from 'store/slices/devicesSlice';
import { UnitTypeNames } from 'features/RemoteManagement/Types';
import EditUnitDialog from 'features/RemoteManagement/NewSiteWizard/Steps/dialogs/EditUnitDialog';
import WizardDeviceCard from 'features/RemoteManagement/NewSiteWizard/Steps/components/WizardDeviceCard';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setSelectedUnitId } from 'store/slices/unitsSlice';

interface Props {
  unit: Unit;
  units: Unit[];
  setUnits: (units: Unit[]) => void;
  setIsAddDevicesOpen: (isAddDevicesOpen: boolean) => void;
  setSelectedUnit: (unit: string) => void;
  setShouldRefetch: (shouldRefetch: boolean) => void;
  siteId: string;
}

interface Unit {
  unitPublicId: string;
  unitNumber: string;
  unitName: string;
  unitType: number;
  devices: IDevice[];
}

const UnitCard = ({ unit, units, setShouldRefetch, siteId }: Props) => {
  const [isEditUnitDialogOpen, setIsEditUnitDialogOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleUnitSettings = () => {
    dispatch(setSelectedUnitId(unit.unitPublicId));
    navigate(`/site/${siteId}/units/${unit.unitPublicId}/unitInformation`);
  };

  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="body1" color="primary">
            {unit.unitName} : {unit.unitNumber}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Typography variant="sectionHeader">Unit Details</Typography>
            <Box sx={{ mt: 1, mb: 1 }}>
              <Typography variant="body1">Unit Number: {unit.unitNumber}</Typography>
              <Typography variant="body1">Unit Name: {unit.unitName}</Typography>
              <Typography variant="body1">
                Unit Type:{' '}
                <Tooltip
                  title={UnitTypeNames[unit.unitType as 1 | 2 | 3 | 4 | 5 | 6].description}
                  arrow
                  sx={styles.unitTypeTooltip}
                >
                  <Typography variant="body1" color="secondary" display="inline">
                    {UnitTypeNames[unit.unitType as 1 | 2 | 3 | 4 | 5 | 6].name}
                  </Typography>
                </Tooltip>
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography variant="sectionHeader">Devices in Unit</Typography>
          </Box>

          <Box sx={{ mt: 2, mb: 2 }}>
            <Grid container spacing={2}>
              {unit.devices.length === 0 ? (
                <Grid item xs={12}>
                  <Typography> There are no devices in this unit. </Typography>
                </Grid>
              ) : (
                unit.devices.map((device) => (
                  <Grid item xs={12} sm={6} md={4} lg={4} key={device.basicInfo.macAddress}>
                    <WizardDeviceCard device={device} />
                  </Grid>
                ))
              )}
            </Grid>
          </Box>
          <Box sx={styles.dualButtonContainer}>
            <Button variant="contained" onClick={handleUnitSettings}>
              <Typography variant="body1">Configure Unit</Typography>
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>
      <EditUnitDialog
        isEditUnitDialogOpen={isEditUnitDialogOpen}
        setIsEditUnitDialogOpen={setIsEditUnitDialogOpen}
        units={units}
        unit={unit}
        setShouldRefetch={setShouldRefetch}
      />
    </>
  );
};

export default UnitCard;

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  dualButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
    flexDirection: 'row',
    marginTop: '1rem'
  },
  unitTypeTooltip: {
    fontSize: '1rem',
    cursor: 'pointer'
  }
};
