/* SIF tab stub */
export const SIFLabel = () => {
  return <span>SIF</span>;
};

const SIF = () => {
  return <span>SIF</span>;
};

export default SIF;
