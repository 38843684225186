/* Door Release tab stub */
export const DoorReleaseLabel = () => {
  return <span>Door Release</span>;
};

const DoorRelease = () => {
  return <span>Door Release</span>;
};

export default DoorRelease;
