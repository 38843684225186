import { Typography, TextField, Container } from '@mui/material';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const UnitInformation = () => {
  const params = useParams();
  const unitPublicId = params.unitid || '';
  const unitList = useSelector((state: RootState) => state.units.UnitList);
  const selectedUnit = unitList[unitPublicId];
  const selectedBuilding = useSelector((state: RootState) =>
    selectedUnit ? state.buildings.BuildingList[selectedUnit.buildingPublicId] : null
  );

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>
        Unit Information
      </Typography>
      <TextField label="Unit's Name" variant="outlined" fullWidth margin="normal" value={selectedUnit.unitName} />
      <TextField label="Unit Type" variant="outlined" fullWidth margin="normal" value={selectedUnit.unitType} />
      <TextField
        label="Building Number"
        variant="outlined"
        fullWidth
        margin="normal"
        value={selectedBuilding?.buildingNumber}
      />
    </Container>
  );
};

export default UnitInformation;
