import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { aiphoneCloudApi } from 'services/aiphoneCloud';
import { UUID } from 'crypto';
import defaultIXMV7 from 'data/Device/DefaultValues/Default_IX-MV7';
export interface IDevice {
  publicId: string;
  sitePublicId: string;
  unitPublicId?: string;
  basicInfo: IDeviceBasicInfo;
  networkSettings?: IDeviceNetworkSettings;
  systemInfo?: IDeviceSystemInfo;
  callSettings?: IDeviceCallSettings;
  deviceSettings?: IDeviceDeviceSettings;
  pagingSettings?: IDevicePagingSettings;
  syslogSettings?: IDeviceSyslogSettings;
  contactInputList?: IDeviceContactInput[];
  functionSettings?: IDeviceFunctionSettings;
  transferSettings?: ITransferSettings;
  contactOutputList?: IContactOutput[];
  isUpdated?: boolean;
  lastSyncedBy: string | null;
  lastSyncedOn: string | null;
}

export interface IDeviceBasicInfo {
  firmwareVersion?: any;
  adminId: string;
  adminPass: string;
  adminPassConfirm?: string;
  dateTime: string;
  daylightSavings: boolean;
  deviceModel: number;
  devicePublicId: string;
  deviceType: number;
  macAddress: string;
  onvifId?: string;
  onvifPass?: string;
  onvifPassConfirm?: string;
  rtspId?: string;
  rtspPass?: string;
  rtspPassConfirm?: string;
  stationLanguage: number;
  stationLanguageVer2: number;
  stationName: string;
  stationNumber: string;
  stationPronunciationName: string;
  timezone: number;
  timezoneVer2: number;
  userId: string;
  userPass: string;
  userPassConfirm?: string;
  stationLocation?: string;
  systemExtendMode?: boolean;
}

export interface IDeviceNetworkSettings {
  audioBufferMaximumPackets: number;
  audioBufferStartingPackets: number;
  audioEncoder: number;
  audioEncoder1RtpEndPort: number;
  audioEncoder1RtpStartPort: number;
  audioEncoder2RtpEndPort: number;
  audioEncoder2RtpStartPort: number;
  audioTosValue: string;
  devicePublicId: string;
  hostName: string;
  ipAddressMethod: number;
  ipV4Address: string;
  ipV4DefaultGateway: string;
  ipV4MulticastAddress: string;
  ipV4PrimaryDns: string;
  ipV4PrimarySipAddress: string;
  ipV4SecondaryDns: string;
  ipV4SecondarySipAddress: string;
  ipV4TertiarySipAddress: string;
  ipV6Address: string;
  ipV6DefaultGateway: string;
  ipV6MulticastAddress: string;
  ipV6PrimaryDns: string;
  ipV6PrimarySipAddress: string;
  ipV6SecondaryDns: string;
  ipV6SecondarySipAddress: string;
  ipV6TertiarySipAddress: string;
  ipVersion: number;
  masterStationVideoBitrate: number;
  masterStationVideoDistribution: boolean;
  masterStationVideoFramerate: number;
  masterStationVideoH264Profile: number;
  masterStationVideoIPictureInterval: number;
  ntpPrimaryServerIpV4Address: string;
  ntpPrimaryServerIpV6Address: string;
  ntpPrimaryServerPort: number;
  ntpSecondaryServerIpV4Address: string;
  ntpSecondaryServerIpV6Address: string;
  ntpSecondaryServerPort: number;
  ntpSettings: boolean;
  ntpSyncInterval: number;
  rtpIdleNoticeTimer: number;
  rtpSendInterval: number;
  sipHealthCheckTimer: number;
  sipInboundDtmfTimeout: number;
  sipPrimaryPass: string;
  sipPrimaryPort: number;
  sipPrimaryUser: string;
  sipRegistrationInterval: number;
  sipSecondaryPass: string;
  sipSecondaryPort: number;
  sipSecondaryUser: string;
  sipServerType: number;
  sipSignallingPort: number;
  sipTertiaryPass: string;
  sipTertiaryPort: number;
  sipTertiaryUser: string;
  sipTosValue: string;
  sipUserAgent: string;
  subnetMask: string;
  videoDualStream?: boolean;
  videoEncoder1Bitrate?: number;
  videoEncoder1Framerate?: number;
  videoEncoder1IPictureInterval?: number;
  videoEncoder1RtpEndPort?: number;
  videoEncoder1RtpStartPort: number;
  videoEncoder2IPictureInterval?: number;
  videoEncoder2Method?: number;
  videoEncoder2Resolution?: number;
  videoEncoder2RtpEndPort: number;
  videoEncoder2RtpStartPort: number;
  videoEncoder2Framerate?: number;
  videoEncoder2H264Quality?: number;
  videoEncoder2Bitrate?: number;
  videoEncoder2JpegQuality?: number;
  videoTosValue: string;
  vlanId: number;
  vlanPriority: number;
  vlanSettings: boolean;
}

export interface IAddressBookEntry {
  targetDevicePublicId?: string;
  associatedNetworkCameraPublicId?: string;
}

export interface INetworkCameraListEntry {
  networkCameraPublicId: string;
}

export interface ICallGroupInfoEntry {
  siteCallGroupPublicId: string;
  protocolAudio: number;
  protocolVideo: number;
}

export interface ICustomSoundEntry {
  customSoundPublicId: string;
  operation: number;
}

export interface IDeviceSystemInfo {
  devicePublicId: string;
  nodeLocation: string;
  addressBook?: IAddressBookEntry[];
  associatedNetworkCameraList?: INetworkCameraListEntry[];
  callGroupInfo?: ICallGroupInfoEntry[];
  customSounds?: ICustomSoundEntry[];
}

export interface IContactGroupEntry {
  targetDevicePublicId: string;
  contactGroupNumber: number;
  protocol: number;
}

export interface IContactGroup {
  contactGroup: IContactGroupEntry[];
}

export interface ICallTimerChangeEntry {
  siteCallGroupPublicId: string;
  timerChangePriority: number;
  timerChangeNumber: number;
}

export interface ICallTimerChange {
  callTimerChange: ICallTimerChangeEntry[];
}

export interface ICallScheduleEntry {
  scheduleNumber: number;
  startTime: string;
  endTime: string;
  destinationSiteCallGroupPublicId: string;
  callPriority: number;
}

export interface IIndividualCallScheduleEntry extends ICallScheduleEntry {
  scheduleDate: string;
}

export interface ICallSchedule {
  sunday: ICallScheduleEntry[];
  monday: ICallScheduleEntry[];
  tuesday: ICallScheduleEntry[];
  wednesday: ICallScheduleEntry[];
  thursday: ICallScheduleEntry[];
  friday: ICallScheduleEntry[];
  saturday: ICallScheduleEntry[];
  individual: IIndividualCallScheduleEntry[];
}

export interface IDeviceCallSettings {
  devicePublicId: string;
  callButtonFunction?: number;
  callMode?: number;
  outgoingCallTimeout: number;
  incomingCallRingtone?: string;
  incomingCallTimeout: number;
  incomingCallRingtoneCount?: number;
  callButtonIncomingCallRingtoneCount: number;
  contactInputIncomingCallRingtoneCount: number;
  siteCallGroup?: string;
  callTimerChangeTime?: number;
  callBusyToneSettings: string;
  callErrorTone?: string;
  callResume?: boolean;
  autoAnswer: boolean;
  ipCallPriority: number;
  contactGroup?: IContactGroup;
  callTimerChange?: ICallTimerChange;
  callSchedule?: ICallSchedule;
  ringtoneSetting?: UUID;
}

export interface IDeviceDeviceSettings {
  statusLed: boolean;
  pushToTalk: boolean;
  callTimeout: number;
  onHoldSound: UUID;
  pagingSound: UUID;
  privacyMode: boolean;
  timeoutSound: UUID;
  displayKeypad: boolean;
  devicePublicId: UUID;
  monitorTimeout: number;
  autoAnswerSound: UUID;
  deviceSpeedDial: IDeviceSpeedDial[];
  doorReleaseSound: UUID;
  pinJackInputType: number;
  displayBrightness: number;
  incomingCallSound: UUID;
  volumeNotification: number;
  scanMonitorInterval: number;
  timeoutWarningSound: UUID;
  picturePicturePrimary: number;
  volumeExternalSpeaker: number;
  volumeIncomingHandset: number;
  volumeIncomingSpeaker: number;
  volumeOutgoingHandset: number;
  volumeOutgoingSpeaker: number;
  volumeIncomingPage: number;
  volumeOperation: boolean;
  outputToExternalSpeaker: number;
  volumeExternalMicrophone: number;
  ipPhoneCallRate: number;
  optionContactOutputKey: string;
  picturePictureDisplayRatio: number;
  automaticSupervisionSettings?: IAutomaticSupervisionSettings;
}

export interface IDeviceSpeedDial {
  publicId: UUID;
  buttonName: string;
  buttonNumber: number;
  targetDevices: UUID[];
  speedDialFunction: number;
  optionRelaySecurity: boolean;
  disableButtonSettings: boolean;
}

export interface IAutomaticSupervisionSetting {
  startTime: string;
  daySetting: number;
  periodSetting: number;
}

export interface IAutomaticSupervisionSettings {
  line: IAutomaticSupervisionSetting;
  error: IAutomaticSupervisionSetting;
}

export interface IDeviceTypes {
  [key: string]: string[];
}

export interface IDevicePagingSettings {
  allPage: IPagingStationLists;
  pagingEnabled: boolean;
  pagingTimeout: number;
  devicePublicId: UUID;
  pagingStartDelay: number;
  pagingUrgentRejectCalls: boolean;
}

export interface IPagingStationLists {
  select: UUID[];
  unicast: UUID[];
}

export interface IDeviceSyslogSettings {
  syslogPort: number;
  devicePublicId: UUID;
  ipV4SyslogAddress: string;
  ipV6SyslogAddress: string;
}

export interface IDeviceContactInput {
  api1: string;
  api2: string;
  publicId: UUID;
  ringtone: UUID;
  inputName: string;
  inputNumber: number;
  pagingGroup: IPagingGroup;
  callSchedule: IContactInputCallScheduleContainer;
  detectionTime: number;
  inputFunction: number;
  inputPolarity: number;
  devicePublicId: UUID;
  incomingCallTimeout: number;
  outgoingCallTimeout: number;
  siteCallGroupPublicId: UUID;
}

export interface IPagingGroup {
  ringCount: number;
  pagingType: number;
  pagingPriority: number;
  soundSettingPublicId: UUID;
}

export interface IContactInputCallScheduleContainer {
  friday: IContactInputCallSchedule[];
  monday: IContactInputCallSchedule[];
  sunday: IContactInputCallSchedule[];
  tuesday: IContactInputCallSchedule[];
  saturday: IContactInputCallSchedule[];
  thursday: IContactInputCallSchedule[];
  wednesday: IContactInputCallSchedule[];
  individual: IContactInputIndividualCallSchedule[];
}

interface IContactInputCallSchedule {
  scheduleNumber: number;
  startTime: string;
  endTime: string;
  siteCallGroupPublicId: UUID;
  callGroupPriority: number;
}

export interface IContactInputIndividualCallSchedule extends IContactInputCallSchedule {
  scheduleDate: string;
}

export interface IDeviceFunctionSettings {
  sifEvent: ISifEvent[];
  smtpAuth: false;
  smtpEvent: ISmtpEvent[];
  smtpAuthId: string;
  cgiSettings: boolean;
  displayMode: number;
  eapPassword: string;
  eapUsername: string;
  sifSettings: boolean;
  smtpAuthMode: number;
  chimeSchedule: IChimeScheduleContainer;
  displayImage1: string;
  displayImage2: string;
  displayLayout: number;
  devicePublicId: UUID;
  displayModeTop: number;
  sipUriSettings: boolean;
  smtpEncryption: number;
  smtpPortNumber: number;
  smtpServerName: string;
  ieee802Security: boolean;
  displayBaseColor: number;
  recordingAutoEnd: number;
  smtpAuthPassword: string;
  displayImageSpace: string;
  eapSecurityMethod: number;
  recordingAddition: boolean;
  recordingOverwrite: boolean;
  unlockSettingsList: IUnlockSettings[];
  recordingAllowManual: boolean;
  recordingFileDivision: number;
  recordingInputPriority: number;
  smtpSenderEmailAddress: string;
  displayButtonNumberName: string;
  displayButtonSelectName: string;
  receptionModeCallReject: boolean;
  sifTransmissionInterval: number;
  automaticRecordingTriggers: IAutomaticRecordingTriggers;
  displayButtonListSelectName: string;
  smtpDestinationEmailAddress1: string;
  smtpDestinationEmailAddress2: string;
  smtpDestinationEmailAddress3: string;
  displayButtonGeneralInformationName: string;
  networkCameraEventNotificationRingtoneCount: number;
  smtpEventSendScheduledLogEmailAddress1SendTime: string;
  smtpEventSendScheduledLogEmailAddress2SendTime: string;
  smtpEventSendScheduledLogEmailAddress3SendTime: string;
  smtpEventSendScheduledLogEmailAddress1SendInterval: number;
  smtpEventSendScheduledLogEmailAddress2SendInterval: number;
  smtpEventSendScheduledLogEmailAddress3SendInterval: number;
}

export interface ISifEvent {
  eventType: number;
  sifDestination1: boolean;
  sifDestination2: boolean;
  sifDestination3: boolean;
  sifDestination4: boolean;
  sifDestination5: boolean;
  sifDestination6: boolean;
  sifDestination7: boolean;
  sifDestination8: boolean;
  sifDestination9: boolean;
  sifDestination10: boolean;
  sifDestination11: boolean;
  sifDestination12: boolean;
  sifDestination13: boolean;
  sifDestination14: boolean;
  sifDestination15: boolean;
  sifDestination16: boolean;
}

export interface ISmtpEvent {
  eventType: number;
  emailSubject: string;
  enableEmail1: boolean;
  enableEmail2: boolean;
  enableEmail3: boolean;
}

export interface IChimeScheduleContainer {
  friday: IChimeSchedule[];
  monday: IChimeSchedule[];
  sunday: IChimeSchedule[];
  tuesday: IChimeSchedule[];
  saturday: IChimeSchedule[];
  thursday: IChimeSchedule[];
  wednesday: IChimeSchedule[];
  individual: IChimeIndividualSchedule[];
}

export interface IChimeSchedule {
  scheduleNumber: number;
  startTime: string;
  soundType: number;
  customSoundPublicId: UUID;
}

export interface IUnlockSettings {
  targetDevicePublicId: UUID;
  deviceTarget: number;
  unlockCode: string;
}

export interface IChimeIndividualSchedule extends IChimeSchedule {
  scheduleDate: string;
}

export interface IAutomaticRecordingTriggers {
  callStart: boolean;
  monitoring: boolean;
  incomingCall: boolean;
  outgoingCall: boolean;
  scheduledStart: boolean;
  callStartDoorStation: boolean;
  monitorStationDoorStation: boolean;
}

export interface ITransferSettings {
  transferDelay: boolean;
  devicePublicId: UUID;
  transferAbsent: boolean;
  transferUserLock: boolean;
  transferDelayTime: number;
  transferScheduleLink: boolean;
  transferScheduleVer3: ITransferScheduleVer3;
  deviceTransferDelayDestinationList: UUID[];
  transferDelayRetransferDestination: UUID;
  deviceTransferAbsentDestinationList: UUID[];
  transferAbsentRetransferDestination: UUID;
}

export interface ITransferScheduleVer3 {
  sunday: ITransferSchedule[];
  monday: ITransferSchedule[];
  tuesday: ITransferSchedule[];
  wednesday: ITransferSchedule[];
  thursday: ITransferSchedule[];
  friday: ITransferSchedule[];
  saturday: ITransferSchedule[];
  individual: ITransferScheduleIndividual[];
}

export interface ITransferSchedule {
  transferNumber: number;
  startTime: string;
  endTime: string;
  destination1PublicId: UUID;
  destination2PublicId: UUID;
  destination3PublicId: UUID;
  destination4PublicId: UUID;
  destination5PublicId: UUID;
  destination6PublicId: UUID;
  destination7PublicId: UUID;
  destination8PublicId: UUID;
  destination9PublicId: UUID;
  destination10PublicId: UUID;
  retransferDestinationPublicId: UUID;
}

export interface ITransferScheduleIndividual extends ITransferSchedule {
  scheduleDate: string;
}

export interface IContactOutput {
  sound: UUID;
  timer: number;
  control: IContactOutputControl;
  publicId: UUID;
  soundEnd: UUID;
  unlockKey: string;
  outputName: string;
  soundStart: UUID;
  optionRelay: boolean;
  callSchedule: IContactOutputCallScheduleContainer;
  outputNumber: number;
  devicePublicId: UUID;
  outputFunction: number;
}

export interface IContactOutputControl {
  monitoredNormal: boolean;
  duringCallNormal: boolean;
  duringCallUrgent: boolean;
  duringCallPriority: boolean;
  incomingCallNormal: boolean;
  incomingCallUrgent: boolean;
  incomingPageNormal: boolean;
  incomingPageUrgent: boolean;
  outgoingCallNormal: boolean;
  outgoingCallUrgent: boolean;
  outgoingPageNormal: boolean;
  outgoingPageUrgent: boolean;
  incomingCallPriority: boolean;
  outgoingCallPriority: boolean;
}

export interface IContactOutputCallScheduleContainer {
  friday: IContactOutputCallSchedule[];
  monday: IContactOutputCallSchedule[];
  sunday: IContactOutputCallSchedule[];
  tuesday: IContactOutputCallSchedule[];
  saturday: IContactOutputCallSchedule[];
  thursday: IContactOutputCallSchedule[];
  wednesday: IContactOutputCallSchedule[];
  individual: IContactOutputIndividualCallSchedule[];
}

export interface IContactOutputCallSchedule {
  scheduleNumber: number;
  startTime: string;
  endTime: string;
}

export interface IContactOutputIndividualCallSchedule extends IContactOutputCallSchedule {
  scheduleDate: string;
}

export interface IUpdateSelectedDeviceSettings {
  deviceSettings: IDeviceDeviceSettings;
  publicId: string;
}

export interface DeviceList {
  [key: string]: IDevice;
}

export interface DeviceDefaults {
  [key: number]: IDevice;
}

interface DevicesState {
  DeviceList: DeviceList;
  DeviceListByType: IDeviceTypes;
  SelectedDevice: string | null;
  getDevicesNetworkSettingsStatus: 'loading' | 'succeeded' | 'failed' | 'initial';
}

export const deviceDefaults: DeviceDefaults = {
  4: defaultIXMV7
};

export const deviceTypeMapping: { [key: number]: string } = {
  4: 'MasterStation',
  5: 'SubMasterStation',
  6: 'NetworkAdapter',
  8: 'VideoDoorStation',
  9: 'AudioDoorStation',
  10: 'AudioDoorStation',
  11: 'VideoDoorStation',
  14: 'TenantStation',
  15: 'EntranceStation',
  16: 'GuardStation',
  17: 'LiftControl',
  18: 'NetworkAdapter',
  20: 'VideoDoorStation'
};

const initialState: DevicesState = {
  DeviceList: {},
  DeviceListByType: {},
  SelectedDevice: null,
  getDevicesNetworkSettingsStatus: 'initial'
};

function getUpdatedDeviceGroups(devices: IDevice[]): IDeviceTypes {
  const updatedDeviceGroups: IDeviceTypes = {
    MasterStation: [],
    SubMasterStation: [],
    EntranceStation: [],
    TenantStation: [],
    GuardStation: [],
    VideoDoorStation: [],
    AudioDoorStation: [],
    EmergencyStation: [],
    NetworkAdapter: [],
    LiftControl: []
  };

  devices.forEach((device: IDevice) => {
    const { deviceType } = device.basicInfo;
    const group = deviceTypeMapping[deviceType];
    if (group) {
      try {
        updatedDeviceGroups[group].push(device.publicId);
      } catch (e) {
        console.log(`Device type ${deviceType} not found in deviceTypeMapping`);
      }
    }
  });

  return updatedDeviceGroups;
}

const devicesSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    setSelectedDevice: (state, action: PayloadAction<string>) => {
      state.SelectedDevice = action.payload;
    },
    setDevicesBasicInfoList: (state, action) => {
      state.DeviceList = action.payload;
      state.DeviceListByType = getUpdatedDeviceGroups(action.payload);
    },
    setDeviceNetworkSettings: (state, action) => {
      const publicId = action.payload.publicId;
      state.DeviceList[publicId].networkSettings = action.payload.networkSettings;
    },
    updateSelectedDevice: (state, action) => {
      console.log('updateSelectedDevice', action.payload.device.publicId, action.payload.device);
      state.DeviceList[action.payload.device.publicId] = action.payload.device;
    },
    setDeviceBasicInfo: (state, action) => {
      console.log('setDeviceBasicInfo', action.payload.publicId, action.payload.basicInfo);
      state.DeviceList[action.payload.publicId].basicInfo = action.payload.basicInfo;
    },
    setDeviceCallSettings: (state, action) => {
      console.log('setDeviceCallSettings', action.payload.device.publicId, action.payload.device.callSettingsß);
      state.DeviceList[action.payload.device.publicId].callSettings = action.payload.device.callSettings;
    },
    setDeviceDeviceSettings: (state, action) => {
      state.DeviceList[action.payload.device.publicId].deviceSettings = action.payload.device.deviceSettings;
    },
    setDeviceUnitPublicId: (state, action) => {
      state.DeviceList[action.payload.devicePublicId].unitPublicId = action.payload.unitPublicId;
    },
    clearDevices: (state) => {
      state.DeviceList = {};
      state.DeviceListByType = {};
    },
    clearSelectedDevice: (state) => {
      state.SelectedDevice = null;
    },
    removeDevice: (state, action) => {
      delete state.DeviceList[action.payload];
      state.DeviceListByType = getUpdatedDeviceGroups(Object.values(state.DeviceList));
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(aiphoneCloudApi.endpoints.getDeviceListWithSitePublicId.matchFulfilled, (state, { payload }) => {
      if (payload.deviceList) {
        state.DeviceList = payload.deviceList;
        state.DeviceListByType = getUpdatedDeviceGroups(Object.values(state.DeviceList));
      } else {
        state.DeviceList = {};
        state.DeviceListByType = {};
      }
    });
  }
});

export const selectDevices = (state: RootState) => state.devices.DeviceList;

export const selectDeviceTypes = (state: RootState) => state.devices.DeviceListByType;

export const getSelectedDevice = (state: RootState) => {
  return state.devices.DeviceList[state.devices.SelectedDevice as string];
};

export const getDeviceWithPublicId = (state: RootState, publicId: string) => {
  return state.devices.DeviceList[publicId];
};

export const {
  setSelectedDevice,
  setDevicesBasicInfoList,
  setDeviceNetworkSettings,
  setDeviceBasicInfo,
  setDeviceCallSettings,
  setDeviceDeviceSettings,
  setDeviceUnitPublicId,
  clearDevices,
  clearSelectedDevice,
  updateSelectedDevice,
  removeDevice
} = devicesSlice.actions;

export const devicesReducer = devicesSlice.reducer;
