import { Backdrop, Box, CircularProgress, Container, Pagination, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetUnitListWithSitePublicIdQuery } from 'services/aiphoneCloud';
import { RootState } from 'store';
import { IDevice } from 'store/slices/devicesSlice';
import UnitCard from './UnitCard';

interface Unit {
  unitPublicId: string;
  unitNumber: string;
  unitName: string;
  unitType: number;
  devices: IDevice[];
}

const Units = () => {
  const [units, setUnits] = useState<Unit[]>([]);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [, setSelectedUnit] = useState<string>('');
  const [, setIsAddDevicesOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;
  const site = useSelector((state: RootState) => state.site);
  const deviceList = useSelector((state: RootState) => state.devices.DeviceList);
  const buildingList = useSelector((state: RootState) => state.buildings.BuildingList);
  const { data, refetch } = useGetUnitListWithSitePublicIdQuery(
    { qty: '-1', page: 0, sitePublicId: site.siteInfo.publicId },
    {
      skip: !site.siteInfo.publicId
    }
  );

  useEffect(() => {
    if (data) {
      const units: Unit[] = [];

      Object.entries(data.unitList).forEach(([key, value]) => {
        const devicesArray: IDevice[] = [];
        value.devicePublicIds?.forEach((devicePublicId: string) => {
          deviceList[devicePublicId] && devicesArray.push(deviceList[devicePublicId]);
        });
        units.push({
          unitPublicId: data.unitList[key].publicId,
          unitNumber: data.unitList[key].unitNumber,
          unitName: data.unitList[key].unitName,
          unitType: data.unitList[key].unitType,
          devices: devicesArray
        });
      });
      setUnits(units);
    } else {
      setUnits([]);
    }
  }, [data]);

  useEffect(() => {
    if (shouldRefetch) {
      refetch();
      setShouldRefetch(false);
    }
  }, [shouldRefetch]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const renderUnitsList = () => {
    if (units.length === 0) {
      return (
        <Box sx={{ mt: 5 }}>
          <Typography variant="h6" align="center">
            No units found
          </Typography>
        </Box>
      );
    }

    return (
      <>
        <Box sx={styles.tabs}>
          <Tabs value={tabValue} onChange={handleTabChange}>
            {Object.entries(buildingList).map(([buildingId, building]) => {
              return <Tab key={buildingId} label={building.buildingName} />;
            })}
          </Tabs>
        </Box>
        {units.slice((page - 1) * itemsPerPage, page * itemsPerPage).map((unit) => {
          return (
            <UnitCard
              key={unit.unitNumber}
              unit={unit}
              units={units}
              setUnits={setUnits}
              setIsAddDevicesOpen={setIsAddDevicesOpen}
              setSelectedUnit={setSelectedUnit}
              setShouldRefetch={setShouldRefetch}
              siteId={site.siteInfo.publicId}
            />
          );
        })}
        <Pagination
          count={Math.ceil(units.length / itemsPerPage)}
          page={page}
          onChange={(event, value) => setPage(value)}
          sx={{ mt: 2, mb: 2 }}
        />
      </>
    );
  };

  return (
    <Container maxWidth="lg">
      {data ? (
        <>{renderUnitsList()}</>
      ) : (
        <Backdrop open={true} sx={styles.backdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </Container>
  );
};

const styles = {
  backdrop: {
    color: '#fff',
    zIndex: 'theme.zIndex.drawer'
  },
  tabs: {
    mb: 2
  }
};

export default Units;
