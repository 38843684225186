import { Box, Typography, Button, Container, Tooltip, Backdrop, CircularProgress, Tabs, Tab } from '@mui/material';
import { useEffect, useState } from 'react';
import { DeviceCategories } from 'features/RemoteManagement/Types';
import AddDeviceManuallyDataGrid from './Datagrids/AddDeviceManuallyDataGrid';
import StationSearchDataGrid from './Datagrids/StationSearchDataGrid';
import {
  addSpaceBeforeCapitalLetters,
  getDeviceModelFromString,
  getDeviceTypeIntFromSearch
} from 'shared/utils/helperFunctions';
import { SearchDevice } from 'features/RemoteManagement/Types';
import { GridValidRowModel } from '@mui/x-data-grid';
import { createDevices } from 'shared/api/Aws/awsApi';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import {
  useBatchUpdateDevicesMutation,
  useCreateUnitsWizardMutation,
  useGetDeviceListWithSitePublicIdQuery,
  useLazyGetUnitListWithBuildingPublicIdQuery
} from 'services/aiphoneCloud';
import AddDevicesWizard from 'features/RemoteManagement/SiteDashboard/Devices/wizard/AddDevicesWizard';
import AddDevicesTabContent from './components/AddDevicesTabContent';
import useConfigureWizardCommUnits from 'features/RemoteManagement/Hooks/useConfigureWizardCommUnits';
interface Props {
  isRegistered: boolean;
  handlePreviousStep: () => void;
  handleNextStep: () => void;
}

interface ICreateDevicesResponse {
  macAddress: string;
  publicId: string;
}

function AddDevices({ isRegistered, handlePreviousStep, handleNextStep }: Props) {
  const [isAddingManually, setIsAddingManually] = useState(false);
  const [restrictedMacAddresses, setRestrictedMacAddresses] = useState<string[]>(['00:00:00:00:00:00']);
  const [searchSelectedDevices, setSearchSelectedDevices] = useState<SearchDevice[]>([]);
  const [manualSelectedDevices, setManualSelectedDevices] = useState<GridValidRowModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldFetchDevices, setShouldFetchDevices] = useState(false);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [isAddDevicesWizardOpen, setIsAddDevicesWizardOpen] = useState(false);
  const site = useSelector((state: RootState) => state.site);
  const devices = useSelector((state: RootState) => state.devices);
  const buildingPublicId = useSelector((state: RootState) => Object.keys(state.buildings.BuildingList)[0]);
  const { generateUnits } = useConfigureWizardCommUnits();
  const [createUnits] = useCreateUnitsWizardMutation();
  const [batchUpdateDevices] = useBatchUpdateDevicesMutation();
  const [fetchUnits] = useLazyGetUnitListWithBuildingPublicIdQuery();
  const { refetch } = useGetDeviceListWithSitePublicIdQuery({
    qty: '-1',
    page: 0,
    sitePublicId: site.siteInfo.publicId
  });
  const deviceListByType = devices.DeviceListByType;

  const strings = {
    manual: {
      title: 'Manual Device Entry',
      body1:
        'The manual device entry will allow you to add devices by entering the MAC address and IP address of the device. Adding devices manually is useful if the devices are not connected to the same network as the IXGW-GW gateway or cannot be found by using station search.'
    },
    search: {
      title: 'Gateway Station Search',
      body1:
        'The gateway station search will find all intercoms connected to the same network as your IXGW-GW gateway. Please ensure that the stations are connected to the same network as the gateway and that the stations are powered on.'
    },
    existingDevices: {
      title: 'Devices',
      body1: 'Please confirm device list below and continue to the next step or add additional devices.'
    },
    backButtonText: 'Back',
    addManually: 'Add Devices Manually',
    addWithSearch: 'Add Devices with Station Search',
    continueButtonText: 'Continue',
    stationSearch: 'Searching for Stations...'
  };

  useEffect(() => {
    if (shouldFetchDevices) {
      refetch();
      // handleNextStep();
    }
  }, [shouldFetchDevices, refetch]);

  const addDevicesToSite = async () => {
    const devicesToAdd = [...searchSelectedDevices, ...manualSelectedDevices]
      .map((device) => {
        const deviceType = getDeviceTypeIntFromSearch(device.device_type);
        const deviceModel = getDeviceModelFromString(device.model_number);
        const deviceMacAddress = device.mac_addr;
        const deviceStationNumber = device.station_number || '';
        const deviceStationName = device.station_name || '';
        const deviceFirmwareVersion = device.fw_ver || '';
        if (deviceType) {
          return {
            deviceType: deviceType,
            deviceModel: deviceModel,
            macAddress: deviceMacAddress,
            stationNumber: deviceStationNumber,
            stationName: deviceStationName,
            firmwareVersion: deviceFirmwareVersion
          };
        }
        return null;
      })
      .filter((device) => device !== null);

    const params = {
      sitePublicId: site.siteInfo.publicId,
      devices: devicesToAdd
    };
    try {
      setIsLoading(true);

      // Create the newly added devices in the DB
      const response = (await createDevices(params)) as { data: ICreateDevicesResponse[] };

      //If the site is a commercial system then create the units for the devices that were created
      if (site.siteInfo.typeId === 1) {
        //Generate the units for the devices that were created
        const units = generateUnits(devicesToAdd, response?.data);
        await createUnits(units);

        //Update the station number and station name for the devices that were created
        const devicesToUpdate = units.newUnitsArray.flatMap((unit) =>
          unit.devicePublicIds.map((publicId, index) => ({
            sitePublicId: site.siteInfo.publicId,
            publicId,
            basicInfo: {
              devicePublicId: publicId,
              stationNumber: `01${unit.unitNumber}${index + 1}`,
              stationName: `Station 01${unit.unitNumber}${index + 1}`
            }
          }))
        );
        await batchUpdateDevices({ devices: devicesToUpdate });

        //Update Redux store with the newly created units by fetching
        await fetchUnits(buildingPublicId);
      }

      //Get the updated device list from the database
      setShouldFetchDevices(true);

      setIsLoading(false);
    } catch (error) {
      console.error('Error configuring system:', error);
      // TODO: Add error handling
    }
  };

  const renderAddDeviceEntry = () => {
    return (
      <Box sx={styles.centerContent}>
        <Typography variant="h4" sx={styles.title}>
          {!Object.entries(devices.DeviceList).some(([, device]) => {
            return device.basicInfo.deviceType !== 18;
          })
            ? isAddingManually || !isRegistered
              ? strings.manual.title
              : strings.search.title
            : strings.existingDevices.title}
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          {!Object.entries(devices.DeviceList).some(([, device]) => {
            return device.basicInfo.deviceType !== 18;
          })
            ? isAddingManually || !isRegistered
              ? strings.manual.body1
              : strings.search.body1
            : strings.existingDevices.body1}
        </Typography>
      </Box>
    );
  };

  const renderAddDeviceManuallyDataGrid = () => {
    return (
      <AddDeviceManuallyDataGrid
        manualSelectedDevices={manualSelectedDevices}
        setManualSelectedDevices={(devices: GridValidRowModel[]) => setManualSelectedDevices(devices)}
        restrictedMacAddresses={restrictedMacAddresses}
        setRestrictedMacAddresses={setRestrictedMacAddresses}
      />
    );
  };

  const renderStationSearchDataGrid = () => {
    return (
      <StationSearchDataGrid
        searchSelectedDevices={searchSelectedDevices}
        setSearchSelectedDevices={(devices: GridValidRowModel[]) => setSearchSelectedDevices(devices)}
        setIsAddingManually={setIsAddingManually}
        restrictedMacAddresses={restrictedMacAddresses}
        setRestrictedMacAddresses={setRestrictedMacAddresses}
      />
    );
  };

  const renderExistingDeviceList = () => {
    const handleTabChange = (e: any, tabIndex: number) => {
      setCurrentTabIndex(tabIndex);
    };

    let assignedIndex = 0;

    return (
      <>
        <Box sx={styles.buttonContainer}>
          <Button variant="contained" onClick={() => setIsAddDevicesWizardOpen(true)}>
            Add Devices
          </Button>
        </Box>
        <Tabs value={currentTabIndex} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
          {Object.keys(deviceListByType).map((deviceType, index) => {
            const deviceCount = deviceListByType[deviceType].length;
            if (deviceCount === 0) {
              return null;
            }
            DeviceCategories[index].assignedIndex = assignedIndex;
            assignedIndex++;
            return (
              <Tab
                key={index}
                label={deviceCount > 0 ? `${addSpaceBeforeCapitalLetters(deviceType)}s (${deviceCount})` : deviceType}
              />
            );
          })}
        </Tabs>
        {DeviceCategories.map((category) => {
          const deviceCount = deviceListByType[category.deviceGroup]
            ? deviceListByType[category.deviceGroup].length
            : 0;

          if (currentTabIndex === category.assignedIndex && deviceCount !== 0) {
            return <AddDevicesTabContent category={category.deviceGroup} key={category.deviceGroup} />;
          }
        })}
        <AddDevicesWizard
          isAddDevicesWizardOpen={isAddDevicesWizardOpen}
          setIsAddDevicesWizardOpen={setIsAddDevicesWizardOpen}
          sitePublicId={site.siteInfo.publicId}
          setShouldFetchDevices={setShouldFetchDevices}
          restrictedMacAddresses={restrictedMacAddresses}
          setRestrictedMacAddresses={setRestrictedMacAddresses}
        />
      </>
    );
  };
  // TODO: RE-THINK OF THIS LOGIC
  const renderDualButtonContainer = () => {
    // If using the gateway search, confirm that the gateway is added to the site
    const isGatewayAddedToSite = Object.keys(devices.DeviceList).find(
      (deviceID) => deviceID === site.siteInfo.registeredGatewayPublicId
    );
    // check devices are added in addition to the gateway
    const deviceCount = Object.keys(devices.DeviceList).filter(
      (deviceID) => deviceID !== site.siteInfo.registeredGatewayPublicId
    );

    const enableAddDevicesButton =
      (Object.keys(devices.DeviceList).length === 0 || isGatewayAddedToSite) && deviceCount.length === 0;

    return enableAddDevicesButton ? (
      <Box sx={styles.dualButtonContainer}>
        <Button variant="contained" color="primary" onClick={handlePreviousStep}>
          {strings.backButtonText}
        </Button>
        <Tooltip title={!isRegistered ? 'A registered IXGW-GW is required to use the station search feature' : null}>
          <span>
            <Button
              color="primary"
              onClick={() => {
                setIsAddingManually(!isAddingManually);
              }}
              disabled={!isRegistered}
            >
              {isAddingManually || !isRegistered ? strings.addWithSearch : strings.addManually}
            </Button>
          </span>
        </Tooltip>

        <Tooltip
          title={
            searchSelectedDevices.length === 0 && manualSelectedDevices.length === 0
              ? 'A device must be added to the system to continue'
              : ''
          }
        >
          <span>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={(searchSelectedDevices.length === 0 && manualSelectedDevices.length === 0) || isLoading}
              onClick={addDevicesToSite}
            >
              {strings.continueButtonText}
            </Button>
          </span>
        </Tooltip>
      </Box>
    ) : (
      <Box sx={styles.dualButtonContainer}>
        <Button variant="contained" color="primary" onClick={handlePreviousStep}>
          {strings.backButtonText}
        </Button>
        <Button variant="contained" color="primary" onClick={handleNextStep}>
          {strings.continueButtonText}
        </Button>
      </Box>
    );
  };

  return !Object.entries(devices.DeviceList).some(([, device]) => {
    return device.basicInfo.deviceType !== 18;
  }) ? (
    <Container maxWidth="md">
      <Box>
        {renderAddDeviceEntry()}
        {isAddingManually || !isRegistered ? renderAddDeviceManuallyDataGrid() : renderStationSearchDataGrid()}
        {renderDualButtonContainer()}
        {isLoading && (
          <Backdrop open={true} sx={styles.backdrop}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </Box>
    </Container>
  ) : (
    <Container maxWidth="lg">
      <Box>
        {renderAddDeviceEntry()}
        {renderExistingDeviceList()}
        {renderDualButtonContainer()}
      </Box>
    </Container>
  );
}

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  dualButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    marginTop: '1rem'
  },
  backdrop: {
    color: '#fff',
    zIndex: 'theme.zIndex.drawer'
  },
  title: {
    mb: 3,
    color: 'primary.main',
    fontWeight: 'bold'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: 2
  },
  w100: {
    width: '100%'
  }
};

export default AddDevices;
