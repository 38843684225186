/**
 * Input Output has 4 tabs (Door Release, Option Input, Access Code, Relay Output)
 * user can navigate through each tab setting for each device
 */
import TabsContainer from 'shared/components/TabsContainer';
import { DeviceTabType } from 'features/RemoteManagement/DeviceDashboard/DeviceTabs';
import { useSelector } from 'react-redux';
import { DeviceCategory } from 'features/RemoteManagement/Types';
import { Box } from '@mui/material';
import { getSelectedDevice } from 'store/slices/devicesSlice';
import { getDeviceCategoryFromDeviceTypeModel } from 'shared/utils/helperFunctions';

const InputOutput = () => {
  const selectedDevice = useSelector(getSelectedDevice)?.basicInfo;
  if (!selectedDevice) return <Box>Error Loading Device</Box>;
  const deviceCategory = getDeviceCategoryFromDeviceTypeModel(selectedDevice?.deviceType, selectedDevice?.deviceModel);

  let inputOutputTabs: DeviceTabType[] = [];

  switch (deviceCategory) {
    case DeviceCategory.VideoDoorStation:
    case DeviceCategory.AudioDoorStation:
    case DeviceCategory.EmergencyStation:
    case DeviceCategory.MasterStation:
    case DeviceCategory.SubMasterStation:
      inputOutputTabs = [
        DeviceTabType.InputOutput_OptionInput,
        DeviceTabType.InputOutput_RelayOutput,
        DeviceTabType.InputOutput_DoorRelease
      ];
      break;
    case DeviceCategory.EntranceStation:
    case DeviceCategory.GuardStation:
    case DeviceCategory.TenantStation:
      inputOutputTabs = [
        DeviceTabType.InputOutput_OptionInput,
        DeviceTabType.InputOutput_RelayOutput,
        DeviceTabType.InputOutput_AccessCode,
        DeviceTabType.InputOutput_DoorRelease
      ];
      break;
    case DeviceCategory.NetworkAdapter:
      inputOutputTabs = [
        DeviceTabType.InputOutput_ContactInput,
        DeviceTabType.InputOutput_RelayOutput,
        DeviceTabType.InputOutput_DoorRelease
      ];
      break;
  }

  return <TabsContainer tabTypes={inputOutputTabs} />;
};

export default InputOutput;
