import { Box, InputAdornment } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedDevice, updateSelectedDevice } from 'store/slices/devicesSlice';
import * as Yup from 'yup';
import { Form, Formik, Field } from 'formik';
import { Button, TextField } from '@mui/material';
import { Grid } from '@mui/material';
import { getString } from 'shared/utils/LocalizationUtils';
import { useUpdateDeviceMutation } from 'services/aiphoneCloud';
import containerStyle from 'shared/styles/advancedSettingContainerStyle';
import { useState } from 'react';
import SnackbarAlert from 'shared/components/SnackbarAlert';
import StringUtils from 'shared/utils/StringUtils';

/* SIP Settings tab stub */
export const SIPSettingsLabel = () => {
  return <span>SIP Settings</span>;
};

const SIPSettings = () => {
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [updateDevice, { isLoading: isUpdating }] = useUpdateDeviceMutation();
  const fieldErrorRequired = getString('Field_Error_Required');
  const fieldErrorNotValid = getString('Field_Error_NotValid');
  const sipTosValueTitle = getString('AdvancedSettings_SipTosValue_Title');
  const sipTosValueDesc = getString('AdvancedSettings_SipTosValue_Desc');
  const buttonSubmit = getString('Button_Submit');
  const buttonReset = getString('Button_Reset');
  const errorUpdateDevice = getString('AdvancedSettings_Error_UpdateDevice');
  const successUpdateDevice = getString('AdvancedSettings_Success_UpdateDevice');
  const selectedDevice = useSelector(getSelectedDevice);
  const [formikSchema, setFormikSchema] = useState<Yup.ObjectSchema<any> | null>(null);
  const tosRegexValidate = /^[0-9a-fA-F]{2}$/;
  const tosRegexOnChange = /^[0-9a-fA-F]{0,2}$/;
  const formDevice = {
    networkSettings: {
      sipTosValue: selectedDevice.networkSettings?.sipTosValue.replace('0x', '')
    }
  };

  const getValidationSchema = (currentValues: any) => {
    const networkSchema: any = {};
    let formattedFieldRequired: string;

    if (formDevice.networkSettings.sipTosValue !== undefined && formDevice.networkSettings.sipTosValue !== null) {
      formattedFieldRequired = StringUtils.format(fieldErrorRequired, sipTosValueTitle);
      networkSchema.sipTosValue = Yup.string()
        .required(formattedFieldRequired)
        .test('sipTosValue', fieldErrorNotValid, (value: string) => {
          return tosRegexValidate.test(value);
        });
    }

    return Yup.object({
      networkSettings: Yup.object().shape(networkSchema)
    });
  };

  const onSubmit = async (values: any, actions: any) => {
    const newVals = JSON.parse(JSON.stringify(values));
    newVals.networkSettings.sipTosValue = `0x${newVals.networkSettings.sipTosValue.toUpperCase()}`;
    const params = {
      device: {
        publicId: selectedDevice.publicId,
        ...newVals
      }
    };

    const newDevice = JSON.parse(JSON.stringify(selectedDevice));

    newDevice.networkSettings = {
      ...selectedDevice.networkSettings,
      ...newVals.networkSettings
    };

    updateDevice(params)
      .then((response) => {
        if ('error' in response) {
          throw response.error;
        }

        dispatch(updateSelectedDevice({ device: newDevice }));
        setShowAlert(true);
        actions.resetForm({
          values: values
        });
      })
      .catch(() => {
        setErrorMessage(errorUpdateDevice);
      });
  };

  if (!formikSchema) {
    setFormikSchema(getValidationSchema(formDevice));
  }

  return (
    <Box sx={containerStyle.settingsPageContainer}>
      <SnackbarAlert
        type="error"
        time={10000}
        text={`${errorMessage}`}
        isOpen={!!errorMessage}
        onClose={() => setErrorMessage(null)}
      />
      <SnackbarAlert
        type="success"
        time={3000}
        text={successUpdateDevice}
        isOpen={showAlert}
        onClose={() => setShowAlert(false)}
      />
      <Formik initialValues={formDevice} onSubmit={onSubmit} validationSchema={formikSchema}>
        {({ dirty, touched, errors, isSubmitting, handleChange }) => (
          <Form style={containerStyle.form}>
            <Box sx={containerStyle.gridContainer}>
              {formDevice.networkSettings.sipTosValue !== null &&
              formDevice.networkSettings.sipTosValue !== undefined ? (
                <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                  <Grid item xs={5} lg={7}>
                    <Box sx={containerStyle.itemTitle}>{sipTosValueTitle}</Box>
                    <Box sx={containerStyle.itemDesc}>{sipTosValueDesc}</Box>
                  </Grid>
                  <Grid item xs={5} lg={3}>
                    <Box sx={containerStyle.fieldContainer}>
                      <Field
                        as={TextField}
                        type="text"
                        label={sipTosValueTitle}
                        name="networkSettings.sipTosValue"
                        style={containerStyle.textField}
                        helperText={touched.networkSettings?.sipTosValue && errors.networkSettings?.sipTosValue}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start" sx={containerStyle.tosAdornment}>
                              0x
                            </InputAdornment>
                          )
                        }}
                        onChange={(e: any) => {
                          // Only allow valid values
                          if (tosRegexOnChange.test(e.target.value)) {
                            handleChange(e);
                          }
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              ) : null}
            </Box>
            <Box sx={containerStyle.submitContainer}>
              <Button
                type="reset"
                variant="contained"
                color="primary"
                disabled={!dirty || isSubmitting || isUpdating}
                style={containerStyle.submitBarButton}
              >
                {buttonReset}
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!dirty || isSubmitting || isUpdating}
                style={containerStyle.submitBarButton}
              >
                {buttonSubmit}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default SIPSettings;
