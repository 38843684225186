/* Display Mode tab stub */
import { Tooltip } from '@mui/material';

export const DisplayModeLabel = () => {
  return (
    <Tooltip title="Display Mode, Misc. setting for Sensor">
      <span>Display Mode</span>
    </Tooltip>
  );
};

const DisplayMode = () => {
  return <span>Display Mode</span>;
};

export default DisplayMode;
