/* Delay Transfer tab stub */
export const DelayTransferLabel = () => {
  return <span>Delay Transfer</span>;
};

const DelayTransfer = () => {
  return <span>Delay Transfer</span>;
};

export default DelayTransfer;
