import { Box, Card, CardHeader, CardContent, Container, Grid, Link } from '@mui/material';
import { COOKIE_URL, PRIVACY_URL, TERMS_URL } from '../Auth/constants';
import { Policy as PolicyIcon, Cookie as CookieIcon, Assignment as AssignmentIcon } from '@mui/icons-material';

const Legal = () => {
  return (
    <Container maxWidth="lg">
      <Card>
        <CardContent>
          <Box sx={styles.logoWrapper} component="img" src="/AiphoneCloud-Logo_Blue.png" alt="Aiphone Cloud Logo" />
        </CardContent>
      </Card>
      <div style={{ height: 20 }} />

      {/*  */}
      <Card sx={styles.cardContainer}>
        <Grid sx={styles.cardHeaderContent}>
          <CardHeader title="Legal Documents" />
        </Grid>
        <CardContent>
          <Grid sx={styles.linkContainer}>
            <Box component={Link} href={COOKIE_URL} sx={styles.link} target="_blank">
              <CookieIcon /> Cookie Policy
            </Box>

            <Box component={Link} href={PRIVACY_URL} sx={styles.link} target="_blank">
              <PolicyIcon /> Privacy Policy
            </Box>

            <Box component={Link} href={TERMS_URL} sx={styles.link} target="_blank">
              <AssignmentIcon /> Terms & Conditions
            </Box>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
};

const styles = {
  logoWrapper: {
    padding: 3,
    display: 'block',
    maxWidth: '60%',
    margin: '0 auto'
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px'
  },
  link: {
    margin: '10px',
    textDecoration: 'none',
    color: '#0071CE',
    fontWeight: 'bold',
    fontSize: '1.5rem'
  },
  cardHeaderContent: {
    padding: '40px',
    width: '30%',
    backgroundColor: 'primary.dark',
    color: '#fff'
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    marginY: '20px'
  }
};

export default Legal;
