import React, { useState } from 'react';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';
import { Field } from 'formik';
import { Slider, Stack } from '@mui/material';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';

interface CustomSliderProps {
  label: string;
  name: string;
  min: number;
  max: number;
  step: number;
  onChange: (value: number) => void;
  error?: string;
  spacing?: number;
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  sx?: object;
  styles?: object;
  alignItems?: 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'baseline';
  helperText: boolean;
  defaultValue?: number;
}

export const CustomSlider: React.FC<CustomSliderProps> = ({
  label,
  name,
  min,
  max,
  step,
  onChange,
  spacing = 2,
  direction = 'row',
  sx = { mb: 1 },
  alignItems = 'center',
  styles,
  helperText,
  defaultValue
}) => {
  const [value, setValue] = useState(defaultValue);

  return (
    <Stack spacing={spacing} direction={direction} sx={sx} alignItems={alignItems} style={styles}>
      <VolumeDownIcon />
      <Field
        as={Slider}
        label={label}
        name={name}
        helperText={helperText}
        value={value}
        defaultValue={defaultValue}
        valueLabelDisplay="auto"
        step={step}
        min={min}
        max={max}
        onChange={(event: React.ChangeEvent<HTMLInputElement>, value: number) => {
          setValue(value);
          onChange(value);
        }}
      />
      <VolumeUpIcon />
    </Stack>
  );
};
