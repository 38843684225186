import { Button, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SnackbarAlert from 'shared/components/SnackbarAlert';
import { adminResetPasswordChallenge } from 'shared/api/Aws/authApi';
import { isApiError } from 'shared/api/ApiError';
import { CreatePassword } from './CreatePassword';

const AdminResetChallenge = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const [, setValidation] = useState({});
  const [passwordVerified, setPasswordVerified] = useState(false);
  const username = searchParams.get('id');
  const code = searchParams.get('vc');
  const navigate = useNavigate();

  const initialValues = {
    password: '',
    confirmPassword: ''
  };

  const handleResetPassword = async (values: any) => {
    setIsLoading(true);
    const resetPasswordPayload = {
      userData: {
        verificationCode: code,
        username: username,
        password: values.password
      }
    };
    await adminResetPasswordChallenge(resetPasswordPayload)
      .then((response) => {
        console.log(response);
        setSuccessMessage('Password successfully changed, redirecting to login page.');
        setIsLoading(false);
        setTimeout(() => {
          window.location.href = '/auth/login';
        }, 2000);
      })
      .catch((error) => {
        if (isApiError(error)) {
          if (`${error}` === 'ApiError: Attempt limit exceeded, please try after some time.') {
            setErrorMessage('Too many password reset attempts. Please try again later.');
          } else {
            setErrorMessage(
              'An error occurred while resetting your password. Please return to Login and use the Forgot Password link to reset the password.'
            );
          }
        } else {
          setErrorMessage('An error occurred while resetting your password. Please try again.');
        }
        setIsLoading(false);
      });
  };

  return (
    <>
      <SnackbarAlert
        type="error"
        time={10000}
        text={`${errorMessage}`}
        isOpen={!!errorMessage}
        onClose={() => setErrorMessage('')}
      />

      <SnackbarAlert
        type="success"
        time={10000}
        text={`${successMessage}`}
        isOpen={!!successMessage}
        onClose={() => setSuccessMessage('')}
      />

      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values) => {
          handleResetPassword(values);
        }}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <CreatePassword setValidation={setValidation} setPasswordVerified={setPasswordVerified} />
            <LoadingButton
              type="submit"
              sx={styles.submitButton}
              loading={isLoading}
              disabled={!passwordVerified}
              variant="contained"
            >
              Change Password
            </LoadingButton>
            <Button sx={styles.returnToLogin} onClick={() => navigate('/auth/login')}>
              Return to login
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

const styles = {
  submitButton: {
    fontSize: '1.5rem',
    fontWeight: '700',
    borderRadius: '0.25 rem',
    width: '100%',
    marginTop: '2rem',
    marginBottom: '1rem'
  },
  inputField: {
    marginBottom: 1,
    width: '100%',
    '& .MuiInputBase-input': {
      backgroundColor: '#ffffff'
    },
    '&.MuiFormHelperText-root': {
      color: 'red'
    },
    '& .MuiInputLabel-root': {
      color: 'red'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey'
      },
      '&:hover fieldset': {
        borderColor: '#003366'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#0071ce'
      }
    },
    '.MuiFormHelperText-root': {
      color: '#d32f2f'
    }
  },
  returnToLogin: {
    color: '#0071CE',
    fontFamily: "'Roboto Condensed', sans-serif",
    fontWeight: 'bold',
    float: 'right'
  }
};

export default AdminResetChallenge;
