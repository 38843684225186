import {
  Alert,
  AlertColor,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography
} from '@mui/material';
import { useGetUserAttrQuery, useResendTempPasswordMutation, useResetPasswordMutation } from 'services/aiphoneCloud';
import CancelIcon from '@mui/icons-material/Cancel';
import { LoadingButton } from '@mui/lab';

interface IResetPasswordDialog {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  selectedUser: string | undefined;
  setErrorMessage: (message: string) => void;
  setIsError: (isError: boolean) => void;
  setSuccessMessage: (message: string) => void;
  setIsSuccess: (isSuccess: boolean) => void;
  resetUserType: string;
}

const ResetPasswordDialog = ({
  isOpen,
  setIsOpen,
  selectedUser,
  setErrorMessage,
  setIsError,
  setSuccessMessage,
  setIsSuccess
}: IResetPasswordDialog) => {
  const {
    data: userData,
    isLoading: isUserDataLoading,
    isFetching: isFetchingUserData
  } = useGetUserAttrQuery(selectedUser || '', {
    skip: !selectedUser
  });
  const [resetPassword, { isLoading: isResetPasswordLoading }] = useResetPasswordMutation();
  const [resendTempPassword, { isLoading: isResendTempPasswordLoading }] = useResendTempPasswordMutation();

  const handlePasswordReset = () => {
    if (userData?.UserStatus === 'FORCE_CHANGE_PASSWORD') {
      resendTempPassword({ email: userData.UserAttributes[0].Value })
        .unwrap()
        .then((response) => {
          if (response.error) {
            setErrorMessage('Failed to reset password');
            setIsError(true);
          } else {
            setSuccessMessage('Password reset email sent');
            setIsSuccess(true);
            setIsOpen(false);
          }
        });
    } else {
      resetPassword({ username: selectedUser || '' })
        .unwrap()
        .then((response) => {
          if (response.error) {
            setErrorMessage('Failed to reset password');
            setIsError(true);
          } else {
            setSuccessMessage('Password reset initiated');
            setIsSuccess(true);
            setIsOpen(false);
          }
        });
    }
  };

  const UserStatusInfo = () => {
    let alertSeverity: AlertColor = 'info';
    let alertMessage = '';
    switch (userData?.UserStatus) {
      case 'RESET_REQUIRED':
        alertSeverity = 'warning';
        alertMessage = 'This user has a password reset in progress.';
        break;
      case 'FORCE_CHANGE_PASSWORD':
        alertSeverity = 'info';
        alertMessage =
          'This user has never logged in before and must change their password on first login. A new temporary password will be sent to the user.';
        break;
      case 'CONFIRMED':
        alertSeverity = 'success';
        alertMessage =
          'This user is an active user, a password reset will require the user to change their password on next login. A password reset link will be sent to the user.';
        break;
      default:
        alertSeverity = 'info';
    }
    return <Alert severity={alertSeverity}>{alertMessage}</Alert>;
  };

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(!isOpen)} aria-labelledby="" maxWidth="md" fullWidth>
      <Grid container spacing={1}>
        <Grid item xs={11}>
          <DialogTitle>Reset User Password</DialogTitle>
        </Grid>
        <Grid item xs={1}>
          <IconButton color="default" aria-label="cancel" onClick={() => setIsOpen(false)}>
            <CancelIcon />
          </IconButton>
        </Grid>
      </Grid>
      <DialogContent>
        {isUserDataLoading ? (
          <Box sx={styles.centerContent}>
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            <Typography variant="body1">
              This action will initiate a password reset for the user. An authentication code will be promptly
              dispatched to the user's designated contact method for account recovery. The user will temporarily lose
              access to their account until they establish a new password.
            </Typography>
            <Box sx={styles.my2}>
              {isFetchingUserData ? (
                <Box sx={styles.centerContent}>
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  <UserStatusInfo />

                  <DialogActions>
                    <Button variant="contained" onClick={() => setIsOpen(false)} color="primary">
                      Cancel
                    </Button>
                    {userData?.UserStatus === 'RESET_REQUIRED' ? null : (
                      <LoadingButton
                        variant="contained"
                        onClick={handlePasswordReset}
                        loading={isResendTempPasswordLoading || isResetPasswordLoading}
                        color="primary"
                      >
                        Reset Password
                      </LoadingButton>
                    )}
                  </DialogActions>
                </>
              )}
            </Box>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

const styles = {
  my2: {
    marginY: 2
  },
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
};

export default ResetPasswordDialog;
