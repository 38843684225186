/* Access Code tab stub */
export const AccessCodeLabel = () => {
  return <span>Access Code</span>;
};

const AccessCode = () => {
  return <span>Access Code</span>;
};

export default AccessCode;
