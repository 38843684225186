/* Option Input tab stub */
export const OptionInputLabel = () => {
  return <span>Option Input</span>;
};

const OptionInput = () => {
  return <span>Option Input</span>;
};

export default OptionInput;
