import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import { useUpdateUnitMutation } from 'services/aiphoneCloud';
import { RootState } from 'store';
import { IDevice } from 'store/slices/devicesSlice';
import * as yup from 'yup';

interface Props {
  isEditUnitDialogOpen: boolean;
  setIsEditUnitDialogOpen: (isEditUnitDialogOpen: boolean) => void;
  units: Unit[];
  unit: Unit;
  setShouldRefetch: (shouldRefetch: boolean) => void;
}

interface Unit {
  unitPublicId: string;
  unitNumber: string;
  unitName: string;
  unitType: number;
  devices: IDevice[];
}

const EditUnitDialog = ({ isEditUnitDialogOpen, setIsEditUnitDialogOpen, units, unit, setShouldRefetch }: Props) => {
  //check if state.units.UnitList[unit.unitPublicId] exists if not return empty array
  const unitData = useSelector((state: RootState) =>
    state.units.UnitList ? state.units.UnitList[unit.unitPublicId] : null
  );
  const [updateUnit] = useUpdateUnitMutation();
  const initialValues = {
    unitName: '',
    unitNumber: ''
  };

  const validationSchema = yup.object().shape({
    unitName: yup.string().required('Unit name is required').max(24, 'Unit name must be less than 24 characters'),
    unitNumber: yup
      .string()
      .required('Unit number is required')
      .test('isUnique', 'Unit number already exists', (value) => {
        return !units.some((u) => u.unitNumber === value.padStart(4, '0'));
      })
      .test('isNumber', 'Unit number must be a number', (value) => {
        const regex = /^[0-9]*$/;
        return regex.test(value);
      })
  });

  const handleSaveUnit = async (values: any) => {
    const params = {
      unitData: {
        ...unitData,
        unitName: values.unitName,
        unitNumber: values.unitNumber
      },
      unitPublicId: unit.unitPublicId
    };

    await updateUnit(params);
    setShouldRefetch(true);
    setIsEditUnitDialogOpen(false);
  };

  return (
    <Dialog
      open={isEditUnitDialogOpen}
      onClose={() => {
        setIsEditUnitDialogOpen(false);
      }}
    >
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSaveUnit}>
        {({ errors, touched }) => (
          <Form>
            <DialogTitle>Edit Unit</DialogTitle>
            <DialogContent>
              <DialogContentText>Edit the unit number and name.</DialogContentText>
              <Box sx={{ mt: 2 }}>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={12} sm={12} xs={12}>
                    <Field
                      name="unitName"
                      label="Unit Name"
                      as={TextField}
                      fullWidth
                      error={touched.unitName && Boolean(errors.unitName)}
                      helperText={touched.unitName && errors.unitName}
                    />
                  </Grid>
                  <Grid item lg={6} md={12} sm={12} xs={12}>
                    <Field
                      name="unitNumber"
                      label="Unit Number"
                      as={TextField}
                      fullWidth
                      error={touched.unitNumber && Boolean(errors.unitNumber)}
                      helperText={touched.unitNumber && errors.unitNumber}
                    />
                  </Grid>
                </Grid>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setIsEditUnitDialogOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditUnitDialog;
