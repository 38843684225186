import { Field, Form, Formik } from 'formik';
import React, { FC } from 'react';
import { Box, CircularProgress, Divider, Grid, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as yup from 'yup';
import { IRegisterGatewayWithCloud } from '../RegisterGateway';
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from 'shared/constantAwsApi';

interface IRegisterGatewayFormProps {
  awsPropertyId: string;
  onSubmitCallback: (values: IRegisterGatewayWithCloud) => void;
  isLoading: boolean;
}

const initialValues = {
  macAddress: ''
};

const macValidation = yup.object().shape({
  macAddress: yup
    .string()
    .required('MAC address is required')
    .matches(/^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/, 'Invalid MAC address')
});

export const RegisterGatewayForm: FC<IRegisterGatewayFormProps> = ({ awsPropertyId, onSubmitCallback, isLoading }) => {
  const strings = {
    enterMacAddress: 'Enter the MAC address of the gateway below or scan the QR code on the gateway:',
    registerGatewayButton: 'Register Gateway'
  };

  return (
    <Formik initialValues={initialValues} validationSchema={macValidation} onSubmit={onSubmitCallback}>
      {({ dirty, isValid, errors, touched, isSubmitting }) => (
        <Form>
          <Grid container spacing={2} sx={{ mt: 2, mb: 6 }}>
            <Grid item xs={5}>
              <Box sx={styles.centerContent}>
                <Grid item xs={12}>
                  <Box sx={styles.centerContent}>
                    <Typography variant="h6" sx={{ mb: 4 }}>
                      {strings.enterMacAddress}
                    </Typography>
                  </Box>
                </Grid>
                <Box sx={(styles.centerContent, styles.macAddressFieldBox)}>
                  <Field
                    name="macAddress"
                    as={TextField}
                    label="IXGW-GW MAC Address"
                    variant="outlined"
                    error={errors.macAddress && !!touched.macAddress}
                    helperText={errors.macAddress && !!touched.macAddress}
                    sx={styles.macAddressField}
                    maxLength={17}
                  />
                </Box>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                  loading={isSubmitting || isLoading}
                  disabled={isSubmitting || !isValid || !dirty}
                  loadingIndicator={<CircularProgress size="20px" color="white" />}
                >
                  {strings.registerGatewayButton}
                </LoadingButton>
              </Box>
            </Grid>
            <Grid item xs={2} sx={styles.centerContent}>
              <Box sx={styles.separator}>
                <Divider orientation="vertical" sx={styles.divider} />
              </Box>
            </Grid>
            <Grid item xs={5} sx={styles.centerContent}>
              {/* [TESTING] WILL REMOVE FOR PROD - FOR TESTING ONLY */}
              <Typography variant="h6" sx={{ mb: 4 }}>
                ACL Property ID : {awsPropertyId || 'Create ACL Site ID first'}
              </Typography>
              <Box sx={styles.centerContent}>
                <Box
                  component="img"
                  sx={{ width: '100%' }}
                  src={`${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/IXGW-Mac-Location.png`}
                  alt="location of mac address on ixgw-gw device"
                ></Box>
              </Box>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: 1
  },
  separator: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  macAddressFieldBox: {
    width: '100%',
    mb: 2
  },
  macAddressField: {
    width: '100%'
  },
  divider: {
    height: '125%'
  }
};
