import { Alert, AlertTitle, Box, Button, List, ListItem, Typography } from '@mui/material';
import GeneralInfo from '../../UiParts/GeneralInfo';
import * as Yup from 'yup';
import { FeaturedSystems } from 'features/QuikSpec/Types/QuikSpecTypes';
import { updateFormValuesJP } from 'features/QuikSpec/Utils/QuikspecUpdateFormValuesJP';
import { jpAccessories, jpDoorStations, jpSystemConstraints, jpMasterStations, jpHidden } from '../../../Types/JPTypes';
import { acControlKits, acLicenses, acReaderKits, acHidden, acSystemConstraints } from '../../../Types/ACTypes';
import { useState } from 'react';
import QuikspecCategoryTabs from '../../UiParts/QuikspecCategoryTabs';
import QuikspecCategoryAcControllers from '../../UiParts/QuikspecCategoryAcControllers';
import QuikspecCategoryAcLicenses from '../../UiParts/QuikspecCategoryAcLicenses';
import { Form, Formik } from 'formik';
import QuikspecSystemConstraints from '../../UiParts/QuikspecSystemConstraints';
import QuikspecSubmitButton from '../../UiParts/QuikspecSubmitButton';
import { useNavigate, useLocation } from 'react-router-dom';
import QuikspecNote from 'features/QuikSpec/Components/UiParts/QuikspecNote';
import { FormValues } from '../../../Types/QuikspecFormTypes';
import getInitialFormValues from 'features/QuikSpec/Utils/QuikspecInitialFormValues';
import { createQuikSpecAndNavigate, updateQuikspecAndNavigate } from '../../../Utils/QuikspecActionAndNavigate';

// Function to check if at least one quantity is greater than 0
const atLeastOneQuantityGreaterThanZero = (values: any) => {
  const items = Object.values(values);
  return items.some((item: any) => item.itemName === 'JP-4MED' && item.quantity > 0);
};

// Function to check if at least one door station is spec-ed
const atLeastOneDoorStation = (values: any) => {
  const items = Object.values(values);
  const totalDoorStations = items
    .filter((item) => item.category === 'Door Stations')
    .reduce((total, item) => total + item.accessories.reduce((total, item) => total + item.quantity, 0), 0);
  return totalDoorStations > 0;
};

//Function to check if there are no more than 4 door stations for a JP system
const atMostFourDoorStations = (values: any) => {
  const items = Object.values(values);
  const totalDoorStations = items
    .filter((item) => item.category === 'Door Stations')
    .reduce((total, item) => total + item.accessories.reduce((total, item) => total + item.quantity, 0), 0);
  return totalDoorStations <= jpDoorStations[0]?.maxQuantityAllowedPerCategory;
};
const validationSchema = Yup.object().shape({
  quikspecName: Yup.string().required('Quikspec Name is required'),
  marketType: Yup.string().required('Market Type is required'),
  items: Yup.array()
    .of(
      Yup.object().shape({
        itemName: Yup.string().required('Item Name is required'),
        quantity: Yup.number().min(0, 'Quantity must be greater than or equal to 0').required('Quantity is required')
      })
    )
    .test('atLeastOneQuantity', 'At least JP-4MED must have quantity greater than 0', atLeastOneQuantityGreaterThanZero)
    .test('atLeastOneDoorStation', 'At least one door station is required', atLeastOneDoorStation)
    .test('atMostFourDoorStations', 'There can only be at most 4 Door stations', atMostFourDoorStations)
});

const QuikspecJP = () => {
  const navigate = useNavigate();
  const [acOpen, setACOpen] = useState(false);

  const location = useLocation();

  const state = location.state || {};
  const siteName: string = location.state.siteName as string;
  const siteIndex = FeaturedSystems.findIndex((system) => system.siteName === siteName);
  const systemName = FeaturedSystems[siteIndex].seriesName;
  const systemforC2 = FeaturedSystems[siteIndex].systemforC2;

  const categories = [
    ...jpMasterStations,
    ...jpDoorStations,
    ...jpAccessories,
    ...jpHidden,
    ...acControlKits,
    ...acLicenses,
    ...acReaderKits,
    ...acHidden
  ];
  const stateFormValues = state && Object.keys(state).length > 1 ? state.formValues : undefined;
  const initialValues = getInitialFormValues(systemName, categories, stateFormValues, systemforC2);

  const handleSubmit = async (values: FormValues, actions: any) => {
    try {
      actions.setSubmitting(true);
      //filter out items with quantity set to zero in order to submit quikspec
      values = updateFormValuesJP(values);

      //determine if form is in edit mode
      const editing = stateFormValues != undefined;
      if (editing) {
        //update QuikSpec and navigate to QuikSpec Summary page
        await updateQuikspecAndNavigate(values, actions, navigate);
        actions.setSubmitting(false);
        return;
      } else {
        //create QuikSpec and navigate to QuikSpec Summary page
        await createQuikSpecAndNavigate(values, actions, navigate);
        actions.setSubmitting(false);
      }
    } catch (error) {
      actions.setSubmitting(false);
      actions.setErrors({ submit: 'Something went wrong. Please try again or contact customer support.' });
    }
  };
  return (
    <Box>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ errors, touched, isSubmitting }) => {
          return (
            <Form>
              <GeneralInfo editing={state.formValues != undefined} />
              {/* Render QuikspecCategoryTabs for each category */}
              {!acOpen && (
                <Box>
                  <QuikspecSystemConstraints systemConstraints={jpSystemConstraints} />
                  <QuikspecCategoryTabs categories={jpMasterStations} />
                  <QuikspecCategoryTabs categories={jpDoorStations} />
                  <QuikspecCategoryTabs categories={jpAccessories} />
                  <Box align="center">
                    <Typography variant="h4" align="center">
                      Looking for additional access control options?
                    </Typography>
                    <Button variant="contained" color="secondary" onClick={() => setACOpen(true)}>
                      Click here to add the AC Series to your JP Specification
                    </Button>
                  </Box>
                </Box>
              )}
              {acOpen && (
                <Box>
                  <QuikspecSystemConstraints systemConstraints={acSystemConstraints} />
                  <QuikspecCategoryAcControllers categories={acControlKits} />
                  <QuikspecCategoryAcLicenses categories={acLicenses} />
                  <QuikspecCategoryTabs categories={acReaderKits} />
                  <Box align="center">
                    <Typography variant="h4" align="center">
                      Need to go back?
                    </Typography>
                    <Button variant="contained" color="secondary" onClick={() => setACOpen(false)}>
                      Click here to return to the JP System page
                    </Button>
                  </Box>
                </Box>
              )}
              {(stateFormValues
                ? Object.keys(errors).length > 0
                : Object.keys(touched).length > 0 && Object.keys(errors).length > 0) && (
                <Alert severity="error">
                  <AlertTitle>Error: </AlertTitle>
                  <List>
                    {Object.keys(errors).map((field) => (
                      <ListItem key={field}>
                        <Typography variant="body2" color="error">
                          {errors[field]}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                </Alert>
              )}
              <QuikspecSubmitButton editing={state.formValues != undefined} errors={errors} submitting={isSubmitting} />
              <QuikspecNote systemName={initialValues.systemName} />
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default QuikspecJP;
