import { Box, Button, Card, CardHeader, Grid, TextField } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FunctionComponent, useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';
interface AppProps {
  id: string;
  appName: string;
  appNumber: string;
  buildingId: string;
  unitNumber: string;
  qrCode: string;
  handleDeleteApp: (appId: string) => void;
}

const MobileAppCard: FunctionComponent<AppProps> = ({
  id,
  appName,
  appNumber,
  buildingId,
  unitNumber,
  qrCode,
  handleDeleteApp
}) => {
  const [showQrCode, setShowQRCode] = useState(false);
  return (
    <Card sx={styles.appContainer}>
      <Grid sx={styles.header}>
        <CardHeader title={`${appNumber} - ${appName}`} sx={styles.headerText} />
        <Button>
          <DeleteForeverIcon onClick={() => handleDeleteApp(id)} sx={styles.deleteIcon} />
        </Button>
      </Grid>
      <Box sx={styles.appBox}>
        <Grid sx={styles.info}>
          <Grid sx={styles.infoValue}>
            <TextField
              label="Building Number"
              id="outlined-size-small"
              defaultValue={buildingId}
              size="small"
              disabled={true}
            />
          </Grid>
        </Grid>
        <Grid sx={styles.info}>
          <Grid sx={styles.infoValue}>
            <TextField
              label="Unit Number"
              id="outlined-size-small"
              defaultValue={unitNumber}
              size="small"
              disabled={true}
            />
          </Grid>
        </Grid>
        <FormControlLabel control={<Switch onChange={() => setShowQRCode(!showQrCode)} />} label="show QR code" />
        {showQrCode && (
          <Grid>
            <QRCodeSVG value={qrCode} />
          </Grid>
        )}
      </Box>
    </Card>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  main: {
    padding: '20px'
  },
  appContainer: {
    margin: '10px',
    boxShadow: 3,
    overflow: 'auto'
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#D7D7D7',
    boxShadow: 2
  },
  headerText: {
    width: '90%',
    textAlign: 'center'
  },
  deleteIcon: {
    color: 'grey'
  },
  appBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: '10px'
  },
  info: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    margin: '1px'
  },
  infoValue: {
    display: 'flex',
    alignContent: 'right',
    alignItems: 'right',
    margin: '5px'
  }
};

export default MobileAppCard;
