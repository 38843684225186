/* Option Input Page tab stub */
export const OptionInputPageLabel = () => {
  return <span>Option Input Page</span>;
};

const OptionInputPage = () => {
  return <span>Option Input Page</span>;
};

export default OptionInputPage;
