import { Provider } from 'react-redux';
import { store } from './store';
import { ThemeProvider, CssBaseline } from '@mui/material';
import theme from './data/theme';
import ReactGA from 'react-ga4';
import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import AppRoutes from './router/AppRoutes';
import { useEffect } from 'react';

function App() {
  const getMeasurementId = () => {
    const url = new URL(window.location.href);
    if (url.hostname === 'beta.aiphone.cloud') {
      return 'G-SRCF0YZC2F';
    } else if (url.hostname === 'aiphone.cloud') {
      return 'G-350PJT2TQN';
    }
    return null;
  };

  const measurementId = getMeasurementId();
  if (measurementId) {
    ReactGA.initialize(measurementId);
  }

  useEffect(() => {
    if (measurementId) {
      ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
    }
  }, [measurementId]);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppRoutes />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
