import { useDispatch, useSelector } from 'react-redux';
import { getSelectedDevice, setDeviceBasicInfo } from 'store/slices/devicesSlice';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { Button } from '@mui/material';
import { getString } from 'shared/utils/LocalizationUtils';
import { useUpdateDeviceMutation } from 'services/aiphoneCloud';
import containerStyle from 'shared/styles/advancedSettingContainerStyle';

/* Units & Stations tab stub */
export const UnitsStationsLabel = () => {
  return <span>Units & Stations</span>;
};

const UnitsStations = () => {
  const dispatch = useDispatch();
  const [updateDevice, { isLoading: isUpdating }] = useUpdateDeviceMutation();
  const selectedDevice = useSelector(getSelectedDevice);

  const getValidationSchema = () => {
    const basicInfoSchema: any = {};
    /* Hidden but not removed per request
    if (selectedDevice.basicInfo.stationLocation !== null && selectedDevice.basicInfo.stationLocation !== undefined) {
      const stationLocationMaxLen = 24;
      basicInfoSchema.stationLocation = Yup.string().max(
        stationLocationMaxLen,
        stringUtils.format(
          getString('Field_Error_MaxLen'),
          stationLocationTitle,
          stationLocationMaxLen
        )
      );
    }*/

    return Yup.object({
      basicInfo: Yup.object(basicInfoSchema)
    });
  };

  const onSubmit = async (values: any) => {
    console.log('Submitting', values);

    const params = {
      device: {
        basicInfo: values.basicInfo,
        publicId: values.publicId,
        unitPublicId: values.unitPublicId
      }
    };
    updateDevice(params);

    dispatch(setDeviceBasicInfo(params));
  };

  return (
    <Formik
      initialValues={selectedDevice}
      onSubmit={onSubmit}
      validationSchema={getValidationSchema()}
      enableReinitialize
    >
      {({ dirty, isSubmitting }) => (
        <Form style={containerStyle.form}>
          <div style={containerStyle.gridContainer}>
            {/* Hidden but no removed per request
            selectedDevice.basicInfo.stationLocation !== null ?
              <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                <Grid item xs={5} lg={7}>
                  <div style={containerStyle.itemTitle}>{getString('AdvancedSettings_StationLocation_Title')}</div>
                  <div style={containerStyle.itemDesc}>{getString('AdvancedSettings_StationLocation_Desc')}</div>
                </Grid>
                <Grid item xs={5} lg={3}>
                  <div style={containerStyle.textFieldContainer}>
                    <Field
                      as={TextField}
                      label={getString('AdvancedSettings_StationLocation_Title')}
                      name="basicInfo.stationLocation"
                      size="small"
                      style={containerStyle.textField}
                      helperText={touched.basicInfo?.stationLocation && errors.basicInfo?.stationLocation}
                    />
                  </div>
                </Grid>
              </Grid>
            : null
            */}
          </div>
          <div style={containerStyle.submitContainer}>
            <Button
              type="reset"
              variant="contained"
              color="primary"
              disabled={!dirty || isSubmitting || isUpdating}
              style={containerStyle.submitBarButton}
            >
              {getString('Button_Reset')}
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!dirty || isSubmitting || isUpdating}
              style={containerStyle.submitBarButton}
            >
              {getString('Button_Submit')}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default UnitsStations;
