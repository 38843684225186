import Dialog from '@mui/material/Dialog';
import {
  Autocomplete,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { LoadingButton } from '@mui/lab';
import React from 'react';
import {
  useGetAssignableRolesQuery,
  useGetBranchesWithCompanyPublicIdQuery,
  useGetCompaniesQuery,
  useInviteBranchUserMutation
} from '../../../services/aiphoneCloud';
import { Field, Form, Formik } from 'formik';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import RoleDescriptions from '../RoleDescriptions';
import { UserInfo } from 'shared/utils/UserUtils';
import CompanySelect from '../shared/components/CompanySelect';
import { IBranch, ICompanyList } from './EditRegisteredUserDialog';
import BranchSelect from '../shared/components/BranchSelect';

interface IInviteUserDialogProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setErrorMessage: (errorMessage: string) => void;
  setSuccessMessage: (successMessage: string) => void;
  setIsError: (isError: boolean) => void;
  setIsSuccess: (isSuccess: boolean) => void;
  companies: ICompanyList;
  // These props are the branch and company names and IDs of the first the user has access to.
  adminBranchName: string;
  adminBranchId: string;
  adminCompanyName: string;
}

const InviteUserDialog = ({
  isOpen,
  setIsOpen,
  setErrorMessage,
  setSuccessMessage,
  setIsError,
  setIsSuccess,
  companies,
  adminBranchName,
  adminBranchId,
  adminCompanyName
}: IInviteUserDialogProps) => {
  const [company, setCompany] = React.useState('');
  const [branch, setBranch] = React.useState('');
  const [branchName, setBranchName] = React.useState('');
  const [role, setRole] = React.useState('');
  const [inviteUser, { isLoading: isInviteLoading }] = useInviteBranchUserMutation();
  const { data: branches, isFetching: isFetchingBranches } = useGetBranchesWithCompanyPublicIdQuery(company, {
    skip: !company
  });
  const { data: roles, isFetching: isFetchingRoles } = useGetAssignableRolesQuery({});
  const user = useSelector((state: RootState) => state.users.currentUser);
  const currentBranch = useSelector((state: RootState) => state.branches.currentBranch);
  const currentCompany = useSelector((state: RootState) => state.branches.currentBranch?.company);

  // This dialog can be reached by Aiphone Admin, Branch Admin, and Branch Manager.
  // Only when Branch Manager, the selectable roles are different.
  const hasAdminRole = new UserInfo().isBranchAdmin();
  const hasManagerRole = new UserInfo().isBranchManager();

  const buildInitialFormValues = () => {
    if (hasManagerRole || hasAdminRole) {
      return {
        company: {
          publicId: currentCompany?.publicId,
          name: currentCompany?.name,
          state: currentCompany?.state,
          postalCode: currentCompany?.postalCode
        },
        branch: currentBranch?.publicId,
        role: '',
        email: ''
      };
    } else {
      return {
        company: {
          publicId: '',
          name: '',
          state: '',
          postalCode: ''
        },
        branch: '',
        role: '',
        email: ''
      };
    }
  };

  const resetStateValues = (): void => {
    setCompany('');
    setBranch('');
    setRole('');
  };

  const handleCloseClick = () => {
    setIsOpen(false);
    resetStateValues();
  };

  const handleInviteUser = (values: any) => {
    const formattedEmail = values.email.toLowerCase();
    //const branchName = branches[values.branch]?.branchName;
    const inviteUserPayload = {
      companyPublicId: values.company.publicId,
      branchPublicId: values.branch,
      rolePublicId: role,
      email: formattedEmail,
      invitedBy: user?.firstName,
      invitedTo: currentBranch?.branchName || values.branch,
      languageId: 2,
      countryId: 2
    };
    inviteUser(inviteUserPayload)
      .unwrap()
      .then((response) => {
        if (response.error) {
          setIsError(true);
          setErrorMessage(response.error.message);
          return;
        }
        setIsSuccess(true);
        setSuccessMessage('User invited successfully');
        setIsOpen(false);
        setRole('');
      })
      .catch((error) => {
        const errorCode = JSON.parse(error.data)?.errorCode;
        if (errorCode === '23505') {
          setErrorMessage('User already has a role with this branch.');
          setIsError(true);
          setIsOpen(false);
          setRole('');
          return;
        }
      });
  };

  const validateSchema = yup.object().shape({
    email: yup.string().email('Invalid email').required('Email is required'),
    company: yup.object().required('Company is required'),
    branch: yup.string().required('Branch is required'),
    role: yup.string().required('Role is required')
  });

  return (
    <Dialog open={isOpen} onClose={() => handleCloseClick()} aria-labelledby="" maxWidth="sm" fullWidth>
      <Grid container spacing={1}>
        <Grid item xs={11}>
          <DialogTitle>Invite User</DialogTitle>
        </Grid>
        <Grid item xs={1}>
          <IconButton color="default" aria-label="cancel" onClick={() => handleCloseClick()}>
            <CancelIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Formik
        initialValues={buildInitialFormValues()}
        validationSchema={validateSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleInviteUser(values);
          setSubmitting(false);
        }}
      >
        {(props) => {
          const { errors, touched } = props;
          return (
            <Form>
              <DialogContent>
                <Box>
                  <Typography variant="body1" color="initial">
                    Use the form below to invite a dealer admin or member to AiphoneCloud.
                  </Typography>
                </Box>
                <Box>
                  <Box sx={styles.my2}>
                    <Typography variant="h6" color="initial">
                      User Information
                    </Typography>
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field
                        name="email"
                        id="email"
                        as={TextField}
                        label="Email"
                        variant="outlined"
                        size="small"
                        error={errors.email && touched.email}
                        helperText={errors.email && touched.email ? errors.email : ''}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Box sx={styles.my2}>
                    <Typography variant="h6" color="initial">
                      Branch Information
                    </Typography>
                  </Box>

                  <Grid container spacing={2}>
                    {adminCompanyName.length === 0 && (
                      <CompanySelect
                        companies={companies}
                        handleChange={async (event: React.ChangeEvent<HTMLInputElement>, value: any) => {
                          await props.setFieldValue('company', value);
                          setCompany(value?.publicId);
                        }}
                      />
                    )}
                    {adminCompanyName.length !== 0 && (
                      <Grid item xs={6}>
                        <Field
                          as={TextField}
                          sx={styles.inputField}
                          label="Company Name"
                          size="small"
                          name="company"
                          value={adminCompanyName}
                          disabled
                        />
                      </Grid>
                    )}
                    {adminCompanyName.length === 0 && (
                      <BranchSelect
                        isBranchesFetching={isFetchingBranches}
                        isCompanySelected={!!company}
                        branches={branches}
                        handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          const selectedValue = event.target.value;
                          props.handleChange(event);
                          const selectedBranch = branches[selectedValue];
                          if (selectedBranch) {
                            setBranchName(selectedBranch.branchName);
                            setBranch(selectedBranch.publicId);
                          }
                        }}
                      />
                    )}
                    {adminCompanyName.length !== 0 && (
                      <Grid item xs={6}>
                        <Field
                          as={TextField}
                          sx={styles.inputField}
                          label="Branch"
                          size="small"
                          name="branch"
                          value={adminBranchId}
                          inputProps={{ value: adminBranchName }}
                          disabled
                        />
                      </Grid>
                    )}
                    <Grid item xs={6}>
                      <FormControl
                        sx={styles.inputField}
                        size="small"
                        fullWidth
                        helperText={touched.role && errors.role ? errors.role : ''}
                        error={touched.role && !!errors.role}
                      >
                        <InputLabel id="role-label">Select a role</InputLabel>
                        <Field
                          name="role"
                          id="role"
                          as={Select}
                          labelId="role-label"
                          label="Select a role"
                          onChange={(event) => {
                            props.handleChange(event);
                            setRole(event.target.value as string);
                          }}
                          helperText={touched.role && errors.role ? errors.role : ''}
                          error={touched.role && !!errors.role}
                        >
                          {isFetchingRoles
                            ? []
                            : hasManagerRole
                            ? [...roles.branch]
                                .filter((role) => role.roleName !== 'Admin')
                                .map((role) => (
                                  <MenuItem key={role.publicId} value={role.publicId}>
                                    {role.roleName}
                                  </MenuItem>
                                ))
                            : [...roles.branch].map((role) => (
                                <MenuItem key={role.publicId} value={role.publicId}>
                                  {role.roleName}
                                </MenuItem>
                              ))}
                        </Field>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <RoleDescriptions roleName={roles.branch.find((id) => id.publicId === role)?.roleName} />
                </Box>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => handleCloseClick()} variant="contained">
                  Cancel
                </Button>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={isInviteLoading}
                  disabled={props.isSubmitting}
                >
                  Invite User
                </LoadingButton>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

const styles = {
  mt2: {
    marginTop: 2
  },
  my2: {
    marginY: 2
  },
  inputField: {
    width: '100%',
    '& .MuiInputBase-input': {
      backgroundColor: '#ffffff'
    },
    '&.MuiFormHelperText-root': {
      color: 'red'
    },
    '& .MuiInputLabel-root': {
      color: 'red',
      '&.Mui-focused': {
        color: 'black'
      }
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'gray'
      },
      '&:hover fieldset': {
        '& .enabled': {
          borderColor: '#003366'
        }
      },
      '&.Mui-focused fieldset': {
        borderColor: '#0071ce'
      }
    }
  }
};

export default InviteUserDialog;
