/* Email tab stub */
export const EmailLabel = () => {
  return <span>Email</span>;
};

const Email = () => {
  return <span>Email</span>;
};

export default Email;
