/* Recording tab stub */
export const RecordingLabel = () => {
  return <span>Recording</span>;
};

const Recording = () => {
  return <span>Recording</span>;
};

export default Recording;
