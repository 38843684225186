import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { Field, Form, Formik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import { useDeleteBranchMutation } from 'services/aiphoneCloud';
import { useState } from 'react';
import { getString } from 'shared/utils/LocalizationUtils';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  selectionModel: GridRowSelectionModel;
  setIsError: (isError: boolean) => void;
  setErrorMessage: (errorMessage: string) => void;
  setIsSuccess: (isSuccess: boolean) => void;
  setSuccessMessage: (successMessage: string) => void;
}

const DeleteBranchDialog = ({
  isOpen,
  setIsOpen,
  selectionModel,
  setIsError,
  setErrorMessage,
  setIsSuccess,
  setSuccessMessage
}: Props) => {
  const [canDelete, setCanDelete] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteBranch] = useDeleteBranchMutation();
  const branchDelete = getString('Button_DeleteBranch');
  const branchDeleteMessage = getString('Message_DeleteBranch');
  const note = getString('Message_Note');
  const cantUndo = getString('Message_CantUndo');
  const cancelButton = getString('Button_Cancel_DeleteBranch');

  const handleCloseImportClick = () => {
    setIsOpen(false);
    setCanDelete(false);
  };

  const verifyDeletion = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === 'delete') {
      setCanDelete(true);
    } else {
      setCanDelete(false);
    }
  };

  const initialForm = () => ({
    delete: ''
  });

  const onSubmit = async () => {
    setIsDeleting(true);
    deleteBranch(selectionModel[0].toString())
      .unwrap()
      .then((response) => {
        if (response.error) {
          setIsError(true);
          setIsDeleting(false);
          setErrorMessage('Error deleting branch. Please try again later.');
        } else {
          setIsOpen(false);
          setIsSuccess(true);
          setIsDeleting(false);
          setCanDelete(false);
          setSuccessMessage('Branch deleted successfully');
          return;
        }
      })
      .catch((error) => {
        setErrorMessage(JSON.parse(error.data)?.errorDetails || 'Unexpected error');
        setIsError(true);
        setIsDeleting(false);
      });
  };

  return (
    <Dialog
      onClose={() => handleCloseImportClick()}
      open={isOpen}
      disableEscapeKeyDown={true}
      maxWidth="sm"
      fullWidth={true}
    >
      <Grid container spacing={1}>
        <Grid item xs={11}>
          <DialogTitle>{branchDelete}</DialogTitle>
        </Grid>
        <Grid item xs={1}>
          <IconButton color="default" aria-label="cancel" onClick={handleCloseImportClick}>
            <CancelIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Box>
        <Formik initialValues={initialForm()} onSubmit={onSubmit} validateOnBlur={true}>
          {(props) => {
            const { handleChange, errors, touched } = props;
            return (
              <Form>
                <DialogContent>
                  <Box>
                    <Typography variant="body1" color="text.primary">
                      {branchDeleteMessage}
                    </Typography>
                  </Box>
                  <Box sx={styles.my2}>
                    <Field
                      name="delete"
                      as={TextField}
                      sx={styles.inputField}
                      label="Enter 'delete' to confirm deletion"
                      size="small"
                      helperText={touched.delete && errors.delete ? errors.delete : ''}
                      error={touched.delete && !!errors.delete}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleChange(e);
                        verifyDeletion(e);
                      }}
                    />
                    <Typography variant="caption" color="GrayText">
                      <strong>{note}</strong> {cantUndo}
                    </Typography>
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button variant="contained" onClick={handleCloseImportClick}>
                    {cancelButton}
                  </Button>
                  <LoadingButton variant="contained" type="submit" loading={isDeleting} disabled={!canDelete}>
                    {branchDelete}
                  </LoadingButton>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Dialog>
  );
};

const styles = {
  my2: {
    marginY: 2
  },
  inputField: {
    width: '100%',
    '& .MuiInputBase-input': {
      backgroundColor: '#ffffff'
    },
    '&.MuiFormHelperText-root': {
      color: 'red'
    },
    '& .MuiInputLabel-root': {
      color: 'red',
      '&.Mui-focused': {
        color: 'black'
      }
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey'
      },
      '&:hover fieldset': {
        borderColor: '#003366'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#0071ce'
      }
    }
  }
};

export default DeleteBranchDialog;
