import { IUserPermissions } from 'store/slices/usersSlice';

export interface UserInfoParams {
  userString?: string;
  userObj?: any;
}

export class UserInfo {
  firstName: string;
  lastName: string;
  email: string;
  countryId: number;
  language: number;
  marketingOptIn: boolean;
  publicId: string;
  phoneNumber: string;
  phoneExtension: string;
  permissions: IUserPermissions;

  public isAdmin() {
    return this.isAiphoneAdmin() || this.isBranchAdmin();
  }

  public isAiphoneAdmin() {
    if (this.permissions.global?.role.roleName === 'Aiphone Admin') {
      return true;
    }
    return false;
  }

  public isBranchAdmin() {
    //TODO: change to selected branch
    if (this.permissions.branch !== null && this.permissions.branch !== undefined) {
      for (const branch of Object.values(this.permissions.branch)) {
        if (Array.isArray(branch.roleList)) {
          for (const role of branch.roleList) {
            if (role.roleName === 'Admin') {
              return true;
            }
          }
        }
      }
    }

    return false;
  }

  public isAiphone() {
    const aiphoneRoles = ['Aiphone Admin', 'Aiphone Sales', 'Aiphone Tech'];
    if (aiphoneRoles.includes(this.permissions.global?.role.roleName)) {
      return true;
    }

    return false;
  }

  public isBranchManager() {
    //TODO: change to selected branch
    if (this.permissions.branch !== null && this.permissions.branch !== undefined) {
      for (const branch of Object.values(this.permissions.branch)) {
        if (Array.isArray(branch.roleList)) {
          for (const role of branch.roleList) {
            if (role.roleName === 'Manager') {
              return true;
            }
          }
        }
      }
    }

    return false;
  }

  public saveUser() {
    // TODO: add API call to update user info
    localStorage.setItem('user', JSON.stringify(this));
  }

  constructor(userObj?: any) {
    if (!userObj) {
      userObj = JSON.parse(sessionStorage.getItem('user') ?? '{}');
    }
    this.firstName = userObj.firstName ?? '';
    this.lastName = userObj.lastName ?? '';
    this.phoneNumber = userObj.phoneNumber ?? '';
    this.phoneExtension = userObj.phoneExtension ?? '';
    this.marketingOptIn = userObj.marketingOptIn ?? false;
    this.publicId = userObj?.publicId ?? '';
    this.email = userObj?.email ?? '';
    this.countryId = userObj?.countryId ?? 2;
    this.language = userObj?.languageId ?? 2;
    this.permissions = userObj?.permissions ?? {};
  }
}
