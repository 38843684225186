import React from 'react';
import { Autocomplete, AutocompleteRenderInputParams, Grid, TextField } from '@mui/material';
import { Field, useField } from 'formik';
import { ICompany, ICompanyList } from '../../Dialogs/EditRegisteredUserDialog';

interface CompanySelectProps {
  companies: ICompanyList;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>, value: ICompany) => void;
}

const strings = {
  name: 'company',
  label: 'Select a company'
};

const CompanySelect: React.FC<CompanySelectProps> = ({ companies, handleChange }: CompanySelectProps) => {
  const [, meta] = useField('company');
  const formattedOptions = Object.entries(companies).map(([key, value]) => {
    return { publicId: key, name: value.name, state: value.state, postalCode: value.postalCode };
  });

  return (
    <Grid item xs={6}>
      <Field
        name={strings.name}
        as={Autocomplete}
        id={strings.name}
        onChange={handleChange}
        options={formattedOptions}
        getOptionLabel={(option: ICompany) => option.name}
        isOptionEqualToValue={(option: ICompany, value: ICompany) => {
          return option.publicId === value.publicId;
        }}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            {...params}
            label={strings.label}
            variant="outlined"
            size="small"
            fullWidth
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error ? meta.error : ''}
          />
        )}
      />
    </Grid>
  );
};

export default CompanySelect;
