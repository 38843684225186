import { Box, Button, Card, CardActions, CardContent, Grid, Tooltip, Typography } from '@mui/material';
import { DOOR_STATIONS, MASTER_STATIONS } from 'features/RemoteManagement/Types';
import React from 'react';
import { useSelector } from 'react-redux';
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from 'shared/constantAwsApi';
import { getDeviceModelNumberFromModelType } from 'shared/utils/helperFunctions';
import { RootState } from 'store';
import { IDevice, getDeviceWithPublicId } from 'store/slices/devicesSlice';

interface Props {
  units: Unit[];
  selectedUnit: Unit;
  deviceId: string;
  addDeviceToUnit: (device: IDevice) => void;
  removeDeviceFromUnit: (device: IDevice) => void;
}

interface Unit {
  unitPublicId: string;
  unitNumber: string;
  unitName: string;
  unitType: number;
  devices: IDevice[];
}

const DeviceUnitCard = ({ units, selectedUnit, deviceId, addDeviceToUnit, removeDeviceFromUnit }: Props) => {
  const device = useSelector((state: RootState) => getDeviceWithPublicId(state, deviceId) as IDevice);
  const deviceType = device.basicInfo.deviceType;

  const handleAddDeviceToUnit = () => {
    addDeviceToUnit(device);
  };

  const handleRemoveDeviceFromUnit = () => {
    removeDeviceFromUnit(device);
  };

  const isDeviceAlreadyAddedToUnit = selectedUnit.devices.some(
    (d) => d.basicInfo.macAddress === device.basicInfo.macAddress
  );

  const isDeviceAddedToAnyUnit = units.some((unit) =>
    unit.devices.some((d) => d.basicInfo.macAddress === device.basicInfo.macAddress)
  );

  const isUnitAtMaxCapacity = () => {
    return selectedUnit.devices.length >= 10 ? true : false;
  };

  const isDoorStationAtMaxCapacity = () => {
    const doorStationsCount = selectedUnit.devices.reduce((count, device) => {
      return DOOR_STATIONS.includes(device.basicInfo.deviceType) ? count + 1 : count;
    }, 0);

    return doorStationsCount >= 2;
  };

  const isMasterStationAtMaxCapacity = () => {
    const masterStationsCount = selectedUnit.devices.reduce((count, device) => {
      return MASTER_STATIONS.includes(device.basicInfo.deviceType) ? count + 1 : count;
    }, 0);

    return masterStationsCount >= 8;
  };

  const findUnitNumber = () => {
    const unit = units.find((unit) => unit.devices.some((d) => d.basicInfo.macAddress === device.basicInfo.macAddress));
    return unit?.unitNumber;
  };

  const imageUrl = `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/icons/${getDeviceModelNumberFromModelType(
    device.basicInfo.deviceModel,
    device.basicInfo.deviceType
  )}.png`;

  return (
    <Grid item xs={4}>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Typography gutterBottom variant="h5" component="div">
                {device.basicInfo.macAddress}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <strong>Model Number: </strong>
                {getDeviceModelNumberFromModelType(device.basicInfo.deviceModel, device.basicInfo.deviceType)}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <strong>Station Name: </strong>
                {device.basicInfo.stationName}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <strong>Station Number: </strong>
                {device.basicInfo.stationNumber}
              </Typography>
            </Grid>
            <Grid
              item
              xs={4}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Box component="img" src={imageUrl} />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          {DOOR_STATIONS.includes(deviceType) ? (
            isDoorStationAtMaxCapacity() ? (
              <Tooltip title="A maximum of 2 door stations may be added to this unit type." arrow>
                <span>
                  <Button
                    size="small"
                    onClick={handleAddDeviceToUnit}
                    disabled={isDeviceAddedToAnyUnit || isDoorStationAtMaxCapacity()}
                  >
                    Add to Unit
                  </Button>
                </span>
              </Tooltip>
            ) : (
              <Button size="small" onClick={handleAddDeviceToUnit} disabled={isDeviceAddedToAnyUnit}>
                Add to Unit
              </Button>
            )
          ) : (
            <Button
              size="small"
              onClick={handleAddDeviceToUnit}
              disabled={isDeviceAddedToAnyUnit || isMasterStationAtMaxCapacity()}
            >
              Add to Unit
            </Button>
          )}
          {isDeviceAddedToAnyUnit && !isDeviceAlreadyAddedToUnit ? (
            <Typography variant="body2" color="text.secondary">
              Already assigned to Unit {findUnitNumber()}
            </Typography>
          ) : null}
          {isDeviceAlreadyAddedToUnit ? (
            <Button size="small" onClick={handleRemoveDeviceFromUnit}>
              Remove from Unit
            </Button>
          ) : null}
          {isUnitAtMaxCapacity() ? (
            <Typography variant="body2" color="text.secondary">
              Unit is at max capacity
            </Typography>
          ) : null}
        </CardActions>
      </Card>
    </Grid>
  );
};

export default DeviceUnitCard;
