import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import theme from 'data/theme';
import { useLocation } from 'react-router-dom';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { quikspecDisclaimer } from '../../Types/QuikSpecTypes';
import WireInfoTable from './WireInfoTable';
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from 'shared/constantAwsApi';
import strings from 'features/QuikSpec/Components/LanguageFiles/ProductList/en.json';

const { productlist } = strings;

const isValidDescriptionItemKey = (key: any): key is keyof typeof productlist => {
  return key in productlist;
};

const QuikspecSummary = () => {
  const location = useLocation();
  const { values } = location.state;
  const sortedItems = [...values.items].sort((a, b) => a.category.localeCompare(b.category));
  //const systemName = values.systemName;

  /**
   * Function to export Quikspec Summary as PDF
   */
  const handleExportPDF = () => {
    const doc = new jsPDF();

    const logoHeight = 9;
    const logoWidth = 60;
    const padding = 10;
    const headerHeight = 10;
    const lineHeight = 10;
    const pageWidth = doc.internal.pageSize.getWidth();
    const dateQuoted = new Date().toLocaleDateString();

    const quikspecItemsHeader = ['Qty', 'Model', 'Description'];
    const quikspecItemsData = sortedItems.map((item) => {
      const deviceKey: string = item.itemName.replace(/-/g, '');
      return [item.quantity, item.itemName, isValidDescriptionItemKey(deviceKey) && productlist[deviceKey]];
    });

    doc.addImage(
      `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/Aiphone-Logo.jpg`,
      'JPG',
      padding,
      padding,
      logoWidth,
      logoHeight
    );
    doc.text(`${values.quikspecName}`, pageWidth / 2, logoHeight * 2 + headerHeight, { align: 'center' });
    doc.text(`Date Quoted: ${dateQuoted}`, pageWidth / 2, padding + logoHeight + headerHeight * 2, { align: 'center' });

    autoTable(doc, {
      head: [quikspecItemsHeader],
      body: quikspecItemsData,
      startY: padding + logoHeight + headerHeight * 3,
      pageBreak: 'auto'
    });

    addWireInfoToPDF(doc, lineHeight);
    addNotesToPDF(doc, lineHeight);

    doc.setFontSize(10); // Set the font size to 10
    doc.setTextColor(100); // Set the text color to a light gray
    const pageHeight = doc.internal.pageSize.getHeight();
    let disclaimerPositionY = doc.lastAutoTable.finalY + lineHeight;
    // Disclaimer height equals to almost 4 lineHeight with the current message and page width, + 1 lineHeight from the previous table
    if (doc.lastAutoTable.finalY + lineHeight * 5 > pageHeight) {
      doc.addPage(); // Add a new page
      disclaimerPositionY = padding; // Reset the output position
    }
    doc.text(quikspecDisclaimer, doc.internal.pageSize.getWidth() - 190, disclaimerPositionY);

    // doc.save() will automatically replace characters cannot be used for a filename (\/:*?"<>|) with underscore
    doc.save('QuikSpec Summary - ' + values.quikspecName + '.pdf');
  };

  const addNotesToPDF = (doc: jsPDF, lineHeight: number) => {
    if (values.notes && values.notes.length > 0) {
      const notesHeader = ['Notes'];
      const notesData = values.notes.map((note, index) => [`${index + 1}. ` + note]);
      autoTable(doc, {
        head: [notesHeader],
        body: notesData,
        startY: doc.lastAutoTable.finalY + lineHeight * 2,
        pageBreak: 'auto'
      });
    }
  };

  const addWireInfoToPDF = (doc: jsPDF, lineHeight: number) => {
    if (values.wires && values.wires.length > 0) {
      const wireInfoHeader = [
        'System',
        'Wire #',
        'Description',
        'Max. Distance',
        'Jacket/Nom. O.D.',
        'Capacitance/Loop Res.'
      ];
      const wireInfoData = values.wires.map((wireInfo) => [
        wireInfo.system,
        wireInfo.wire,
        wireInfo.description,
        wireInfo.maxDistance,
        wireInfo.jacketNomOD,
        wireInfo.capacitanceLoopRes
      ]);
      doc.text('Aiphone Wire', 80, doc.lastAutoTable.finalY + lineHeight);
      autoTable(doc, {
        head: [wireInfoHeader],
        body: wireInfoData,
        startY: doc.lastAutoTable.finalY + lineHeight * 2,
        pageBreak: 'auto'
      });
    }
  };

  return (
    <Paper elevation={3} sx={styles.summaryPaper}>
      <Box sx={styles.sectionBox}>
        <Box sx={styles.quikspecSummaryBox}>
          <Typography variant="h3">QuikSpec Summary</Typography>
          <Box sx={styles.exportIcon} onClick={handleExportPDF}>
            <Grid container>
              <Grid item>
                <img src={`${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/PDF_32.png`} />
              </Grid>
              <Grid item sx={styles.pdfText}>
                Export as PDF
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box sx={styles.siteInfo}>
          <Typography variant="h6">Site Name: {values.quikspecName}</Typography>
          <Typography variant="h6">Market Type: {values.marketType}</Typography>
        </Box>
        <TableContainer component={Box}>
          <Box>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={styles.headerCell}>
                    <Typography variant="body2" align="center">
                      <strong>Qty</strong>
                    </Typography>
                  </TableCell>
                  <TableCell sx={styles.headerCell}>
                    <Typography variant="body2" align="center">
                      <strong>Model</strong>
                    </Typography>
                  </TableCell>
                  <TableCell sx={styles.headerCell}>
                    <Typography variant="body2">
                      <strong>Description</strong>
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={styles.tableBody}>
                {sortedItems.map((item) => {
                  const deviceKey: string = item.itemName.replace(/-/g, '');
                  return (
                    <TableRow key={item.itemName} sx={styles.row}>
                      <TableCell>
                        <Typography variant="body2" align="center">
                          {item.quantity}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" align="center">
                          {item.itemName}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {isValidDescriptionItemKey(deviceKey) && productlist[deviceKey]}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </TableContainer>
      </Box>
      {values.wires && values.wires.length > 0 ? (
        <Box sx={styles.sectionBox}>
          <Typography variant="h4">Aiphone Wire</Typography>
          <WireInfoTable wireInfoArray={values.wires} />
        </Box>
      ) : null}
      {values.notes && values.notes.length > 0 ? (
        <Box sx={styles.sectionBox}>
          <Typography variant="h4">Notes</Typography>
          {values.notes.map((note, index) => (
            <Typography key={index} textAlign="left" variant="body1">{`${index + 1}. ${note}`}</Typography>
          ))}
        </Box>
      ) : null}
      <Box sx={styles.sectionBox}>
        <Typography variant="body2" color="textSecondary" align="left">
          {quikspecDisclaimer}
        </Typography>
      </Box>
    </Paper>
  );
};

const styles = {
  summaryPaper: {
    padding: '1rem',
    margin: '1rem',
    borderRadius: '1rem'
  },
  sectionBox: {
    padding: '1rem',
    textAlign: 'center'
  },
  quikspecSummaryBox: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  siteInfo: {
    marginTop: '1rem',
    marginBottom: '1rem'
  },
  summaryTable: {
    width: '100%'
  },
  headerCell: {
    backgroundColor: theme.palette.primary.dark,
    color: 'white.main'
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#f2f2f2'
    }
  },
  tableBody: {
    borderRadius: '1rem'
  },
  tableHead: {
    backgroundColor: theme.palette.grey[300]
  },
  exportIcon: {
    position: { sm: 'relative', md: 'absolute' },
    right: 0,
    cursor: 'pointer'
  },
  pdfText: {
    position: 'relative',
    top: 3
  }
};
export default QuikspecSummary;
