import CONFIG from '../../../config';
import { ErrorType, ApiError } from '../ApiError';

const HTTP_API_URL = CONFIG.httpApiEndpoint;
const IXG_API_URL = CONFIG.ixgApiEndPoint;
const IXG_GET_SITE_JSON_URL = CONFIG.ixgApiEndPoint2;
const S3_ACL_URL = 'https://ixg-manage-upload-3.s3.ap-northeast-1.amazonaws.com';

// Different types of calls that can be made to ACL
type FuncType =
  | 'GetUploadURL'
  | 'GetRoomAuthCode_v2'
  | 'GetRoomAuthCodeProgress'
  | 'GetNextPropertyID'
  | 'CheckEmailDuplication'
  | 'GetUser'
  | 'GetClientGWInfo';

// Type of data that can be passed into call body
type CallData = object | number[] | { Email: string };

// Format of ACL call Body
interface AclApiCall {
  PropertyID?: number;
  Func: FuncType;
  Data?: CallData;
}

/**
 * Fetch call to ACL api
 * @param data Data body of call
 * @param idtoken Authorization token
 * @returns Result of fetch
 */
async function aclfetch(data: AclApiCall, idtoken: string) {
  try {
    const host = new URL(IXG_API_URL).host;
    return await fetch(IXG_API_URL, {
      method: 'POST',
      keepalive: true,
      headers: {
        Host: host,
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `${idtoken}`,
        'Content-Length': JSON.stringify(data).length.toString()
      },
      body: JSON.stringify(data),
      signal: AbortSignal.timeout(5000)
    });
  } catch (error) {
    if (error instanceof DOMException && error.name === 'AbortError') {
      throw new ApiError(`Error`, ErrorType.TIMEOUT);
    }
    // Handle errors before the request, such as around the network. Also handle other errors.
    throw new ApiError(`Error`, ErrorType.UNEXPECTED);
  }
}

/**
 * Get Preauthorised S3 URL to upload to ACL S3 bucket
 * @param propertyid Id of property to upload for
 * @param idtoken Authorization token
 * @returns Preauthorised S3 URL
 */
export async function GetUploadURL(propertyid: number, idtoken: string) {
  const data: AclApiCall = { PropertyID: propertyid, Func: 'GetUploadURL', Data: {} };

  const response = await aclfetch(data, idtoken);

  return handleResponse(response);
}

/**
 * Send message with room id's that you want to retreave auth codes for
 * @param propertyid Id of property
 * @param roomids Room Ids to retreave auth codes for
 * @param idtoken Authorization token
 * @returns Result of fetch call
 */
export async function GetRoomAuthCode(propertyid: number, roomids: number[], idtoken: string) {
  const data: AclApiCall = { PropertyID: propertyid, Func: 'GetRoomAuthCode_v2', Data: roomids };

  const response = await aclfetch(data, idtoken);

  return handleResponse(response);
}

/**
 * Check if Room auth codes are available
 * @param propertyid Id of property
 * @param idtoken Authorization token
 * @returns Result of fetch call
 */
export async function GetRoomAuthCodeProgress(propertyid: number, idtoken: string) {
  const data: AclApiCall = { PropertyID: propertyid, Func: 'GetRoomAuthCodeProgress', Data: {} };

  const response = await aclfetch(data, idtoken);

  return handleResponse(response);
}

/**
 * Retreave next room code
 * @param idtoken Authorization token
 * @returns Next room code
 */
export async function GetNextPropertyId(idtoken: string) {
  const data: AclApiCall = { Func: 'GetNextPropertyID' };
  const response = await aclfetch(data, idtoken);
  return handleResponse(response);
}

/**
 * Upload data to ACL using S3 Preauth URL
 * Need to use this call instead of S3 URL to avoid CORS in browser
 * @param url S3 Pre-authorised URL
 * @param data System data to send to ACL S3 bucket
 * @returns Respose if upload was successful
 */
export async function UploadData(url: string, data: any) {
  // Remove start URL for api call to proxy
  const modifedurl = url.replace(S3_ACL_URL, HTTP_API_URL + '/upload');

  const response = await fetch(modifedurl, {
    method: 'PUT',
    keepalive: true,
    headers: {
      'Content-Type': ''
    },
    body: JSON.stringify(data)
  });

  return response;
}

/**
 * Check is email is already in system
 * @param email User email
 * @returns Result if email already exists in system
 */
export async function checkemail(email: string) {
  const data: AclApiCall = { Func: 'CheckEmailDuplication', Data: { Email: email } };

  const response = await fetch(HTTP_API_URL + '/checkemail', {
    method: 'POST',
    keepalive: true,
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    },
    body: JSON.stringify(data)
  });
  return handleResponse(response);
}

/**
 * Retrieve user informarion
 * @param token Authorization token
 * @param userId UserId for the user
 * @returns User information
 */
export async function GetUserInfo(userId: any, token: any) {
  const data: AclApiCall = { Func: 'GetUser', Data: { UserID: userId } };
  const response = await aclfetch(data, token);
  return handleResponse(response);
}

/**
 * Retrieve GWs for the property
 * @param token Authorization token
 * @param publicId Unique identifier for a property in the system
 * @returns returns list of Gateways for the property
 */
export async function GetGWInfo(token: any, publicId: number) {
  const data: AclApiCall = { PropertyID: publicId, Func: 'GetClientGWInfo' };
  const response = await aclfetch(data, token);
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  return handleResponse(response);
}

export async function GetSiteJSON(token: any) {
  try {
    const response = await fetch(IXG_GET_SITE_JSON_URL + '/properties', {
      method: 'POST',
      keepalive: true,
      headers: {
        Host: 'execute-api.ap-northeast-1.amazonaws.com',
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: token
      },
      signal: AbortSignal.timeout(5000)
    });
    if (!response.ok) {
      throw new ApiError(`HTTP error! status: ${response.status}`, ErrorType.SERVER);
    }
    return await response.text();
  } catch (error) {
    if (error instanceof DOMException && error.name === 'AbortError') {
      throw new ApiError(`Error`, ErrorType.TIMEOUT);
    }
    // Handle errors before the request, such as around the network. Also handle other errors.
    throw new ApiError(`Error`, ErrorType.UNEXPECTED);
  }
}

/**
 * Handle response from fetch call
 * @param response Response from fetch call
 * @returns Response body
 */
async function handleResponse(response: Response) {
  const responseJson = await response.json();
  if (!response.ok || responseJson.StatusCode !== 200) {
    throw new ApiError(
      `Response error! status: ${response.status}, body: ${JSON.stringify(responseJson)}`,
      ErrorType.SERVER
    );
  }
  return responseJson;
}
