import { createContext, ReactNode, useState } from 'react';

interface AuthProviderProps {
  children?: ReactNode;
}

interface ISiteContext {
  siteName: string;
  setSiteName: (newState: string) => void;
  publicId: string;
  setPublicId: (newState: string) => void;
}

const initialValue = {
  siteName: '',
  setSiteName: () => undefined,
  publicId: '',
  setPublicId: () => undefined
};

const SiteContext = createContext<ISiteContext>(initialValue);

const SiteProvider = ({ children }: AuthProviderProps) => {
  const [siteName, setSiteName] = useState(initialValue.siteName);
  const [publicId, setPublicId] = useState(initialValue.publicId);

  return (
    <SiteContext.Provider
      value={{
        siteName,
        setSiteName,
        publicId,
        setPublicId
      }}
    >
      {children}
    </SiteContext.Provider>
  );
};

export { SiteContext, SiteProvider };
