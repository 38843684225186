import { useState } from 'react';
import { AppBar, Badge, Box, Drawer, Hidden, IconButton, Toolbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SensorDoorSharpIcon from '@mui/icons-material/SensorDoorSharp';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link, redirect, useLocation, useNavigate } from 'react-router-dom';
import { resetUsersState } from 'store';
import { useDispatch } from 'react-redux';
import GatewayTest from 'test/TestGateway'; //will remove for production
import Tooltip from '@mui/material/Tooltip';

function AppHeader(props: any) {
  const [isNotificationsDrawerOpen, setIsNotificationsDrawerOpen] = useState(false);
  const [isGatewayDrawerOpen, setIsGatewayDrawerOpen] = useState(false);
  const currentUrl = window.location.href;
  const isPrductionAccount = !/localhost|beta|alpha/.test(currentUrl);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const logout = () => {
    dispatch(resetUsersState());
    localStorage.clear();
    redirect('/');
    window.location.reload();
  };

  return (
    <AppBar position="sticky" sx={styles.appBar}>
      <Toolbar sx={styles.toolBar}>
        <IconButton
          onClick={() => {
            props.setCollapsed(!props.collapsed);
            if (window.innerWidth < 600) {
              props.setToggled(!props.toggled);
            }
          }}
        >
          <MenuIcon fontSize="large" htmlColor="#ffffff" sx={styles.menuIcon} />
        </IconButton>
        <Hidden smDown>
          <Link to="/">
            <Box component="img" sx={styles.appLogo} src="/AiphoneCloud-Logo_Header.png" />
          </Link>
        </Hidden>
        <Box sx={{ flexGrow: 1 }} />

        {/********************  Hide for production and staging - start */}
        {!isPrductionAccount && (
          <Tooltip title="Developer only">
            <IconButton aria-label="" onClick={() => setIsGatewayDrawerOpen(!isGatewayDrawerOpen)}>
              <Badge badgeContent={0} overlap="circular">
                <SensorDoorSharpIcon fontSize="large" htmlColor="#ffffff" sx={styles.menuIcon} />
              </Badge>
            </IconButton>
          </Tooltip>
        )}
        {!isPrductionAccount && (
          <IconButton aria-label="" onClick={() => setIsNotificationsDrawerOpen(!isNotificationsDrawerOpen)}>
            <Badge badgeContent={0} overlap="circular">
              <NotificationsIcon fontSize="large" htmlColor="#ffffff" sx={styles.menuIcon} />
            </Badge>
          </IconButton>
        )}

        {/********************  Hide for production and staging - end */}

        <IconButton aria-label="" onClick={() => navigate('/profile')}>
          <AccountCircleIcon
            fontSize="large"
            htmlColor={location.pathname === '/profile' ? '#1CACFC' : '#ffffff'}
            sx={styles.menuIcon}
          />
        </IconButton>
        {/* TODO: Create better logged in state */}
        <Link to="/">
          <IconButton aria-label="" onClick={() => logout()}>
            <LogoutIcon fontSize="large" htmlColor="#ffffff" sx={styles.menuIcon} />
          </IconButton>
        </Link>
      </Toolbar>

      {/****************************** Will delete for production - start */}

      <Drawer anchor="right" open={isGatewayDrawerOpen} onClose={() => setIsGatewayDrawerOpen(false)}>
        <Box sx={styles.drawer}>
          <GatewayTest />
        </Box>
      </Drawer>
      {/*******************************  Will delete for production - end */}

      <Drawer anchor="right" open={isNotificationsDrawerOpen} onClose={() => setIsNotificationsDrawerOpen(false)}>
        <Box sx={styles.drawer}>
          <Typography variant="h6" color="initial">
            Notifications
          </Typography>
        </Box>
      </Drawer>
    </AppBar>
  );
}

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  appBar: {
    bgcolor: 'primary',
    paddingLeft: 0,
    top: 0
  },
  appLogo: {
    ml: 2,
    cursor: 'pointer',
    width: '10rem',
    marginTop: '.5rem'
    // '@media  (min-width: 600px)': {
    //   width: '2rem'
    // }
  },
  menuIcon: {
    '&:hover': {
      color: '#1CACFC'
    }
  },
  toolBar: {
    paddingLeft: '12px',
    '@media (min-width: 600px)': {
      paddingLeft: '12px'
    }
  },
  drawer: {
    padding: '2rem',
    width: '20rem'
  }
};

export default AppHeader;
