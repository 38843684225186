import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllUsers, getUserById } from 'shared/api/Aws/awsApi';
import { ErrorType } from '../../shared/api/ApiError';
import { RootState } from 'store';
import { aiphoneCloudApi } from 'services/aiphoneCloud';

export type CloudUserResponse = {
  c2_company_id: string;
  c2_user_id: string | null;
  cloud_organization_public_id: string;
  created_on: string;
  email: string;
  first_name: string;
  id: number;
  is_deleted: boolean;
  language: string | null;
  last_name: string;
  last_updated_on: string;
  marketing_opt_in: boolean;
  phone_extension: string | null;
  phone_number: string;
  public_id: string;
  role: string | null;
  title: string | null;
};

export type CloudUser = {
  publicId: string;
  countryId: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  role: string | null;
  c2UserId: string | null;
  createdOn: string;
  lastUpdatedOn: string;
  marketingOptIn: boolean;
  title: string | null;
  languageId: string | null;
  phoneExtension: string | null;
  permissions: IUserPermissions;
};

interface IPermission {
  [key: string]: {
    role: {
      features: string[];
      publicId: string;
      roleName: string;
    };
  };
}

export interface IRole {
  featureList: string[];
  publicId: string;
  roleName: string;
}

export interface IBranchPermissions {
  [key: string]: {
    companyName: string;
    branchName: string;
    roleList: IRole[];
  };
}

export interface IUserPermissions {
  site: IPermission[];
  branch: IBranchPermissions;
  global: IGlobalRole;
}

interface IUserRole {
  publicId: string;
  roleName: string;
}

interface IGlobalRole {
  features: string[];
  publicId: string;
  roleName: string;
}

interface IAvailableRoles {
  site: IUserRole[];
  branch: IUserRole[];
  global?: IGlobalRole[];
}

interface UsersState {
  users: CloudUser[];
  currentUser: CloudUser | null;
  usersError: string | null;
  loading: boolean;
  availableRoles: IAvailableRoles | null;
}

const initialState: UsersState = {
  users: [],
  currentUser: null,
  usersError: null,
  loading: false,
  availableRoles: null
};

/**Fetch all cloud users*/
export const fetchUsers = createAsyncThunk<CloudUserResponse[], void, { rejectValue: { errorMessage: ErrorType } }>(
  'users/fetchUsers',
  async (_, { rejectWithValue }) => {
    const response = await getAllUsers();
    if (!response?.data) {
      return rejectWithValue({ errorMessage: ErrorType.SERVER });
    }
    return response.data;
  }
);

/**Fetch cloud user by user id*/
export const fetchUserById = createAsyncThunk<
  CloudUser,
  { userSub: string },
  { rejectValue: { errorMessage: ErrorType } }
>('users/fetchUserById', async (params, { rejectWithValue }) => {
  const response = await getUserById(params);
  if (!response?.data) {
    return rejectWithValue({ errorMessage: ErrorType.SERVER });
  }
  return response.data;
});

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    addUsers: (state, action) => {
      const incomingUser = action.payload;
      incomingUser.forEach((iUser: CloudUser) => {
        const index = state.users.findIndex((user: CloudUser) => user.publicId === iUser.publicId);
        if (index !== -1) {
          state.users[index] = iUser;
        } else {
          state.users.push(iUser);
        }
      });
    },
    resetUsersState: (state) => {
      state.users = [];
      state.currentUser = null;
    },
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
    setAssignableRoles: (state, action) => {
      const rolesData = action.payload;

      const site = rolesData.site
        ? rolesData.site.map((role: any) => ({
            publicId: role.publicId,
            roleName: role.roleName
          }))
        : [];

      const branch = rolesData.branch
        ? rolesData.branch.map((role: any) => ({
            publicId: role.publicId,
            roleName: role.roleName
          }))
        : [];

      const global = rolesData.global
        ? rolesData.global.map((role: any) => ({
            publicId: role.publicId,
            roleName: role.roleName
          }))
        : [];

      state.availableRoles = { site, branch, global };
    },
    setUserProfile: (state, action) => {
      state.currentUser.firstName = action.payload.firstName;
      state.currentUser.lastName = action.payload.lastName;
      state.currentUser.phoneNumber = action.payload.phoneNumber;
      state.currentUser.marketingOptIn = action.payload.marketingOptIn;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        const updatedUserStructure = action.payload.map((user) => {
          return {
            c2UserId: user.c2_user_id,
            cloudOrganizationPublicId: user.cloud_organization_public_id,
            createdOn: user.created_on,
            email: user.email,
            firstName: user.first_name,
            language: user.language,
            lastName: user.last_name,
            lastUpdatedOn: user.last_updated_on,
            marketingOptIn: user.marketing_opt_in,
            phoneExtension: user.phone_extension,
            phoneNumber: user.phone_number,
            publicId: user.public_id,
            role: user.role,
            title: user.title
          };
        });

        state.users = updatedUserStructure;
        state.loading = false;
      })
      .addCase(fetchUsers.rejected, (state) => {
        state.loading = false;
        state.usersError = 'Error during fetching users.';
      })
      .addCase(fetchUserById.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserById.fulfilled, (state, action) => {
        state.currentUser = action.payload;
        state.loading = false;
      })
      .addCase(fetchUserById.rejected, (state) => {
        state.loading = false;
        state.usersError = 'Error during fetching user by id.';
      })
      .addMatcher(aiphoneCloudApi.endpoints.getUserWithPublicId.matchFulfilled, (state, action) => {
        const cloudUser: CloudUser = action.payload;
        state.currentUser = cloudUser;
        sessionStorage.setItem('user', JSON.stringify(cloudUser));
      })
      .addMatcher(aiphoneCloudApi.endpoints.getAssignableRoles.matchFulfilled, (state, action) => {
        const rolesData = action.payload;
        const site = rolesData.site
          ? rolesData.site.map((role: any) => ({
              publicId: role.publicId,
              roleName: role.roleName
            }))
          : [];

        const branch = rolesData.branch
          ? rolesData.branch.map((role: any) => ({
              publicId: role.publicId,
              roleName: role.roleName
            }))
          : [];

        const global = rolesData.global
          ? rolesData.global.map((role: any) => ({
              publicId: role.publicId,
              roleName: role.roleName
            }))
          : [];

        state.availableRoles = { site, branch, global };
      });
  }
});

export const getCurrentUser = (state: RootState) => state.users.currentUser;

export const { addUsers, resetUsersState, setCurrentUser, setAssignableRoles, setUserProfile } = usersSlice.actions;
export const usersReducer = usersSlice.reducer;
