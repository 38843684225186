import { useState } from 'react';
import { Box, Container, Tab, Tabs } from '@mui/material';
import Site from './Site/Site';

const SiteInfo = () => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const handleTabChange = (e: any, tabIndex: number) => {
    setCurrentTabIndex(tabIndex);
  };

  return (
    <Container maxWidth="xl">
      <Box sx={styles.siteContainer}>
        <Tabs value={currentTabIndex} onChange={handleTabChange}>
          <Tab label="Site" />
          <Tab label="Installer Information" />
        </Tabs>
      </Box>
      {/* SITE */}
      {currentTabIndex === 0 && <Site />}
    </Container>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  mainContainer: {
    width: '100%'
  },
  siteContainer: {
    borderBottom: 1,
    borderColor: 'divider'
  }
};

export default SiteInfo;
