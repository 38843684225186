import React, { FC } from 'react';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedDevice, IDeviceDeviceSettings, setDeviceDeviceSettings } from 'store/slices/devicesSlice';
import { Form, Formik } from 'formik';
import { Button } from '@mui/material';
import { getString } from 'shared/utils/LocalizationUtils';
import { useUpdateDeviceMutation } from 'services/aiphoneCloud';
import containerStyle from 'shared/styles/advancedSettingContainerStyle';
import {
  VolumeExternalMicrophone,
  VolumeExternalSpeaker,
  VolumeIncomingHandset,
  VolumeIncomingSpeaker,
  VolumeNotification,
  VolumeOperation,
  VolumeOutgoingHandset,
  VolumeOutgoingSpeaker
} from './Components';

export const VolumeLabel = () => {
  return <span>{getString('AdvancedSettings_Tab_Volume')}</span>;
};

export interface IVolumeProps {
  defaultValue?: number;
}

const Volume: FC | null = () => {
  const dispatch = useDispatch();
  const [updateDevice, { isLoading: isUpdating }] = useUpdateDeviceMutation();
  const { publicId, unitPublicId, deviceSettings } = useSelector(getSelectedDevice);

  /*Validation schema for all fields from deviceSettings which we want to edit.*/
  const validationSchema = yup.object().shape({
    volumeIncomingHandset: yup.number(),
    volumeExternalMicrophone: yup.number(),
    volumeExternalSpeaker: yup.number(),
    volumeIncomingSpeaker: yup.number(),
    volumeNotification: yup.number(),
    volumeOperation: yup.boolean(),
    volumeOutgoingHandset: yup.number(),
    volumeOutgoingSpeaker: yup.number()
  });

  /*Make API request to update deviseSettings in DB and update deviceSettings in Redux*/
  const onSubmit = async (values: IDeviceDeviceSettings) => {
    const params = {
      device: {
        deviceSettings: values,
        publicId,
        unitPublicId
      }
    };

    updateDevice(params);
    dispatch(setDeviceDeviceSettings(params));
  };

  if (!deviceSettings) return null;

  return (
    <Formik initialValues={deviceSettings} onSubmit={onSubmit} validationSchema={validationSchema} enableReinitialize>
      {({ dirty, isSubmitting }) => {
        return (
          <Form style={containerStyle.form}>
            <div style={containerStyle.gridContainer}>
              <VolumeIncomingSpeaker defaultValue={deviceSettings.volumeIncomingSpeaker} />
              <VolumeIncomingHandset defaultValue={deviceSettings.volumeIncomingHandset} />
              <VolumeNotification defaultValue={deviceSettings.volumeNotification} />
              <VolumeOutgoingSpeaker defaultValue={deviceSettings.volumeOutgoingSpeaker} />
              <VolumeOutgoingHandset defaultValue={deviceSettings.volumeOutgoingHandset} />
              <VolumeExternalSpeaker defaultValue={deviceSettings.volumeExternalSpeaker} />
              <VolumeExternalMicrophone defaultValue={deviceSettings.volumeExternalMicrophone} />
              <VolumeOperation defaultValue={deviceSettings.volumeOperation} />
            </div>
            <div style={containerStyle.submitContainer}>
              <Button
                type="reset"
                variant="contained"
                color="primary"
                disabled={!dirty || isSubmitting || isUpdating}
                style={containerStyle.submitBarButton}
              >
                {getString('Button_Reset')}
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!dirty || isSubmitting || isUpdating}
                style={containerStyle.submitBarButton}
              >
                {getString('Button_Submit')}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Volume;
