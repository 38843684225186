import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import {
  Alert,
  AlertTitle,
  Divider,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box } from '@mui/system';
import Spinner from 'features/SimBilling/Components/UiParts/Spinner';
import {
  useAclAuth,
  useFetchInvoices,
  useFetchSubscriptionDetail,
  useFetchSubscriptionPlans,
  usePropertyLogic
} from 'features/SimBilling/Hooks';
import { isAclUserThePayer } from 'features/SimBilling/Utils/SimBillingUtils';

const Billing = () => {
  const emptyValue = '-';
  const { aclToken } = useAclAuth();

  /**
   * Lifecycle Methods Section
   */
  const [error, setError] = useState<string | null>(null);
  const [plan, setPlan] = useState<string | null>(null);
  const { user, siteDetails, subscriptionId, userError, siteError } = usePropertyLogic();
  const { invoicesList, invoicesError } = useFetchInvoices(subscriptionId);
  const { currentPriceId, subscriptionDetail, subscriptionDetailError } = useFetchSubscriptionDetail(subscriptionId);

  const countryCodeForSubscriptionPlans =
    siteDetails?.Payer === 'Dealer' ? user?.CompanyCountryCode : siteDetails?.CountryCode;
  const { subscriptionPlans, subscriptionPlansError } = useFetchSubscriptionPlans(
    countryCodeForSubscriptionPlans ?? null
  );

  useEffect(() => {
    if (invoicesError) {
      setError(`Error encountered while retrieving invoices. Error: ${invoicesError}`);
    } else if (subscriptionDetailError) {
      setError(`Error encountered while retrieving subscription details. Error: ${subscriptionDetailError}`);
    } else if (subscriptionPlansError) {
      setError(`Error encountered while retrieving subscription plans. Error: ${subscriptionPlansError}`);
    } else if (userError || siteError) {
      setError('Error encountered. Please try again.');
    }
  }, [userError, siteError, invoicesError, subscriptionDetailError, subscriptionPlansError]);
  useEffect(() => {
    if (subscriptionDetail && subscriptionPlans) {
      const matchingPlan = subscriptionPlans.find((plan) => plan.priceID === currentPriceId);
      if (matchingPlan) {
        setPlan(matchingPlan.productName);
      }
    }
  }, [currentPriceId, subscriptionPlans]);

  const isLoading = !(
    siteDetails &&
    !userError &&
    !siteError &&
    !invoicesError &&
    !subscriptionDetailError &&
    !subscriptionPlansError &&
    (subscriptionId == null || invoicesList != null)
  );

  /**Format UNIX timestamp to a readable date*/
  const formatDate = (timestamp: number, locale = 'default', options: Intl.DateTimeFormatOptions = {}) => {
    const date = new Date(timestamp * 1000);
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }
    return date.toLocaleDateString(locale, { year: 'numeric', month: 'long', day: 'numeric', ...options });
  };

  if (!aclToken) {
    return <Navigate replace to="/simbilling" />;
  }

  return (
    <>
      {isLoading && !error && <Spinner />}
      <Box>
        <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
          <Typography variant="sectionHeader" gutterBottom>
            Billing
          </Typography>
          {error && (
            <Alert severity="error" sx={styles.error}>
              <AlertTitle>Error: </AlertTitle>
              {error}
            </Alert>
          )}
          {user && siteDetails && isAclUserThePayer(user, siteDetails) && (
            <Box>
              <Typography variant="h6" sx={styles.labelsWithMargin}>
                Subscription:
                <Typography variant="body1" display="inline" sx={styles.paymentInformationValues}>
                  {plan ? plan : emptyValue}
                </Typography>
              </Typography>
              <Typography variant="h6" sx={styles.labelsWithMargin}>
                Subscription Status:
                <Typography
                  variant="body1"
                  display="inline"
                  sx={styles.subscriptionStatus(subscriptionDetail?.status || null)}
                >
                  {subscriptionDetail?.status || 'N/A'}
                </Typography>
              </Typography>
              {subscriptionDetail?.status !== 'canceled' && (
                <Typography variant="h6" sx={styles.labelsWithMargin}>
                  Next Payment Date:
                  <Typography variant="body1" display="inline" sx={styles.paymentInformationValues}>
                    {subscriptionDetail ? formatDate(subscriptionDetail.current_period_end) : emptyValue}
                  </Typography>
                </Typography>
              )}
              <Divider />
              <Box sx={styles.componentWithTopMargin}>
                <Typography variant="h5" sx={styles.labelsWithMargin}>
                  Billing History
                </Typography>
                {invoicesList && invoicesList.length > 0 ? (
                  <TableContainer component={Paper}>
                    <Table aria-label="billing history table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Invoice Number</TableCell>
                          <TableCell>Payment Date</TableCell>
                          <TableCell>Description</TableCell>
                          <TableCell>Paid</TableCell>
                          <TableCell align="left">Amount</TableCell>
                          <TableCell align="left">Currency</TableCell>
                          <TableCell align="center">Invoice URL</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {invoicesList.map((invoice, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell component="th" scope="row">
                                {invoice.number}
                              </TableCell>
                              <TableCell>{formatDate(invoice.created)}</TableCell>
                              <TableCell size="small">{invoice.description}</TableCell>
                              <TableCell>{invoice.paid ? 'Yes' : 'No'}</TableCell>
                              <TableCell>${invoice.total / 100}</TableCell>
                              <TableCell>${invoice.currency.toUpperCase()}</TableCell>

                              <TableCell size="small" align="center">
                                <Link target="_blank" href={invoice.hosted_invoice_url} variant="body2">
                                  <OpenInNewIcon color="primary" />
                                </Link>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Box>
                    <Typography variant="h6">No invoices exist for this subscription</Typography>
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </Paper>
      </Box>
    </>
  );
};

const styles = {
  card: {
    minWidth: 275
  },
  labelsWithMargin: {
    mb: 2,
    mt: 2
  },
  paymentInformationValues: {
    ml: 1
  },
  componentWithTopMargin: {
    mt: 2
  },
  title: {
    fontSize: 14,
    color: 'text.secondary',
    gutterBottom: true
  },
  balance: {
    mb: 1.5,
    color: 'text.secondary'
  },
  autoPay: {
    variant: 'body2'
  },
  list: {
    component: 'nav',
    ariaLabel: 'payments'
  },
  cellSize: {
    small: '',
    medium: '',
    large: ''
  },
  error: {
    mb: 2
  },
  subscriptionStatus: (status: string | null) => {
    let color;
    switch (status) {
      case 'active':
        color = '#4caf50';
        break;
      case 'canceled':
        color = '#f44336';
        break;
      case 'past_due':
        color = '#ff9800';
        break;
      default:
        color = 'black';
        break;
    }
    return {
      color: color,
      marginLeft: 1,
      fontWeight: 'bold'
    };
  }
};

export default Billing;
