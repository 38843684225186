import { Box, MenuItem, Tooltip, Button, Select, TextField, CircularProgress, Checkbox, Slider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedDevice, updateSelectedDevice } from 'store/slices/devicesSlice';
import * as Yup from 'yup';
import { Form, Formik, Field } from 'formik';
import { Grid } from '@mui/material';
import { getString } from 'shared/utils/LocalizationUtils';
import { useUpdateDeviceMutation } from 'services/aiphoneCloud';
import containerStyle from 'shared/styles/advancedSettingContainerStyle';
import { RegexIpV4, RegexIpV6 } from 'features/RemoteManagement/Types';
import stringUtils from 'shared/utils/StringUtils';
import SnackbarAlert from 'shared/components/SnackbarAlert';
import { useEffect, useState } from 'react';
import { EnumList, fetchEnumList } from 'shared/utils/EnumUtils';

export const IPAddressLabel = () => {
  return (
    <Tooltip title={getString('AdvancedSettings_Tab_Tooltip_IpAddress')}>
      <span>{getString('AdvancedSettings_Tab_IpAddress')}</span>
    </Tooltip>
  );
};

const IPAddress = () => {
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [updateDevice, { isLoading: isUpdating }] = useUpdateDeviceMutation();
  const selectedDevice = useSelector(getSelectedDevice);
  const [enumList, setEnumList] = useState<EnumList>({ country: {}, state: {} });
  const [formikSchema, setFormikSchema] = useState<Yup.ObjectSchema<any> | null>(null);
  const [fetchingEnums, setFetchingEnums] = useState(true);
  const fieldErrorMaxLen = getString('Field_Error_MaxLen');
  const fieldErrorRequired = getString('Field_Error_Required');
  const hostNameTitle = getString('AdvancedSettings_HostName_Title');
  const hostNameDesc = getString('AdvancedSettings_HostName_Desc');
  const ipVersionTitle = getString('AdvancedSettings_IpVersion_Title');
  const ipVersionDesc = getString('AdvancedSettings_IpVersion_Desc');
  const ipAddressMethodTitle = getString('AdvancedSettings_IpAddressMethod_Title');
  const ipAddressMethodDesc = getString('AdvancedSettings_IpAddressMethod_Desc');
  const subnetMaskTitle = getString('AdvancedSettings_SubnetMask_Title');
  const subnetMaskDesc = getString('AdvancedSettings_SubnetMask_Desc');
  const ipV4DefaultGatewayTitle = getString('AdvancedSettings_IpV4DefaultGateway_Title');
  const ipV4DefaultGatewayDesc = getString('AdvancedSettings_IpV4DefaultGateway_Desc');
  const ipV4PrimaryDnsTitle = getString('AdvancedSettings_IpV4PrimaryDns_Title');
  const ipV4PrimaryDnsDesc = getString('AdvancedSettings_IpV4PrimaryDns_Desc');
  const ipV4SecondaryDnsTitle = getString('AdvancedSettings_IpV4SecondaryDns_Title');
  const ipV4SecondaryDnsDesc = getString('AdvancedSettings_IpV4SecondaryDns_Desc');
  const ipV4AddressTitle = getString('AdvancedSettings_IpV4Address_Title');
  const ipV4AddressDesc = getString('AdvancedSettings_IpV4Address_Desc');
  const ipV4AddressErrorInvalid = getString('IpV4Address_Error_Invalid');
  const ipV4MulticastAddressTitle = getString('AdvancedSettings_IpV4MulticastAddress_Title');
  const ipV4MulticastAddressDesc = getString('AdvancedSettings_IpV4MulticastAddress_Desc');
  const ipV6AddressErrorInvalid = getString('IpV6Address_Error_Invalid');
  const ipV6AddressTitle = getString('AdvancedSettings_IpV6Address_Title');
  const ipV6AddressDesc = getString('AdvancedSettings_IpV6Address_Desc');
  const ipV6DefaultGatewayTitle = getString('AdvancedSettings_IpV6DefaultGateway_Title');
  const ipV6DefaultGatewayDesc = getString('AdvancedSettings_IpV6DefaultGateway_Desc');
  const ipV6PrimaryDnsTitle = getString('AdvancedSettings_IpV6PrimaryDns_Title');
  const ipV6PrimaryDnsDesc = getString('AdvancedSettings_IpV6PrimaryDns_Desc');
  const ipV6SecondaryDnsTitle = getString('AdvancedSettings_IpV6SecondaryDns_Title');
  const ipV6SecondaryDnsDesc = getString('AdvancedSettings_IpV6SecondaryDns_Desc');
  const ipV6MulticastAddressTitle = getString('AdvancedSettings_IpV6MulticastAddress_Title');
  const ipV6MulticastAddressDesc = getString('AdvancedSettings_IpV6MulticastAddress_Desc');
  const errorUpdateDevice = getString('AdvancedSettings_Error_UpdateDevice');
  const successUpdateDevice = getString('AdvancedSettings_Success_UpdateDevice');
  const vlanSettingsTitle = getString('AdvancedSettings_VlanSettings_Title');
  const vlanSettingsDesc = getString('AdvancedSettings_VlanSettings_Desc');
  const vlanIdTitle = getString('AdvancedSettings_VlanId_Title');
  const vlanIdDesc = getString('AdvancedSettings_VlanId_Desc');
  const vlanPriorityTitle = getString('AdvancedSettings_VlanPriority_Title');
  const vlanPriorityDesc = getString('AdvancedSettings_VlanPriority_Desc');
  const buttonSubmit = getString('Button_Submit');
  const buttonReset = getString('Button_Reset');
  const vlanPriorityMin = 0;
  const vlanPriorityMax = 7;
  const formDevice = {
    hostName: selectedDevice.networkSettings?.hostName,
    subnetMask: selectedDevice.networkSettings?.subnetMask,
    ipVersion: selectedDevice.networkSettings?.ipVersion,
    ipAddressMethod: selectedDevice.networkSettings?.ipAddressMethod,
    ipV4Address: selectedDevice.networkSettings?.ipV4Address,
    ipV4DefaultGateway: selectedDevice.networkSettings?.ipV4DefaultGateway,
    ipV6Address: selectedDevice.networkSettings?.ipV6Address,
    ipV6DefaultGateway: selectedDevice.networkSettings?.ipV6DefaultGateway,
    ipV4PrimaryDns: selectedDevice.networkSettings?.ipV4PrimaryDns,
    ipV4SecondaryDns: selectedDevice.networkSettings?.ipV4SecondaryDns,
    ipV6PrimaryDns: selectedDevice.networkSettings?.ipV6PrimaryDns,
    ipV6SecondaryDns: selectedDevice.networkSettings?.ipV6SecondaryDns,
    ipV4MulticastAddress: selectedDevice.networkSettings?.ipV4MulticastAddress,
    ipV6MulticastAddress: selectedDevice.networkSettings?.ipV6MulticastAddress,
    vlanSettings: selectedDevice.networkSettings?.vlanSettings,
    vlanId: selectedDevice.networkSettings?.vlanId,
    vlanPriority: selectedDevice.networkSettings?.vlanPriority
  };

  useEffect(() => {
    fetchEnumList().then((data) => {
      setEnumList(data);
      setFetchingEnums(false);
    });
  }, []);

  const getValidationSchema = (currValues: any) => {
    const networkSchema: any = {};

    if (formDevice.hostName !== null && formDevice.hostName !== undefined) {
      const hostNameMaxLen = 64;
      networkSchema.hostName = Yup.string().max(
        hostNameMaxLen,
        stringUtils.format(fieldErrorMaxLen, hostNameTitle, hostNameMaxLen)
      );
    }

    if (currValues.ipVersion && enumList.ipVersion[currValues.ipVersion].value === 'IPV4') {
      if (formDevice.ipV4Address !== null && formDevice.ipV4Address !== undefined) {
        networkSchema.ipV4Address = Yup.string().matches(new RegExp(`^${RegexIpV4}$`), ipV4AddressErrorInvalid);
      }

      if (formDevice.subnetMask !== null && formDevice.subnetMask !== undefined) {
        networkSchema.subnetMask = Yup.string().matches(new RegExp(`^${RegexIpV4}$`), ipV4AddressErrorInvalid);
      }

      if (formDevice.ipV4DefaultGateway !== null && formDevice.ipV4DefaultGateway !== undefined) {
        networkSchema.ipV4DefaultGateway = Yup.string().matches(new RegExp(`^${RegexIpV4}$`), ipV4AddressErrorInvalid);
      }

      if (formDevice.ipV4PrimaryDns !== null && formDevice.ipV4PrimaryDns !== undefined) {
        networkSchema.ipV4PrimaryDns = Yup.string().matches(new RegExp(`^${RegexIpV4}$`), ipV4AddressErrorInvalid);
      }

      if (formDevice.ipV4SecondaryDns !== null && formDevice.ipV4SecondaryDns !== undefined) {
        networkSchema.ipV4SecondaryDns = Yup.string().matches(new RegExp(`^${RegexIpV4}$`), ipV4AddressErrorInvalid);
      }

      if (formDevice.ipV4MulticastAddress !== null && formDevice.ipV4MulticastAddress !== undefined) {
        networkSchema.ipV4MulticastAddress = Yup.string().matches(
          new RegExp(`^${RegexIpV4}$`),
          ipV4AddressErrorInvalid
        );
      }
    } else if (currValues.ipVersion && enumList.ipVersion[currValues.ipVersion].value === 'IPV6') {
      if (formDevice.ipV6Address !== null && formDevice.ipV6Address !== undefined) {
        networkSchema.ipV6Address = Yup.string().matches(new RegExp(`^${RegexIpV6}$`), ipV6AddressErrorInvalid);
      }

      if (formDevice.ipV6DefaultGateway !== null && formDevice.ipV6DefaultGateway !== undefined) {
        networkSchema.ipV6DefaultGateway = Yup.string().matches(new RegExp(`^${RegexIpV6}$`), ipV6AddressErrorInvalid);
      }

      if (formDevice.ipV6PrimaryDns !== null && formDevice.ipV6PrimaryDns !== undefined) {
        networkSchema.ipV6PrimaryDns = Yup.string().matches(new RegExp(`^${RegexIpV6}$`), ipV6AddressErrorInvalid);
      }

      if (formDevice.ipV6SecondaryDns !== null && formDevice.ipV6SecondaryDns !== undefined) {
        networkSchema.ipV6SecondaryDns = Yup.string().matches(new RegExp(`^${RegexIpV6}$`), ipV6AddressErrorInvalid);
      }

      if (formDevice.ipV6MulticastAddress !== null && formDevice.ipV6MulticastAddress !== undefined) {
        networkSchema.ipV6MulticastAddress = Yup.string().matches(
          new RegExp(`^${RegexIpV6}$`),
          ipV6AddressErrorInvalid
        );
      }
    }

    if (formDevice.vlanSettings !== null && formDevice.vlanSettings !== undefined) {
      networkSchema.vlanSettings = Yup.boolean();
    }

    if (formDevice.vlanId !== null && formDevice.vlanId !== undefined) {
      const vlanIdMin = 0;
      const vlanIdMax = 4094;
      networkSchema.vlanId = Yup.number().min(vlanIdMin).max(vlanIdMax);
    }

    if (formDevice.vlanPriority !== null && formDevice.vlanPriority !== undefined) {
      networkSchema.vlanPriority = Yup.number().min(vlanPriorityMin).max(vlanPriorityMax);
    }

    return Yup.object(networkSchema);
  };

  const onSubmit = async (values: any, actions: any) => {
    const params = {
      device: {
        publicId: selectedDevice.publicId,
        networkSettings: {
          hostName: values.hostName,
          subnetMask: values.subnetMask,
          ipVersion: values.ipVersion,
          ipAddressMethod: values.ipAddressMethod,
          ipV4Address: null,
          ipV4DefaultGateway: null,
          ipV6Address: null,
          ipV6DefaultGateway: null,
          ipV4PrimaryDns: null,
          ipV4SecondaryDns: null,
          ipV6PrimaryDns: null,
          ipV6SecondaryDns: null,
          ipV4MulticastAddress: null,
          ipV6MulticastAddress: null,
          vlanSettings: values.vlanSettings,
          vlanId: values.vlanId,
          vlanPriority: values.vlanPriority
        }
      }
    };

    if (enumList.ipVersion[values.ipVersion].value === 'IPV4') {
      params.device.networkSettings.ipV4Address = values.ipV4Address;
      params.device.networkSettings.ipV4DefaultGateway = values.ipV4DefaultGateway;
      params.device.networkSettings.ipV4PrimaryDns = values.ipV4PrimaryDns;
      params.device.networkSettings.ipV4SecondaryDns = values.ipV4SecondaryDns;
      params.device.networkSettings.ipV4MulticastAddress = values.ipV4MulticastAddress;
    } else if (formDevice.ipVersion && enumList.ipVersion[formDevice.ipVersion].value === 'IPV6') {
      params.device.networkSettings.ipV6Address = values.ipV6Address;
      params.device.networkSettings.ipV6DefaultGateway = values.ipV6DefaultGateway;
      params.device.networkSettings.ipV6PrimaryDns = values.ipV6PrimaryDns;
      params.device.networkSettings.ipV6SecondaryDns = values.ipV6SecondaryDns;
      params.device.networkSettings.ipV6MulticastAddress = values.ipV6MulticastAddress;
    }

    const newDevice = JSON.parse(JSON.stringify(selectedDevice));

    newDevice.networkSettings = {
      ...newDevice.networkSettings,
      ...values
    };

    updateDevice(params)
      .then((response) => {
        if ('error' in response) {
          throw response.error;
        }

        dispatch(updateSelectedDevice({ device: newDevice }));
        setShowAlert(true);
        actions.resetForm({
          values: values
        });
      })
      .catch(() => {
        setErrorMessage(errorUpdateDevice);
      });
  };

  if (!fetchingEnums && !formikSchema) {
    setFormikSchema(getValidationSchema(formDevice));
  }

  return (
    <Box sx={containerStyle.settingsPageContainer}>
      <SnackbarAlert
        type="error"
        time={10000}
        text={`${errorMessage}`}
        isOpen={!!errorMessage}
        onClose={() => setErrorMessage(null)}
      />
      <SnackbarAlert
        type="success"
        time={3000}
        text={successUpdateDevice}
        isOpen={showAlert}
        onClose={() => setShowAlert(false)}
      />
      {fetchingEnums ? (
        <CircularProgress />
      ) : (
        <Formik initialValues={formDevice} onSubmit={onSubmit} validationSchema={formikSchema}>
          {({ values, dirty, touched, errors, isSubmitting, handleChange }) => (
            <Form style={containerStyle.form}>
              <Box sx={containerStyle.gridContainer}>
                {formDevice.hostName !== null && formDevice.hostName !== undefined ? (
                  <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                    <Grid item xs={5} lg={7}>
                      <Box sx={containerStyle.itemTitle}>{hostNameTitle}</Box>
                      <Box sx={containerStyle.itemDesc}>{hostNameDesc}</Box>
                    </Grid>
                    <Grid item xs={5} lg={3}>
                      <Box sx={containerStyle.fieldContainer}>
                        <Field
                          as={TextField}
                          label={hostNameTitle}
                          name="hostName"
                          size="small"
                          style={containerStyle.textField}
                          helperText={touched.hostName && errors.hostName}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                ) : null}
                {formDevice.ipAddressMethod !== null && formDevice.ipAddressMethod !== undefined ? (
                  <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                    <Grid item xs={5} lg={7}>
                      <Box sx={containerStyle.itemTitle}>{ipAddressMethodTitle}</Box>
                      <Box sx={containerStyle.itemDesc}>{ipAddressMethodDesc}</Box>
                    </Grid>
                    <Grid item xs={5} lg={3}>
                      <Box sx={containerStyle.fieldContainer}>
                        <Field
                          as={Select}
                          label={ipAddressMethodTitle}
                          name="ipAddressMethod"
                          style={containerStyle.selectField}
                          helperText={touched.ipAddressMethod && errors.ipAddressMethod}
                        >
                          {Object.keys(enumList.ipAddressMethod).map((key) => {
                            return (
                              <MenuItem key={key} value={key}>
                                {enumList.ipAddressMethod[key].value}
                              </MenuItem>
                            );
                          })}
                        </Field>
                      </Box>
                    </Grid>
                  </Grid>
                ) : null}
                {formDevice.ipVersion !== null && formDevice.ipVersion !== undefined ? (
                  <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                    <Grid item xs={5} lg={7}>
                      <Box sx={containerStyle.itemTitle}>{ipVersionTitle}</Box>
                      <Box sx={containerStyle.itemDesc}>{ipVersionDesc}</Box>
                    </Grid>
                    <Grid item xs={5} lg={3}>
                      <Box sx={containerStyle.fieldContainer}>
                        <Field
                          as={Select}
                          label={ipVersionTitle}
                          name="ipVersion"
                          style={containerStyle.selectField}
                          helperText={touched.ipVersion && errors.ipVersion}
                          onChange={(e: any) => {
                            const newVals = { ...values, ipVersion: e.target.value };
                            handleChange(e);
                            setFormikSchema(getValidationSchema(newVals));
                          }}
                        >
                          {Object.keys(enumList.ipVersion).map((key) => {
                            return (
                              <MenuItem key={key} value={key}>
                                {enumList.ipVersion[key].value}
                              </MenuItem>
                            );
                          })}
                        </Field>
                      </Box>
                    </Grid>
                  </Grid>
                ) : null}
                {values.ipVersion && enumList.ipVersion[values.ipVersion].value === 'IPV4' ? (
                  <>
                    {formDevice.ipV4Address !== null && formDevice.ipV4Address !== undefined ? (
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-evenly"
                        style={containerStyle.itemContainer}
                      >
                        <Grid item xs={5} lg={7}>
                          <Box sx={containerStyle.itemTitle}>{ipV4AddressTitle}</Box>
                          <Box sx={containerStyle.itemDesc}>{ipV4AddressDesc}</Box>
                        </Grid>
                        <Grid item xs={5} lg={3}>
                          <Box sx={containerStyle.fieldContainer}>
                            <Field
                              as={TextField}
                              label={ipV4AddressTitle}
                              name="ipV4Address"
                              size="small"
                              style={containerStyle.textField}
                              helperText={touched.ipV4Address && errors.ipV4Address}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    ) : null}
                    {formDevice.subnetMask !== null && formDevice.subnetMask !== undefined ? (
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-evenly"
                        style={containerStyle.itemContainer}
                      >
                        <Grid item xs={5} lg={7}>
                          <Box sx={containerStyle.itemTitle}>{subnetMaskTitle}</Box>
                          <Box sx={containerStyle.itemDesc}>{subnetMaskDesc}</Box>
                        </Grid>
                        <Grid item xs={5} lg={3}>
                          <Box sx={containerStyle.fieldContainer}>
                            <Field
                              as={TextField}
                              label={subnetMaskTitle}
                              name="subnetMask"
                              size="small"
                              style={containerStyle.textField}
                              helperText={touched.subnetMask && errors.subnetMask}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    ) : null}
                    {formDevice.ipV4DefaultGateway !== null && formDevice.ipV4DefaultGateway !== undefined ? (
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-evenly"
                        style={containerStyle.itemContainer}
                      >
                        <Grid item xs={5} lg={7}>
                          <Box sx={containerStyle.itemTitle}>{ipV4DefaultGatewayTitle}</Box>
                          <Box sx={containerStyle.itemDesc}>{ipV4DefaultGatewayDesc}</Box>
                        </Grid>
                        <Grid item xs={5} lg={3}>
                          <Box sx={containerStyle.fieldContainer}>
                            <Field
                              as={TextField}
                              label={ipV4DefaultGatewayTitle}
                              name="ipV4DefaultGateway"
                              size="small"
                              style={containerStyle.textField}
                              helperText={touched.ipV4DefaultGateway && errors.ipV4DefaultGateway}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    ) : null}
                    {formDevice.ipV4PrimaryDns !== null && formDevice.ipV4PrimaryDns !== undefined ? (
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-evenly"
                        style={containerStyle.itemContainer}
                      >
                        <Grid item xs={5} lg={7}>
                          <Box sx={containerStyle.itemTitle}>{ipV4PrimaryDnsTitle}</Box>
                          <Box sx={containerStyle.itemDesc}>{ipV4PrimaryDnsDesc}</Box>
                        </Grid>
                        <Grid item xs={5} lg={3}>
                          <Box sx={containerStyle.fieldContainer}>
                            <Field
                              as={TextField}
                              label={ipV4PrimaryDnsTitle}
                              name="ipV4PrimaryDns"
                              size="small"
                              style={containerStyle.textField}
                              helperText={touched.ipV4PrimaryDns && errors.ipV4PrimaryDns}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    ) : null}
                    {formDevice.ipV4SecondaryDns !== null && formDevice.ipV4SecondaryDns !== undefined ? (
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-evenly"
                        style={containerStyle.itemContainer}
                      >
                        <Grid item xs={5} lg={7}>
                          <Box sx={containerStyle.itemTitle}>{ipV4SecondaryDnsTitle}</Box>
                          <Box sx={containerStyle.itemDesc}>{ipV4SecondaryDnsDesc}</Box>
                        </Grid>
                        <Grid item xs={5} lg={3}>
                          <Box sx={containerStyle.fieldContainer}>
                            <Field
                              as={TextField}
                              label={ipV4SecondaryDnsTitle}
                              name="ipV4SecondaryDns"
                              size="small"
                              style={containerStyle.textField}
                              helperText={touched.ipV4SecondaryDns && errors.ipV4SecondaryDns}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    ) : null}
                    {formDevice.ipV4MulticastAddress !== null && formDevice.ipV4MulticastAddress !== undefined ? (
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-evenly"
                        style={containerStyle.itemContainer}
                      >
                        <Grid item xs={5} lg={7}>
                          <Box sx={containerStyle.itemTitle}>{ipV4MulticastAddressTitle}</Box>
                          <Box sx={containerStyle.itemDesc}>{ipV4MulticastAddressDesc}</Box>
                        </Grid>
                        <Grid item xs={5} lg={3}>
                          <Box sx={containerStyle.fieldContainer}>
                            <Field
                              as={TextField}
                              label={ipV4MulticastAddressTitle}
                              name="ipV4MulticastAddress"
                              size="small"
                              style={containerStyle.textField}
                              helperText={touched.ipV4MulticastAddress && errors.ipV4MulticastAddress}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    ) : null}
                  </>
                ) : values.ipVersion && enumList.ipVersion[values.ipVersion].value === 'IPV6' ? (
                  <>
                    {formDevice.ipV6Address !== null && formDevice.ipV6Address !== undefined ? (
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-evenly"
                        style={containerStyle.itemContainer}
                      >
                        <Grid item xs={5} lg={7}>
                          <Box sx={containerStyle.itemTitle}>{ipV6AddressTitle}</Box>
                          <Box sx={containerStyle.itemDesc}>{ipV6AddressDesc}</Box>
                        </Grid>
                        <Grid item xs={5} lg={3}>
                          <Box sx={containerStyle.fieldContainer}>
                            <Field
                              as={TextField}
                              label={ipV6AddressTitle}
                              name="ipV6Address"
                              size="small"
                              style={containerStyle.textField}
                              helperText={touched.ipV6Address && errors.ipV6Address}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    ) : null}
                    {formDevice.ipV6DefaultGateway !== null && formDevice.ipV6DefaultGateway !== undefined ? (
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-evenly"
                        style={containerStyle.itemContainer}
                      >
                        <Grid item xs={5} lg={7}>
                          <Box sx={containerStyle.itemTitle}>{ipV6DefaultGatewayTitle}</Box>
                          <Box sx={containerStyle.itemDesc}>{ipV6DefaultGatewayDesc}</Box>
                        </Grid>
                        <Grid item xs={5} lg={3}>
                          <Box sx={containerStyle.fieldContainer}>
                            <Field
                              as={TextField}
                              label={ipV6DefaultGatewayTitle}
                              name="ipV6DefaultGateway"
                              size="small"
                              style={containerStyle.textField}
                              helperText={touched.ipV6DefaultGateway && errors.ipV6DefaultGateway}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    ) : null}
                    {formDevice.ipV6PrimaryDns !== null && formDevice.ipV6PrimaryDns !== undefined ? (
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-evenly"
                        style={containerStyle.itemContainer}
                      >
                        <Grid item xs={5} lg={7}>
                          <Box sx={containerStyle.itemTitle}>{ipV6PrimaryDnsTitle}</Box>
                          <Box sx={containerStyle.itemDesc}>{ipV6PrimaryDnsDesc}</Box>
                        </Grid>
                        <Grid item xs={5} lg={3}>
                          <Box sx={containerStyle.fieldContainer}>
                            <Field
                              as={TextField}
                              label={ipV6PrimaryDnsTitle}
                              name="ipV6PrimaryDns"
                              size="small"
                              style={containerStyle.textField}
                              helperText={touched.ipV6PrimaryDns && errors.ipV6PrimaryDns}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    ) : null}
                    {formDevice.ipV6SecondaryDns !== null && formDevice.ipV6SecondaryDns !== undefined ? (
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-evenly"
                        style={containerStyle.itemContainer}
                      >
                        <Grid item xs={5} lg={7}>
                          <Box sx={containerStyle.itemTitle}>{ipV6SecondaryDnsTitle}</Box>
                          <Box sx={containerStyle.itemDesc}>{ipV6SecondaryDnsDesc}</Box>
                        </Grid>
                        <Grid item xs={5} lg={3}>
                          <Box sx={containerStyle.fieldContainer}>
                            <Field
                              as={TextField}
                              label={ipV6SecondaryDnsTitle}
                              name="ipV6SecondaryDns"
                              size="small"
                              style={containerStyle.textField}
                              helperText={touched.ipV6SecondaryDns && errors.ipV6SecondaryDns}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    ) : null}
                    {formDevice.ipV6MulticastAddress !== null && formDevice.ipV6MulticastAddress !== undefined ? (
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-evenly"
                        style={containerStyle.itemContainer}
                      >
                        <Grid item xs={5} lg={7}>
                          <Box sx={containerStyle.itemTitle}>{ipV6MulticastAddressTitle}</Box>
                          <Box sx={containerStyle.itemDesc}>{ipV6MulticastAddressDesc}</Box>
                        </Grid>
                        <Grid item xs={5} lg={3}>
                          <Box sx={containerStyle.fieldContainer}>
                            <Field
                              as={TextField}
                              label={ipV6MulticastAddressTitle}
                              name="ipV6MulticastAddress"
                              size="small"
                              style={containerStyle.textField}
                              helperText={touched.ipV6MulticastAddress && errors.ipV6MulticastAddress}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    ) : null}
                  </>
                ) : null}
                {formDevice.vlanSettings !== null && formDevice.vlanSettings !== undefined ? (
                  <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                    <Grid item xs={5} lg={7}>
                      <Box sx={containerStyle.itemTitle}>{vlanSettingsTitle}</Box>
                      <Box sx={containerStyle.itemDesc}>{vlanSettingsDesc}</Box>
                    </Grid>
                    <Grid item xs={5} lg={3}>
                      <Box sx={containerStyle.fieldContainer}>
                        <Field
                          as={Checkbox}
                          label={vlanSettingsTitle}
                          name="vlanSettings"
                          style={containerStyle.toggleField}
                          helperText={touched.vlanSettings && errors.vlanSettings}
                          checked={values.vlanSettings}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                ) : null}
                {formDevice.vlanId !== null && formDevice.vlanId !== undefined ? (
                  <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                    <Grid item xs={5} lg={7}>
                      <Box sx={containerStyle.itemTitle}>{vlanIdTitle}</Box>
                      <Box sx={containerStyle.itemDesc}>{vlanIdDesc}</Box>
                    </Grid>
                    <Grid item xs={5} lg={3}>
                      <Box sx={containerStyle.fieldContainer}>
                        <Field
                          as={TextField}
                          label={vlanIdTitle}
                          type="number"
                          name="vlanId"
                          size="small"
                          style={containerStyle.textField}
                          helperText={touched.vlanId && errors.vlanId}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                ) : null}
                {formDevice.vlanPriority !== null && formDevice.vlanPriority !== undefined ? (
                  <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                    <Grid item xs={5} lg={7}>
                      <Box sx={containerStyle.itemTitle}>{vlanPriorityTitle}</Box>
                      <Box sx={containerStyle.itemDesc}>{vlanPriorityDesc}</Box>
                    </Grid>
                    <Grid item xs={5} lg={3}>
                      <Box sx={containerStyle.fieldContainer}>
                        <Grid container spacing={2} alignItems={'center'}>
                          <Grid item xs={7.5}>
                            <Field
                              as={Slider}
                              type="range"
                              min={vlanPriorityMin}
                              max={vlanPriorityMax}
                              label={vlanPriorityTitle}
                              name="vlanPriority"
                            />
                          </Grid>
                          <Grid item xs={4.5}>
                            <Field
                              as={TextField}
                              type="number"
                              name="vlanPriority"
                              size="small"
                              onChange={(e: any) => {
                                e.target.value = parseInt(e.target.value);
                                if (e.target.value > vlanPriorityMax) {
                                  e.target.value = vlanPriorityMax;
                                } else if (e.target.value < vlanPriorityMin) {
                                  e.target.value = vlanPriorityMin;
                                }
                                handleChange(e);
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                ) : null}
              </Box>
              <Box sx={containerStyle.submitContainer}>
                <Button
                  type="reset"
                  variant="contained"
                  color="primary"
                  disabled={!dirty || isSubmitting || isUpdating}
                  style={containerStyle.submitBarButton}
                >
                  {buttonReset}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!dirty || isSubmitting || isUpdating}
                  style={containerStyle.submitBarButton}
                >
                  {buttonSubmit}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      )}
    </Box>
  );
};

export default IPAddress;
