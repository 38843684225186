/* Initialization tab stub */
export const InitializationLabel = () => {
  return <span>Initialization</span>;
};

const Initialization = () => {
  return <span>Initialization</span>;
};

export default Initialization;
