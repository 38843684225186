import { Box, CircularProgress, Typography } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ApartmentIcon from '@mui/icons-material/Apartment';
import PeopleIcon from '@mui/icons-material/People';
import DevicesIcon from '@mui/icons-material/Devices';
import BuildIcon from '@mui/icons-material/Build';
import SettingsIcon from '@mui/icons-material/Settings';
import Divider from '@mui/material/Divider';
import RouterIcon from '@mui/icons-material/Router';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { useTheme, Grid } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

function RemoteManagementSideBar(props: any) {
  const sitePublicId = useSelector((state: RootState) => state.site.siteInfo.publicId);
  const siteName = useSelector((state: RootState) => state.site.siteInfo.siteName);
  const isWizardComplete = useSelector((state: RootState) => state.site.siteInfo.isWizardCompleted);
  const isSiteLoading = useSelector((state: RootState) => state.site.Loading);
  const theme = useTheme();
  const location = useLocation();

  return (
    <Sidebar
      style={styles.sidebar}
      breakPoint="md"
      backgroundColor={theme.palette.neutral.light}
      collapsed={props.collapsed}
      toggled={props.toggled}
    >
      <Link style={styles.siteLink} to={isWizardComplete ? `/site/${sitePublicId}/siteinfo` : '#'}>
        <Box sx={styles.siteBadge}>
          {isSiteLoading ? (
            <Box sx={styles.loadingSpinner}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Typography sx={styles.siteName}>{siteName}</Typography>

              <Grid container spacing={1} direction="column" justifyContent="center">
                <Grid xs={12} item></Grid>
              </Grid>
            </>
          )}
        </Box>
      </Link>
      <Menu
        menuItemStyles={{
          button: ({ active }) => {
            return {
              backgroundColor: active ? '#c9e7ff' : undefined,
              color: active ? '#0071CE' : undefined,
              '&:hover': {
                backgroundColor: active ? '#c9e7ff' : '#e2f2ff'
              }
            };
          }
        }}
      >
        <MenuItem
          active={location.pathname === `/site/${sitePublicId}/siteinfo`}
          component={<Link to={`/site/${sitePublicId}/siteinfo`} />}
          icon={<DashboardIcon />}
        >
          <Typography variant="body2">Site Info</Typography>
        </MenuItem>
        <SubMenu label="Intercoms" rootStyles={styles.subMenu} icon={<DevicesIcon />}>
          <MenuItem
            active={location.pathname === `/site/${sitePublicId}/answering-stations`}
            component={<Link to={`/site/${sitePublicId}/answering-stations`} />}
            icon={<DevicesIcon />}
            disabled={!isWizardComplete}
          >
            <Typography variant="body2">Answering Stations</Typography>
          </MenuItem>
          <MenuItem
            active={location.pathname === `/site/${sitePublicId}/door-stations`}
            component={<Link to={`/site/${sitePublicId}/door-stations`} />}
            icon={<DevicesIcon />}
            disabled={!isWizardComplete}
          >
            <Typography variant="body2">Door Stations</Typography>
          </MenuItem>
          <MenuItem
            active={location.pathname === `/site/${sitePublicId}/entrance-stations`}
            component={<Link to={`/site/${sitePublicId}/entrance-stations`} />}
            icon={<DevicesIcon />}
            disabled={!isWizardComplete}
          >
            <Typography variant="body2">Entrance Stations</Typography>
          </MenuItem>
        </SubMenu>
        <SubMenu label="Adaptors" rootStyles={styles.subMenu} icon={<RouterIcon />}>
          <MenuItem
            active={location.pathname === `/site/${sitePublicId}/gateway-settings`}
            component={<Link to={`/site/${sitePublicId}/gateway-settings`} />}
            icon={<RouterIcon />}
            disabled={!isWizardComplete}
          >
            <Typography variant="body2">Gateway Settings</Typography>
          </MenuItem>
          <MenuItem
            active={location.pathname === `/site/${sitePublicId}/io-settings`}
            component={<Link to={`/site/${sitePublicId}/io-settings`} />}
            icon={<RouterIcon />}
            disabled={!isWizardComplete}
          >
            <Typography variant="body2">I/O Settings</Typography>
          </MenuItem>
          <MenuItem
            active={location.pathname === `/site/${sitePublicId}/lift-control-settings`}
            component={<Link to={`/site/${sitePublicId}/lift-control-settings`} />}
            icon={<RouterIcon />}
            disabled={true}
          >
            <Typography variant="body2">Lift Control Settings</Typography>
          </MenuItem>
        </SubMenu>
        <SubMenu rootStyles={styles.subMenu} label="Tenant Management" icon={<ApartmentIcon />}>
          <MenuItem
            active={location.pathname === `/site/${sitePublicId}/units`}
            component={<Link to={`/site/${sitePublicId}/units`} />}
            icon={<ApartmentIcon />}
            disabled={!isWizardComplete}
          >
            <Typography variant="body2">Units</Typography>
          </MenuItem>
          <MenuItem
            active={location.pathname === `/site/${sitePublicId}/tenants`}
            component={<Link to={`/site/${sitePublicId}/tenants`} />}
            icon={<ApartmentIcon />}
            disabled={!isWizardComplete}
          >
            <Typography variant="body2">Tenants</Typography>
          </MenuItem>
        </SubMenu>
      </Menu>
      <Menu
        menuItemStyles={{
          button: ({ active }) => {
            return {
              backgroundColor: active ? theme.palette.neutral.medium : undefined
            };
          }
        }}
        rootStyles={styles.secondMenu}
      >
        <Divider />
        <MenuItem active={location.pathname === '/'} component={<Link to="/" />} icon={<DashboardIcon />}>
          <Typography style={styles.overflowHidden} variant="body2">
            Home
          </Typography>
        </MenuItem>
        <MenuItem
          active={location.pathname === `/site/${sitePublicId}/users`}
          component={<Link to={`/site/${sitePublicId}/users`} />}
          icon={<PeopleIcon />}
          disabled={!isWizardComplete}
        >
          <Typography variant="body2">Administrators</Typography>
        </MenuItem>
        <MenuItem
          active={location.pathname === `/site/${sitePublicId}/settings`}
          component={<Link to={`/site/${sitePublicId}/settings`} />}
          icon={<SettingsIcon />}
          disabled={!isWizardComplete}
        >
          <Typography variant="body2">Settings</Typography>
        </MenuItem>
        <MenuItem
          active={location.pathname === '/remotemanagement'}
          component={<Link to="/remotemanagement" />}
          icon={<BuildIcon />}
        >
          <Typography style={styles.overflowHidden} variant="body2">
            Remote Management
          </Typography>
        </MenuItem>
      </Menu>
    </Sidebar>
  );
}

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  sidebar: {
    height: 'calc(100%-64px)',
    position: 'sticky',
    top: '0',
    left: '0'
  },
  siteLink: {
    textDecoration: 'none'
  },
  siteBadge: {
    height: '5.5rem',
    border: '2px solid #D7D7D7',
    boxShadow: '.5px .5px .5px .5px rgba(0,0,0,0.2)',
    borderRadius: '2%',
    marginX: '.5rem',
    marginY: '.5rem',
    '&:hover': {
      backgroundColor: '#f3f3f3'
    }
  },
  siteName: {
    color: '#003366',
    textDecoration: 'none',
    fontSize: '1.25rem',
    fontWeight: '700',
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'block',
    marginY: '.5rem',
    paddingX: '.5rem'
  },
  progressBar: {
    backgroundColor: '#ffd5b1 ',
    '& .MuiLinearProgress-bar': {
      backgroundColor: '#FF7500'
    },
    padding: '.30rem',
    marginLeft: '.5rem',
    marginRight: '.5rem'
  },
  progressBarText: {
    textAlign: 'center',
    color: '#003366'
  },
  overflowHidden: {
    overflow: 'hidden'
  },
  secondMenu: {
    position: 'absolute',
    bottom: '0',
    '@media (max-height: 700px)': {
      position: 'relative'
    }
  },
  loadingSpinner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 75
  },
  subMenu: {
    '&:hover': {
      backgroundColor: '#e2f2ff'
    },
    '.ps-submenu-content': {
      width: 'auto'
    },
    margin: 0,
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: 1.43,
    letterSpacing: '0.01071em'
  }
};

export default RemoteManagementSideBar;
