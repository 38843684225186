import { Alert, AlertTitle, Box, List, ListItem, Typography } from '@mui/material';
import GeneralInfo from '../../UiParts/GeneralInfo';
import * as Yup from 'yup';
import { FeaturedSystems, Item } from 'features/QuikSpec/Types/QuikSpecTypes';
import { updateFormValuesAC } from 'features/QuikSpec/Utils/QuikspecUpdateFormValuesAC';
import { acControlKits, acLicenses, acReaderKits, acSystemConstraints, acHidden } from '../../../Types/ACTypes';
import QuikspecCategoryTabs from '../../UiParts/QuikspecCategoryTabs';
import QuikspecCategoryAcControllers from '../../UiParts/QuikspecCategoryAcControllers';
import QuikspecCategoryAcLicenses from '../../UiParts/QuikspecCategoryAcLicenses';
import { Form, Formik } from 'formik';
import QuikspecSystemConstraints from '../../UiParts/QuikspecSystemConstraints';
import QuikspecSubmitButton from '../../UiParts/QuikspecSubmitButton';
import { useNavigate, useLocation } from 'react-router-dom';
import QuikspecNote from 'features/QuikSpec/Components/UiParts/QuikspecNote';
import { FormValues } from '../../../Types/QuikspecFormTypes';
import getInitialFormValues from 'features/QuikSpec/Utils/QuikspecInitialFormValues';
import { createQuikSpecAndNavigate, updateQuikspecAndNavigate } from '../../../Utils/QuikspecActionAndNavigate';

// Function to check if at least one quantity is greater than 0
const atLeastOneQuantityGreaterThanZero = (values: any) => {
  const items = Object.values(values);
  return items.some((item: any) => item.quantity > 0);
};

const atMostEightyCardReaders = (values: any) => {
  const items = Object.values(values);
  const cardReaderNames = acReaderKits[0]?.items.map((item: Item) => item.deviceName) || [];
  const relevantItems = items.filter((item: any) => cardReaderNames.includes(item.itemName));
  const totalQuantity = relevantItems.reduce((total: number, item: any) => total + item.quantity, 0);
  return totalQuantity <= (acReaderKits[0]?.maxQuantityAllowedPerCategory || 80);
};

const validationSchema = Yup.object().shape({
  quikspecName: Yup.string().required('Quikspec Name is required'),
  marketType: Yup.string().required('Market Type is required'),
  items: Yup.array()
    .of(
      Yup.object().shape({
        itemName: Yup.string().required('Item Name is required'),
        quantity: Yup.number().min(0, 'Quantity must be greater than or equal to 0').required('Quantity is required')
      })
    )
    .test(
      'atLeastOneQuantity',
      'At least one item must have quantity greater than 0',
      atLeastOneQuantityGreaterThanZero
    )
    .test('atMostEightyCardReaders', 'At most 80 card readers can be added', atMostEightyCardReaders)
});

const QuikspecAC = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const state = location.state || {};
  const siteName: string = location.state.siteName as string;
  const siteIndex = FeaturedSystems.findIndex((system) => system.siteName === siteName);
  const systemName = FeaturedSystems[siteIndex].seriesName;
  const systemforC2 = FeaturedSystems[siteIndex].systemforC2;

  const categories = [...acControlKits, ...acLicenses, ...acReaderKits, ...acHidden];

  const stateFormValues = state && Object.keys(state).length > 1 ? state.formValues : undefined;
  const initialValues = getInitialFormValues(systemName, categories, stateFormValues, systemforC2);

  const handleSubmit = async (values: FormValues, actions: any) => {
    try {
      actions.setSubmitting(true);
      values = updateFormValuesAC(values);

      //determine if form is in edit mode
      const editing = stateFormValues != undefined;
      if (editing) {
        //update QuikSpec and navigate to QuikSpec Summary page
        await updateQuikspecAndNavigate(values, actions, navigate);
        actions.setSubmitting(false);
        return;
      } else {
        //create QuikSpec and navigate to QuikSpec Summary page
        await createQuikSpecAndNavigate(values, actions, navigate);

        actions.setSubmitting(false);
      }
    } catch (error) {
      actions.setSubmitting(false);
      actions.setErrors({ submit: 'Something went wrong. Please try again or contact customer support.' });
    }
  };

  return (
    <Box>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ errors, touched, isSubmitting }) => {
          return (
            <Form>
              <GeneralInfo editing={state.formValues != undefined} />
              <QuikspecSystemConstraints systemConstraints={acSystemConstraints} />
              <QuikspecCategoryAcControllers categories={acControlKits} />
              <QuikspecCategoryAcLicenses categories={acLicenses} />
              <QuikspecCategoryTabs categories={acReaderKits} />
              {(stateFormValues
                ? Object.keys(errors).length > 0
                : Object.keys(touched).length > 0 && Object.keys(errors).length > 0) && (
                <Alert severity="error">
                  <AlertTitle>Error: </AlertTitle>
                  <List>
                    {Object.keys(errors).map((field) => (
                      <ListItem key={field}>
                        <Typography variant="body2" color="error">
                          {errors[field]}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                </Alert>
              )}
              <QuikspecSubmitButton editing={state.formValues != undefined} errors={errors} submitting={isSubmitting} />
              <QuikspecNote systemName={initialValues.systemName} />
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default QuikspecAC;
