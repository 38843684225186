import CreateSite from './CreateNewSite/CreateSite';
// import ImportSite from './ImportSite/ImportSite';
import Sites from './Sites';
import { Box, Grid } from '@mui/material';
import { useGetSitesQuery } from 'services/aiphoneCloud';

const RemoteManagement = () => {
  const { data: mySites, isFetching } = useGetSitesQuery({});

  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <CreateSite />
        </Grid>
        {/* <Grid item xs={6}>
          <ImportSite />
        </Grid> */}
        <Grid item xs={12}>
          <Sites isFetching={isFetching} mySites={mySites} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default RemoteManagement;
