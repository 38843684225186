/**
 * Page used to let the user navigate through the Integrations tabs
 * user can navigate through each tab setting for each device
 */
import TabsContainer from 'shared/components/TabsContainer';
import { DeviceTabType } from 'features/RemoteManagement/DeviceDashboard/DeviceTabs';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { findDeviceTypeForDevice } from 'shared/utils/helperFunctions';
import { DeviceCategory } from 'features/RemoteManagement/Types';

const Integrations = () => {
  const deviceCategory = findDeviceTypeForDevice(
    useSelector((state: RootState) => state.devices.SelectedDevice)
  )?.deviceCategory;
  let integrationsTabs: DeviceTabType[] = [];

  switch (deviceCategory) {
    case DeviceCategory.GuardStation:
    case DeviceCategory.MasterStation:
    case DeviceCategory.TenantStation:
      integrationsTabs = [
        DeviceTabType.Integrations_Email,
        DeviceTabType.Integrations_Recording,
        DeviceTabType.Integrations_DisplayMode,
        DeviceTabType.Integrations_NetworkCameraIntegration,
        DeviceTabType.Integrations_SIF,
        DeviceTabType.Integrations_CGI
      ];
      break;
    case DeviceCategory.VideoDoorStation:
    case DeviceCategory.AudioDoorStation:
    case DeviceCategory.EmergencyStation:
      integrationsTabs = [
        DeviceTabType.Integrations_Email,
        DeviceTabType.Integrations_Recording,
        DeviceTabType.Integrations_SIF,
        DeviceTabType.Integrations_CGI
      ];
      break;
    case DeviceCategory.EntranceStation:
      integrationsTabs = [
        DeviceTabType.Integrations_Email,
        DeviceTabType.Integrations_Recording,
        DeviceTabType.Integrations_DisplayMode,
        DeviceTabType.Integrations_SIF,
        DeviceTabType.Integrations_CGI
      ];
      break;
    case DeviceCategory.NetworkAdapter:
    case DeviceCategory.LiftControl:
      integrationsTabs = [DeviceTabType.Integrations_SIF, DeviceTabType.Integrations_CGI];
      break;
  }

  return <TabsContainer tabTypes={integrationsTabs} />;
};

export default Integrations;
