import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowSelectionModel,
  GridRowsProp,
  GridToolbarContainer
} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import LockResetIcon from '@mui/icons-material/LockReset';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import React, { useEffect } from 'react';
import { Box, Button } from '@mui/material';
import { useGetUsersWithGlobalRolesQuery } from 'services/aiphoneCloud';
import InviteAiphoneUserDialog from './Dialogs/InviteAiphoneUserDialog';
import DeleteUserDialog from './Dialogs/DeleteUserDialog';
import EditAiphoneUserDialog from './Dialogs/EditAiphoneUserDialog';
import ResetPasswordDialog from './Dialogs/ResetPasswordDialog';
import { Add } from '@mui/icons-material';
import { CloudUser } from 'store/slices/usersSlice';
import SnackbarAlert from 'shared/components/SnackbarAlert';
import { useSelector } from 'react-redux';
import { getCurrentUser } from 'store/slices/usersSlice';

interface IGetAiphoneUsersResponse {
  userList: { [key: string]: CloudUser };
  perPage: number;
  pageNumber: number;
  totalPages: number;
  totalUsers: number;
}

const AiphoneUsersDataGrid = () => {
  const defaultPageSize = 50;

  const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([]);
  const [isInviteUserDialogOpen, setisInviteUserDialogOpen] = React.useState(false);
  const [isDeleteUserDialogOpen, setIsDeleteUserDialogOpen] = React.useState(false);
  const [isEditUserDialogOpen, setIsEditUserDialogOpen] = React.useState(false);
  const [isResetPasswordDialogOpen, setIsResetPasswordDialogOpen] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState<CloudUser>();
  const [isError, setIsError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('');
  const [usersList, setUsersList] = React.useState<{ [key: string]: CloudUser }>({});
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(defaultPageSize);
  const [rowCount, setRowCount] = React.useState(0);

  // check the logged in user's role and disable Tab 2 if the user is not an Admin
  const currentUser = useSelector(getCurrentUser);
  const hasGlobalAdminRole = currentUser ? currentUser.permissions.global?.role.roleName === 'Aiphone Admin' : false;

  const fetchData = () => {
    return useGetUsersWithGlobalRolesQuery({
      qty: pageSize.toString(),
      page: page.toString()
    });
  };

  /*Fetch users*/
  const { data, isFetching, error, refetch } = fetchData();
  const usersData: IGetAiphoneUsersResponse = data as IGetAiphoneUsersResponse;

  /*Hook components*/
  useEffect(() => {
    if ((usersData || error) && isSuccess) {
      refetch();
    }
  }, [page, pageSize, isSuccess]);

  useEffect(() => {
    if (usersData?.userList) {
      setUsersList(usersData.userList);
      setRowCount(data.totalUsers);
    } else if (usersData) {
      setUsersList({});
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      const errorData = JSON.parse((error as { data: string }).data);
      const errorCode = errorData.errorCode;
      console.log(error);
      if (errorCode === 'A0001') {
        setErrorMessage('Unauthorized to fetch users, please contact your administrator.');
      } else {
        setErrorMessage('Failed to fetch users');
      }
      setIsError(true);
    }
  }, [error]);

  /*Prep content for DataGrid*/
  const computeRows = (): GridRowsProp => {
    return Object.entries(usersList).map(([key, value]: [string, CloudUser]) => {
      return {
        id: value.publicId,
        firstName: value.firstName,
        lastName: value.lastName,
        role: value.permissions.global.role.roleName,
        email: value.email
      };
    });
  };

  const computeColumns = (): GridColDef[] => {
    const columns: GridColDef[] = [
      { field: 'firstName', type: 'string', headerName: 'First Name', flex: 1 },
      { field: 'lastName', type: 'string', headerName: 'Last Name', flex: 1 },
      {
        field: 'role',
        type: 'string',
        headerName: 'Role',
        flex: 1,
        valueGetter: (param) => {
          if (param.value === 'Aiphone Admin') {
            return 'Admin';
          } else {
            return 'Member';
          }
        }
      },
      { field: 'email', type: 'string', headerName: 'Email', flex: 1 }
    ];

    if (hasGlobalAdminRole) {
      columns.push({
        field: 'actions',
        type: 'actions',
        flex: 1,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit User"
            showInMenu
            onClick={() => {
              setIsEditUserDialogOpen(true);
              setSelectedUser(usersList[params.row.id]);
            }}
          />,
          <GridActionsCellItem
            icon={<LockResetIcon />}
            label="Reset Password"
            showInMenu
            onClick={() => {
              setIsResetPasswordDialogOpen(true);
              setSelectedUser(usersList[params.row.id]);
            }}
          />
        ]
      });
    }

    return columns;
  };

  const rows: GridRowsProp = !isFetching ? computeRows() : [];
  const columns: GridColDef[] = computeColumns();

  const AiphoneUsersToolbar = () => {
    return (
      <GridToolbarContainer>
        {hasGlobalAdminRole && (
          <Box sx={styles.w100}>
            <Button onClick={() => setisInviteUserDialogOpen(true)} disabled={isFetching} startIcon={<Add />}>
              Invite User
            </Button>
            <Button
              onClick={() => {
                setIsDeleteUserDialogOpen(true);
              }}
              disabled={isFetching || selectionModel.length !== 1}
              startIcon={<DeleteForeverIcon />}
            >
              Delete User
            </Button>
          </Box>
        )}
      </GridToolbarContainer>
    );
  };

  return (
    <>
      <Box sx={styles.w100}>
        <DataGrid
          rows={rows}
          columns={columns}
          loading={isFetching}
          checkboxSelection
          disableRowSelectionOnClick
          pageSizeOptions={[25, 50, 100]}
          paginationModel={{ page: page, pageSize: pageSize }}
          onPaginationModelChange={(model) => {
            setPage(model.page);
            setPageSize(model.pageSize);
          }}
          slots={{ toolbar: AiphoneUsersToolbar }}
          rowSelectionModel={selectionModel}
          onRowSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          rowCount={rowCount}
          paginationMode="server"
          pagination
          autoHeight
        />
      </Box>
      <InviteAiphoneUserDialog
        isOpen={isInviteUserDialogOpen}
        setIsOpen={setisInviteUserDialogOpen}
        setErrorMessage={setErrorMessage}
        setIsError={setIsError}
        setSuccessMessage={setSuccessMessage}
        setIsSuccess={setIsSuccess}
      />
      <DeleteUserDialog
        selectionModel={selectionModel}
        isOpen={isDeleteUserDialogOpen}
        setIsOpen={setIsDeleteUserDialogOpen}
        setErrorMessage={setErrorMessage}
        setIsError={setIsError}
        setSuccessMessage={setSuccessMessage}
        setIsSuccess={setIsSuccess}
      />
      <EditAiphoneUserDialog
        isOpen={isEditUserDialogOpen}
        setIsOpen={setIsEditUserDialogOpen}
        selectedUser={selectedUser}
        setErrorMessage={setErrorMessage}
        setIsError={setIsError}
        setSuccessMessage={setSuccessMessage}
        setIsSuccess={setIsSuccess}
      />
      <ResetPasswordDialog
        isOpen={isResetPasswordDialogOpen}
        setIsOpen={setIsResetPasswordDialogOpen}
        selectedUser={selectedUser?.publicId}
        setErrorMessage={setErrorMessage}
        setIsError={setIsError}
        setSuccessMessage={setSuccessMessage}
        setIsSuccess={setIsSuccess}
        resetUserType="Aiphone"
      />
      <SnackbarAlert type="error" time={6000} text={errorMessage} isOpen={isError} onClose={() => setIsError(false)} />
      <SnackbarAlert
        type="success"
        time={6000}
        text={successMessage}
        isOpen={isSuccess}
        onClose={() => {
          setIsSuccess(false);
        }}
      />
    </>
  );
};

const styles = {
  w100: {
    width: '100%'
  }
};

export default AiphoneUsersDataGrid;
