/**
 * This will be the centralize place for redux
 * This will help solve some 'circular imports' issues
 */
import { configureStore } from '@reduxjs/toolkit';
import {
  siteReducer,
  addSite,
  updateSite,
  setSiteLoading,
  setIsWizardComplete,
  addAwsPropertyId
} from './slices/siteSlice';
import { devicesReducer } from './slices/devicesSlice';
import { unitsReducer } from './slices/unitsSlice';
import { usersReducer, addUsers, resetUsersState, setCurrentUser } from './slices/usersSlice';
import { tenantsReducer, resetTenantState, updateTenants, deleteTenant } from './slices/tenantsSlice';
import { appsReducer } from './slices/appsSlice';
import { ixgAclApiSlice } from './api/ixgAclApiSlice';
import { aclUserReducer, initAclUser } from 'features/SimBilling/Stores/aclUserSlice';
import {
  aclSitesReducer,
  resetAclSites,
  resetAclSiteError,
  selectAclSite
} from 'features/SimBilling/Stores/aclSitesSlice';
import {
  subscriptionPlansReducer,
  resetfetchSubPlansDataError
} from 'features/SimBilling/Stores/subscriptionPlansSlice';
import {
  gatewayReducer,
  addStationsFromGw,
  resetGatewayState,
  addGatewayInfo,
  updateGWId
} from './slices/rmGatewaySlice';
import { aiphoneCloudApi } from 'services/aiphoneCloud';
import { setupListeners } from '@reduxjs/toolkit/query';
import { buildingsReducer } from './slices/buildingsSlice';
import { localizationReducer } from './slices/localizationSlice';
import { branchReducer } from './slices/branchSlice';

const store = configureStore({
  reducer: {
    site: siteReducer,
    buildings: buildingsReducer,
    devices: devicesReducer,
    aclSites: aclSitesReducer,
    aclUser: aclUserReducer,
    subscriptionPlans: subscriptionPlansReducer,
    units: unitsReducer,
    users: usersReducer,
    tenants: tenantsReducer,
    locale: localizationReducer,
    apps: appsReducer,
    branches: branchReducer,
    gateway: gatewayReducer,
    [ixgAclApiSlice.reducerPath]: ixgAclApiSlice.reducer,
    [aiphoneCloudApi.reducerPath]: aiphoneCloudApi.reducer
    // Add other reducers if needed
    //example: userReducer
  },
  // Add middleware if needed
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(ixgAclApiSlice.middleware, aiphoneCloudApi.middleware)
});

setupListeners(store.dispatch);

export { store };

//all the reducers action will be exported right here

export {
  addSite,
  updateSite,
  setSiteLoading,
  setIsWizardComplete,
  addUsers,
  resetUsersState,
  setCurrentUser,
  resetTenantState,
  updateTenants,
  deleteTenant,
  initAclUser,
  addAwsPropertyId,
  resetAclSites,
  selectAclSite,
  resetAclSiteError,
  resetfetchSubPlansDataError,
  addStationsFromGw,
  resetGatewayState,
  addGatewayInfo,
  updateGWId
};

/**
 * Extract the 'RootState' type and the 'Dispatch' type
 * so that they can be referenced as needed with correct update
 */
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
