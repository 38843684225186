import { Container } from '@mui/material';

const UnitDevices: React.FC = () => {
  return (
    <Container maxWidth="lg">
      <h1>UnitDevices</h1>
    </Container>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {};

export default UnitDevices;
