import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField } from '@mui/material';
import React, { useState } from 'react';
import Login from 'shared/api/Acl/Login';

interface Props {
  handleClose: (event: React.SyntheticEvent) => void;
}

const ActivationPanel = ({ handleClose }: Props) => {
  const [id, setId] = useState('');
  const [code, setCode] = useState('');
  const [result, setResult] = useState('');

  const handleSetId = (e: React.ChangeEvent<HTMLInputElement>) => setId(e.target.value);
  const handleSetCode = (e: React.ChangeEvent<HTMLInputElement>) => setCode(e.target.value);

  const handleVerification = async () => {
    const result = await Login.confirm(id, code);
    setResult(result);
  };

  return (
    <>
      <DialogTitle>Activation</DialogTitle>
      <DialogContent>
        <Grid container direction={'column'} spacing={5}>
          <Grid item>
            <DialogContentText>Enter Administrator ID and Verification Code.</DialogContentText>
          </Grid>
          <Grid item>
            <TextField fullWidth value={id} onChange={handleSetId} label="Administrator ID" id="adminId" />
          </Grid>
          <Grid item>
            <TextField fullWidth value={code} onChange={handleSetCode} label="Verification Code" id="code" />
          </Grid>
          <Grid item>{result}</Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleVerification}>Activate</Button>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </>
  );
};

export default ActivationPanel;
