import { Box, Button, CircularProgress } from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowSelectionModel,
  GridRowsProp,
  GridToolbarContainer
} from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useGetBranchesQuery } from 'services/aiphoneCloud';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddBranchDialog from '../UiParts/AddBranchDialog';
import Add from '@mui/icons-material/Add';
import SettingsIcon from '@mui/icons-material/Settings';
import SnackbarAlert from 'shared/components/SnackbarAlert';
import EditBranchDialog from '../UiParts/EditBranchDialog';
import DeleteBranchDialog from '../UiParts/DeleteBranchDialog';
import { useSelector } from 'react-redux';
import { getCurrentUser } from 'store/slices/usersSlice';
import { EnumList, IStateValue, fetchEnumList } from 'shared/utils/EnumUtils';
import { getString } from 'shared/utils/LocalizationUtils';

type BranchResponse = {
  branchList: {
    [key: string]: BranchRecord;
  };
  perPage: number;
  pageNumber: number;
  totalPages: number;
  totalBranches: number;
};

type BranchRecord = {
  publicId: string;
  company: {
    name: string;
    stateId: number;
    publicId: string;
    postalCode: string;
  };
  address: string;
  address2: string;
  address3: string;
  city: string;
  branchName: string;
  c2CompanyName: string;
  aiphoneCloudAccountNumber: string;
  phoneNumber: string;
  stateId: number;
  postalCode: string;
  isDutyFree: boolean;
  sitePublicId: string[];
  createdBy: string;
  lastUpdatedBy: string;
  createdOn: string;
  lastUpdatedOn: string;
};

const BranchesDataGrid = () => {
  const defaultPageSize = 50;

  const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([]);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isError, setIsError] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('');
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isCreateBranchDialogOpen, setisCreateBranchDialogOpen] = React.useState(false);
  const [isEditBranchDialogOpen, setisEditBranchDialogOpen] = React.useState(false);
  const [selectedBranch, setSelectedBranch] = React.useState('');
  const [isDeleteBranchDialogOpen, setisDeleteBranchDialogOpen] = React.useState(false);
  const [branchList, setBranchList] = React.useState<{ [key: string]: BranchRecord }>({});
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(defaultPageSize);
  const [rowCount, setRowCount] = React.useState(0);
  const [enumList, setEnumList] = useState<EnumList>({ country: {}, state: {} });
  const [fetchingEnums, setFetchingEnums] = useState(true);

  const currentUser = useSelector(getCurrentUser);
  const hasGlobalAdminRole = currentUser ? currentUser.permissions.global.role.roleName === 'Aiphone Admin' : false;
  const addBranch = getString('Button_BranchToolBar_AddBranch');
  const deleteBranch = getString('Button_DeleteBranch');

  const fetchData = () => {
    return useGetBranchesQuery({
      qty: pageSize.toString(),
      page: page.toString()
    });
  };

  /*Fetch branches*/
  const { data, isFetching, error, refetch } = fetchData();
  const branchData: { [key: string]: BranchRecord } = data ? data.branchList : {};

  useEffect(() => {
    if ((data || error) && isSuccess) {
      refetch();
    }
  }, [page, pageSize, isSuccess]);

  useEffect(() => {
    if (data?.branchList) {
      setBranchList(branchData);
      setRowCount(data.totalBranches);
    } else if (branchData) {
      setBranchList({});
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      const errorData = JSON.parse((error as { data: string }).data);
      const errorCode = errorData.errorCode;
      if (errorCode === 'A0001') {
        setErrorMessage('Unauthorized to fetch users, please contact your administrator.');
      } else {
        setErrorMessage('Failed to fetch users');
      }
      setIsError(true);
    }
  }, [error]);

  useEffect(() => {
    fetchEnumList().then((data) => {
      setEnumList(data);
      setFetchingEnums(false);
    });
  }, []);

  const computeRows = (): GridRowsProp => {
    return Object.entries(branchList).map(([key, branch]) => ({
      id: key,
      companyName: branch.company.name,
      branchName: branch.branchName,
      address: branch.address,
      address2: branch.address2,
      city: branch.city,
      state: enumList.state[branch.stateId].value,
      stateId: branch.stateId,
      zipCode: branch.postalCode,
      country: enumList.country[(enumList.state[branch.stateId] as IStateValue).countryId].value,
      phoneNumber: branch.phoneNumber
    }));
  };

  const computeColumns = (): GridColDef[] => {
    return [
      { field: 'companyName', headerName: 'Company', flex: 1 },
      { field: 'branchName', headerName: 'Branch', flex: 1 },
      { field: 'address', headerName: 'Address', flex: 1 },
      { field: 'address2', headerName: 'Address 2', flex: 1 },
      { field: 'city', headerName: 'City', flex: 1 },
      { field: 'state', headerName: 'State', flex: 1 },
      { field: 'zipCode', headerName: 'Zip Code', flex: 1 },
      { field: 'country', headerName: 'Country', flex: 1 },
      { field: 'phoneNumber', headerName: 'Phone #', flex: 1 },
      {
        field: 'actions',
        type: 'actions',
        flex: 1,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<SettingsIcon />}
            label="Edit"
            onClick={() => {
              setisEditBranchDialogOpen(true);
              setSelectedBranch(params.row.id);
            }}
          />
        ]
      }
    ];
  };

  const rows: GridRowsProp = !isFetching && !fetchingEnums ? computeRows() : [];
  const columns: GridColDef[] = computeColumns();

  const BranchesToolBar = () => {
    return (
      <GridToolbarContainer>
        <Box sx={styles.w100}>
          <Button onClick={() => setisCreateBranchDialogOpen(true)} disabled={isFetching} startIcon={<Add />}>
            {addBranch}
          </Button>
          {hasGlobalAdminRole && (
            <Button
              onClick={() => {
                setisDeleteBranchDialogOpen(true);
              }}
              disabled={isFetching || selectionModel.length !== 1}
              startIcon={<DeleteForeverIcon />}
            >
              {deleteBranch}
            </Button>
          )}
        </Box>
      </GridToolbarContainer>
    );
  };

  return (
    <>
      <DataGrid
        rows={rows}
        columns={columns}
        loading={isFetching}
        checkboxSelection
        disableRowSelectionOnClick
        pageSizeOptions={[25, 50, 100]}
        paginationModel={{ page: page, pageSize: pageSize }}
        onPaginationModelChange={(model) => {
          setPage(model.page);
          setPageSize(model.pageSize);
        }}
        slots={{ toolbar: BranchesToolBar }}
        rowSelectionModel={selectionModel}
        onRowSelectionModelChange={(newSelectionModel) => {
          setSelectionModel(newSelectionModel);
        }}
        rowCount={rowCount}
        paginationMode="server"
        pagination
        autoHeight
      />
      <AddBranchDialog
        isOpen={isCreateBranchDialogOpen}
        setIsOpen={setisCreateBranchDialogOpen}
        setErrorMessage={setErrorMessage}
        setIsError={setIsError}
        setSuccessMessage={setSuccessMessage}
        setIsSuccess={setIsSuccess}
      />
      <EditBranchDialog
        isOpen={isEditBranchDialogOpen}
        setIsOpen={setisEditBranchDialogOpen}
        setErrorMessage={setErrorMessage}
        setIsError={setIsError}
        setSuccessMessage={setSuccessMessage}
        setIsSuccess={setIsSuccess}
        selectedBranch={selectedBranch}
      />
      <DeleteBranchDialog
        isOpen={isDeleteBranchDialogOpen}
        setIsOpen={setisDeleteBranchDialogOpen}
        selectionModel={selectionModel}
        setErrorMessage={setErrorMessage}
        setIsError={setIsError}
        setSuccessMessage={setSuccessMessage}
        setIsSuccess={setIsSuccess}
      />
      <SnackbarAlert type="error" time={6000} text={errorMessage} isOpen={isError} onClose={() => setIsError(false)} />
      <SnackbarAlert
        type="success"
        time={6000}
        text={successMessage}
        isOpen={isSuccess}
        onClose={() => {
          setIsSuccess(false);
        }}
      />
    </>
  );
};

const styles = {
  w100: {
    width: '100%'
  }
};

export default BranchesDataGrid;
