import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { IDevice, setDeviceNetworkSettings } from 'store/slices/devicesSlice';
import { RootState } from 'store';
import { useUpdateDeviceMutation, useHandleGatewayCommandMutation } from 'services/aiphoneCloud';
import { fetchGatewayCommand } from 'shared/rmGateway/gwCommandProcessor';
import { gwCommand } from 'shared/rmGateway/gwCommand';
import SnackbarAlert from 'shared/components/SnackbarAlert';

interface Props {
  category: string;
  ipVersion: string;
}

const dialogText = {
  title: 'Edit Network Info',
  staticBody:
    "We've pre-filled the information for your convenience. Please review and make any necessary changes to ensure accuracy.",
  dhcpBody: 'Automatically assigned IP address via DHCP. No manual editing required.'
};

const DeviceNetworkInfoTabContent = ({ category, ipVersion }: Props) => {
  const dispatch = useDispatch();
  const site = useSelector((state: RootState) => state.site);
  const awsPropertyId = site?.siteInfo?.awsPropertyId;
  const gateway = useSelector((state: RootState) => state.devices.DeviceList[site.siteInfo.registeredGatewayPublicId]);
  const [selectedDevice, setSelectedDevice] = useState<IDevice | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [associatingDevices, setAssociatingDevices] = useState<Record<string, boolean>>({});
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const devices = useSelector((state: RootState) => state.devices);
  const categoryDevices = devices.DeviceListByType[category];
  const [updateDevice, { isLoading: isUpdating }] = useUpdateDeviceMutation();
  const [handleGatewayCommand, { isError: gwCommandError, isLoading: gwLoading }] = useHandleGatewayCommandMutation();

  const initialValues = {
    ipV4Address: selectedDevice?.networkSettings?.ipV4Address ?? '',
    subnetMask: selectedDevice?.networkSettings?.subnetMask ?? '',
    ipV4DefaultGateway: selectedDevice?.networkSettings?.ipV4DefaultGateway ?? '',
    dns: selectedDevice?.networkSettings?.ipV4PrimaryDns ?? ''
  };

  const validationSchema = yup.object().shape({
    ipV4Address: yup
      .string()
      .required('Valid IP Address is required')
      .test('is-valid-ip', 'Invalid IP Address', (value) => {
        return value
          ? /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(value)
          : true;
      }),
    subnetMask: yup
      .string()
      .required('Valid Subnet Mask is required')
      .test('is-valid-ip', 'Invalid Subnet Mask', (value) => {
        return value
          ? /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(value)
          : true;
      }),
    ipV4DefaultGateway: yup
      .string()
      .required('Default Gateway is required')
      .test('is-valid-ip', 'Invalid Default Gateway', (value) => {
        return value
          ? /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(value)
          : true;
      }),
    dns: yup
      .string()
      .required('DNS is required')
      .test('is-valid-ip', 'Invalid DNS', (value) => {
        return value
          ? /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(value)
          : true;
      })
  });

  /**
   * This function is called when the user clicks the edit button on a device card. It sets the selected device and opens the dialog.
   *
   * @param device
   * @param step
   */
  const handleDeviceEdit = (device: IDevice) => {
    setSelectedDevice(device);
    setOpenDialog(true);
  };
  // Associate with Static IP configuration - associate one device at a time
  const staticAssociation = async (device: any) => {
    const isGatewayFirstSync = gateway?.lastSyncedOn === null;
    setIsLoading(true);
    const deviceMacAddress = devices.DeviceList[device.publicId].basicInfo.macAddress;
    // TODO: notice deviceName is not being populated in some case. Need to investigate
    let deviceName: string = devices.DeviceList[device.publicId].basicInfo.stationName;
    //Temporary fix for missing deviceName
    if (deviceName.length === 0) {
      deviceName = 'CS-office-gw';
    }
    // TODO: Need extra conditional check to tell if the device is already associated...
    // TODO: if new gateway, use 'admin' otherwise, use the existing gwId and gwPassword

    const gatewayInfo = {
      awsPropertyId,
      gwMacAddress: gateway?.basicInfo?.macAddress,
      gwId: isGatewayFirstSync ? 'admin' : gateway?.basicInfo?.adminId,
      gwPassword: isGatewayFirstSync ? 'admin' : gateway?.basicInfo?.adminPass
    };
    const deviceInfo = {
      deviceMacAddress,
      deviceIpV4Address: device.networkSettings.ipV4Address,
      deviceSubnetMask: device.networkSettings.subnetMask,
      deviceIpV4DefaultGateway: device.networkSettings.ipV4DefaultGateway,
      deviceName
    };

    setAssociatingDevices((prevState) => ({ ...prevState, [deviceMacAddress]: true }));

    try {
      const ioTPayload = fetchGatewayCommand('sendCommand', gwCommand.ASSOCIATE, gatewayInfo, deviceInfo, 'static');
      if (ioTPayload === 'Missing information') {
        setError('Missing information');
        setIsLoading(false);
        throw new Error('Missing information');
      }
      await handleGatewayCommand(ioTPayload);
      const fetchPayload = fetchGatewayCommand('fetchResult', gwCommand.ASSOCIATE, gatewayInfo, deviceInfo, 'static');
      const fetchResponse = await handleGatewayCommand(fetchPayload);
      if (gwCommandError || fetchResponse.error || !fetchResponse.data.statusCode.includes('200')) {
        setError('Error associating device');
        setIsLoading(false);
        throw new Error('Error associating device');
      }
      updateDevice({
        device: {
          publicId: device.publicId,
          sitePublicId: device.sitePublicId,
          networkSettings: {
            ...device.networkSettings
          }
        }
      });
      setIsLoading(false);
    } catch (error) {
      setError('Error associating device', error);
    }
  };

  const handleSaveDeviceNetworkInfo = async (values: any) => {
    if (selectedDevice) {
      const updatedDevice = {
        publicId: selectedDevice.publicId,
        sitePublicId: selectedDevice.sitePublicId,
        networkSettings: {
          ...selectedDevice.networkSettings,
          ipV4Address: values.ipV4Address,
          subnetMask: values.subnetMask,
          ipV4DefaultGateway: values.ipV4DefaultGateway,
          dns: values.dns
        }
      };
      try {
        await staticAssociation(updatedDevice);
      } catch (error) {
        //TODO: Handle Error
        setError('Error associating device');
      }
      dispatch(setDeviceNetworkSettings(updatedDevice));
      setOpenDialog(false);
    }
  };

  return (
    <Box sx={{ mt: 2 }}>
      {error && <SnackbarAlert type="error" time={7000} text={error} isOpen={!!error} onClose={() => setError('')} />}
      <Grid container spacing={2}>
        {categoryDevices.map((deviceId) => {
          const device = devices.DeviceList[deviceId];
          return (
            <Grid item xs={4} key={device.publicId}>
              <Card sx={{ position: 'relative' }} key={device.publicId}>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {device.basicInfo.macAddress}
                  </Typography>
                  {associatingDevices[device.basicInfo.macAddress] && (
                    <Box
                      position="absolute"
                      top={0}
                      left={0}
                      width="100%"
                      height="100%"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      bgcolor="rgba(255, 255, 255, 0.7)" // semi-transparent white
                    >
                      {gwLoading && <CircularProgress />}
                    </Box>
                  )}
                  <Typography variant="body2" color="text.secondary">
                    <strong>IP Address:</strong>
                    <br />
                    {device.networkSettings?.ipV4Address}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <strong>Subnet Mask:</strong>
                    <br />
                    {device.networkSettings?.subnetMask}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <strong>Default Gateway:</strong>
                    <br />
                    {device.networkSettings?.ipV4DefaultGateway}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <strong>DNS:</strong>
                    <br />
                    {device.networkSettings?.ipV4PrimaryDns}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    onClick={() => handleDeviceEdit(device)}
                    disabled={associatingDevices[device.basicInfo.macAddress] === false}
                  >
                    {associatingDevices[device.basicInfo.macAddress] === false
                      ? 'Device Associated'
                      : 'Edit Network Info'}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSaveDeviceNetworkInfo}
        >
          {({ errors, touched }) => (
            <Form>
              <DialogTitle>{dialogText.title}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {ipVersion === 'dhcp' ? dialogText.dhcpBody : dialogText.staticBody}
                </DialogContentText>
                <Field
                  name="ipV4Address"
                  label="IP Address"
                  as={TextField}
                  sx={styles.inputField}
                  fullWidth
                  error={touched.ipV4Address && Boolean(errors.ipV4Address)}
                  helperText={touched.ipV4Address && errors.ipV4Address}
                />
                <Field
                  name="subnetMask"
                  label="Subnet Mask"
                  as={TextField}
                  sx={styles.inputField}
                  fullWidth
                  error={touched.subnetMask && Boolean(errors.subnetMask)}
                  helperText={touched.subnetMask && errors.subnetMask}
                />
                <Field
                  name="ipV4DefaultGateway"
                  label="Default Gateway"
                  as={TextField}
                  sx={styles.inputField}
                  fullWidth
                  error={touched.ipV4DefaultGateway && Boolean(errors.ipV4DefaultGateway)}
                  helperText={touched.ipV4DefaultGateway && errors.ipV4DefaultGateway}
                />
                <Field
                  name="dns"
                  label="DNS"
                  as={TextField}
                  sx={styles.inputField}
                  fullWidth
                  error={touched.dns && Boolean(errors.dns)}
                  helperText={touched.dns && errors.dns}
                />
              </DialogContent>
              <DialogActions>
                <Button disabled={isUpdating} type="submit">
                  {gwLoading ? <CircularProgress size="25px" sx={styles.loading} /> : 'Associate Device'}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </Box>
  );
};

const styles = {
  loading: {
    color: 'white'
  },
  inputField: {
    mt: 2
  }
};

export default DeviceNetworkInfoTabContent;
