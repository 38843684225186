import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowSelectionModel,
  GridRowsProp,
  GridToolbarContainer
} from '@mui/x-data-grid';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import React, { useEffect } from 'react';
import { Box, Button } from '@mui/material';
import EditRegisteredUserDialog from './Dialogs/EditRegisteredUserDialog';
import { useGetCompaniesQuery, useGetUsersWithNoBranchRolesQuery } from 'services/aiphoneCloud';
import { CloudUser } from 'store/slices/usersSlice';
import SnackbarAlert from 'shared/components/SnackbarAlert';
import DeleteUserDialog from './Dialogs/DeleteUserDialog';
import { useSelector } from 'react-redux';
import { getCurrentUser } from 'store/slices/usersSlice';

export interface ISelectedUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

const RegisteredUsersDataGrid = () => {
  const defaultPageSize = 50;

  const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([]);
  const [isEditRegisteredUserDialogOpen, setIsEditRegisteredUserDialogOpen] = React.useState(false);
  const [isDeleteUserDialogOpen, setIsDeleteUserDialogOpen] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState<ISelectedUser>({});
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isError, setIsError] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('');
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [usersList, setUsersList] = React.useState<{ [key: string]: CloudUser }>({});
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(defaultPageSize);
  const [rowCount, setRowCount] = React.useState(0);

  const currentUser = useSelector(getCurrentUser);
  const hasGlobalAdminRole = currentUser ? currentUser.permissions.global.roleName === 'Aiphone Admin' : false;

  const fetchData = () => {
    return useGetUsersWithNoBranchRolesQuery({
      qty: pageSize.toString(),
      page: page.toString()
    });
  };

  /*Fetch users*/
  const { data, isFetching, error, refetch } = fetchData();
  const usersData: { [key: string]: CloudUser } = data ? data.userList : {};

  /*Fetch companies*/
  const { data: companies, isFetching: isCompaniesFetching } = useGetCompaniesQuery({
    qty: '1000',
    page: '0'
  });
  const companyList = companies ? companies.companyList : {};

  /*Hook components*/
  useEffect(() => {
    if ((usersData || error) && isSuccess) {
      refetch();
    }
  }, [page, pageSize, isSuccess]);

  useEffect(() => {
    if (data?.userList) {
      setUsersList(usersData);
      setRowCount(data.totalUsers);
    } else if (usersData) {
      setUsersList({});
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      const errorData = JSON.parse((error as { data: string }).data);
      const errorCode = errorData.errorCode;
      if (errorCode === 'A0001') {
        setErrorMessage('Unauthorized to fetch users, please contact your administrator.');
      } else {
        setErrorMessage('Failed to fetch users');
      }
      setIsError(true);
    }
  }, [error]);

  const computeRows = (): GridRowsProp => {
    return Object.entries(usersList).map(([key, value]: [string, CloudUser]) => {
      return {
        id: value.publicId,
        firstName: value.firstName,
        lastName: value.lastName,
        email: value.email
      };
    });
  };

  const computeColumns = (): GridColDef[] => {
    return [
      { field: 'firstName', type: 'string', headerName: 'First Name', flex: 1 },
      { field: 'lastName', type: 'string', headerName: 'Last Name', flex: 1 },
      { field: 'email', type: 'string', headerName: 'Email', flex: 1 },
      {
        field: 'actions',
        type: 'actions',
        flex: 1,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<SettingsIcon />}
            label="Edit"
            onClick={() => {
              setIsEditRegisteredUserDialogOpen(true);
              setSelectedUser({
                id: params.row.id,
                firstName: params.row.firstName,
                lastName: params.row.lastName,
                email: params.row.email
              });
            }}
          />
        ]
      }
    ];
  };

  const RegisteredUsersToolbar = () => {
    return (
      <GridToolbarContainer>
        {hasGlobalAdminRole && (
          <Box sx={styles.w100}>
            <Button
              onClick={() => setIsDeleteUserDialogOpen(true)}
              disabled={isFetching || selectionModel.length !== 1}
              startIcon={<DeleteForeverIcon />}
            >
              Delete User
            </Button>
          </Box>
        )}
      </GridToolbarContainer>
    );
  };

  const rows: GridRowsProp = !isFetching ? computeRows() : [];
  const columns: GridColDef[] = computeColumns();

  return (
    <>
      <Box sx={styles.w100}>
        <DataGrid
          autoHeight
          rows={rows}
          columns={columns}
          loading={isFetching}
          checkboxSelection
          disableRowSelectionOnClick
          pageSizeOptions={[25, 50, 100]}
          paginationModel={{ page: page, pageSize: pageSize }}
          onPaginationModelChange={(model) => {
            setPage(model.page);
            setPageSize(model.pageSize);
          }}
          slots={{ toolbar: RegisteredUsersToolbar }}
          rowSelectionModel={selectionModel}
          onRowSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          rowCount={rowCount}
          paginationMode="server"
          pagination
        />
      </Box>
      <EditRegisteredUserDialog
        isOpen={isEditRegisteredUserDialogOpen}
        setIsOpen={setIsEditRegisteredUserDialogOpen}
        selectedUser={selectedUser}
        companies={companyList}
        isFetching={isCompaniesFetching}
        setErrorMessage={setErrorMessage}
        setIsError={setIsError}
        setSuccessMessage={setSuccessMessage}
        setIsSuccess={setIsSuccess}
      />
      <DeleteUserDialog
        selectionModel={selectionModel}
        isOpen={isDeleteUserDialogOpen}
        setIsOpen={setIsDeleteUserDialogOpen}
        setErrorMessage={setErrorMessage}
        setIsError={setIsError}
        setSuccessMessage={setSuccessMessage}
        setIsSuccess={setIsSuccess}
      />
      <SnackbarAlert type="error" time={6000} text={errorMessage} isOpen={isError} onClose={() => setIsError(false)} />
      <SnackbarAlert
        type="success"
        time={6000}
        text={successMessage}
        isOpen={isSuccess}
        onClose={() => {
          setIsSuccess(false);
        }}
      />
    </>
  );
};

const styles = {
  w100: {
    width: '100%'
  }
};

export default RegisteredUsersDataGrid;
