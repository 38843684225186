import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import MultiTenantSiteWizard from './MultiTenantSiteWizard';
import CommercialSiteWizard from './CommercialSiteWizard';

const SetupWizard = () => {
  const siteData = useSelector((state: RootState) => state.site.siteInfo);

  if (siteData.typeId === 1) {
    return <CommercialSiteWizard />;
  } else {
    return <MultiTenantSiteWizard />;
  }
};

export default SetupWizard;
