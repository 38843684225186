import { createSlice } from '@reduxjs/toolkit';
import { fetchSiteData } from './siteSlice';

interface Tenant {
  //TODO Update id to ID when we update the component that adds tenants
  id: string;
  FirstName: string;
  LastName: string;
  UnitNumber: string;
  AccessCode: string;
}

interface TenantState {
  Tenants: Tenant[];
  SelectedTenant: Tenant | null | undefined;
  Loading: boolean;
}

const initialState: TenantState = {
  Tenants: [],
  SelectedTenant: null,
  Loading: false
};

const tenantsSlice = createSlice({
  name: 'tenants',
  initialState,
  reducers: {
    updateTenants: (state, action) => {
      const index = state.Tenants.findIndex((tenant) => tenant.id === action.payload.id);
      if (index !== -1) {
        state.Tenants[index] = { ...state.Tenants[index], ...action.payload };
      } else {
        state.Tenants.push(action.payload);
      }
    },
    deleteTenant: (state, action) => {
      const id = action.payload;
      //TODO Update id to ID
      state.Tenants = state.Tenants.filter((tenant) => tenant.id !== id);
    },
    resetTenantState: (state) => {
      state.Tenants = [];
      state.SelectedTenant = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSiteData.fulfilled, (state, action) => {
      state.Loading = false;
      state.Tenants = action.payload.tenants ? action.payload.tenants.Tenants : [];
    });
    // .addCase(updateJsonForSite.fulfilled, (state, action) => {
    //   // Update the site JSON with the data you passed to updateJsonForSite
    //   state.tenants = action.meta.arg.siteJson.Tenants || []; // use meta.arg to access original arguments
    // });
  }
});

export const { updateTenants, deleteTenant, resetTenantState } = tenantsSlice.actions;
export const tenantsReducer = tenantsSlice.reducer;
