import React, { FC } from 'react';
import { FormControl, InputLabel, MenuItem, Select, Grid } from '@mui/material';
import { Field } from 'formik';
import { IRole } from '../../Dialogs/EditRegisteredUserDialog';

interface IRoleSelectProps {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  roles: IRole[];
}

const RoleSelect: FC<IRoleSelectProps> = ({ handleChange, roles }) => {
  const renderRoleOptions = () => {
    if (!roles || !roles?.length) {
      return (
        <MenuItem value="">
          <em>No roles found</em>
        </MenuItem>
      );
    }
    return roles.map((role) => (
      <MenuItem key={role.publicId} value={role.publicId}>
        {role.roleName}
      </MenuItem>
    ));
  };

  return (
    <Grid item xs={12}>
      <FormControl size="small" fullWidth>
        <InputLabel id="role-label">Select a role</InputLabel>
        <Field name="role" id="role" as={Select} labelId="role-label" label="Select a role" onChange={handleChange}>
          {renderRoleOptions()}
        </Field>
      </FormControl>
    </Grid>
  );
};

export default RoleSelect;
