/* Monitoring tab stub */
export const MonitoringLabel = () => {
  return <span>Monitoring</span>;
};

const Monitoring = () => {
  return <span>Monitoring</span>;
};

export default Monitoring;
