import { Box, Card, CardContent, Container, Typography, Grid, Alert, Snackbar } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getCurrentUser, IBranchPermissions, IUserPermissions } from 'store/slices/usersSlice';
import useRefetchData from './Hooks/useRefetchData';
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from 'shared/constantAwsApi';

export const hasNonAssociateAccessInBranch = (branch: any): boolean => {
  const allowedRoles = ['Member', 'Manager', 'Admin'];
  return branch.roleList.some((role: any) => allowedRoles.includes(role.roleName));
};

const convertPermissionsBranchToArray = (
  branchPermissions: Record<string, IBranchPermissions>
): IBranchPermissions[] => {
  return Object.values(branchPermissions);
};

const Home = () => {
  useRefetchData();
  const navigate = useNavigate();

  const currentUser = useSelector(getCurrentUser);

  const permissions: IUserPermissions | undefined = currentUser?.permissions;
  const registeredUser = currentUser ? !permissions || (!permissions.global && !permissions.branch) : true;
  const hasGlobalRole = permissions && permissions.global !== null && permissions.global !== undefined;

  const branches: IBranchPermissions[] = permissions?.branch ? convertPermissionsBranchToArray(permissions.branch) : [];
  const hasNonAssociateBranchRole = branches.some((branch) => hasNonAssociateAccessInBranch(branch));

  // User can access SIM Billing if they have global role or have a non-associate branch role
  const canAccessSimBilling = !registeredUser && (hasGlobalRole || hasNonAssociateBranchRole);

  const [hoveredTile, setHoveredTile] = useState<number | null>(null);
  const [showAccessAlert, setShowAccessAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const handleMouseEnter = (index: number) => setHoveredTile(index);
  const handleMouseLeave = () => setHoveredTile(null);

  const handleSimBillingClick = () => {
    if (canAccessSimBilling) {
      navigate('/simbilling');
    } else if (registeredUser) {
      setAlertMessage('Join a Dealer to access SIM Billing. Contact cloudadmin@aiphone.com for more information.');
      setShowAccessAlert(true);
    } else if (!hasNonAssociateBranchRole) {
      setAlertMessage(
        'Your Branch Role does not have access to SIM Billing. Please contact your Branch Admin for access.'
      );
      setShowAccessAlert(true);
    }
  };

  const handleQuikSpecClick = () => {
    navigate('/quikspec');
  };

  const tiles = [
    {
      id: 0,
      primaryTitle: 'QuikSpec',
      primaryDescription: 'Design an Aiphone system using QuikSpec',
      hoverText: 'Generate a complete material list for the most popular Aiphone systems.',
      imageSrc: `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/QuikSpec_Logo.jpg`,
      onClick: handleQuikSpecClick
    },
    {
      id: 1,
      primaryTitle: 'SIM Billing',
      primaryDescription: 'SIM Billing Plans',
      hoverText: 'Subscribe to a Data Plan to use IXGW-TGW Telephone Entry and Cellular Data',
      imageSrc: `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/SIM-Billing_Logo.png`,
      onClick: handleSimBillingClick
    },
    {
      id: 2,
      primaryTitle: 'Remote Management',
      primaryDescription: 'Remote Management',
      hoverText: 'Remote Management will launch in Summer 2024.',
      //TODO : Update finalized image from Marketting
      imageSrc: `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/Remote-Management_Logo.png`
    }
  ];

  return (
    <Container maxWidth="lg">
      <Box sx={styles.logoWrapper} component="img" src="/AiphoneCloud-Logo_Blue.png" alt="Aiphone Cloud Logo" />
      <Grid container spacing={4} marginTop={4}>
        {tiles.map((tile) => (
          <Grid item xs={12} md={4} key={tile.id}>
            <Card
              onMouseEnter={() => handleMouseEnter(tile.id)}
              onMouseLeave={handleMouseLeave}
              onClick={tile.onClick}
              sx={styles.mainContainer}
            >
              <CardContent sx={styles.cardContent}>
                <Box
                  component="img"
                  src={tile.imageSrc}
                  alt={`${tile.primaryTitle} Logo`}
                  sx={tile.primaryTitle === 'QuikSpec' ? styles.quikSpecImage : styles.featureImage}
                />
                <Typography variant="body1" sx={styles.textWrapper}>
                  {tile.primaryDescription}
                </Typography>
                {hoveredTile === tile.id && (
                  <Box sx={styles.featureDetails}>
                    <Typography variant="h6" sx={styles.hoverDisplay}>
                      {tile.hoverText}
                    </Typography>
                  </Box>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Snackbar
        open={showAccessAlert}
        autoHideDuration={5000}
        onClose={() => setShowAccessAlert(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="info">{alertMessage}</Alert>
      </Snackbar>
    </Container>
  );
};

const styles = {
  logoWrapper: {
    padding: 3,
    display: 'block',
    margin: '0 auto',
    width: '100%',
    maxWidth: '600px'
  },
  featureImage: {
    width: '40%',
    margin: '0 auto',
    objectFit: 'contain',
    maxHeight: '100px',
    minWidth: '100px'
  },
  quikSpecImage: {
    width: '60%',
    margin: '0 auto',
    objectFit: 'contain',
    maxHeight: '120px',
    marginBottom: '20px',
    minWidth: '130px'
  },
  textWrapper: {
    marginTop: '16px',
    flexGrow: 1
  },
  featureDetails: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 2,
    borderRadius: '4px'
  },
  mainContainer: {
    cursor: 'pointer',
    textAlign: 'center',
    padding: 1,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '220px'
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '200px',
    maxHeight: '220px'
  },
  hoverDisplay: {
    textAlign: 'center'
  }
};

export default Home;
