/**
 * Sync functionality, Firmware functionality for the site dashboard.
 */
import {
  Alert,
  Box,
  Typography,
  Grid,
  Paper,
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Tooltip,
  CircularProgress
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';
import TextField from '@mui/material/TextField';
import CachedIcon from '@mui/icons-material/Cached';
import SettingsIcon from '@mui/icons-material/Settings';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { IDevice } from 'store/slices/devicesSlice';
import { getDeviceModelNumberFromModelType } from 'shared/utils/helperFunctions';
import { useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { getSite } from 'store/slices/siteSlice';
import containerStyle from 'shared/styles/advancedSettingContainerStyle';
import { getString } from 'shared/utils/LocalizationUtils';
import SnackbarAlert from 'shared/components/SnackbarAlert';
import StringUtils from 'shared/utils/StringUtils';
import { CA, US } from 'country-flag-icons/react/3x2';
import {
  useCreateConfigFilesMutation,
  useUpdateSiteMutation,
  useHandleGatewayCommandMutation,
  useUpdateSyncedDateMutation
} from 'services/aiphoneCloud';
import { fetchGatewayCommand } from 'shared/rmGateway/gwCommandProcessor';
import { gwCommand } from 'shared/rmGateway/gwCommand';
import { EnumList, ICountryValue, IStateValue, fetchEnumList } from 'shared/utils/EnumUtils';
import { GetUploadURL } from 'shared/api/Acl/IxgAclApi';
import CONFIG from 'config';
import { get } from 'http';

interface RowElem {
  id: string;
  MacAddress: string;
  StationNumber: string;
  StationName: string;
  ModelNumber: string | null;
  FirmwareVersion: string;
  Status: string;
  ConfigFileUrl: string;
}

interface Gateway {
  awsPropertyId: string;
  gwMacAddress: string;
  gwId: string;
  gwPassword: string;
  gwIpAddress: string | null | undefined;
}

const Site = () => {
  const dispatch = useDispatch();
  const [updateSyncedDate] = useUpdateSyncedDateMutation();

  const site = useSelector(getSite);
  const gateway = useSelector(
    (state: RootState) => state.devices.DeviceList[site?.siteInfo?.registeredGatewayPublicId]
  );
  const { DeviceList: devices } = useSelector((state: RootState) => state.devices);

  const [gwOnlineStatus, setGwOnlineStatus] = useState('online');
  const [isGWRegistered, setIsGatewayRegistered] = useState(true);
  const [syncStatus, setSyncStatus] = useState('stand by');
  const [fetchIntervalId, setFetchIntervalId] = useState<number | null>(null);
  const [timeoutId, setTimeoutId] = useState<number | null>(null);

  const [isSiteInfoComplete, setIsSiteInfoComplete] = useState(true);
  const [enumList, setEnumList] = useState<EnumList>({ country: {}, state: {} });
  const [fetchingEnums, setFetchingEnums] = useState(true);
  const [rows, setRows] = useState<RowElem[]>([]);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [showAlert, setShowAlert] = useState(false);

  const [createConfigFiles] = useCreateConfigFilesMutation();
  const [updateSite, { isLoading: siteLoading, isSuccess: siteUpdateSuccess }] = useUpdateSiteMutation();
  const [handleGatewayCommand, { isError: isGwSyncError, isLoading: isGwProcessingCommand }] =
    useHandleGatewayCommandMutation();

  const isGatewayFirstSync = gateway?.lastSyncedOn === null;
  const selectCountryText = getString('Select_Country');

  useEffect(() => {
    fetchEnumList().then((data) => {
      setEnumList(data);
      setFetchingEnums(false);
    });
  }, []);

  useEffect(() => {
    if (site.siteInfo.awsPropertyId !== '' && gwOnlineStatus === 'online') {
      getGatewayOnlineStatus();
    }
  }, [gateway]);

  useEffect(() => {
    //  check the site info is complete
    if (!siteLoading) {
      const sitePayload = {
        siteAddress: site.siteInfo.siteAddress,
        siteCity: site.siteInfo.siteCity,
        stateId: site.siteInfo.stateId,
        siteZip: site.siteInfo.siteZip
      };
      hasNullOrUndefined(sitePayload) ? setIsSiteInfoComplete(true) : setIsSiteInfoComplete(false);
    }
  }, [siteUpdateSuccess, site, siteUpdateSuccess]);

  useEffect(() => {
    setRows(buildDeviceRow(Object.values(devices)));
    if (Object.keys(devices).length > 0) {
      setIsGatewayRegistered(gateway !== undefined);
    }
  }, [devices, site]);

  const getColorFromStatus = (status: string) => {
    switch (status) {
      case 'online':
      case 'synced':
      case 'stand by':
        return 'success';

      case 'busy':
      case 'Syncing...':
        return 'warning';

      case 'checking':
        return 'info';

      case 'offline':
      default:
        return 'error';
    }
  };
  const hasNullOrUndefined = (siteInfo: { address: string; city: string; stateId: number; zip: string }) => {
    for (const key in siteInfo) {
      if (siteInfo[key] === null || siteInfo[key] === undefined) {
        return false;
      }
    }
    return true;
  };

  const getGatewayOnlineStatus = async () => {
    if (gwOnlineStatus === 'busy' || gwOnlineStatus === 'checking') {
      return;
    }
    setGwOnlineStatus('checking');

    const gatewayInfo = {
      awsPropertyId: site?.siteInfo?.awsPropertyId,
      gwMacAddress: gateway?.basicInfo?.macAddress,
      gwId: isGatewayFirstSync ? 'admin' : gateway?.basicInfo.adminId,
      gwPassword: isGatewayFirstSync ? 'admin' : gateway?.basicInfo.adminPass,
      gwIpAddress: gateway?.networkSettings?.ipV4Address
    };
    try {
      // send command to gateway via ioT
      const ioTPayload = fetchGatewayCommand('sendCommand', gwCommand.DEVICE_CHECK, gatewayInfo, null, null);
      if (ioTPayload === 'Missing information') {
        throw new Error('Missing information');
      }
      await handleGatewayCommand(ioTPayload).unwrap();

      // fetch the result from the gateway
      const fetchPayload = fetchGatewayCommand('fetchResult', gwCommand.DEVICE_CHECK, gatewayInfo, null, null);
      const fetchResponse = await handleGatewayCommand(fetchPayload).unwrap();
      const statusCode = fetchResponse?.statusCode.slice(0, 3);
      if (statusCode === '200') {
        setGwOnlineStatus('online');
        return;
      }
      if (statusCode === '406') {
        setGwOnlineStatus('busy');
      } else {
        throw new Error(
          'Device check failed. Please check the device connection with the correct IP address and MAC address.'
        );
      }
    } catch (error) {
      console.log('error: ', error);
      if (error.status === 400) {
        setErrorMessage('Try again later. Gateway is not connected to the network.');
      }
      setGwOnlineStatus('offline');
      setErrorMessage('Error: Unable to connect to the gateway. Please check your network connection and try again.');
    }
  };

  /****************************************   FIRMWARE UPDATE  ****************************************/
  const handleSyncFirmware = async () => {
    setRows(rows.map((row) => ({ ...row, Status: 'checking' })));

    // Fetch the latest firmware version
    const firmwareVersionCheck = await fetch('https://n4q4lsqb6a.execute-api.us-west-2.amazonaws.com/prod/latest');
    const latestVersion = await firmwareVersionCheck.json();

    const gatewayInfo = {
      awsPropertyId: site?.siteInfo?.awsPropertyId,
      gwMacAddress: gateway?.basicInfo?.macAddress,
      gwId: gateway?.basicInfo.adminId,
      gwPassword: gateway?.basicInfo.adminPass
    };

    for (const device of rows) {
      const deviceType = device?.ModelNumber;
      const deviceFirmwareVersion = device?.FirmwareVersion;
      const latestFirmwareName = latestVersion[deviceType]?.standard?.name;

      if (
        deviceType in latestVersion &&
        Object.keys(latestVersion[deviceType]).includes('standard') &&
        /\d\.\d\d/.test(deviceFirmwareVersion) &&
        latestVersion[deviceType].standard.version > parseInt(deviceFirmwareVersion.replace('.', ''))
      ) {
        const downloadResponse = await fetch(
          'https://n4q4lsqb6a.execute-api.us-west-2.amazonaws.com/prod/download/' +
            latestVersion[deviceType].standard.name
        );
        const url = await downloadResponse.text();

        const selectedDevice = devices[device.id];
        const deviceInfo = {
          deviceIpAddress: selectedDevice?.networkSettings?.ipV4Address,
          deviceMacAddress: selectedDevice?.basicInfo?.macAddress,
          deviceType: deviceType,
          deviceId: selectedDevice?.basicInfo.adminId,
          devicePassword: selectedDevice?.basicInfo.adminPass,
          deviceFirmwareFileName: latestFirmwareName,
          deviceFirmwareLink: url
        };
        try {
          const ioTPayload = fetchGatewayCommand(
            'sendCommand',
            gwCommand.FIRMWARE_UPDATE,
            gatewayInfo,
            deviceInfo,
            null
          );
          device.Status = 'updating...';
          setRows(rows);
          const sendResponse = await handleGatewayCommand(ioTPayload).unwrap();

          const fetchPayload = fetchGatewayCommand(
            'fetchResult',
            gwCommand.FIRMWARE_UPDATE,
            gatewayInfo,
            deviceInfo,
            null
          );
          const fetchResponse = await handleGatewayCommand(fetchPayload).unwrap();

          if (
            isGwSyncError ||
            !sendResponse?.statusCode.includes('200') ||
            !fetchResponse?.statusCode.includes('200')
          ) {
            device.Status = 'Error';
            setErrorMessage('Failed to update firmware for device');
            setRows(rows);
            return;
          }
          device.Status = 'success...';
          setRows(rows);
        } catch (error) {
          setErrorMessage('Failed to update firmware for device');
        }
      }
    }
  };

  /****************************************   SYNC   ****************************************/
  const isDeviceFirstSync = (device: IDevice) => {
    return device?.lastSyncedOn === null;
  };

  // TODO: sent the gateway command, then check the response after 5 seconds and then 30 seconds until timeout at 5 mins
  const syncGateway = async (gatewayInfo: Gateway, device: RowElem) => {
    try {
      const deviceInfo = {
        deviceIpAddress: gatewayInfo.gwIpAddress,
        deviceMacAddress: gateway?.basicInfo.macAddress,
        deviceStationNumber: gateway?.basicInfo.stationNumber,
        deviceId: gatewayInfo.gwId,
        devicePassword: gatewayInfo.gwPassword,
        deviceConfigFileUrl: device.ConfigFileUrl,
        deviceType: device.ModelNumber,
        deviceFileName: `config-${site.siteInfo.publicId}-${device.id}.txt.gz`
      };

      const ioTPayload = fetchGatewayCommand('sendCommand', gwCommand.SYNC, gatewayInfo, deviceInfo, null);
      if (ioTPayload === 'Missing information') {
        throw new Error('Missing information');
      }
      await handleGatewayCommand(ioTPayload).unwrap();

      // send the second command to see if the gateway return the busy status
      await handleGatewayCommand(ioTPayload).unwrap();
      const fetchPayload = fetchGatewayCommand('fetchResult', gwCommand.SYNC, gatewayInfo, deviceInfo, null);
      const fetchResponse = await handleGatewayCommand(fetchPayload).unwrap();

      // if the gateway status is busy, then wait for 30 seconds and check again
      if (!fetchResponse?.statusCode.includes('406')) {
        // set interval to check the status of the gateway every 30 seconds
        const intervalId = window.setInterval(async () => {
          try {
            const fetchResponse = await handleGatewayCommand(fetchPayload).unwrap();
            if (fetchResponse?.statusCode.includes('200')) {
              if (fetchIntervalId !== null) clearInterval(intervalId);
              if (timeoutId !== null) clearTimeout(timeoutId);
            }
          } catch (retryError) {
            console.error('Retry Error: ', retryError);
            clearInterval(intervalId);
          }
        }, 30000);
        setFetchIntervalId(intervalId);

        // Timeout after 5 minutes
        const timeoutId = window.setTimeout(() => {
          clearInterval(intervalId);
          setErrorMessage('Gateway is taking too long to sync. Please try again.');
          setGwOnlineStatus('busy');
        }, 5 * 60 * 1000);
        setTimeoutId(timeoutId);
      }
      if (!fetchResponse?.statusCode.includes('200')) {
        return false;
      }
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const handleConfigFileUpload = async (device: RowElem) => {
    const selectedDevice = devices[device.id];
    const { publicId } = selectedDevice;
    const deviceType = device.ModelNumber;
    let ioTPayload, fetchPayload, fetchResponse;

    const gatewayInfo = {
      awsPropertyId: site?.siteInfo?.awsPropertyId,
      gwMacAddress: gateway?.basicInfo?.macAddress,
      gwId: isGatewayFirstSync ? 'admin' : gateway?.basicInfo.adminId,
      gwPassword: isGatewayFirstSync ? 'admin' : gateway?.basicInfo.adminPass,
      gwIpAddress: gateway?.networkSettings?.ipV4Address
    };
    const isDeviceFirstSync = selectedDevice.lastSyncedOn === null;
    const deviceId = isDeviceFirstSync ? 'admin' : selectedDevice?.basicInfo.adminId;
    const devicePassword = isDeviceFirstSync ? 'admin' : selectedDevice?.basicInfo.adminPass;

    try {
      // Register Gateway first
      if (deviceType === 'IXGW-GW') {
        const syncGwRespond = await syncGateway(gatewayInfo, device);

        // update Device with sync status
        if (syncGwRespond) {
          const params = {
            device: {
              publicId
            }
          };
          updateSyncedDate(params);
          return 'synced. Rebooting...';
        } else {
          return 'sync failed';
        }
      }

      // TODO: Before syncing other devices, check if the gateway is synced first
      const deviceInfo = {
        deviceIpAddress: selectedDevice?.networkSettings?.ipV4Address,
        deviceMacAddress: device.MacAddress,
        deviceStationNumber: device.StationNumber,
        deviceId,
        devicePassword,
        deviceConfigFileUrl: device.ConfigFileUrl,
        deviceType,
        deviceFileName: `config-${site.siteInfo.publicId}-${device.id}.txt.gz`
      };

      if (!isGwProcessingCommand && gwOnlineStatus === 'connected') {
        ioTPayload = fetchGatewayCommand('sendCommand', gwCommand.SYNC, gatewayInfo, deviceInfo, null);
        if (ioTPayload === 'Missing information') {
          throw new Error('Missing information');
        }
        await handleGatewayCommand(ioTPayload).unwrap();
        fetchPayload = fetchGatewayCommand('fetchResult', gwCommand.SYNC, gatewayInfo, deviceInfo, null);
        fetchResponse = await handleGatewayCommand(fetchPayload).unwrap();

        if (fetchResponse?.statusCode.includes('200')) {
          return 'synced';
        } else {
          return 'sync failed';
        }
      }
    } catch (error) {
      setErrorMessage('Failed to sync with the gateway: ' + error.message);
      return 'sync failed';
    }
  };

  /* TODO: when the user click sync stations,
   * 1. check if the gateway is registered (publicID matched with MAC Address)
   * 2. create the config files
   * 3. sync the devices with the gateway FIRST
   * 4. let the system reboot
   * 5. continue to the rest of the devices
   * 6. if the sync failed, display the error message
   * 7. move on to the next device until the end
   * 8. Sync the failed devices again - click sync stations, then it will only sync it it failed
   *
   */
  const generateConfigFilesAndUpload = async () => {
    // TODO: check to see if the gateway firmware is have 3.81 or higher
    // TODO: check to see if the gateway is synced
    if (gwOnlineStatus === 'busy' || gwOnlineStatus === 'checking') {
      setErrorMessage('Gateway is busy...');
      return;
    }
    // Fetch list of config files
    const createConfigFilesData = {
      sitePublicId: site.siteInfo.publicId,
      devicePublicIds: Object.entries(devices).map(([, device]: [string, IDevice]) => device.publicId)
    };
    const response = await createConfigFiles(createConfigFilesData);
    if ('data' in response) {
      // Sort through devices to place gateway first
      const newRows = JSON.parse(JSON.stringify(rows));

      try {
        newRows.sort((a: RowElem, b: RowElem) => {
          if (a.ModelNumber === 'IXGW-GW') return -1;
          if (b.ModelNumber === 'IXGW-GW') return 1;
          return 0;
        });
      } catch (error) {
        setErrorMessage('Failed to sort devices');
      }

      for (const row of newRows) {
        if (row.Status === 'synced') {
          return;
        }

        row.ConfigFileUrl = response.data.deviceUrls[row.id];
        row.Status = 'syncing...';
        setRows(newRows);
        const gatewaySyncResponse = await handleConfigFileUpload(row);
        if (gatewaySyncResponse === 'sync failed') {
          row.Status = 'sync failed';
          setRows(newRows);
        }
        if (gatewaySyncResponse !== void 0) {
          row.Status = gatewaySyncResponse;
          setRows(newRows);
        }
      }
      setSyncStatus('synced');
    } else {
      throw new Error('Failed to create config files');
    }
  };

  const handleSyncStationsButton = async () => {
    // Proceed only if the gateway is online and currently not running another command
    await getGatewayOnlineStatus();
    if (gwOnlineStatus === 'offline') {
      setErrorMessage('Gateway is offline. Please check your network connection and try again.');
      return;
    }

    setGwOnlineStatus('busy');
    setSyncStatus('waiting...');

    // Generate config files and upload to the IXG-GW
    try {
      await generateConfigFilesAndUpload();
      await getGatewayOnlineStatus();
    } catch (error) {
      setSyncStatus('sync failed');
      await getGatewayOnlineStatus();
    }

    // Sync data to ACL
    try {
      const aclToken = localStorage.getItem('acltoken');

      if (!aclToken) {
        throw new Error('aclToken not set');
      }
      console.log('aclToken: ', aclToken);

      const propertyid = localStorage.getItem('awsPropertyId') || site.siteInfo.awsPropertyId;

      console.log('propertyid: ', Number(propertyid));

      if (!propertyid) {
        throw new Error('propertyid not set');
      }

      const uploadurl = await GetUploadURL(Number(propertyid), aclToken);

      let url = '';
      if (uploadurl.StatusCode === 200) {
        url = uploadurl.Data;
      } else {
        throw new Error('Failed to get upload URL');
      }
      const siteId = site?.siteInfo?.publicId;

      if (!siteId) {
        throw new Error('siteId not set');
      }

      const callingUserPublicId = localStorage.getItem('userId') ?? '';

      if (!callingUserPublicId) {
        throw new Error('callingUserPublicId not set');
      }

      console.log('callingUserPublicId: ', callingUserPublicId);

      const sync_result = await fetch(CONFIG.openApiEndpoint + '/syncAcl', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          action: 'upload',
          siteId,
          url,
          userId: callingUserPublicId
        })
      });

      const sync_result_json = await sync_result.json();

      console.log('sync_result: ', sync_result_json);
    } catch (error) {
      setSyncStatus('Error fetching ACL Token. Please sign in to IXG portal');
      setErrorMessage('Failed to sync with ACL: ' + error.message);
      console.log('error: ', error);
    }
  };

  // TODO: NEED TO ADD THE STATUS OF THE SYNC
  // check each device for the last device.networkSettings.lastSyncedOn and if it is null, set to'standby' otherwise, set to 'synced'

  const buildDeviceRow = (devices: IDevice[]): RowElem[] => {
    return Object.entries(devices).map(([, device]: [string, IDevice]) => ({
      id: device.basicInfo.devicePublicId,
      MacAddress: device.basicInfo.macAddress,
      StationNumber: device.basicInfo.stationNumber,
      StationName: device.basicInfo.stationName,
      ModelNumber: getDeviceModelNumberFromModelType(device.basicInfo.deviceModel, device.basicInfo.deviceType),
      FirmwareVersion: device.basicInfo.firmwareVersion,
      Status: isDeviceFirstSync(device) ? 'stand by' : 'synced',
      ConfigFileUrl: '-'
    }));
  };

  const columns: GridColDef[] = [
    { field: 'MacAddress', headerName: 'Mac Address', width: 150 },
    { field: 'StationNumber', headerName: 'Station Number', width: 150 },
    { field: 'StationName', headerName: 'Station Name', width: 150 },
    { field: 'ModelNumber', headerName: 'Model Number', width: 150 },
    {
      field: 'Status',
      headerName: 'Status',
      width: 150,
      renderCell(params) {
        if (syncStatus === 'sync failed') {
          return (
            <Typography variant="body2" color={getColorFromStatus(params.row.Status)}>
              {params.row.Status}
            </Typography>
          );
        } else {
          return <Typography variant="body2">{params.row.Status}</Typography>;
        }
      }
    },
    { field: 'FirmwareVersion', headerName: 'Firmware Version', width: 150 },
    {
      field: 'ConfigFileUrl',
      headerName: 'Test Config Url',
      width: 150,
      renderCell(params) {
        if (params.row.ConfigFileUrl === '-') {
          return params.row.ConfigFileUrl;
        } else {
          return (
            <a href={params.row.ConfigFileUrl} target="_blank">
              Config File Link
            </a>
          );
        }
      }
    }
  ];

  const getStateList = (countryId: string) => {
    const stateList = Object.keys(enumList.state)
      .map((key) => {
        const stateWalker = enumList.state[key] as IStateValue;
        // If the state is not in the selected country, do not add the option
        // Also do not include the unknown option
        if (stateWalker?.countryId.toString() !== countryId || stateWalker.value === 'Unknown') {
          return null;
        }

        return (
          <MenuItem key={stateWalker.value} value={key}>
            {stateWalker.value}
          </MenuItem>
        );
      })
      .filter((val) => val !== null);

    if (stateList.length === 0) {
      stateList.push(
        <MenuItem key={selectCountryText} value="" disabled={true}>
          {selectCountryText}
        </MenuItem>
      );
    }

    return stateList;
  };

  const renderSiteInfo = () => {
    return (
      <Formik
        initialValues={{
          siteName: site.siteInfo.siteName || '',
          siteAddress: site.siteInfo.siteAddress || '',
          siteCity: site.siteInfo.siteCity || '',
          stateId: site.siteInfo.stateId || null,
          siteZip: site.siteInfo.siteZip || '',
          countryId: (enumList.state[site.siteInfo.stateId] as IStateValue)?.countryId.toString() || ''
        }}
        validationSchema={Yup.object().shape({
          siteName: Yup.string().required('Site Name is required')
        })}
        onSubmit={async (values) => {
          try {
            const response = await updateSite({
              siteData: { ...site.siteInfo, ...values },
              sitePublicId: site.siteInfo.publicId
            });
            if (response && response.status === 200) {
              setShowAlert(true);
              setIsSiteInfoComplete(true);
              //TODO: update redux
            }
          } catch (error) {
            setErrorMessage("Failed to update site's information. Please try again.");
          }
        }}
        enableReinitialize
      >
        {({ dirty, touched, errors, isSubmitting, values }) => {
          return (
            <Form>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    label={getString('SiteInfo_SiteName_Title')}
                    name="siteName"
                    size="small"
                    style={containerStyle.textField}
                    helperText={touched.siteName && errors.siteName}
                    disabled={isSubmitting}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    label={getString('SiteInfo_SiteAddress_Title')}
                    name="siteAddress"
                    size="small"
                    style={containerStyle.textField}
                    helperText={touched.siteAddress && errors.siteAddress}
                    disabled={isSubmitting}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl style={containerStyle.textField} fullWidth size="small">
                    <InputLabel id="site-country">{getString('SiteInfo_SiteCountry_Title')}</InputLabel>
                    <Field
                      as={Select}
                      //TODO: Check the requirements for the ACL site address
                      labelId="site-country"
                      label={getString('SiteInfo_SiteCountry_Title')}
                      id="site-country"
                      name="countryId"
                      size="small"
                      helperText={touched.countryId && errors.countryId}
                      disabled={isSubmitting}
                      autoWidth={false}
                    >
                      {Object.keys(enumList.country).map((key) => {
                        const validCountries = ['CA', 'US'];
                        const countryWalker = enumList.country[key] as ICountryValue;

                        if (!validCountries.includes(countryWalker.alpha2Code)) {
                          return null;
                        }

                        const flagComponent: JSX.Element =
                          countryWalker.alpha2Code === 'CA' ? (
                            <CA title={countryWalker.defaultLanguageName} style={styles.flagStyle} />
                          ) : (
                            <US title={countryWalker.defaultLanguageName} style={styles.flagStyle} />
                          );

                        return (
                          <MenuItem key={key} value={key}>
                            <Grid sx={styles.field}>
                              {flagComponent}
                              {countryWalker.value}
                            </Grid>
                          </MenuItem>
                        );
                      })}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name="stateId"
                    id="stateId"
                    as={Select}
                    labelId="state-label"
                    label={getString('SiteInfo_SiteState_Title')}
                  >
                    {getStateList(values.countryId)}
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    label={getString('SiteInfo_SiteCity_Title')}
                    name="siteCity"
                    size="small"
                    style={containerStyle.textField}
                    helperText={touched.siteCity && errors.siteCity}
                    disabled={isSubmitting}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    as={TextField}
                    label={getString('SiteInfo_SiteZip_Title')}
                    name="siteZip"
                    size="small"
                    style={containerStyle.textField}
                    helperText={touched.siteZip && errors.siteZip}
                    disabled={isSubmitting}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Box sx={containerStyle.submitContainer}>
                <Button type="submit" variant="contained" color="primary" disabled={!dirty || isSubmitting}>
                  {getString('Button_Submit')}
                </Button>
              </Box>
            </Form>
          );
        }}
      </Formik>
    );
  };

  return (
    <>
      <Box sx={{ p: 3 }}>
        <Paper elevation={3} sx={styles.paper}>
          <Typography variant="sectionHeader">Site Information</Typography>

          {!isSiteInfoComplete && (
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            <Alert severity="warning" onClose={() => {}}>
              Please complete the missing fields to proceed.
            </Alert>
          )}

          {site.Loading || fetchingEnums ? (
            <Box sx={styles.spinnerWrapper}>
              <CircularProgress />
            </Box>
          ) : (
            renderSiteInfo()
          )}
        </Paper>
      </Box>
      <Box sx={{ p: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper elevation={3} sx={styles.paper}>
              <Box>
                <Typography variant="sectionHeader">Station Status</Typography>
              </Box>
              {syncStatus.includes('failed') && (
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                <Alert severity="error" onClose={() => {}}>
                  {syncStatus}: Please try again.
                </Alert>
              )}
              {!isGWRegistered && (
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                <Alert severity="warning" onClose={() => {}}>
                  It looks like IXGW-GW isn't registered yet. Please register to continue.
                </Alert>
              )}
              <Box sx={styles.buttonContainer}>
                <Button sx={{ mr: 2 }} startIcon={<CachedIcon />} onClick={handleSyncFirmware}>
                  Sync Firmware
                </Button>

                <Tooltip title="upload config files">
                  <Button
                    sx={{ mr: 2 }}
                    startIcon={<CachedIcon />}
                    disabled={!isGWRegistered || !isSiteInfoComplete || gwOnlineStatus !== 'online'}
                    onClick={handleSyncStationsButton}
                  >
                    {syncStatus === 'syncing' ? 'Syncing....' : 'Sync Stations'}
                  </Button>
                </Tooltip>

                <Button startIcon={<SettingsIcon />}>Configure Sync Settings</Button>
              </Box>
              <Box sx={{ height: 500, width: '100%' }}>
                <DataGrid rows={rows} columns={columns} />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ p: 3 }}>
        <Paper elevation={3} sx={styles.paper}>
          <Typography variant="sectionHeader">IXGW-GW</Typography>
          <Box sx={styles.gwContainer}>
            <LoadingButton
              loading={gwOnlineStatus === 'checking'}
              loadingIndicator={<CircularProgress size="20px" color="white" />}
            >
              <SettingsRemoteIcon color={getColorFromStatus(gwOnlineStatus)} onClick={getGatewayOnlineStatus} />
            </LoadingButton>
            <Typography variant="h6">{gwOnlineStatus}</Typography>
            <Grid sx={styles.gwFieldContainer}>
              <TextField
                disabled
                id="outlined-disabled"
                label="Network Configuration"
                size="small"
                defaultValue="DHCP"
              />
              <TextField
                disabled
                id="outlined-disabled"
                label="Mac Address"
                size="small"
                defaultValue="00:00:00:00:00:00"
              />
              <TextField disabled id="outlined-disabled" label="IP Address" size="small" defaultValue="129.168.0.100" />
              <TextField
                disabled
                id="outlined-disabled"
                label="subnet mask"
                size="small"
                defaultValue="255.255.255.0"
              />
              <TextField disabled id="outlined-disabled" label="gateway" size="small" defaultValue="129.168.0.1" />
            </Grid>
          </Box>
        </Paper>
      </Box>
      <SnackbarAlert
        type="error"
        time={10000}
        text={`${errorMessage}`}
        isOpen={!!errorMessage}
        onClose={() => setErrorMessage(null)}
      />
      <SnackbarAlert
        type="success"
        time={3000}
        text={StringUtils.format(getString('Update_Success'), getString('Site'))}
        isOpen={showAlert}
        onClose={() => setShowAlert(false)}
      />
    </>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  paper: {
    padding: '20px'
  },
  title: {
    marginBottom: '10px',
    color: '#333',
    fontWeight: 'bold',
    textAlign: 'left'
  },
  subtitle: {
    display: 'flex',
    color: '#666',
    padding: '10px'
  },
  icon: {
    fontSize: '5rem'
  },
  syncContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginY: '2rem'
  },
  gwContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #ccc',
    borderRadius: '5px',
    padding: '1rem'
  },
  gwFieldContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '1rem',
    margin: '1rem 0'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'end'
  },
  siteInfoContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '1rem',
    margin: '1rem 0'
  },
  field: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  flagStyle: {
    width: '33px',
    minWidth: '33px',
    height: '22px',
    paddingRight: '10px'
  },
  spinnerWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
};

export default Site;
