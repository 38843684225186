import { createSlice } from '@reduxjs/toolkit';
import { aiphoneCloudApi } from 'services/aiphoneCloud';

// List of Apps added to the site
export interface IAppList {
  [key: string]: IApp;
}

// Grouping of Apps by unit public id as the key
export interface IAppGroup {
  [key: string]: string[];
}

// App details
export interface IApp {
  createdBy: string;
  createdOn: string;
  lastUpdatedBy: string;
  lastUpdatedOn: string;
  publicId: string;
  qrCode: string;
  sitePublicId: string;
  stationName: string;
  stationNumber: string;
  unitPublicId: string;
}

// App state
interface AppState {
  AppList: IAppList;
  AppGroup: IAppGroup;
  SelectedApp: IApp | null | undefined;
  Loading: boolean;
}

// Initial state
const initialState: AppState = {
  AppList: {},
  AppGroup: {},
  SelectedApp: null,
  Loading: false
};

/**
 * Generate App Group by unit public id
 * @param appList
 * @returns
 * IAppGroup
 */
const generateAppGroup = (appList: IAppList) => {
  const appGroup: IAppGroup = {};
  Object.entries(appList).forEach(([key, value]) => {
    if (appGroup[value.unitPublicId]) {
      appGroup[value.unitPublicId].push(key);
    } else {
      appGroup[value.unitPublicId] = [key];
    }
  });
  return appGroup;
};

// Apps slice
const appsSlice = createSlice({
  name: 'apps',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(aiphoneCloudApi.endpoints.getAppWithSiteId.matchFulfilled, (state, { payload }) => {
      state.AppList = payload.appList;
      const appGroup = generateAppGroup(payload.appList);

      console.log(appGroup);
      state.AppGroup = appGroup;
    });
  }
});

// Export the slice actions
export const appsReducer = appsSlice.reducer;
