import { Grid, TextField, IconButton, InputAdornment, styled } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { AccessoryItem } from 'features/QuikSpec/Types/QuikSpecTypes';
import { useQuantityChange } from 'features/QuikSpec/Hooks/useQuantityChange';

const StyledTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    color: '#333',
    fontWeight: 'bold',
    height: '10px'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#888'
    },
    '&:hover fieldset': {
      borderColor: '#555'
    },
    '&.Mui-focused fieldset': {
      borderColor: '#7f8c8d'
    }
  },
  '& .MuiInputAdornment-root .MuiIconButton-root': {
    padding: '10px'
  }
});

const QuantityInputForm = ({ ...props }) => {
  const { itemName, multipleAllowed, maxQuantityPerItem, accessories, countWithAccessories, readOnly } = props;
  const { findFieldProps, handleQuantityChange, incrementQuantity, decrementQuantity } = useQuantityChange(
    itemName,
    multipleAllowed,
    maxQuantityPerItem
  );

  interface AccessoryFieldProps {
    itemName: string;
    accessory: AccessoryItem;
  }

  const AccessoryField = ({ itemName, accessory }: AccessoryFieldProps) => (
    <Grid container style={styles.accessoryContainer}>
      <Grid item>
        <StyledTextField
          name={`items.${itemName}.accessories.${accessory.accessoryName}.quantity`}
          type="text"
          label={accessory.accessoryName}
          value={findFieldProps(accessory.accessoryName).value || 0}
          onChange={(event) => handleQuantityChange(event.target.value, accessory.accessoryName)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => decrementQuantity(accessory.accessoryName)} size="medium">
                  <RemoveIcon />
                </IconButton>
                <IconButton onClick={() => incrementQuantity(accessory.accessoryName)} size="medium">
                  <AddIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
          variant="outlined"
        />
      </Grid>
    </Grid>
  );

  return (
    <Grid container direction="row" justifyContent={'center'}>
      {accessories && countWithAccessories ? (
        accessories.map((accessory: AccessoryItem) => (
          <AccessoryField key={accessory.accessoryName} itemName={itemName} accessory={accessory} />
        ))
      ) : (
        <Grid container>
          <Grid item>
            <StyledTextField
              name={`items.${itemName}.quantity`}
              type="text"
              label="Quantity"
              value={findFieldProps().value || 0}
              onChange={(event) => handleQuantityChange(event.target.value)}
              InputProps={{
                endAdornment: readOnly ? (
                  <Grid />
                ) : (
                  <InputAdornment position="end">
                    <IconButton onClick={() => decrementQuantity()} size="medium">
                      <RemoveIcon />
                    </IconButton>
                    <IconButton onClick={() => incrementQuantity()} size="medium">
                      <AddIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                readOnly: readOnly
              }}
              variant="outlined"
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  accessoryContainer: {
    margin: '8px 0 '
  }
};

export default QuantityInputForm;
