import { ContactCreateParams, ContactSearchParams, ContactUpdateParams } from './apiParamsTypes';
import axios from 'axios';
import CONFIG from '../../../config';
import { ApiError, classifyAndThrowError, ErrorType } from '../ApiError';

export const createContact = async (payload: ContactCreateParams) => {
  try {
    const response = await axios({
      method: 'post',
      url: CONFIG.openApiEndpoint + '/c2Functions',
      params: {
        action: 'contactCreate'
      },
      data: payload
    });
    switch (response.status) {
      case 200:
        return response;
      default:
        throw new ApiError('Error', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};

export const updateContact = async (payload: ContactUpdateParams) => {
  try {
    const response = await axios({
      method: 'post',
      url: CONFIG.openApiEndpoint + '/c2Functions',
      params: {
        action: 'contactUpdate'
      },
      data: payload
    });
    switch (response.status) {
      case 200:
        return response;
      default:
        throw new ApiError('Error', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};

export const findContact = async (payload: ContactSearchParams) => {
  try {
    const response = await axios({
      method: 'post',
      url: CONFIG.openApiEndpoint + '/c2Functions',
      params: {
        action: 'contactSearch'
      },
      data: payload
    });
    switch (response.status) {
      case 200:
        return response;
      default:
        throw new ApiError('Error', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};
