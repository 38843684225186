import { Box, CircularProgress, Input, InputAdornment, MenuItem, Slider, Tooltip, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedDevice, updateSelectedDevice } from 'store/slices/devicesSlice';
import * as Yup from 'yup';
import { Form, Formik, Field } from 'formik';
import { Button, Select, TextField } from '@mui/material';
import { Grid } from '@mui/material';
import { getString } from 'shared/utils/LocalizationUtils';
import { useUpdateDeviceMutation } from 'services/aiphoneCloud';
import containerStyle from 'shared/styles/advancedSettingContainerStyle';
import { useEffect, useState } from 'react';
import SnackbarAlert from 'shared/components/SnackbarAlert';
import StringUtils from 'shared/utils/StringUtils';
import { EnumList, fetchEnumList } from 'shared/utils/EnumUtils';

export const AudioLabel = () => {
  return (
    <Tooltip title="Audio, Packet Priority">
      <span>{getString('AdvancedSettings_Tab_Audio')}</span>
    </Tooltip>
  );
};

const Audio = () => {
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [updateDevice, { isLoading: isUpdating }] = useUpdateDeviceMutation();
  const fieldErrorMin = getString('Field_Error_Min');
  const fieldErrorMax = getString('Field_Error_Max');
  const fieldErrorRequired = getString('Field_Error_Required');
  const fieldErrorNotValid = getString('Field_Error_NotValid');
  const audioEncoderTitle = getString('AdvancedSettings_AudioEncoder_Title');
  const audioEncoderDesc = getString('AdvancedSettings_AudioEncoder_Desc');
  const audioBufferStartingPacketsTitle = getString('AdvancedSettings_AudioBufferStartingPackets_Title');
  const audioBufferStartingPacketsDesc = getString('AdvancedSettings_AudioBufferStartingPackets_Desc');
  const audioBufferMaximumPacketsTitle = getString('AdvancedSettings_AudioBufferMaximumPackets_Title');
  const audioBufferMaximumPacketsDesc = getString('AdvancedSettings_AudioBufferMaximumPackets_Desc');
  const audioTosValueTitle = getString('AdvancedSettings_AudioTosValue_Title');
  const audioTosValueDesc = getString('AdvancedSettings_AudioTosValue_Desc');
  const buttonSubmit = getString('Button_Submit');
  const buttonReset = getString('Button_Reset');
  const errorUpdateDevice = getString('AdvancedSettings_Error_UpdateDevice');
  const successUpdateDevice = getString('AdvancedSettings_Success_UpdateDevice');
  const selectedDevice = useSelector(getSelectedDevice);
  const [enumList, setEnumList] = useState<EnumList>({ country: {}, state: {} });
  const [fetchingEnums, setFetchingEnums] = useState(true);
  const [formikSchema, setFormikSchema] = useState<Yup.ObjectSchema<any> | null>(null);
  const tosRegexValidate = /^[0-9a-fA-F]{2}$/;
  const tosRegexOnChange = /^[0-9a-fA-F]{0,2}$/;
  const formDevice = {
    networkSettings: {
      audioTosValue: selectedDevice.networkSettings?.audioTosValue.replace('0x', ''),
      audioEncoder: selectedDevice.networkSettings?.audioEncoder,
      audioBufferStartingPackets: selectedDevice.networkSettings?.audioBufferStartingPackets,
      audioBufferMaximumPackets: selectedDevice.networkSettings?.audioBufferMaximumPackets
    }
  };
  const startPacketMin = 0;
  const startPacketMax = 4;
  const maxPacketMax = 10;
  const [maxPacketsMin, setMaxPacketsMin] = useState<number>(
    formDevice.networkSettings.audioBufferStartingPackets
      ? Math.max(2, formDevice.networkSettings.audioBufferStartingPackets + 1)
      : 2
  );

  useEffect(() => {
    fetchEnumList().then((data) => {
      setEnumList(data);
      setFetchingEnums(false);
    });
  }, []);

  const getValidationSchema = (currentValues: any) => {
    const networkSchema: any = {};
    let formattedFieldRequired: string;
    let newMaxPacketsMin: number;

    if (formDevice.networkSettings.audioTosValue !== undefined && formDevice.networkSettings.audioTosValue !== null) {
      formattedFieldRequired = StringUtils.format(fieldErrorRequired, audioTosValueTitle);
      networkSchema.audioTosValue = Yup.string()
        .required(formattedFieldRequired)
        .test('audioTosValue', fieldErrorNotValid, (value: string) => {
          return tosRegexValidate.test(value);
        });
    }

    if (formDevice.networkSettings.audioEncoder !== undefined && formDevice.networkSettings.audioEncoder !== null) {
      formattedFieldRequired = StringUtils.format(fieldErrorRequired, audioEncoderTitle);
      networkSchema.audioEncoder = Yup.string()
        .required(formattedFieldRequired)
        .test('audioEncoder', fieldErrorNotValid, (value: string) => {
          return Object.keys(enumList.audioEncoder).includes(value);
        });
    }

    if (
      formDevice.networkSettings.audioBufferStartingPackets !== undefined &&
      formDevice.networkSettings.audioBufferStartingPackets !== null
    ) {
      formattedFieldRequired = StringUtils.format(fieldErrorRequired, audioBufferStartingPacketsTitle);
      networkSchema.audioBufferStartingPackets = Yup.number()
        .required(formattedFieldRequired)
        .min(startPacketMin, StringUtils.format(fieldErrorMin, startPacketMin))
        .max(startPacketMax, StringUtils.format(fieldErrorMax, startPacketMax));
    }

    if (
      formDevice.networkSettings.audioBufferMaximumPackets !== undefined &&
      formDevice.networkSettings.audioBufferMaximumPackets !== null
    ) {
      formattedFieldRequired = StringUtils.format(fieldErrorRequired, audioBufferMaximumPacketsTitle);
      newMaxPacketsMin = Math.max(2, currentValues.networkSettings.audioBufferStartingPackets + 1);
      setMaxPacketsMin(newMaxPacketsMin);
      networkSchema.audioBufferMaximumPackets = Yup.number()
        .required(formattedFieldRequired)
        .min(newMaxPacketsMin, StringUtils.format(fieldErrorMin, newMaxPacketsMin))
        .max(maxPacketMax, StringUtils.format(fieldErrorMax, maxPacketMax));
    }

    return Yup.object({
      networkSettings: Yup.object().shape(networkSchema)
    });
  };

  const onSubmit = async (values: any, actions: any) => {
    const newVals = JSON.parse(JSON.stringify(values));
    newVals.networkSettings.audioTosValue = `0x${newVals.networkSettings.audioTosValue.toUpperCase()}`;
    const params = {
      device: {
        publicId: selectedDevice.publicId,
        ...newVals
      }
    };

    const newDevice = JSON.parse(JSON.stringify(selectedDevice));

    newDevice.networkSettings = {
      ...selectedDevice.networkSettings,
      ...newVals.networkSettings
    };

    updateDevice(params)
      .then((response) => {
        if ('error' in response) {
          throw response.error;
        }

        dispatch(updateSelectedDevice({ device: newDevice }));
        setShowAlert(true);
        actions.resetForm({
          values: values
        });
      })
      .catch(() => {
        setErrorMessage(errorUpdateDevice);
      });
  };

  if (!fetchingEnums && !formikSchema) {
    setFormikSchema(getValidationSchema(formDevice));
  }

  return (
    <Box sx={containerStyle.settingsPageContainer}>
      <SnackbarAlert
        type="error"
        time={10000}
        text={`${errorMessage}`}
        isOpen={!!errorMessage}
        onClose={() => setErrorMessage(null)}
      />
      <SnackbarAlert
        type="success"
        time={3000}
        text={successUpdateDevice}
        isOpen={showAlert}
        onClose={() => setShowAlert(false)}
      />
      {fetchingEnums ? (
        <CircularProgress />
      ) : (
        <Formik initialValues={formDevice} onSubmit={onSubmit} validationSchema={formikSchema}>
          {({ values, dirty, touched, errors, isSubmitting, handleChange }) => (
            <Form style={containerStyle.form}>
              <Box sx={containerStyle.gridContainer}>
                {formDevice.networkSettings.audioTosValue !== null &&
                formDevice.networkSettings.audioTosValue !== undefined ? (
                  <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                    <Grid item xs={5} lg={7}>
                      <Box sx={containerStyle.itemTitle}>{audioTosValueTitle}</Box>
                      <Box sx={containerStyle.itemDesc}>{audioTosValueDesc}</Box>
                    </Grid>
                    <Grid item xs={5} lg={3}>
                      <Box sx={containerStyle.fieldContainer}>
                        <Field
                          as={TextField}
                          type="text"
                          label={audioTosValueTitle}
                          name="networkSettings.audioTosValue"
                          style={containerStyle.textField}
                          helperText={touched.networkSettings?.audioTosValue && errors.networkSettings?.audioTosValue}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start" sx={containerStyle.tosAdornment}>
                                0x
                              </InputAdornment>
                            )
                          }}
                          onChange={(e: any) => {
                            // Only allow valid values
                            if (tosRegexOnChange.test(e.target.value)) {
                              handleChange(e);
                            }
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                ) : null}
                {formDevice.networkSettings.audioEncoder !== null &&
                formDevice.networkSettings.audioEncoder !== undefined ? (
                  <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                    <Grid item xs={5} lg={7}>
                      <Box sx={containerStyle.itemTitle}>{audioEncoderTitle}</Box>
                      <Box sx={containerStyle.itemDesc}>{audioEncoderDesc}</Box>
                    </Grid>
                    <Grid item xs={5} lg={3}>
                      <Box sx={containerStyle.fieldContainer}>
                        <Field
                          as={Select}
                          label={audioEncoderTitle}
                          name="networkSettings.audioEncoder"
                          style={containerStyle.textField}
                          helperText={touched.networkSettings?.audioEncoder && errors.networkSettings?.audioEncoder}
                        >
                          {Object.keys(enumList.audioEncoder).map((key) => {
                            return (
                              <MenuItem key={key} value={key}>
                                {enumList.audioEncoder[key].value}
                              </MenuItem>
                            );
                          })}
                        </Field>
                      </Box>
                    </Grid>
                  </Grid>
                ) : null}
                {formDevice.networkSettings.audioBufferStartingPackets !== null &&
                formDevice.networkSettings.audioBufferStartingPackets !== undefined ? (
                  <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                    <Grid item xs={5} lg={7}>
                      <Box sx={containerStyle.itemTitle}>{audioBufferStartingPacketsTitle}</Box>
                      <Box sx={containerStyle.itemDesc}>{audioBufferStartingPacketsDesc}</Box>
                    </Grid>
                    <Grid item xs={5} lg={3}>
                      <Box sx={containerStyle.fieldContainer}>
                        <Grid container spacing={2} alignItems={'center'}>
                          <Grid item xs={7.5}>
                            <Field
                              as={Slider}
                              type="range"
                              min={startPacketMin}
                              max={startPacketMax}
                              label={audioBufferStartingPacketsTitle}
                              name="networkSettings.audioBufferStartingPackets"
                              onChange={(e: any) => {
                                const newVals = { ...values };
                                newVals.networkSettings.audioBufferStartingPackets = parseInt(e.target.value);
                                handleChange(e);
                                setFormikSchema(getValidationSchema(newVals));
                                if (
                                  (values.networkSettings.audioBufferMaximumPackets as number) <=
                                  newVals.networkSettings.audioBufferStartingPackets
                                ) {
                                  handleChange({
                                    target: {
                                      name: 'networkSettings.audioBufferMaximumPackets',
                                      value: (newVals.networkSettings.audioBufferStartingPackets as number) + 1
                                    }
                                  });
                                }
                              }}
                            />
                          </Grid>
                          <Grid item xs={4.5}>
                            <Field
                              as={TextField}
                              type="number"
                              name="networkSettings.audioBufferStartingPackets"
                              size="small"
                              onChange={(e: any) => {
                                const newVals = { ...values };
                                e.target.value = parseInt(e.target.value);
                                if (e.target.value > startPacketMax) {
                                  e.target.value = startPacketMax;
                                } else if (e.target.value < startPacketMin) {
                                  e.target.value = startPacketMin;
                                }
                                newVals.networkSettings.audioBufferStartingPackets = parseInt(e.target.value);
                                handleChange(e);
                                setFormikSchema(getValidationSchema(newVals));
                                if (
                                  (values.networkSettings.audioBufferMaximumPackets as number) <=
                                  newVals.networkSettings.audioBufferStartingPackets
                                ) {
                                  handleChange({
                                    target: {
                                      name: 'networkSettings.audioBufferMaximumPackets',
                                      value: newVals.networkSettings.audioBufferStartingPackets + 1
                                    }
                                  });
                                }
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                ) : null}
                {formDevice.networkSettings.audioBufferMaximumPackets !== null &&
                formDevice.networkSettings.audioBufferMaximumPackets !== undefined ? (
                  <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                    <Grid item xs={5} lg={7}>
                      <Box sx={containerStyle.itemTitle}>{audioBufferMaximumPacketsTitle}</Box>
                      <Box sx={containerStyle.itemDesc}>{audioBufferMaximumPacketsDesc}</Box>
                    </Grid>
                    <Grid item xs={5} lg={3}>
                      <Box sx={containerStyle.fieldContainer}>
                        <Grid container spacing={2} alignItems={'center'}>
                          <Grid item xs={7.5}>
                            <Field
                              as={Slider}
                              type="range"
                              name="networkSettings.audioBufferMaximumPackets"
                              max={10}
                              min={maxPacketsMin}
                            />
                          </Grid>
                          <Grid item xs={4.5}>
                            <Field
                              as={TextField}
                              type="number"
                              name="networkSettings.audioBufferMaximumPackets"
                              size="small"
                              onChange={(e: any) => {
                                const newVals = { ...values };
                                e.target.value = parseInt(e.target.value);
                                if (e.target.value > maxPacketMax) {
                                  e.target.value = maxPacketMax;
                                } else if (e.target.value < maxPacketsMin) {
                                  e.target.value = maxPacketsMin;
                                }
                                handleChange(e);
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                ) : null}
              </Box>
              <Box sx={containerStyle.submitContainer}>
                <Button
                  type="reset"
                  variant="contained"
                  color="primary"
                  disabled={!dirty || isSubmitting || isUpdating}
                  style={containerStyle.submitBarButton}
                >
                  {buttonReset}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!dirty || isSubmitting || isUpdating}
                  style={containerStyle.submitBarButton}
                >
                  {buttonSubmit}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      )}
    </Box>
  );
};

export default Audio;
