import { Alert, AlertTitle, Box, List, ListItem, Typography } from '@mui/material';
import GeneralInfo from '../../UiParts/GeneralInfo';
import {
  jvMasterStations,
  jvDoorStations,
  optionalFeatures,
  jvSystemConstraints,
  jvHidden
} from '../../../Types/JVTypes';
import QuikspecCategoryTabs from '../../UiParts/QuikspecCategoryTabs';
import QuikspecCategoryTabsRadioButtons from '../../UiParts/QuikspecCategoryTabsRadioButtons';
import { FeaturedSystems, Item } from 'features/QuikSpec/Types/QuikSpecTypes';
import { updateFormValuesJV } from 'features/QuikSpec/Utils/QuikspecUpdateFormValuesJV';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import QuikspecSubmitButton from '../../UiParts/QuikspecSubmitButton';
import QuikspecSystemConstraints from '../../UiParts/QuikspecSystemConstraints';
import { useNavigate, useLocation } from 'react-router-dom';
import QuikspecNote from 'features/QuikSpec/Components/UiParts/QuikspecNote';
import { FormValues } from '../../../Types/QuikspecFormTypes';
import getInitialFormValues from 'features/QuikSpec/Utils/QuikspecInitialFormValues';
import { createQuikSpecAndNavigate, updateQuikspecAndNavigate } from '../../../Utils/QuikspecActionAndNavigate';

// Function to check if at least one quantity is greater than 0
const atLeastOneQuantityGreaterThanZero = (values: any) => {
  const items = Object.values(values);
  return items.some((item: any) => item.quantity > 0);
};

//Function to check if there are no more than 2 Door Stations for a JO system
const atMostTwoDoorStations = (values: any) => {
  const items = Object.values(values);
  const doorStationNames = jvDoorStations[0].items.map((item: Item) => item.deviceName);
  const relevantItems = items.filter((item: any) => doorStationNames.includes(item.itemName));
  const totalQuantity: number = relevantItems.reduce((total: number, item: any) => total + item.quantity, 0);
  return totalQuantity <= (jvDoorStations[0]?.maxQuantityAllowedPerCategory ?? 1);
};

const atMostOneDVFAccessory = (values: any) => {
  const items = Object.values(values);
  const DVFItem: any = items.find((item: any) => item.itemName === 'JV-DVF');
  let totalQuantity = 0;
  if (DVFItem) {
    const accessories = DVFItem.accessories;
    if (accessories) {
      totalQuantity = accessories.reduce((total: number, accessory: any) => total + accessory.quantity, 0);
    }
  } else totalQuantity = 0;
  return totalQuantity <= 1;
};

const atLeastOneDoorStation = (values: any) => {
  const items = Object.values(values);
  const dvfOrDvItems = items.filter((item: any) => item.itemName === 'JV-DVF' || item.itemName === 'JV-DV');
  const hasValidQuantity = dvfOrDvItems.some((item: any) => {
    if (item.itemName === 'JV-DVF') {
      if (item.accessories && item.accessories.length > 0) {
        return item.accessories.some((accessory: any) => accessory.quantity > 0);
      }
    }
    return item.quantity > 0;
  });
  return hasValidQuantity;
};

const validationSchema = Yup.object().shape({
  quikspecName: Yup.string().required('Quikspec Name is required'),
  marketType: Yup.string().required('Market Type is required'),
  items: Yup.array()
    .of(
      Yup.object().shape({
        itemName: Yup.string().required('Item Name is required'),
        quantity: Yup.number().min(0, 'Quantity must be greater than or equal to 0').required('Quantity is required')
      })
    )
    .test(
      'atLeastOneQuantity',
      'At least one item must have quantity greater than 0',
      atLeastOneQuantityGreaterThanZero
    )
    .test('atMostTwoDoorStations', 'At most two Door stations are allowed', atMostTwoDoorStations)
    .test('atMostOneDVFAccessory', 'At most one DVF accessory is allowed', atMostOneDVFAccessory)
    .test('atLeastOneDoorStation', 'At least one Door Station must be selected', atLeastOneDoorStation)
});

const QuikspecJV = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const state = location.state || {};
  const siteName: string = location.state.siteName as string;
  const siteIndex = FeaturedSystems.findIndex((system) => system.siteName === siteName);
  const systemName = FeaturedSystems[siteIndex].seriesName;
  const systemforC2 = FeaturedSystems[siteIndex].systemforC2;
  const categories = [...jvMasterStations, ...jvDoorStations, ...optionalFeatures, ...jvHidden];
  const stateFormValues = state && Object.keys(state).length > 1 ? state.formValues : undefined;
  const initialValues = getInitialFormValues(systemName, categories, stateFormValues, systemforC2);
  const handleSubmit = async (values: FormValues, actions: any) => {
    try {
      actions.setSubmitting(true);
      //filter out items with quantity set to zero in order to submit quikspec
      values = updateFormValuesJV(values);
      //determine if form is in edit mode
      const editing = stateFormValues != undefined;
      if (editing) {
        //update QuikSpec and navigate to QuikSpec Summary page
        await updateQuikspecAndNavigate(values, actions, navigate);
        actions.setSubmitting(false);
        return;
      } else {
        //create QuikSpec and navigate to QuikSpec Summary page
        await createQuikSpecAndNavigate(values, actions, navigate);
        actions.setSubmitting(false);
      }
    } catch (error) {
      console.log(error);
      actions.setSubmitting(false);
      actions.setErrors({ submit: 'Something went wrong. Please try again or contact customer support.' });
    }
  };

  return (
    <Box>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ errors, touched, isSubmitting }) => {
          return (
            <Form>
              <GeneralInfo editing={state.formValues != undefined} />
              <QuikspecSystemConstraints systemConstraints={jvSystemConstraints} />
              {/* Render QuikspecCategoryTabs for each category */}
              <QuikspecCategoryTabs categories={jvMasterStations} />
              <QuikspecCategoryTabsRadioButtons categories={jvDoorStations} initialValues={initialValues} />
              <QuikspecCategoryTabs categories={optionalFeatures} />
              {(stateFormValues
                ? Object.keys(errors).length > 0
                : Object.keys(touched).length > 0 && Object.keys(errors).length > 0) && (
                <Alert severity="error">
                  <AlertTitle>Error: </AlertTitle>
                  <List>
                    {Object.keys(errors).map((field) => (
                      <ListItem key={field}>
                        <Typography variant="body2" color="error">
                          {errors[field]}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                </Alert>
              )}
              <QuikspecSubmitButton editing={state.formValues != undefined} errors={errors} submitting={isSubmitting} />
              <QuikspecNote systemName={initialValues.systemName} />
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default QuikspecJV;
