import React, { FC } from 'react';
import { FormControl, InputLabel, MenuItem, Select, Grid } from '@mui/material';
import { Field } from 'formik';
import { IBranch } from '../../Dialogs/EditRegisteredUserDialog';
import { string } from 'yup';

interface IBranchList {
  [key: string]: IBranch;
}

interface IBranchSelectProps {
  isBranchesFetching: boolean;
  isCompanySelected: boolean;
  branches: IBranchList;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const strings = {
  loading: 'Loading...',
  name: 'branch',
  companyNotSelected: 'Select a company first',
  branchesNotFound: 'No branches found',
  selectBranch: 'Select a branch'
};

const BranchSelect: FC<IBranchSelectProps> = ({ isBranchesFetching, isCompanySelected, branches, handleChange }) => {
  const getSelectLabel = () => {
    if (isBranchesFetching) return strings.loading;
    if (!isCompanySelected) return strings.companyNotSelected;
    if (!branches || Object.keys(branches).length === 0) return strings.branchesNotFound;
    return strings.selectBranch;
  };

  const renderBranchOptions = () => {
    if (isBranchesFetching) {
      return (
        <MenuItem value="">
          <em>{strings.loading}</em>
        </MenuItem>
      );
    }

    if (!isCompanySelected) {
      return (
        <MenuItem value="">
          <em>{strings.companyNotSelected}</em>
        </MenuItem>
      );
    }

    if (branches && Object.keys(branches).length === 0) {
      return (
        <MenuItem value="">
          <em>{strings.branchesNotFound}</em>
        </MenuItem>
      );
    }

    return Object.entries(branches || {}).map(([publicId, branch]: [string, IBranch]) => (
      <MenuItem key={publicId} value={publicId}>
        {branch.branchName}
      </MenuItem>
    ));
  };

  return (
    <Grid item xs={6}>
      <FormControl size="small" fullWidth>
        <InputLabel id="branch-label">{getSelectLabel()}</InputLabel>
        <Field
          name={strings.name}
          id={strings.name}
          as={Select}
          labelId="branch-label"
          label={getSelectLabel()}
          disabled={isBranchesFetching || !isCompanySelected || !branches || Object.keys(branches).length === 0}
          onChange={handleChange}
        >
          {renderBranchOptions()}
        </Field>
      </FormControl>
    </Grid>
  );
};

export default BranchSelect;
