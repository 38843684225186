/* CGI tab stub */
export const CGILabel = () => {
  return <span>CGI</span>;
};

const CGI = () => {
  return <span>CGI</span>;
};

export default CGI;
