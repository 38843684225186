/* Contact Input tab stub */
export const ContactInputLabel = () => {
  return <span>Contact Input</span>;
};

const ContactInput = () => {
  return <span>Contact Input</span>;
};

export default ContactInput;
