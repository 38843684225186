import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { COOKIE_URL, PRIVACY_URL, TERMS_URL } from './constants';
import CookieConsentBanner from 'shared/utils/CookieConsentBanner';
import { getString } from 'shared/utils/LocalizationUtils';

const AuthLayout: React.FC = () => {
  const currentYear = new Date().getFullYear();
  const [showCookieConsentBanner, setShowCookieConsentBanner] = useState(false);
  const cookiePolicy = getString('Button_CookiePolicy');
  const privacyPolicy = getString('Button_PrivacyPolicy');
  const termsConditions = getString('Button_TermsConditions');

  // Check if the cookie has been accepted
  const checkCookieConsent = () => {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith('cookieConsent')) {
        if (cookie.split('=')[1] === 'accepted') {
          setShowCookieConsentBanner(false);
          localStorage.setItem('cookieConsent', 'accepted');
          return;
        }
      }
    }
    setShowCookieConsentBanner(true);
    return;
  };
  useEffect(() => {
    checkCookieConsent();
  }, [setShowCookieConsentBanner]);

  return (
    <>
      <Box sx={styles.authWrapper}>
        <Box sx={styles.authContainer}>
          <Box sx={styles.logoWrapper}>
            <img src="/AiphoneCloud-Logo_Blue.png" alt="Aiphone Logo" width="100%" />
          </Box>
          {showCookieConsentBanner ? (
            <Box sx={{ position: 'fixed', bottom: '190px', zIndex: '900000' }}>
              <CookieConsentBanner setShowCookieConsentBanner={setShowCookieConsentBanner} />
            </Box>
          ) : (
            <Box sx={styles.formWrapper}>
              <Outlet />
            </Box>
          )}
        </Box>
      </Box>
      <Grid container sx={styles.pageFooter}>
        <Grid item>
          <Typography variant="body1" sx={{ color: '#ffffff' }}>
            <a href={COOKIE_URL} target="_blank" rel="noopener noreferrer">
              <Button>{cookiePolicy}</Button>
            </a>
            |
            <a href={PRIVACY_URL} target="_blank" rel="noopener noreferrer">
              <Button>{privacyPolicy}</Button>
            </a>
            |
            <a href={TERMS_URL} target="_blank" rel="noopener noreferrer">
              <Button>{termsConditions}</Button>
            </a>
            |
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" sx={{ color: '#ffffff' }}>
            &nbsp; &copy;{currentYear} Aiphone Corporation. All rights reserved.
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  authWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    width: '100%',
    backgroundImage: `url('/login-background.jpg')`,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  logoWrapper: {
    padding: 3,
    textAlign: 'center'
  },
  formWrapper: {
    paddingX: 2,
    '@media (min-width: 500px)': {
      padding: 2
    }
  },
  authContainer: {
    borderRadius: '10px 10px 10px 10px',
    width: '98%',
    maxWidth: '800px',
    position: 'relative',
    boxShadow: '0 30px 60px 0 rgba(0,0,0,0.3)',
    backgroundColor: 'rgba(255, 255, 255, 0.55)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingX: 2,
    marginY: 1,
    marginBottom: '3rem'
  },
  loginLink: {
    fontSize: '1rem',
    fontWeight: 'bold',
    color: '#ff7500',
    textDecoration: 'none',
    cursor: 'pointer',
    marginX: 3,
    '@media (min-width: 600px)': {
      fontSize: '1.5rem',
      marginX: 10
    }
  },
  tabList: {
    '& button.Mui-selected': {
      color: '#756c6c',
      border: 'solid',
      borderColor: 'white'
    },
    '.MuiTabs-flexContainer': {
      justifyContent: 'center'
    },
    '& button:hover': {
      backgroundColor: '#f85000',
      border: 'solid',
      color: 'white'
    },
    '& button': {
      borderRadius: '5px',
      color: '#a1a1a1',
      border: 'solid',
      borderColor: '#ff7500'
    }
  },
  tabContext: {
    display: 'flex'
  },
  tabIndicator: {
    backgroundColor: '#f85000',
    height: '5px',
    borderRadius: '5px'
  },
  loginFooter: {
    backgroundColor: '#f6f6f6',
    borderTop: '1px solid #dce8f1',
    padding: '5px',
    textAlign: 'center',
    borderRadius: '0 0 10px 10px',
    color: '#ffffff'
  },
  pageFooter: {
    position: 'fixed',
    alignItems: 'center',
    bottom: 0,
    display: 'flex',
    backgroundColor: '#797979',
    width: '100%',
    height: 'auto',
    zIndex: '99',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: '.25rem .25rem',
    color: 'white'
  }
};

export default AuthLayout;
