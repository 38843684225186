/**
 * Network Information has 6 tabs (IP Address, Video, Audio, Time, SIP Settings, Syslog Settings)
 * user can navigate through each tab setting for each device
 */
import TabsContainer from 'shared/components/TabsContainer';
import { DeviceTabType } from 'features/RemoteManagement/DeviceDashboard/DeviceTabs';
import { useSelector } from 'react-redux';
import { DeviceCategory } from 'features/RemoteManagement/Types';
import { getSelectedDevice } from 'store/slices/devicesSlice';
import { Box } from '@mui/material';
import { getDeviceCategoryFromDeviceTypeModel } from 'shared/utils/helperFunctions';

const NetworkInfo = () => {
  const selectedDevice = useSelector(getSelectedDevice)?.basicInfo;
  if (!selectedDevice) return <Box>Error Loading Device</Box>;
  const deviceCategory = getDeviceCategoryFromDeviceTypeModel(selectedDevice?.deviceType, selectedDevice?.deviceModel);
  let networkInfoTabs: DeviceTabType[] = [];

  switch (deviceCategory) {
    case DeviceCategory.EmergencyStation:
    case DeviceCategory.VideoDoorStation:
    case DeviceCategory.AudioDoorStation:
    case DeviceCategory.GuardStation:
    case DeviceCategory.MasterStation:
    case DeviceCategory.SubMasterStation:
    case DeviceCategory.TenantStation:
      networkInfoTabs = [
        DeviceTabType.NetworkInfo_IPV4Address,
        DeviceTabType.NetworkInfo_Video,
        DeviceTabType.NetworkInfo_Audio,
        DeviceTabType.NetworkInfo_Time,
        DeviceTabType.NetworkInfo_SIPSettings,
        DeviceTabType.NetworkInfo_SyslogSettings
      ];
      break;
    case DeviceCategory.EntranceStation:
      networkInfoTabs = [
        DeviceTabType.NetworkInfo_IPV4Address,
        DeviceTabType.NetworkInfo_Audio,
        DeviceTabType.NetworkInfo_Time,
        DeviceTabType.NetworkInfo_SIPSettings,
        DeviceTabType.NetworkInfo_SyslogSettings
      ];
      break;
    case DeviceCategory.NetworkAdapter:
    case DeviceCategory.LiftControl:
      networkInfoTabs = [
        DeviceTabType.NetworkInfo_IPV4Address,
        DeviceTabType.NetworkInfo_Time,
        DeviceTabType.NetworkInfo_SIPSettings,
        DeviceTabType.NetworkInfo_SyslogSettings
      ];
      break;
  }

  return <TabsContainer tabTypes={networkInfoTabs} />;
};

export default NetworkInfo;
