import { IDeviceTypes } from 'store/slices/devicesSlice';

interface Unit {
  unitNumber: string;
  unitName: string;
  unitType: number;
  publicId?: string;
  devicePublicIds: string[];
}

const useConfigureWizardMultiUnits = (devices: IDeviceTypes, buildingPublicId: string, numberOfUnits: number) => {
  const newUnits: Unit[] = [];
  let unitCount = 1;

  for (let i = 0; i < numberOfUnits; i++) {
    const newUnit: Unit = {
      unitNumber: `${unitCount}`.padStart(4, '0'),
      unitName: `ResidentialUnit ${unitCount}`,
      unitType: 4,
      devicePublicIds: []
    };
    newUnits.push(newUnit);
    unitCount++;
  }

  ['MasterStation', 'SubMasterStation'].forEach((stationType) => {
    for (let i = 0; i < devices[stationType].length; i += 8) {
      const newUnit: Unit = {
        unitNumber: `${unitCount}`.padStart(4, '0'),
        unitName: 'CommercialUnit' + `${unitCount}`.padStart(4, '0'),
        unitType: 5,
        devicePublicIds: devices[stationType].slice(i, i + 8).map((device) => device)
      };

      newUnits.push(newUnit);
      unitCount++;
    }
  });

  ['GuardStation'].forEach((stationType) => {
    for (let i = 0; i < devices[stationType].length; i += 8) {
      const newUnit: Unit = {
        unitNumber: `${unitCount}`.padStart(4, '0'),
        unitName: 'GuardUnit' + `${unitCount}`.padStart(4, '0'),
        unitType: 1,
        devicePublicIds: devices[stationType].slice(i, i + 8).map((deviceId) => deviceId)
      };

      newUnits.push(newUnit);
      unitCount++;
    }
  });

  ['EntranceStation'].forEach((stationType) => {
    for (let i = 0; i < devices[stationType].length; i += 10) {
      const newUnit: Unit = {
        unitNumber: `${unitCount}`.padStart(4, '0'),
        unitName: 'EntranceUnit' + `${unitCount}`.padStart(4, '0'),
        unitType: 2,
        devicePublicIds: devices[stationType].slice(i, i + 10).map((deviceId) => deviceId)
      };

      newUnits.push(newUnit);
      unitCount++;
    }
  });

  ['EmergencyStation'].forEach((stationType) => {
    for (let i = 0; i < devices[stationType].length; i += 10) {
      const newUnit: Unit = {
        unitNumber: `${unitCount}`.padStart(4, '0'),
        unitName: 'OutsideAreaUnit' + `${unitCount}`.padStart(4, '0'),
        unitType: 6,
        devicePublicIds: devices[stationType].slice(i, i + 10).map((deviceId) => deviceId)
      };

      newUnits.push(newUnit);
      unitCount++;
    }
  });

  const params = {
    newUnitsArray: newUnits,
    buildingPublicId: buildingPublicId
  };

  return params;

  //  const payload: Payload = await createUnitsWizard(params)
  //    .unwrap()
  //    .then(
  //      (response) => {
  //        fetchUnits(Object.keys(buildingPublicId)[0]);
  //        return response;
  //      },
  //      (error) => {
  //        console.log(error);
  //      }
  //    );

  //  newUnitsArray.forEach((newUnit) => {
  //    const unit = payload.new_unit_data.find((unit) => unit.unitNumber === newUnit.unitNumber);
  //    if (unit) {
  //      newUnit.publicId = unit.publicId;
  //    }
  //  });

  //  params.newUnitsArray.forEach((unit) => {
  //    unit.devicePublicIds.forEach((deviceId) => {
  //      const payloadUnit = payload.new_unit_data.find(
  //        (payloadUnit: PayloadUnit) => payloadUnit.unitNumber === unit.unitNumber
  //      );
  //      dispatch(setDeviceUnitPublicId({ devicePublicId: deviceId, unitPublicId: payloadUnit?.publicId }));
  //    });
  //  });
};

export default useConfigureWizardMultiUnits;
