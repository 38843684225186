/* Speed Dial tab stub */
export const SpeedDialLabel = () => {
  return <span>Speed Dial</span>;
};

const SpeedDial = () => {
  return <span>Speed Dial</span>;
};

export default SpeedDial;
