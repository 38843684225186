/* Network Camera Integration tab stub */
export const NetworkCameraIntegrationLabel = () => {
  return <span>Network Camera Integration</span>;
};

const NetworkCameraIntegration = () => {
  return <span>Network Camera Integration</span>;
};

export default NetworkCameraIntegration;
