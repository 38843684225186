/**
 * Station Information has 2 tabs (Station Identification, Units & Stations)
 * user can navigate through each tab setting for each device
 */
import TabsContainer from 'shared/components/TabsContainer';
import { DeviceTabType } from 'features/RemoteManagement/DeviceDashboard/DeviceTabs';
import { useSelector } from 'react-redux';
import { getDeviceCategoryFromDeviceTypeModel } from 'shared/utils/helperFunctions';
import { DeviceCategory } from 'features/RemoteManagement/Types';
import { getSelectedDevice } from 'store/slices/devicesSlice';
import { Box } from '@mui/material';

const StationInfo = () => {
  const selectedDevice = useSelector(getSelectedDevice)?.basicInfo;
  if (!selectedDevice) return <Box>Error Loading Device</Box>;
  const deviceCategory = getDeviceCategoryFromDeviceTypeModel(selectedDevice?.deviceType, selectedDevice?.deviceModel);
  let stationInfoTabs: DeviceTabType[] = [];

  switch (deviceCategory) {
    case DeviceCategory.VideoDoorStation:
    case DeviceCategory.AudioDoorStation:
    case DeviceCategory.EmergencyStation:
    case DeviceCategory.EntranceStation:
    case DeviceCategory.GuardStation:
    case DeviceCategory.MasterStation:
    case DeviceCategory.SubMasterStation:
    case DeviceCategory.NetworkAdapter:
    case DeviceCategory.TenantStation:
      stationInfoTabs = [DeviceTabType.StationInfo_StationIdentification, DeviceTabType.StationInfo_UnitsStations];
      break;
    case DeviceCategory.LiftControl:
      stationInfoTabs = [DeviceTabType.StationInfo_StationIdentification];
      break;
  }

  return <TabsContainer tabTypes={stationInfoTabs} />;
};

export default StationInfo;
