import { Box, Button, Link, ListItemIcon, ListItemText, MenuItem, Select, Typography } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridValidRowModel,
  useGridApiRef,
  GridCellModes,
  GridCellParams,
  GridCellModesModel,
  GridToolbarContainer
} from '@mui/x-data-grid';
import { useCallback, useState, useEffect } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { SearchDevice } from 'features/RemoteManagement/Types';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { fetchGatewayCommand } from 'shared/rmGateway/gwCommandProcessor';
import { gwCommand } from 'shared/rmGateway/gwCommand';
import { useHandleGatewayCommandMutation, useUpdateDeviceMutation } from 'services/aiphoneCloud';
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from 'shared/constantAwsApi';

interface Props {
  searchSelectedDevices: SearchDevice[];
  setSearchSelectedDevices: (devices: []) => void;
  setIsAddingManually: (isAddingManually: boolean) => void;
  restrictedMacAddresses: string[];
  setRestrictedMacAddresses: (macAddresses: string[]) => void;
}

const getModelNumberOptions = (deviceType: string) => {
  switch (deviceType) {
    case 'IX-MV7':
      return ['IX-MV7-HB-L', 'IX-MV7-B', 'IX-MV7-W', 'IX-MV7-HB', 'IX-MV7-HW-JP'];
    case 'IX-RS':
      return ['IX-RS-B', 'IX-RS-W'];
    case 'IX-SSA':
      return ['IX-SSA', 'IX-SSA-RA', 'IX-SSA-2RA'];
    case 'IX-DV':
      return [
        'IX-DV',
        'IX-DVF',
        'IX-DVF-4A',
        'IX-DVF-6',
        'IX-DVF-HW',
        'IX-DVF-L',
        'IX-DVF-P',
        'IX-DVF-RA',
        'IX-DVF-2RA'
      ];
    case 'IX-SS-2G':
      return ['IX-SS-2G'];
    case 'IX-EA':
      return ['IX-EA'];
    case 'IXG-2C7':
      return ['IXG-2C7', 'IXG-2C7-L'];
    case 'IXG-DM7':
      return ['IXG-DM7-HID'];
    case 'IXG-MK':
      return ['IXG-MK'];
    case 'IXGW-LC':
      return ['IXGW-LC'];
    case 'IXGW-GW':
      return ['IXGW-GW'];
    case 'IX-DVM':
      return ['IX-DVM'];
    default:
      return [];
  }
};

const StationSearchDataGrid = ({
  searchSelectedDevices,
  setSearchSelectedDevices,
  setIsAddingManually,
  restrictedMacAddresses
}: Props) => {
  const selectedDeviceIds = searchSelectedDevices.map((device) => device.id);
  const setCurrentRows = () => {
    if (searchSelectedDevices.length > 0) {
      return searchSelectedDevices;
    }
    return [];
  };
  const [rows, setRows] = useState<SearchDevice[]>(setCurrentRows);
  const [isSearching, setIsSearching] = useState(false);
  const [, setGwFwVer] = useState('');
  const registeredGatewayPublicId = useSelector((state: RootState) => state.site.siteInfo?.registeredGatewayPublicId);
  const deviceList = useSelector((state: RootState) => state.devices.DeviceList);
  const gatewayLoading = useSelector((state: RootState) => state.gateway.loading);
  const gatewayList = useSelector((state: RootState) => state.gateway.gateway) || [];
  const stationList = useSelector((state: RootState) => state.gateway.stations) || [];
  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});
  const [handleGatewayCommand, { isError: gwCommandError, isLoading: gwLoading }] = useHandleGatewayCommandMutation();
  const awsPropertyId = useSelector((state: RootState) => state.site.siteInfo?.awsPropertyId);
  const [updateDevice, { isLoading: isUpdating }] = useUpdateDeviceMutation();

  const searchColumns: GridColDef[] = [
    {
      field: 'mac_addr',
      headerName: 'MAC Address',
      minWidth: 150,
      renderCell: (params) => {
        const isRestricted = restrictedMacAddresses.includes(params.value);
        return (
          <div>
            <Typography variant="body2">{params.value}</Typography>
            {isRestricted && (
              <Typography variant="caption" color="error">
                Already added to site
              </Typography>
            )}
          </div>
        );
      }
    },
    { field: 'device_type', headerName: 'Device Type', minWidth: 130 },
    { field: 'ip_addr', headerName: 'IP Address', minWidth: 130 },
    { field: 'station_type', headerName: 'Station Type', minWidth: 130 },
    {
      field: 'model_number',
      headerName: 'Model Number',
      width: 200,
      editable: true,
      type: 'singleSelect',
      valueOptions: (params) => getModelNumberOptions(params.row.device_type),
      renderCell(params) {
        const imgUrl = `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/icons/${params.value}.png`;
        if (params.value) {
          return (
            <MenuItem sx={{ width: '100%' }}>
              {params.value ? (
                <ListItemIcon sx={styles.stationIcons}>
                  <img src={imgUrl} alt="" />
                </ListItemIcon>
              ) : null}
              <ListItemText primary={params.value} />
            </MenuItem>
          );
        } else {
          return (
            <MenuItem sx={{ width: '100%', backgroundColor: 'rgb(253, 237, 237)' }}>
              <ListItemText sx={{ display: 'flex', justifyContent: 'center' }} primary="Select Model Number" />
            </MenuItem>
          );
        }
      },
      renderEditCell(params) {
        return (
          <Select
            SelectDisplayProps={{
              style: { display: 'flex', alignItems: 'center' }
            }}
            value={params.value}
            onChange={(event) => {
              params.api.setEditCellValue({
                id: params.id,
                field: params.field,
                value: event.target.value
              });
            }}
            inputProps={{
              'aria-label': 'Select Model Number'
            }}
            fullWidth
            placeholder="Select Model Number"
            defaultOpen
          >
            {getModelNumberOptions(params.row.device_type).map((option) => {
              const imgUrl = `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/icons/${option}.png`;
              return (
                <MenuItem key={option} value={option} disableGutters sx={{ width: '100%' }}>
                  <ListItemIcon sx={styles.stationIcons}>
                    <img src={imgUrl} alt="" />
                  </ListItemIcon>
                  <ListItemText primary={option} />
                </MenuItem>
              );
            })}
          </Select>
        );
      }
    },
    { field: 'station_name', headerName: 'Name', width: 120 },
    { field: 'station_number', headerName: 'No.', width: 75 }
  ];
  const apiRef = useGridApiRef();

  useEffect(() => {
    if (gatewayLoading) {
      setIsSearching(true);
    } else {
      const formattedGatewayList = sortDevices([...gatewayList, ...stationList]);
      setRows(formattedGatewayList);
      setIsSearching(false);
    }
  }, [gatewayLoading]);

  const sortDevices = (searchFromGateway) => {
    const updatedSearchFromGateway = searchFromGateway.map((device, index) => {
      switch (device.device_type) {
        case 'IX-MV7':
          return { ...device, station_type: 'Video Master', id: index + 1, model_number: '' };
        case 'IX-RS':
          return { ...device, station_type: 'Sub Master', id: index + 1, model_number: '' };
        case 'IXW-MA':
          return { ...device, station_type: 'I/O Network Adaptor', id: index + 1, model_number: '' };
        case 'IX-DV':
          return { ...device, station_type: 'Video Door', id: index + 1, model_number: '' };
        case 'IX-SSA':
        case 'IX-SS':
          return { ...device, device_type: 'IX-SSA', station_type: 'Audio Door', id: index + 1, model_number: '' };
        case 'IX-SS-2G':
          return { ...device, station_type: 'Stainless Steel Audio Door', id: index + 1, model_number: '' };
        case 'IXG-2C7':
          return { ...device, station_type: 'Apartment', id: index + 1, model_number: '' };
        case 'IXG-DM7':
          return { ...device, station_type: 'Video Entrance', id: index + 1, model_number: '' };
        case 'IXG-MK':
          return { ...device, station_type: 'Video Guard', id: index + 1, model_number: '' };
        case 'IXG-LC':
          return { ...device, station_type: 'Lift Controller', id: index + 1, model_number: '' };
        case 'IXGW-GW':
          return { ...device, station_type: 'Gateway Adaptor', id: index + 1, model_number: '' };
        case 'IX-DVM':
          return { ...device, station_type: 'Mullion Video Door', id: index + 1, model_number: '' };
        case 'IX-EA':
          return { ...device, station_type: 'Video Door', id: index + 1, model_number: '' };
        case 'IX-SOFT':
          return { ...device, station_type: 'Master Door', id: index + 1, model_number: '' };
        default:
          return { ...device, id: index + 1 };
      }
    });
    return updatedSearchFromGateway;
  };

  const handleStationSearch = async () => {
    const gateway = deviceList[registeredGatewayPublicId];
    const gwMacAddress = gateway?.basicInfo?.macAddress;
    const gwId = gateway?.basicInfo?.adminId || 'admin';
    const gwPassword = gateway?.basicInfo?.adminPass || 'admin';

    setIsSearching(true);
    try {
      const ioTPayload = fetchGatewayCommand(
        'sendCommand',
        gwCommand.STATION_SEARCH,
        { awsPropertyId, gwMacAddress, gwId, gwPassword },
        null,
        null
      );
      await handleGatewayCommand(ioTPayload).unwrap();
      const fetchPayload = fetchGatewayCommand(
        'fetchResult',
        gwCommand.STATION_SEARCH,
        { awsPropertyId, gwMacAddress, gwId, gwPassword },
        null,
        null
      );
      const fetchResponse = await handleGatewayCommand(fetchPayload).unwrap();
      setIsSearching(false);

      // Update Firmware version for the gateway
      const gateway = fetchResponse.find((device) => device.mac_addr === gwMacAddress);
      if (gateway) {
        setGwFwVer(gateway.fw_ver);
        const params = {
          device: {
            basicInfo: {
              ...gateway.basicInfo,
              firmwareVersion: gateway.basicInfo.firmwareVersion
            },
            publicId: gateway.publicId,
            unitPublicId: gateway.unitPublicId
          }
        };
        updateDevice(params);
      }

      const searchFromGateway = fetchResponse?.payload;
      if (searchFromGateway?.length > 0) {
        const updatedSearchFromGateway = sortDevices(searchFromGateway);
        setRows(updatedSearchFromGateway);
      } else {
        setRows([]);
      }
      setIsSearching(false);
    } catch (error) {
      setIsSearching(false);
      setRows([]);
    }
  };

  const handleCellClick = useCallback((params: GridCellParams) => {
    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View }
              }),
              {}
            )
          }),
          {}
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {}
          ),
          [params.field]: { mode: params.field === 'model_number' ? GridCellModes.Edit : GridCellModes.View }
        }
      };
    });
  }, []);

  const handleCellModesModelChange = useCallback((newModel: GridCellModesModel) => {
    setCellModesModel(newModel);
  }, []);

  const handleSearchRowSelection = (selection: GridValidRowModel) => {
    const selectedRows = selection.map((rowId: any) => {
      const row = rows.find((row) => row.id === rowId);
      return row;
    });
    setSearchSelectedDevices(selectedRows);
  };

  const processRowUpdate = (updatedRow: any, originalRow: any) => {
    const updatedRows = rows.map((row) => {
      if (row.id === updatedRow.id) {
        return updatedRow;
      }
      return row;
    });
    if (updatedRow.model_number !== originalRow.model_number) {
      setRows(updatedRows);
      return { ...updatedRow, model_number: updatedRow.model_number };
    } else {
      return updatedRow;
    }
  };

  return (
    <Box>
      <Box sx={{ height: 550, width: '100%' }}>
        <DataGrid
          apiRef={apiRef}
          rows={rows}
          columns={searchColumns}
          checkboxSelection
          disableRowSelectionOnClick
          cellModesModel={cellModesModel}
          onCellModesModelChange={handleCellModesModelChange}
          onCellClick={handleCellClick}
          onRowSelectionModelChange={handleSearchRowSelection}
          processRowUpdate={(updatedRow, originalRow) => processRowUpdate(updatedRow, originalRow)}
          onProcessRowUpdateError={(
            params //TODO: Handle Error
          ) => console.error('Error updating row:', params)}
          rowSelectionModel={selectedDeviceIds}
          isRowSelectable={(params) => {
            if (restrictedMacAddresses.includes(params.row.mac_addr)) {
              return false;
            }
            if (params.row.model_number !== '') {
              return true;
            }
            return false;
          }}
          loading={isSearching}
          slots={{
            noRowsOverlay: () => {
              return (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                  <Typography variant="h5">
                    No stations found,{' '}
                    <Link
                      sx={styles.clickableLink}
                      onClick={() => {
                        setIsAddingManually(true);
                      }}
                    >
                      try adding devices manually
                    </Link>{' '}
                    or search again.
                    <Button color="primary" startIcon={<SearchIcon />} onClick={handleStationSearch} />
                  </Typography>
                </Box>
              );
            },
            toolbar: () => {
              return (
                <GridToolbarContainer>
                  <Button color="primary" startIcon={<SearchIcon />} onClick={handleStationSearch}>
                    Search for stations
                  </Button>
                </GridToolbarContainer>
              );
            }
          }}
        />
      </Box>
    </Box>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  stationIcons: {
    minWidth: '80px !important',
    display: 'flex',
    justifyContent: 'center'
  },
  clickableLink: {
    cursor: 'pointer'
  },
  restrictedMacAddress: {
    backgroundColor: 'gray'
  },
  allowedMacAddress: {
    backgroundColor: 'white'
  }
};

export default StationSearchDataGrid;
