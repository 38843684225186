import { useSelector } from 'react-redux';
import { Box, Paper } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { RootState } from 'store';

const Tenants = () => {
  const unit = useSelector((state: RootState) => state.units.SelectedUnit);
  const siteTenants = useSelector((state: RootState) => state.tenants).Tenants;
  //TODO Update all instances if Tenant.id to Tenant.Id
  const unitTenants = siteTenants.filter((tenant) => unit.Tenants.find((id) => id === tenant.id));

  const tenants = unitTenants
    ? unitTenants.map((tenant) => ({
        id: tenant.id,
        firstName: tenant.FirstName,
        lastName: tenant.LastName
      }))
    : [];

  const renderTenantGrid = (rows: any) => (
    <Box>
      <Box>
        <DataGrid
          columns={[
            { field: 'firstName', headerName: 'First Name', width: 200 },
            { field: 'lastName', headerName: 'Last Name', width: 200 }
          ]}
          rows={rows}
        />
      </Box>
    </Box>
  );

  return (
    <Box sx={{ p: 3 }}>
      <Paper sx={styles.main}>{renderTenantGrid(tenants)}</Paper>
    </Box>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  deviceContainer: {
    pt: '20px'
  },
  main: {
    padding: '20px'
  }
};

export default Tenants;
