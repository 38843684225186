import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridPreProcessEditCellProps,
  GridRenderEditCellParams,
  GridEditInputCell
} from '@mui/x-data-grid';
import * as uuid from 'uuid';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { forwardRef, useEffect, useMemo } from 'react';
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from 'shared/constantAwsApi';

const initialRows: GridRowsProp = [];

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
}

interface TooltipChildProps extends GridRenderEditCellParams {
  error: boolean;
}

interface Props {
  manualSelectedDevices: GridRowModel[];
  setManualSelectedDevices: (devices: GridRowModel[]) => void;
  restrictedMacAddresses: string[];
}

const AddDeviceManuallyDataGrid = ({
  manualSelectedDevices,
  setManualSelectedDevices,
  restrictedMacAddresses
}: Props) => {
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [isBatchAddDialogOpen, setIsBatchAddDialogOpen] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const [batchType, setBatchType] = React.useState('');
  const [batchModel, setBatchModel] = React.useState('');
  const [batchQuantity, setBatchQuantity] = React.useState(0);
  const selectedDeviceIds = useMemo(() => manualSelectedDevices.map((device) => device.id), [manualSelectedDevices]);
  const setCurrentRows = () => {
    if (manualSelectedDevices.length > 0) {
      return manualSelectedDevices;
    }
    return initialRows;
  };
  const [rows, setRows] = React.useState(setCurrentRows);

  useEffect(() => {
    const editing = Object.values(rowModesModel).some((row) => row.mode === GridRowModes.Edit);
    setIsEditing(editing);
  }, [rowModesModel]);

  const deviceTypes = [
    { value: 'IX-MV7', label: 'Video Master Station' },
    { value: 'IX-RS', label: 'Sub Master Station' },
    { value: 'IX-DV', label: 'Video Door Station' },
    { value: 'IX-DVM', label: 'Mullion Video Door Station' },
    { value: 'IX-SSA', label: 'Audio Door Station' },
    { value: 'IX-EA', label: 'Plastic Video Door Station' },
    { value: 'IX-SS-2G', label: 'Stainless Steel Audio Door Station' },
    { value: 'IXW-MA', label: 'I/O Network Adaptor' },
    { value: 'IXG-DM7', label: 'Video Entrance Station' },
    { value: 'IXG-MK', label: 'Video Guard Station' },
    { value: 'IXG-2C7', label: 'Tenant Station' },
    { value: 'IXGW-LC', label: 'Lift Controller' },
    { value: 'IXGW-GW', label: 'Gateway Adaptor' }
  ];

  function validateMacAddress(value: string) {
    if (!value) {
      return 'MAC Address is required';
    }
    if (value.length < 17) {
      return 'MAC Address must be 17 characters long';
    }
    if (value.length > 17) {
      return 'MAC Address must be 17 characters long';
    }
    const macAddressRegex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
    if (!macAddressRegex.test(value)) {
      return 'MAC Address must be in the format XX:XX:XX:XX:XX:XX';
    }

    if (restrictedMacAddresses.includes(value)) {
      return 'MAC Address is already in use';
    }

    const isMacAddressDuplicate = rows.some((row) => row.mac_addr === value);
    if (isMacAddressDuplicate && !rows.find((row) => row.mac_addr === value)?.isNew) {
      return 'MAC Address must be unique';
    }

    return null;
  }

  /**
   * Solution for issue with MUI GridEditInputCell not properly forwarding ref
   *
   * This is a workaround based on https://github.com/mui/material-ui/issues/33476#issuecomment-1574005080
   */
  const TooltipChild = forwardRef<HTMLInputElement, TooltipChildProps>(({ id, ...props }, ref) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { rowNode, colDef, cellMode, hasFocus, formattedValue, isEditable, isProcessingProps, ...other } = props;
    return (
      <div {...other} id={id?.toString()} ref={ref}>
        <GridEditInputCell {...props} id={id} />
      </div>
    );
  });

  function MacAddressEditInputCell(props: GridRenderEditCellParams) {
    const { error: errorProp } = props;
    const errorMessage = validateMacAddress(props.value as string);
    const hasError = Boolean(errorProp || errorMessage);
    return (
      <Tooltip open={hasError && props.hasFocus} title={errorMessage}>
        <TooltipChild {...props} error={hasError} ref={props.inputRef} />
      </Tooltip>
    );
  }

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleManualDeviceSelection = (newSelection: GridRowId[]) => {
    const selectedRows = rows.filter((row) => newSelection.includes(row.id));
    setManualSelectedDevices(selectedRows);
  };

  function EditToolbar(props: EditToolbarProps) {
    const { setRows, setRowModesModel } = props;
    const handleAddDevice = () => {
      const id = uuid.v4();
      setRows((oldRows) => [...oldRows, { id, mac_addr: '00:0B:AA:', device_type: '', model_number: '', isNew: true }]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, isNew: true }
      }));
    };

    const handleBatchAddDevice = () => {
      isBatchAddDialogOpen ? setIsBatchAddDialogOpen(false) : setIsBatchAddDialogOpen(true);
    };

    return (
      <GridToolbarContainer>
        <Tooltip title={isEditing ? 'Only one device may be edited at a time' : ''}>
          <span>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleAddDevice} disabled={isEditing}>
              Add Device
            </Button>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleBatchAddDevice} disabled={isEditing}>
              Batch Add Devices
            </Button>
          </span>
        </Tooltip>
      </GridToolbarContainer>
    );
  }

  const getModelNumberOptions = (deviceType: string) => {
    switch (deviceType) {
      case 'IX-MV7':
        return ['IX-MV7-HB-L', 'IX-MV7-B', 'IX-MV7-W', 'IX-MV7-HB', 'IX-MV7-HW-JP'];
      case 'IX-RS':
        return ['IX-RS-B', 'IX-RS-W'];
      case 'IX-SSA':
        return ['IX-SSA', 'IX-SSA-RA', 'IX-SSA-2RA'];
      case 'IX-DV':
        return [
          'IX-DV',
          'IX-DVF',
          'IX-DVF-4A',
          'IX-DVF-6',
          'IX-DVF-HW',
          'IX-DVF-L',
          'IX-DVF-P',
          'IX-DVF-RA',
          'IX-DVF-2RA'
        ];
      case 'IX-SS-2G':
        return ['IX-SS-2G'];
      case 'IX-EA':
        return ['IX-EA'];
      case 'IXG-2C7':
        return ['IXG-2C7', 'IXG-2C7-L'];
      case 'IXG-DM7':
        return ['IXG-DM7-HID'];
      case 'IXG-MK':
        return ['IXG-MK'];
      case 'IXGW-LC':
        return ['IXGW-LC'];
      case 'IXGW-GW':
        return ['IXGW-GW'];
      case 'IX-DVM':
        return ['IX-DVM'];
      default:
        return [];
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'mac_addr',
      headerName: 'MAC Address',
      width: 180,
      editable: true,
      valueParser(value: any) {
        return value.toUpperCase();
      },
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const errorMessage = validateMacAddress(params.props.value as string);
        return { ...params.props, error: errorMessage };
      },
      renderEditCell: MacAddressEditInputCell
    },
    {
      field: 'device_type',
      headerName: 'Device Type',
      width: 220,
      editable: true,
      type: 'singleSelect',
      valueOptions: deviceTypes
    },
    {
      field: 'model_number',
      headerName: 'Model Number',
      width: 250,
      editable: true,
      type: 'singleSelect',
      valueOptions: (params) => getModelNumberOptions(params.row.device_type),
      renderCell(params) {
        const imgUrl = `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/icons/${params.value}.png`;
        if (params.value) {
          return (
            <MenuItem sx={{ width: '100%' }}>
              {params.value ? (
                <ListItemIcon sx={styles.stationIcons}>
                  <img src={imgUrl} alt="" />
                </ListItemIcon>
              ) : (
                <></>
              )}
              <ListItemText primary={params.value} />
            </MenuItem>
          );
        }
      },
      renderEditCell(params) {
        const id = `model_number_${params.row.id}`;
        return (
          <Select
            SelectDisplayProps={{
              style: { display: 'flex', alignItems: 'center' }
            }}
            value={params.value}
            id={id}
            onChange={(event) => {
              params.api.setEditCellValue({
                id: params.id,
                field: params.field,
                value: event.target.value
              });
            }}
            inputProps={{
              'aria-label': 'Select Model Number'
            }}
            fullWidth
            notched
            placeholder="Select Model Number"
            disabled={params.row.device_type === ''}
          >
            {getModelNumberOptions(params.row.device_type).map((option) => {
              const imgUrl = `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/icons/${option}.png`;
              return (
                <MenuItem key={option} value={option} disableGutters sx={{ width: '100%' }}>
                  <ListItemIcon sx={styles.stationIcons}>
                    <img src={imgUrl} alt="" />
                  </ListItemIcon>
                  <ListItemText primary={option} />
                </MenuItem>
              );
            })}
          </Select>
        );
      }
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main'
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
            disabled={isEditing}
          />,
          <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={handleDeleteClick(id)} color="inherit" />
        ];
      }
    }
  ];

  return (
    <>
      <Box
        sx={{
          height: 550,
          width: '100%',
          '& .actions': {
            color: 'text.secondary'
          },
          '& .textPrimary': {
            color: 'text.primary'
          }
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          getRowId={(row) => row.id}
          editMode="row"
          checkboxSelection
          disableRowSelectionOnClick
          rowModesModel={rowModesModel}
          rowSelectionModel={selectedDeviceIds}
          onRowModesModelChange={handleRowModesModelChange}
          onRowSelectionModelChange={handleManualDeviceSelection}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          slots={{
            toolbar: EditToolbar,
            noRowsOverlay: () => {
              return (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                  <Box>
                    <Typography variant="h5" display={'block'}>
                      No stations have been added yet.
                    </Typography>
                    <Typography variant="h5">Click the Add Device button to start adding stations.</Typography>
                  </Box>
                </Box>
              );
            }
          }}
          slotProps={{
            toolbar: { setRows, setRowModesModel }
          }}
          isRowSelectable={(params) =>
            !params.row.isNew &&
            params.row.device_type !== '' &&
            params.row.model_number !== '' &&
            params.row.mac_addr !== ''
          }
        />
      </Box>
      <Dialog
        open={isBatchAddDialogOpen}
        onClose={() => {
          setIsBatchAddDialogOpen(false);
        }}
        maxWidth="md"
      >
        <DialogTitle>Batch Add Devices</DialogTitle>
        <DialogContent>
          <DialogContentText>Select a device type, model number, and enter a quantity below.</DialogContentText>
          <Box sx={styles.mtmb2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="device-type-label">Select Device Type</InputLabel>
                  <Select
                    labelId="device-type-label"
                    id="device-type"
                    value={batchType}
                    onChange={(event) => {
                      setBatchType(event.target.value as string);
                    }}
                    inputProps={{
                      'aria-label': 'Select Device Type'
                    }}
                    fullWidth
                    label="Select Device Type"
                  >
                    {deviceTypes.map((option) => {
                      return (
                        <MenuItem key={option.value} value={option.value}>
                          <ListItemText primary={option.label} />
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="model-number-batch-label">Select Model Number</InputLabel>
                  <Select
                    labelId="model-number-batch-label"
                    id="model-number-batch"
                    SelectDisplayProps={{
                      style: { display: 'flex', alignItems: 'center' }
                    }}
                    value={batchModel}
                    onChange={(event) => {
                      setBatchModel(event.target.value as string);
                    }}
                    inputProps={{
                      'aria-label': 'Select Model Number'
                    }}
                    label="Select Model Number"
                    fullWidth
                    disabled={batchType === ''}
                  >
                    {getModelNumberOptions(batchType).map((option) => {
                      const imgUrl = `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/icons/${option}.png`;
                      return (
                        <MenuItem key={option} value={option} sx={styles.width100}>
                          <ListItemIcon sx={styles.stationIcons}>
                            <img src={imgUrl} alt="" />
                          </ListItemIcon>
                          <ListItemText primary={option} />
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Quantity"
                  type="number"
                  value={batchQuantity}
                  onChange={(event) => {
                    setBatchQuantity(Number(event.target.value));
                  }}
                  fullWidth
                  disabled={batchType === '' || batchModel === ''}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsBatchAddDialogOpen(false)}>Close</Button>
          <Button
            onClick={() => {
              const newRows: {
                id: string;
                mac_addr: string;
                device_type: string;
                model_number: string;
                isNew: boolean;
              }[] = [];
              for (let i = 0; i < batchQuantity; i++) {
                const id = uuid.v4();
                newRows.push({
                  id,
                  mac_addr: '00:0B:AA:',
                  device_type: batchType,
                  model_number: batchModel,
                  isNew: true
                });
              }
              setRows((oldRows) => [...oldRows, ...newRows]);
              setBatchModel('');
              setBatchType('');
              setBatchQuantity(0);
              setIsBatchAddDialogOpen(false);
            }}
            disabled={batchType === '' || batchModel === '' || batchQuantity === 0}
          >
            Add Devices
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  stationIcons: {
    minWidth: '80px !important',
    display: 'flex',
    justifyContent: 'center'
  },
  mtmb2: {
    mt: 2,
    mb: 2
  },

  width100: {
    width: '100%'
  }
};

export default AddDeviceManuallyDataGrid;
