/**
 * Call Setting _ have 7 Master Station tabs.
 *   (Address Book, Call Destination, Call Origination, Incoming Call, Guard Button, Speed Dial, Monitoring).
 * user can navigate through each tab setting for each device (Audio, video Station)
 */
import TabsContainer from 'shared/components/TabsContainer';
import { DeviceTabType } from 'features/RemoteManagement/DeviceDashboard/DeviceTabs';
import { useSelector } from 'react-redux';
import { DeviceCategory } from 'features/RemoteManagement/Types';
import { getSelectedDevice } from 'store/slices/devicesSlice';
import { Box } from '@mui/material';
import { getDeviceCategoryFromDeviceTypeModel } from 'shared/utils/helperFunctions';

const CallSettings = () => {
  const selectedDevice = useSelector(getSelectedDevice)?.basicInfo;
  if (!selectedDevice) return <Box>Error Loading Device</Box>;
  const deviceCategory = getDeviceCategoryFromDeviceTypeModel(selectedDevice?.deviceType, selectedDevice?.deviceModel);

  // Tabs available on all Pages.
  let callSettingsTabs: DeviceTabType[] = [];

  switch (deviceCategory) {
    case DeviceCategory.MasterStation:
    case DeviceCategory.SubMasterStation:
    case DeviceCategory.GuardStation:
      callSettingsTabs = [
        DeviceTabType.CallSettings_AddressBook,
        DeviceTabType.CallSettings_CallOrigination,
        DeviceTabType.CallSettings_IncomingCall,
        DeviceTabType.CallSettings_SpeedDial,
        DeviceTabType.CallSettings_Monitoring
      ];
      break;
    case DeviceCategory.TenantStation:
      callSettingsTabs = [
        DeviceTabType.CallSettings_AddressBook,
        DeviceTabType.CallSettings_CallOrigination,
        DeviceTabType.CallSettings_IncomingCall,
        DeviceTabType.CallSettings_GuardButton,
        DeviceTabType.CallSettings_Monitoring
      ];
      break;
    case DeviceCategory.EntranceStation:
      callSettingsTabs = [
        DeviceTabType.CallSettings_AddressBook,
        DeviceTabType.CallSettings_DirectStationCallList,
        DeviceTabType.CallSettings_CallOrigination,
        DeviceTabType.CallSettings_IncomingCall,
        DeviceTabType.CallSettings_GuardButton,
        DeviceTabType.CallSettings_Monitoring
      ];
      break;
    case DeviceCategory.VideoDoorStation:
    case DeviceCategory.AudioDoorStation:
    case DeviceCategory.EmergencyStation:
      callSettingsTabs = [
        DeviceTabType.CallSettings_CallDestination,
        DeviceTabType.CallSettings_CallOrigination,
        DeviceTabType.CallSettings_IncomingCall
      ];
      break;
  }

  return <TabsContainer tabTypes={callSettingsTabs} />;
};

export default CallSettings;
