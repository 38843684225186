import { createSlice } from '@reduxjs/toolkit';
import { UUID } from 'crypto';
import { aiphoneCloudApi } from 'services/aiphoneCloud';

export interface Unit {
  accessCode: string;
  aclBuildingId: string;
  aclGwaId: string;
  aclUnitId: string;
  buildingPublicId: UUID;
  createdOn: string;
  lastUpdatedOn: string;
  publicId: UUID;
  unitAwsRoomAuthCode: string;
  unitEmailAddress: string;
  unitGuardUnitId: string;
  unitLiftControlAdaptor: string;
  unitName: string;
  unitNumber: string;
  unitType: number;
}

export interface IUnitTypes {
  [key: string]: string[];
}

interface UnitState {
  UnitList: UnitList;
  UnitListByType: IUnitTypes;
  SelectedUnit: string;
  Loading: boolean;
}

export interface UnitList {
  [key: string]: Unit;
}

export interface IUnitInfo {
  devicePublicIds?: UUID[];
  unit: Unit;
}

const initialState: UnitState = {
  UnitList: {},
  UnitListByType: {},
  SelectedUnit: '',
  Loading: false
};

const unitTypeMapping: { [key: number]: string } = {
  1: 'Guard',
  2: 'Entrance',
  3: 'InsideArea',
  4: 'Residential',
  5: 'Commercial',
  6: 'OutsideArea'
};

const getUpdatedUnitListByType = (unitList: UnitList) => {
  const unitListByType: IUnitTypes = {
    Guard: [],
    Entrance: [],
    InsideArea: [],
    Residential: [],
    Commercial: [],
    OutsideArea: []
  };

  Object.values(unitList).forEach((unit) => {
    const unitType = unitTypeMapping[unit.unitType];
    unitListByType[unitType].push(unit.publicId);
  });

  return unitListByType;
};

const unitsSlice = createSlice({
  name: 'units',
  initialState,
  reducers: {
    setSelectedUnitId: (state, action) => {
      state.SelectedUnit = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(aiphoneCloudApi.endpoints.getUnitListWithBuildingPublicId.matchFulfilled, (state, { payload }) => {
        state.UnitList = {};
        state.UnitList = payload.unitList;
        state.UnitListByType = getUpdatedUnitListByType(payload.unitList);
      })
      .addMatcher(aiphoneCloudApi.endpoints.getBuildings.matchFulfilled, (state, { payload }) => {
        if (payload.unitPublicIds > 0) {
          state.UnitList = payload.unitPublicIds;
        }
      });
  }
});

export const { setSelectedUnitId } = unitsSlice.actions;

export const unitsReducer = unitsSlice.reducer;
