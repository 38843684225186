import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { LoadingButton } from '@mui/lab';
import { Field, Form, Formik } from 'formik';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { useAdminUpdateUserMutation } from 'services/aiphoneCloud';
import { CloudUser } from 'store/slices/usersSlice';

interface IEditAiphoneUserDialogProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  selectedUser?: CloudUser;
  setErrorMessage: (errorMessage: string) => void;
  setIsError: (isError: boolean) => void;
  setSuccessMessage: (successMessage: string) => void;
  setIsSuccess: (isSuccess: boolean) => void;
}

const EditAiphoneUserDialog = ({
  isOpen,
  setIsOpen,
  selectedUser,
  setErrorMessage,
  setIsError,
  setSuccessMessage,
  setIsSuccess
}: IEditAiphoneUserDialogProps) => {
  const globalRoles = useSelector((state: RootState) => state.users.availableRoles?.global);
  const [adminUpdateUser] = useAdminUpdateUserMutation();
  const [isLoading, setIsLoading] = React.useState(false);

  const handleUpdateUser = (values: any) => {
    const updateUserRequest = {
      userData: {
        firstName: values.firstName,
        lastName: values.lastName,
        email: selectedUser?.email,
        countryId: selectedUser?.countryId || 2,
        phoneNumber: selectedUser?.phoneNumber,
        language: selectedUser?.languageId,
        phoneExtension: selectedUser?.phoneExtension,
        marketingOptIn: selectedUser?.marketingOptIn
      },
      roleData: {
        initialRole: selectedUser?.permissions.global.role.publicId,
        newRole: values.role
      },
      publicId: selectedUser?.publicId
    };
    setIsLoading(true);

    adminUpdateUser(updateUserRequest)
      .unwrap()
      .then(() => {
        setSuccessMessage('User updated successfully');
        setIsSuccess(true);
        setIsLoading(false);
        setIsOpen(false);
      })
      .catch(() => {
        setErrorMessage('Failed to update user profile');
        setIsError(true);
        setIsLoading(false);
      });
  };

  return (
    <Dialog onClose={() => setIsOpen(false)} open={isOpen} disableEscapeKeyDown={true} maxWidth="sm" fullWidth={true}>
      <Grid container spacing={1}>
        <Grid item xs={11}>
          <DialogTitle>Edit User</DialogTitle>
        </Grid>
        <Grid item xs={1}>
          <IconButton color="default" aria-label="cancel" onClick={() => setIsOpen(false)}>
            <CancelIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Formik
        enableReinitialize
        initialValues={{
          firstName: selectedUser?.firstName ?? '',
          lastName: selectedUser?.lastName ?? '',
          email: selectedUser?.email ?? '',
          role: selectedUser?.permissions?.global?.role?.publicId ?? ''
        }}
        onSubmit={async (values) => {
          console.log(values);
          handleUpdateUser(values);
        }}
      >
        {(props) => {
          const { values } = props;
          return (
            <Form>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="First Name"
                      name="firstName"
                      value={values.firstName}
                      size="small"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Last Name"
                      name="lastName"
                      value={values.lastName}
                      size="small"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField fullWidth label="Email" name="email" value={values.email} size="small" disabled />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl size="small" x={styles.inputField} fullWidth>
                      <InputLabel id="role-label">Select a role</InputLabel>
                      <Field name="role" id="role" as={Select} labelId="role-label" label="Select a role">
                        {
                          // eslint-disable-next-line no-nested-ternary
                          globalRoles === undefined
                            ? []
                            : globalRoles.length === 0
                            ? 'No roles found'
                            : globalRoles.map((role) => (
                                <MenuItem key={role.publicId} value={role.publicId}>
                                  {role.roleName}
                                </MenuItem>
                              ))
                        }
                      </Field>
                    </FormControl>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button variant="contained" onClick={() => setIsOpen(false)}>
                  Cancel
                </Button>
                <LoadingButton variant="contained" type="submit" loading={isLoading}>
                  Save
                </LoadingButton>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

const styles = {
  inputField: {
    marginBottom: 1,
    width: '100%',
    '& .MuiInputBase-input': {
      backgroundColor: '#ffffff'
    },
    '&.MuiFormHelperText-root': {
      color: 'red'
    },
    '& .MuiInputLabel-root': {
      color: 'red',
      '&.Mui-focused': {
        color: 'black'
      }
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey'
      },
      '&:hover fieldset': {
        borderColor: '#003366'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#0071ce'
      }
    }
  }
};

export default EditAiphoneUserDialog;
