import { Box, Button, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import React, { useState } from 'react';
import Login from 'shared/api/Acl/Login';
import CircularProgress from '@mui/material/CircularProgress';

interface Props {
  handleClose: (event: React.SyntheticEvent) => void;
  setIsActivationDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsRegisterDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreatePanel = ({ handleClose, setIsActivationDialogOpen, setIsRegisterDialogOpen }: Props) => {
  const [id, setId] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPW, setConfirmPW] = useState('');
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [result, setResult] = useState('');

  const handleSetId = (e: React.ChangeEvent<HTMLInputElement>) => setId(e.target.value);
  const handleSetPassword = (e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value);
  const handleSetConfirmPW = (e: React.ChangeEvent<HTMLInputElement>) => setConfirmPW(e.target.value);
  const handleSetEmail = (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value);

  const handleSignup = async () => {
    try {
      setIsSubmitting(true);
      const result = await Login.signup(id, password, email);
      setResult(result);
      setIsRegisterDialogOpen(false);
      setIsActivationDialogOpen(true);
      setIsSubmitting(false);
    } catch (e: any) {
      setIsSubmitting(false);
    }
  };

  return (
    <Box>
      <DialogTitle>Create a New Administrator ID</DialogTitle>
      <DialogContent>
        <Grid container direction={'column'} spacing={5}>
          <Grid item>
            <TextField
              fullWidth
              value={id}
              onChange={handleSetId}
              label="Administrator ID"
              id="adminId"
              margin="dense"
              sx={styles.inputField}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              value={password}
              onChange={handleSetPassword}
              label="Password"
              id="password"
              type="password"
              helperText="The password must be at least 8 characters and include uppercase letters, lowercase letters, and numbers"
              sx={styles.inputField}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              value={confirmPW}
              onChange={handleSetConfirmPW}
              label="Confirm Password"
              id="confirmPassword"
              type="password"
              sx={styles.inputField}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              value={email}
              onChange={handleSetEmail}
              label="Email Address"
              id="email"
              type="email"
              sx={styles.inputField}
            />
          </Grid>
          <Grid item>{result}</Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button sx={styles.button} onClick={handleSignup}>
          {isSubmitting ? <CircularProgress size="25px" sx={{ color: 'white' }} /> : 'Create'}
        </Button>
        <Button sx={styles.button} onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Box>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  inputField: {
    marginBottom: 1,
    width: '100%',
    '& .MuiInputLabel-root': {
      color: 'grey',
      '&.Mui-focused': {
        color: 'black'
      }
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey'
      },
      '&:hover fieldset': {
        borderColor: '#003366'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#0071ce'
      }
    },
    '&.MuiFormHelperText-root': {
      color: '#d32f2f'
    }
  },
  button: {
    backgroundColor: '#d24800',
    color: 'white',
    borderRadius: '5px',
    margin: '5px',
    '&:hover': {
      backgroundColor: '#f85000',
      color: 'white'
    }
  }
};
export default CreatePanel;
