import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  Tab,
  Tabs,
  Typography,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Checkbox,
  Popover
} from '@mui/material';
import theme from 'data/theme';
import { QuikspecCategory } from 'features/QuikSpec/Types/QuikSpecTypes';
import useQuantityChange from '../../Hooks/useQuantityChange';
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from 'shared/constantAwsApi';
import RadioButton from './QuikspecRadioButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface Props {
  categories: QuikspecCategory[];
}

const QuikspecCategoryAcLicenses = ({ categories }: Props) => {
  const [selectedRadioItem, setSelectedRadioItem] = useState<string>('');
  const { handleQuantityChange, directChangeQuantity, directGetQuantity } = useQuantityChange(
    selectedRadioItem,
    false,
    1
  );
  const [selectedCheckBox, setSelectedCheckBox] = useState<boolean>(false);

  /**
   * 1)If any item within a category has its multipleAllowed set to false, we will handle it using RadioButton.
   * We'll assume there are multiple items with multipleAllowed set to false.
   * In such cases, we'll take the first item as the default value.
   *
   * 2) The handleQuantityChange function should be executed within a useEffect hook because we need to wait until selectedRadioItem is updated.
   * This ensures that changes are handled correctly after the state has been updated.
   * */
  useEffect(() => {
    if (!selectedRadioItem) {
      categories[0].items.forEach((item) => {
        /**
         * If 'selectedRadioItem' is not empty, it means we have already found the default value.
         * Therefore, we just need to skip the loop.
         * */
        if (item?.multipleAllowed === false) {
          if (directGetQuantity(item.deviceName) > 0) {
            return setSelectedRadioItem(item.deviceName);
          }
        }
      });
    }
    handleQuantityChange('1');
  }, [selectedRadioItem]);

  useEffect(() => {
    if (!selectedCheckBox) {
      if (directGetQuantity('AC-HOST') > 0) {
        return setSelectedCheckBox(true);
      }
    }
  }, [setSelectedCheckBox]);

  /**
   * When the radio button component updates,
   * it implies that the previous value will always be false ('0'),
   * and the selected value will always be true ('1').
   * In this function, we set the previous value to false.
   * The current value is set in the useEffect component after the state has been updated.
   * */
  const handleRadioBtnChange = (itemName: string) => {
    selectedRadioItem && handleQuantityChange('0');
    setSelectedRadioItem(itemName);
    if (itemName === 'AC-NIO-SB1' || itemName === 'AC-NIO-SB5') {
      setSelectedCheckBox(false);
      directChangeQuantity('AC-HOST', '0');
    }
  };

  const handleCheckBoxChange = (itemName: string) => {
    if (selectedCheckBox === false) {
      setSelectedCheckBox(true);
      directChangeQuantity(itemName, '1');
    } else {
      setSelectedCheckBox(false);
      directChangeQuantity(itemName, '0');
    }
  };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'product-popover' : undefined;

  return (
    <Box sx={styles.mainStationsBox}>
      <Grid container justifyContent="center" flexDirection={'column'} sx={styles.mainStationsTabGrid}>
        <Tabs value={0} variant="fullWidth" TabIndicatorProps={{ style: { display: 'none' } }}>
          {categories.map((product) => (
            <Tab key={product.id} label={product.title} sx={styles.mainStationsTab} />
          ))}
        </Tabs>

        <Grid container justifyContent="center">
          <Grid item xs={12} sx={styles.categoryDescription}>
            <Typography variant="h6" component={'h6'} sx={styles.descriptionContainer}>
              {categories[0].description}
            </Typography>
          </Grid>
          <Grid item sm={12} md={4} align="center">
            <Card sx={styles.licenseImage}>
              <CardHeader
                title="AC NIO"
                sx={styles.header}
                titleTypographyProps={{ fontSize: '1.3rem', fontWeight: '500' }}
              />
              <CardContent sx={styles.cardContent}>
                <CardMedia
                  component="img"
                  image={`${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/AC-NIO.jpg`}
                  sx={styles.deviceImage}
                />
              </CardContent>
              <CardContent sx={styles.cardComp}>
                <Button
                  aria-describedby={id}
                  variant="contained"
                  color="primary"
                  onClick={handleClick}
                  endIcon={<InfoOutlinedIcon />}
                  sx={styles.button}
                >
                  Compare Licenses
                </Button>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                  }}
                  sx={styles.popover}
                >
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Features</TableCell>
                          <TableCell>Essential</TableCell>
                          <TableCell>Small Business</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <strong>Doors</strong>
                          </TableCell>
                          <TableCell>40</TableCell>
                          <TableCell>80</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Cameras</strong>
                          </TableCell>
                          <TableCell>40</TableCell>
                          <TableCell>80</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Partitions</strong>
                          </TableCell>
                          <TableCell>1</TableCell>
                          <TableCell>5</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>LDAP</strong>
                          </TableCell>
                          <TableCell>No</TableCell>
                          <TableCell>Yes</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Elevator</strong>
                          </TableCell>
                          <TableCell>No</TableCell>
                          <TableCell>Yes</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <strong>Unmanaged Doors</strong>
                          </TableCell>
                          <TableCell>No</TableCell>
                          <TableCell>Yes</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Popover>
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={12} md={8} sx={styles.form}>
            <Grid item xs={12} sx={styles.essLicenseHeader}>
              Essential License
            </Grid>
            <Grid item xs={12}>
              The Essential license can manage a system of up to 40 doors and cameras. It can be installed on a local
              server PC or on the AC-HOST embedded server.
            </Grid>
            <Grid item xs={12}>
              <RadioButton
                value="AC-NIO-ESS1"
                label="1 Year"
                checked={'AC-NIO-ESS1' === selectedRadioItem}
                onChange={() => handleRadioBtnChange('AC-NIO-ESS1')}
              />
            </Grid>
            <Grid item xs={12}>
              <RadioButton
                value="AC-NIO-ESS5"
                label="5 Year"
                checked={'AC-NIO-ESS5' === selectedRadioItem}
                onChange={() => handleRadioBtnChange('AC-NIO-ESS5')}
              />
            </Grid>
            {('AC-NIO-ESS1' === selectedRadioItem || 'AC-NIO-ESS5' === selectedRadioItem) && (
              <Grid item xs={12}>
                <Checkbox
                  checked={selectedCheckBox}
                  onChange={() => handleCheckBoxChange('AC-HOST')}
                  sx={styles.orgCheckBox}
                />
                Would you like to include the AC-HOST embedded server?
              </Grid>
            )}
            <Grid item xs={12} sx={styles.sbLicenseHeader}>
              Small Business License
            </Grid>
            <Grid item xs={12}>
              The Small Business license can manage up to 80 doors and cameras, as well as elevators and unmanaged
              doors.This license allows up to five partitions and also supports Lightweight Directory Access Protocol
              (LDAP) integration.
            </Grid>
            <Grid item xs={12}>
              <RadioButton
                value="AC-NIO-SB1"
                label="1 Year"
                checked={'AC-NIO-SB1' === selectedRadioItem}
                onChange={() => handleRadioBtnChange('AC-NIO-SB1')}
              />
            </Grid>
            <Grid item xs={12}>
              <RadioButton
                value="AC-NIO-SB5"
                label="5 Year"
                checked={'AC-NIO-SB5' === selectedRadioItem}
                onChange={() => handleRadioBtnChange('AC-NIO-SB5')}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  mainStationsBox: {
    my: 4,
    boxShadow: '0 4px 16px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff',
    borderRadius: '8px',
    overflow: 'hidden'
  },
  mainStationsTabGrid: {
    my: 2,
    width: '95%',
    mx: 'auto'
  },
  mainStationsTab: {
    flexGrow: 1,
    flexBasis: 0,
    borderRadius: '0',
    '&:first-of-type': {
      borderTopLeftRadius: '7px',
      borderBottomLeftRadius: '7px'
    },
    '&:last-of-type': {
      borderTopRightRadius: '7px',
      borderBottomRightRadius: '7px'
    },
    fontWeight: 'bold',
    color: 'rgba(0, 0, 0, 0.87)',
    backgroundColor: '#f0f0f0',
    '&.Mui-selected': {
      color: '#ffffff',
      backgroundColor: theme.palette.primary.main
    },
    '&:hover': {
      backgroundColor: '#d0d0d0',
      opacity: 1
    }
  },
  categoryDescription: {
    my: 2,
    py: 2
  },
  descriptionContainer: {
    color: '#2c3e50',
    textAlign: 'center',
    fontSize: '1.1rem',
    fontWeight: 500
  },
  licenseImage: {
    width: 'auto',
    height: '340px',
    boxShadow: '3',
    position: 'relative',
    marginBottom: 3
  },
  header: {
    textAlign: 'center',
    background: 'linear-gradient(to right, #1e3c72, #2a5298)',
    color: '#fff',
    padding: '4px',
    boxShadow: 'inset 0 -1px 0 0 #e0e0e0',
    fontSize: '1.1rem',
    fontWeight: '200'
  },
  cardContent: {
    position: 'relative',
    textAlign: 'center',
    display: 'flex'
  },
  cardComp: {
    textAlign: 'center'
  },
  deviceImage: {
    objectFit: 'contain',
    maxWidth: '200px',
    maxHeight: '200px',
    margin: 'auto',
    display: 'block'
  },
  essLicenseHeader: {
    color: 'white.main',
    background: theme.palette.secondary.main,
    textAlign: 'center'
  },
  sbLicenseHeader: {
    color: 'white.main',
    background: theme.palette.secondary.main,
    textAlign: 'center',
    marginTop: '2rem'
  },
  popover: {
    marginTop: '5px',
    '& .MuiTypography-root': {
      fontSize: '0.85rem',
      color: '#333333'
    }
  },
  button: {
    marginTop: '5px',
    borderRadius: '20px',
    textTransform: 'none',
    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.1),0px 2px 2px 0px rgba(0,0,0,0.08),0px 1px 5px 0px rgba(0,0,0,0.06)',
    padding: '6px 16px',
    backgroundColor: '#0044CC',
    '&:hover': {
      backgroundColor: '#FFFFFF',
      opacity: 1
    }
  },
  orgCheckBox: {
    position: 'relative',
    left: -10
  },
  form: {
    paddingLeft: 3
  }
};

export default QuikspecCategoryAcLicenses;
