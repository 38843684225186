import { Box, Typography } from '@mui/material';
const QuikspecIE = () => {
  return (
    <Box>
      <Typography align="center" variant="h5">
        We will update the brand new QuikSpec for IE Series soon!
      </Typography>
      {/*<GeneralInfo></GeneralInfo>*/}
    </Box>
  );
};

export default QuikspecIE;
