import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Grid,
  IconButton,
  Select,
  MenuItem,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Typography,
  Box
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { LoadingButton } from '@mui/lab';
import { Field, Form, Formik } from 'formik';
import { useGetAssignableRolesQuery, useInviteGlobalUserMutation } from 'services/aiphoneCloud';
import * as yup from 'yup';

interface IInviteAiphoneUserDialogProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setErrorMessage: (errorMessage: string) => void;
  setIsError: (isError: boolean) => void;
  setSuccessMessage: (successMessage: string) => void;
  setIsSuccess: (isSuccess: boolean) => void;
}

const InviteAiphoneUserDialog = ({
  isOpen,
  setIsOpen,
  setErrorMessage,
  setIsError,
  setSuccessMessage,
  setIsSuccess
}: IInviteAiphoneUserDialogProps) => {
  const { data: assignableRoles, isFetching: isRolesFetching } = useGetAssignableRolesQuery({});
  const [inviteUser, { isLoading: isInviteLoading }] = useInviteGlobalUserMutation();

  const initialValues = {
    email: '',
    role: ''
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email()
      .matches(/@aiphone\.com$/, 'Email must end with @aiphone.com')
      .required(),
    role: yup.string().required()
  });

  const handleInviteAiphoneUser = (values: any) => {
    const formattedEmail = values.email.toLowerCase();
    inviteUser({
      email: formattedEmail,
      role: values.role,
      countryId: 2,
      languageId: 2
    })
      .unwrap()
      .then((response) => {
        if (response.error) {
          setIsError(true);
          setErrorMessage(response.error.message);
          return;
        }
        setIsSuccess(true);
        setSuccessMessage('User invited successfully');
        setIsOpen(false);
      })
      .catch((error) => {
        setErrorMessage(JSON.parse(error.data)?.errorDetails || 'Unexpected error');
        setIsError(true);
      });
  };

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(!isOpen)} aria-labelledby="" maxWidth="lg" fullWidth>
      <Grid container spacing={8}>
        <Grid item xs={11}>
          <DialogTitle>Invite Aiphone User</DialogTitle>
        </Grid>
        <Grid item xs={1}>
          <IconButton color="default" aria-label="cancel" onClick={() => setIsOpen(false)}>
            <CancelIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          handleInviteAiphoneUser(values);
        }}
      >
        {(props) => {
          return (
            <Form>
              <DialogContent>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="body1" color="initial">
                    Fill in the details below to invite a new Aiphone user. The user will receive an email with a
                    temporary password to login, and will be prompted to change it upon first login.
                  </Typography>
                  <Typography variant="body2" color="initial">
                    Note: The user's email must end with @aiphone.com
                  </Typography>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Field
                      name="email"
                      as={TextField}
                      size="small"
                      label="Email"
                      type="email"
                      fullWidth
                      error={props.touched.email && !!props.errors.email}
                      helperText={props.touched.email && props.errors.email}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="user-role-label">Select a Role</InputLabel>
                      <Field
                        name="role"
                        as={Select}
                        size="small"
                        label="Select a Role"
                        id="user-role"
                        labelId="user-role-label"
                        fullWidth
                        required
                        disabled={isRolesFetching}
                      >
                        <MenuItem value="" disabled>
                          Select a Role
                        </MenuItem>
                        {isRolesFetching
                          ? []
                          : assignableRoles.global.map((role) => (
                              <MenuItem key={role.publicId} value={role.publicId}>
                                {role.roleName}
                              </MenuItem>
                            ))}
                      </Field>
                    </FormControl>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button variant="contained" onClick={() => setIsOpen(false)}>
                  Cancel
                </Button>
                <LoadingButton variant="contained" type="submit" loading={isInviteLoading} disabled={isInviteLoading}>
                  Invite
                </LoadingButton>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default InviteAiphoneUserDialog;
