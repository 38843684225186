import { Card, CardContent, Grid, List, Typography, ListItem } from '@mui/material';
import PersonPinCircleOutlinedIcon from '@mui/icons-material/PersonPinCircleOutlined';

interface IRoleDescriptionsProps {
  roleName: string;
}

const RoleDescriptions = ({ roleName }: IRoleDescriptionsProps) => {
  const description = {
    Admin: {
      name: 'Admin',
      permissions: [
        'Invite new users to my branch and assign them any role',
        'Promote existing users to my branch and assign them any role',
        'Manage the roles of other users in my branch',
        'Edit information for users in the Branch (except emaill)',
        'Create QuikSpecs',
        'Manage SIM Billing',
        'Manage Remote Management sites under the branch'
      ]
    },
    Manager: {
      name: 'Manager',
      permissions: [
        'Invite users to the branch and assign them a role (except admin)',
        'Manage the roles of other users in the branch',
        'Create QuikSpecs',
        'Manage SIM Billing Subscriptions',
        'Manage Remote Management sites'
      ]
    },
    Member: {
      name: 'Member',
      permissions: [
        'Create QuikSpecs',
        'Manage SIM Billing Subscriptions',
        'View Branch Remote Management sites',
        'Create new Remote Management Site',
        'Can be granted permission to edit a Remote Management Site'
      ]
    },
    Associate: {
      name: 'Associate',
      permissions: ['Create QuikSpecs', 'Can be granted permission to edit a Remote Management Site']
    },
    AiphoneAdmin: {
      name: 'Aiphone Admin',
      permissions: [
        'Invite other Aiphone users to be admins and assign a role',
        'Create Companies and Branches',
        'Promote users to a Branch and assign a role',
        'View and Edit any SIM Billing or Remote Management site'
      ]
    },
    AiphoneSales: {
      name: 'Aiphone Sales',
      permissions: [
        'Create Companies and Branches',
        'Promote users to a Branch and assign a role',
        'Create their own SIM Billing or Remote Management site'
      ]
    },
    AiphoneTech: {
      name: 'Aiphone Tech',
      permissions: [
        'Create Companies and Branches',
        'Promote users to a Branch and assign a role',
        'View and Edit any SIM Billing or Remote Management site'
      ]
    }
  };

  return (
    <Grid item xs={12}>
      {roleName && (
        <Card sx={styles.permissionContainer}>
          <Typography variant="h6" color="initial">
            Permissions granted to {roleName}:
          </Typography>
          <CardContent>
            <List>
              {description[roleName]?.permissions?.map((desc) => (
                <ListItem>
                  <PersonPinCircleOutlinedIcon />
                  <Typography variant="body2" color="initial" sx={{ paddingX: 2 }}>
                    {desc}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
      )}
    </Grid>
  );
};

const styles = {
  permissionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 1,
    paddingX: 1
  }
};

export default RoleDescriptions;
