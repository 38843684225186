/**
 * Maintenance has 2 tabs (Firmware Update, Initialization)
 * user can navigate through each tab setting for each device
 */
import TabsContainer from 'shared/components/TabsContainer';
import { DeviceTabType } from '../DeviceTabs';

const Maintenance = () => {
  const MaintenanceTabs: DeviceTabType[] = [
    DeviceTabType.Maintenance_FirmwareUpdate,
    DeviceTabType.Maintenance_Initialization
  ];

  return <TabsContainer tabTypes={MaintenanceTabs} />;
};

export default Maintenance;
