import { FC } from 'react';
import containerStyle from '../../../../../../shared/styles/advancedSettingContainerStyle';
import { Grid } from '@mui/material';
import { getString } from '../../../../../../shared/utils/LocalizationUtils';
import { useField, useFormikContext } from 'formik';
import { CustomSlider } from '../CustomSlider';
import { IVolumeProps } from '../../Volume';

const volumeConfig = {
  volumeType: 'volumeExternalSpeaker',
  volumeTitle: 'AdvancedSettings_ExternalSpeakerVolume_Title',
  volumeDesc: 'AdvancedSettings_ExternalSpeakerVolume_Desc'
};

export const VolumeExternalSpeaker: FC<IVolumeProps> = ({ defaultValue }) => {
  const { setFieldValue } = useFormikContext();
  const { volumeType, volumeTitle, volumeDesc } = volumeConfig;

  /*Static data*/
  const externalSpeakerVolumeTitle = getString(volumeTitle);
  const externalSpeakerVolumeDesc = getString(volumeDesc);

  /*Validation*/
  const [, volumeExternalSpeakerMeta] = useField(volumeType);

  /*Handlers*/
  const handleChange = async (value: number): Promise<void> => {
    await setFieldValue(volumeType, value);
  };

  return (
    <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
      <Grid item xs={6} lg={7}>
        <div style={containerStyle.itemTitle}>{externalSpeakerVolumeTitle}</div>
        <div style={containerStyle.itemDesc}>{externalSpeakerVolumeDesc}</div>
      </Grid>
      <Grid item xs={6} lg={4} justifyContent="flex-end">
        <CustomSlider
          label={externalSpeakerVolumeTitle}
          name={volumeType}
          min={1}
          max={10}
          step={1}
          onChange={handleChange}
          defaultValue={defaultValue}
          helperText={!!(volumeExternalSpeakerMeta.touched && volumeExternalSpeakerMeta.error)}
          styles={containerStyle.sliderBar}
        />
      </Grid>
    </Grid>
  );
};
