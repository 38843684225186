import React from 'react';
import Introduction from './Steps/Introduction';
import RegisterGateway from './Steps/RegisterGateway';
import AddDevices from './Steps/AddDevices';
import DeviceBasicInfo from './Steps/DeviceBasicInfo';
import DeviceNetworkInfo from './Steps/DeviceNetworkInfo';
import SiteConfirmation from './Steps/SiteConfirmation';
import AddApps from './Steps/AddApps';
import SyncSystemSettings from './Steps/SyncSystemSettings';

enum CommercialWizardSteps {
  Introduction,
  RegisterGateway,
  AddDevices,
  AddApps,
  BasicInfo,
  NetworkSettings,
  // Confirmation,
  SyncSiteSettings
}

const CommercialSiteWizard: React.FC = () => {
  const [currentStep, setCurrentStep] = React.useState<CommercialWizardSteps>(CommercialWizardSteps.Introduction);
  const [isChecked1, setIsChecked1] = React.useState(false);
  const [isChecked2, setIsChecked2] = React.useState(false);
  const [isChecked3, setIsChecked3] = React.useState(false);
  const [isChecked4, setIsChecked4] = React.useState(false);
  const [isRegistered, setIsRegistered] = React.useState(false);

  const handleNextStep = () => {
    setCurrentStep((prevStep) => {
      return prevStep + 1;
    });
  };

  const handlePreviousStep = () => {
    setCurrentStep((prevStep) => {
      return prevStep - 1;
    });
  };

  const renderStep = (step: CommercialWizardSteps) => {
    switch (step) {
      case CommercialWizardSteps.Introduction:
        return (
          <Introduction
            isChecked1={isChecked1}
            isChecked2={isChecked2}
            isChecked3={isChecked3}
            isChecked4={isChecked4}
            setIsChecked1={setIsChecked1}
            setIsChecked2={setIsChecked2}
            setIsChecked3={setIsChecked3}
            setIsChecked4={setIsChecked4}
            handleNextStep={handleNextStep}
          />
        );
      case CommercialWizardSteps.RegisterGateway:
        return (
          <RegisterGateway
            isRegistered={isRegistered}
            setIsRegistered={setIsRegistered}
            handlePreviousStep={handlePreviousStep}
            handleNextStep={handleNextStep}
          />
        );
      case CommercialWizardSteps.AddDevices:
        return (
          <AddDevices
            isRegistered={isRegistered}
            handlePreviousStep={handlePreviousStep}
            handleNextStep={handleNextStep}
          />
        );
      case CommercialWizardSteps.AddApps:
        return <AddApps handlePreviousStep={handlePreviousStep} handleNextStep={handleNextStep} />;
      case CommercialWizardSteps.BasicInfo:
        return <DeviceBasicInfo handlePreviousStep={handlePreviousStep} handleNextStep={handleNextStep} />;
      case CommercialWizardSteps.NetworkSettings:
        return <DeviceNetworkInfo handlePreviousStep={handlePreviousStep} handleNextStep={handleNextStep} />;
      // case CommercialWizardSteps.Confirmation:
      //   return <SiteConfirmation />;
      case CommercialWizardSteps.SyncSiteSettings:
        return <SyncSystemSettings />;
    }
  };

  return <React.Fragment>{renderStep(currentStep)}</React.Fragment>;
};

export default CommercialSiteWizard;
