import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Box, Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import SnackbarAlert from '../../../shared/components/SnackbarAlert';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import PasswordField from '../../../shared/components/PasswordField';
import { LoadingButton } from '@mui/lab';
import * as yup from 'yup';
import { AuthContext } from '../../../context/AuthContext';
import { loginUser } from '../../../shared/api/Aws/authApi';
import { isApiError } from '../../../shared/api/ApiError';
import { getCognitoErrorMessage } from '../utils/cognitoOperations';
import { loginValidation } from '../validationSchemas';
import { getAndSetC2Contact } from '../utils/c2Operations';
import { get } from 'http';
import { getString } from 'shared/utils/LocalizationUtils';

type FormValues = yup.InferType<typeof loginValidation>;

const Login: React.FC = () => {
  const { setPassword, setUsername, setErrorMessage, setSession, errorMessage, setSessionUserAttributes } =
    useContext(AuthContext);
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const forgotPasswordButton = getString('Button_ForgotPassword');
  const loginButton = getString('Button_LogIn');
  const needAnAccount = getString('Button_NeedAnAccount');
  const registerHere = getString('Button_RegisterHere');

  useEffect(() => {
    setErrorMessage('');
  }, []);

  const handleLogin = async (values: FormValues, { setSubmitting }: FormikHelpers<any>) => {
    const formattedEmail = values.email.toLowerCase();
    try {
      /*C2 processes*/
      await getAndSetC2Contact(values.email);

      setUsername(values.email);
      setPassword(values.password);

      /*Authorization in Cognito process*/
      const loginUserPayload = {
        userData: {
          email: formattedEmail,
          password: values.password
        }
      };
      const loginUserResponse = await loginUser(loginUserPayload);
      if (loginUserResponse?.data?.type === 'NewPasswordRequired') {
        setSessionUserAttributes(loginUserResponse?.data?.userAttributes);
        navigate('/auth/new-password');
        return;
      }
      localStorage.setItem('refreshToken', loginUserResponse?.data.refreshToken);
      localStorage.setItem('token', loginUserResponse?.data.token);
      localStorage.setItem('userId', loginUserResponse?.data.userId);
      navigate('/');
      setSubmitting(false);
    } catch (error: unknown) {
      if (isApiError(error)) {
        /**
         * Temporary solution until the other parts of the application are ready.
         * Todo: Remove this condition when we enable login for other emails from '@aiphone.com'.
         * **/
        if (`${error}` === 'ApiError: Invalid email address') {
          setSuccessMessage('Your account has been registered successfully! Stay tuned till the grand launch!');
          return;
        }

        if ('UserNotConfirmedException' === error.code) {
          navigate('/auth/confirm-account');
        }
        if ('NewPasswordRequired' === error.code) {
          console.log(error.session);
          setSession(error.session);
          navigate('/auth/new-password');
        }
        if ('PasswordResetRequiredException' === error.code) {
          navigate('/auth/reset-required');
        }
        const errorMessages = getCognitoErrorMessage(error.code);
        setErrorMessage(errorMessages);
      } else if (`${error}` === 'Error: User account not active.') {
        setErrorMessage('User account not active.');
      } else {
        setErrorMessage('An error occurred while registering your account. Please try again.');
      }
      return;
    }
  };

  return (
    <Box>
      <SnackbarAlert type="error" time={7000} text={`${error}`} isOpen={!!error} onClose={() => setError(null)} />
      <SnackbarAlert
        type="success"
        time={7000}
        text={`${successMessage}`}
        isOpen={!!successMessage}
        onClose={() => setSuccessMessage(null)}
      />
      <Formik initialValues={{ email: '', password: '' }} validationSchema={loginValidation} onSubmit={handleLogin}>
        {({ errors, touched, isSubmitting }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field
                  name="email"
                  as={TextField}
                  sx={styles.inputField}
                  label="Email Address"
                  helperText={touched.email && errors.email}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="password"
                  as={PasswordField}
                  sx={styles.inputField}
                  label="Password"
                  helperText={touched.password && errors.password}
                />
              </Grid>
              {errorMessage && (
                <Grid item xs={12}>
                  <Alert severity="error">{errorMessage}</Alert>
                </Grid>
              )}
              <Grid item xs={12} sx={styles.otherOptions}>
                <Grid container sx={styles.otherOptionContainer}>
                  <Grid item xs={12}>
                    <Button sx={styles.forgotPasswordButton} onClick={() => navigate('/auth/reset-password')}>
                      {forgotPasswordButton}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={styles.loginButtonContainer}>
                <Box sx={styles.centerContent}>
                  <LoadingButton
                    type="submit"
                    sx={styles.loginButton}
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    variant="aiphoneOrange"
                    loadingIndicator={<CircularProgress size="20px" color="white" />}
                  >
                    {loginButton}
                  </LoadingButton>
                </Box>
              </Grid>
              <Grid item xs={12} sx={styles.createAccountContainer}>
                <Box sx={styles.centerContent}>
                  <Typography
                    variant="body2"
                    color="primary"
                    sx={{
                      fontFamily: "'Roboto Condensed', sans-serif"
                    }}
                  >
                    {needAnAccount}
                    <Button sx={styles.registrationButton} onClick={() => navigate('/auth/registration')}>
                      {registerHere}
                    </Button>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  loginButton: {
    fontSize: '1.5rem',
    fontWeight: '700',
    borderRadius: '0.25 rem',
    width: '40%'
  },
  centerContent: {
    display: 'flex',
    justifyContent: 'center'
  },
  inputField: {
    marginBottom: 1,
    width: '100%',
    '& .MuiInputBase-input': {
      backgroundColor: '#ffffff'
    },
    '&.MuiFormHelperText-root': {
      color: 'red'
    },
    '& .MuiInputLabel-root': {
      color: 'red',
      '&.Mui-focused': {
        color: 'black'
      }
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey'
      },
      '&:hover fieldset': {
        borderColor: '#003366'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#0071ce'
      }
    }
  },
  otherOptions: {
    paddingTop: '0 !important'
  },
  otherOptionContainer: {
    alignItems: 'center'
  },
  forgotPasswordButton: {
    color: '#0071CE',
    float: 'right',
    fontFamily: "'Roboto Condensed', sans-serif"
  },
  registrationButton: {
    color: '#0071CE',
    fontFamily: "'Roboto Condensed', sans-serif"
  },
  loginButtonContainer: {
    mt: 2
  },
  createAccountContainer: {
    mb: 2
  }
};

export default Login;
