import Dialog from '@mui/material/Dialog';
import { DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material';
import { useState } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Button, TextField, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { useDeleteUserMutation } from 'services/aiphoneCloud';
import { GridRowSelectionModel } from '@mui/x-data-grid';

interface IDeleteUserDialogProps {
  selectionModel: GridRowSelectionModel;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setErrorMessage: (errorMessage: string) => void;
  setIsError: (isError: boolean) => void;
  setSuccessMessage: (successMessage: string) => void;
  setIsSuccess: (isSuccess: boolean) => void;
}

const DeleteUserDialog = ({
  selectionModel,
  isOpen,
  setIsOpen,
  setErrorMessage,
  setIsError,
  setSuccessMessage,
  setIsSuccess
}: IDeleteUserDialogProps) => {
  const [canDelete, setCanDelete] = useState(false);
  const [deleteUser, { isLoading: isDeleteLoading }] = useDeleteUserMutation();

  const handleCloseClick = () => {
    setIsOpen(false);
    setCanDelete(false);
  };

  const verifyDeletion = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === 'delete') {
      setCanDelete(true);
    } else {
      setCanDelete(false);
    }
  };

  const initialValues = {
    delete: ''
  };

  const handleUserDelete = async () => {
    const userId = selectionModel[0];
    deleteUser({ targetUserPublicId: userId })
      .unwrap()
      .then((response) => {
        if (response.error) {
          setIsError(true);
          setErrorMessage(response.error.message);
          return;
        }
        setIsSuccess(true);
        setSuccessMessage('User deleted successfully');
        setIsOpen(false);
        setCanDelete(false);
      })
      .catch((error) => {
        setErrorMessage(JSON.parse(error.data)?.errorDetails || 'Unexpected error');
        setIsError(true);
      });
  };

  return (
    <Dialog onClose={() => handleCloseClick()} open={isOpen} disableEscapeKeyDown={true} maxWidth="sm" fullWidth={true}>
      <Grid container spacing={1}>
        <Grid item xs={11}>
          <DialogTitle>Delete User</DialogTitle>
        </Grid>
        <Grid item xs={1}>
          <IconButton color="default" aria-label="cancel" onClick={() => handleCloseClick()}>
            <CancelIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Box>
        <Formik initialValues={initialValues} onSubmit={handleUserDelete} validateOnBlur={true}>
          {(props) => {
            const { handleChange, errors, touched } = props;
            return (
              <Form>
                <DialogContent>
                  <Box>
                    <Typography variant="body1" color="text.primary">
                      Are you sure you want to delete the selected user?
                    </Typography>
                  </Box>
                  <Box sx={styles.my2}>
                    <Field
                      name="delete"
                      as={TextField}
                      sx={styles.inputField}
                      label="Enter 'delete' to confirm deletion"
                      size="small"
                      helperText={touched.delete && errors.delete ? errors.delete : ''}
                      error={touched.delete && !!errors.delete}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleChange(e);
                        verifyDeletion(e);
                      }}
                    />
                    <Typography variant="caption" color="GrayText">
                      <strong>Note:</strong> This operation cannot be reverted.
                    </Typography>
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button variant="contained" onClick={() => handleCloseClick()}>
                    Cancel
                  </Button>
                  <LoadingButton variant="contained" type="submit" loading={isDeleteLoading} disabled={!canDelete}>
                    Delete User
                  </LoadingButton>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Dialog>
  );
};

const styles = {
  my2: {
    marginY: 2
  },
  inputField: {
    width: '100%',
    '& .MuiInputBase-input': {
      backgroundColor: '#ffffff'
    },
    '&.MuiFormHelperText-root': {
      color: 'red'
    },
    '& .MuiInputLabel-root': {
      color: 'red',
      '&.Mui-focused': {
        color: 'black'
      }
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey'
      },
      '&:hover fieldset': {
        borderColor: '#003366'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#0071ce'
      }
    }
  }
};

export default DeleteUserDialog;
