import { Box, Container, Typography, Button, Pagination, Backdrop, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import UnitCard from './components/UnitCard';
import { useGetUnitListWithSitePublicIdQuery, useUpdateDeviceMutation } from 'services/aiphoneCloud';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { IDevice } from 'store/slices/devicesSlice';
import AddDeviceToUnitDialog from './dialogs/AddDeviceToUnitDialog';
import AddMobileDeviceToUnitDialog from './dialogs/AddMobileDeviceToUnitDialog';

interface Props {
  handlePreviousStep: () => void;
  handleNextStep: () => void;
}

interface Unit {
  unitPublicId: string;
  unitNumber: string;
  unitName: string;
  unitType: number;
  devices: IDevice[];
}

const strings = {
  title: 'Add Devices to Units',
  body1: 'Associate the units in your site with the devices you added in the previous step.',
  backButtonText: 'Back',
  continueButtonText: 'Continue'
};

const AssociateUnits = ({ handlePreviousStep, handleNextStep }: Props) => {
  const [units, setUnits] = useState<Unit[]>([]);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState<Unit | null>(null);
  const [isAddDevicesOpen, setIsAddDevicesOpen] = useState(false);
  const [isAddMobileDevicesOpen, setIsAddMobileDevicesOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [updateDevice] = useUpdateDeviceMutation();
  const sitePublicId = useSelector((state: RootState) => state.site.siteInfo.publicId);
  const { data, isSuccess, refetch } = useGetUnitListWithSitePublicIdQuery(
    { qty: -1, page: 0, sitePublicId: sitePublicId },
    { skip: !sitePublicId }
  );
  const unitList = data?.unitList;
  const deviceList = useSelector((state: RootState) => state.devices.DeviceList);
  const itemsPerPage = 10;
  const deviceGroups = useSelector((state: RootState) => state.devices.DeviceListByType);
  useEffect(() => {
    if (unitList) {
      const units: Unit[] = [];
      Object.entries(unitList).forEach(([key, value]) => {
        const devicesArray: IDevice[] = [];
        value.devicePublicIds?.forEach((devicePublicId: string) => {
          deviceList[devicePublicId] && devicesArray.push(deviceList[devicePublicId]);
        });
        units.push({
          unitPublicId: unitList[key].publicId,
          unitNumber: unitList[key].unitNumber,
          unitName: unitList[key].unitName,
          unitType: unitList[key].unitType,
          devices: devicesArray || []
        });
      });
      setUnits(units);
    } else {
      setUnits([]);
    }
  }, [unitList]);

  useEffect(() => {
    if (shouldRefetch) {
      refetch();
      setShouldRefetch(false);
    }
  }, [shouldRefetch]);

  const isEveryDeviceAddedToUnit = () => {
    const unitDevicesPublicId = new Set();
    for (const unit of units) {
      for (const device of unit.devices) {
        unitDevicesPublicId.add(device.publicId);
      }
    }

    for (const [key, value] of Object.entries(deviceGroups)) {
      if (key !== 'LiftControl' && key !== 'NetworkAdapter' && key !== 'EmergencyStation') {
        for (const device of value) {
          if (!unitDevicesPublicId.has(device)) {
            return false;
          }
        }
      }
    }

    return true;
  };

  const handleSaveUnits = () => {
    const deviceUnitMappings = units.flatMap((unit) => {
      return unit.devices.map((device) => {
        return {
          unitName: unit.unitName,
          unitNumber: unit.unitNumber,
          devicePublicId: device.publicId,
          unitPublicId: unit.unitPublicId,
          sitePublicId: sitePublicId
        };
      });
    });
    for (const deviceUnitMapping of deviceUnitMappings) {
      const params = {
        device: {
          publicId: deviceUnitMapping.devicePublicId,
          unitPublicId: deviceUnitMapping.unitPublicId,
          sitePublicId: sitePublicId
        }
      };
      updateDevice(params);
    }
    handleNextStep();
  };

  const renderAssociateUnitsEntry = () => {
    return (
      <Box sx={styles.centerContent}>
        <Typography variant="h4" sx={{ mb: 3, color: 'primary.main', fontWeight: 'bold' }}>
          {strings.title}
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          {strings.body1}
        </Typography>
      </Box>
    );
  };

  const renderUnitsList = () => {
    return (
      <Box>
        {units.slice((page - 1) * itemsPerPage, page * itemsPerPage).map((unit) => {
          return (
            <UnitCard
              key={unit.unitNumber}
              unit={unit}
              units={units}
              setUnits={setUnits}
              setIsAddMobileDevicesOpen={setIsAddMobileDevicesOpen}
              setIsAddDevicesOpen={setIsAddDevicesOpen}
              setSelectedUnit={setSelectedUnit}
              setShouldRefetch={setShouldRefetch}
            />
          );
        })}
        <Pagination
          count={Math.ceil(units.length / itemsPerPage)}
          page={page}
          onChange={(event, value) => setPage(value)}
          sx={{ mt: 2, mb: 2 }}
        />
      </Box>
    );
  };

  const renderDualButtonContainer = () => {
    return (
      <Box sx={styles.dualButtonContainer}>
        <Button variant="contained" color="primary" onClick={handlePreviousStep}>
          {strings.backButtonText}
        </Button>
        <Button variant="contained" color="primary" onClick={handleSaveUnits} disabled={!isEveryDeviceAddedToUnit()}>
          {strings.continueButtonText}
        </Button>
      </Box>
    );
  };

  return (
    <Container maxWidth="lg">
      {isSuccess ? (
        <>
          {renderAssociateUnitsEntry()}
          {renderUnitsList()}
          {renderDualButtonContainer()}
        </>
      ) : (
        <Backdrop open={true} sx={styles.backdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <AddDeviceToUnitDialog
        isAddDevicesOpen={isAddDevicesOpen}
        setIsAddDevicesOpen={setIsAddDevicesOpen}
        selectedUnit={selectedUnit ? selectedUnit : undefined}
        units={units}
        setUnits={setUnits}
        deviceGroups={deviceGroups}
      />
      <AddMobileDeviceToUnitDialog
        isAddMobileDevicesOpen={isAddMobileDevicesOpen}
        setIsAddMobileDevicesOpen={setIsAddMobileDevicesOpen}
        selectedUnit={selectedUnit ? selectedUnit : undefined}
        units={units}
        setUnits={setUnits}
      />
    </Container>
  );
};

const styles = {
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  dualButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
    flexDirection: 'row',
    marginTop: '1rem'
  },
  backdrop: {
    color: '#fff',
    zIndex: 'theme.zIndex.drawer'
  }
};

export default AssociateUnits;
