import { useDispatch, useSelector } from 'react-redux';
import { IDevice, getSelectedDevice, updateSelectedDevice } from 'store/slices/devicesSlice';
import * as Yup from 'yup';
import { Form, Formik, Field } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import { Grid } from '@mui/material';
import { getString } from 'shared/utils/LocalizationUtils';
import { useUpdateDeviceMutation } from 'services/aiphoneCloud';
import { RootState } from 'store';
import containerStyle from 'shared/styles/advancedSettingContainerStyle';
import StringUtils from 'shared/utils/StringUtils';
import SnackbarAlert from 'shared/components/SnackbarAlert';
import { useEffect, useState } from 'react';
import { EnumList, fetchEnumList } from 'shared/utils/EnumUtils';

export const StationIdentificationLabel = () => {
  return <span>{getString('AdvancedSettings_Tab_StationIdentification')}</span>;
};

const StationIdentification = () => {
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [updateDevice, { isLoading: isUpdating }] = useUpdateDeviceMutation();
  const deviceList = useSelector((state: RootState) => state.devices.DeviceList);
  const stationNameTitle = getString('AdvancedSettings_StationName_Title');
  const stationNameDesc = getString('AdvancedSettings_StationName_Desc');
  const stationNumberTitle = getString('AdvancedSettings_StationNumber_Title');
  const stationNumberDesc = getString('AdvancedSettings_StationNumber_Desc');
  const timezoneTitle = getString('AdvancedSettings_Timezone_Title');
  const timezoneDesc = getString('AdvancedSettings_Timezone_Desc');
  const timezoneVer2Title = getString('AdvancedSettings_TimezoneVer2_Title');
  const timezoneVer2Desc = getString('AdvancedSettings_TimezoneVer2_Desc');
  const daylightSavingsTitle = getString('AdvancedSettings_DaylightSavings_Title');
  const daylightSavingsDesc = getString('AdvancedSettings_DaylightSavings_Desc');
  const fieldErrorMaxLen = getString('Field_Error_MaxLen');
  const fieldErrorRequired = getString('Field_Error_Required');
  const fieldErrorUnique = getString('Field_Error_Unique');
  const errorUpdateDevice = getString('AdvancedSettings_Error_UpdateDevice');
  const successUpdateDevice = getString('AdvancedSettings_Success_UpdateDevice');
  const buttonReset = getString('Button_Reset');
  const buttonSubmit = getString('Button_Submit');
  const selectedDevice = useSelector(getSelectedDevice);
  const [enumList, setEnumList] = useState<EnumList>({ country: {}, state: {} });
  const [fetchingEnums, setFetchingEnums] = useState(true);
  const formDevice = {
    stationName: selectedDevice.basicInfo.stationName,
    stationNumber: selectedDevice.basicInfo.stationNumber,
    timezone: selectedDevice.basicInfo.timezone,
    timezoneVer2: selectedDevice.basicInfo.timezoneVer2,
    daylightSavings: selectedDevice.basicInfo.daylightSavings
  };

  useEffect(() => {
    fetchEnumList().then((data) => {
      setEnumList(data);
      setFetchingEnums(false);
    });
  }, []);

  const getValidationSchema = () => {
    const basicInfoSchema: any = {};

    if (formDevice.stationNumber !== null && formDevice.stationNumber !== undefined) {
      const stationNumberMaxLen = 24;

      basicInfoSchema.stationNumber = Yup.string()
        .required(StringUtils.format(fieldErrorRequired, stationNumberTitle))
        .max(stationNumberMaxLen, StringUtils.format(fieldErrorMaxLen, stationNumberTitle, stationNumberMaxLen))
        .test('isUnique', StringUtils.format(fieldErrorUnique, stationNumberTitle), (value: string) => {
          return !Object.values(deviceList).some(
            (device: IDevice) => device.publicId != selectedDevice.publicId && device.basicInfo?.stationNumber === value
          );
        });
    }

    if (formDevice.stationName !== null && formDevice.stationName !== undefined) {
      const stationNameMaxLen = 24;

      basicInfoSchema.stationName = Yup.string().max(
        stationNameMaxLen,
        StringUtils.format(fieldErrorMaxLen, stationNameTitle, stationNameMaxLen)
      );
    }

    return Yup.object(basicInfoSchema);
  };

  const onSubmit = async (values: any, actions: any) => {
    const params = {
      device: {
        publicId: selectedDevice.publicId,
        basicInfo: values
      }
    };

    const newDevice = JSON.parse(JSON.stringify(selectedDevice));

    newDevice.basicInfo = {
      ...newDevice.basicInfo,
      ...values
    };

    updateDevice(params)
      .then((response) => {
        if ('error' in response) {
          throw response.error;
        }

        dispatch(updateSelectedDevice({ device: newDevice }));
        setShowAlert(true);
        actions.resetForm({
          values: values
        });
      })
      .catch(() => {
        setErrorMessage(errorUpdateDevice);
      });
  };

  return (
    <Box sx={containerStyle.settingsPageContainer}>
      <SnackbarAlert
        type="error"
        time={10000}
        text={`${errorMessage}`}
        isOpen={!!errorMessage}
        onClose={() => setErrorMessage(null)}
      />
      <SnackbarAlert
        type="success"
        time={3000}
        text={successUpdateDevice}
        isOpen={showAlert}
        onClose={() => setShowAlert(false)}
      />
      {fetchingEnums ? (
        <CircularProgress />
      ) : (
        <Formik initialValues={formDevice} onSubmit={onSubmit} validationSchema={getValidationSchema()}>
          {({ values, dirty, touched, errors, isSubmitting }) => (
            <Form style={containerStyle.form}>
              <Box sx={containerStyle.gridContainer}>
                {formDevice.stationNumber !== null && formDevice.stationNumber !== undefined ? (
                  <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                    <Grid item xs={5} lg={7}>
                      <Box sx={containerStyle.itemTitle}>{stationNumberTitle}</Box>
                      <Box sx={containerStyle.itemDesc}>{stationNumberDesc}</Box>
                    </Grid>
                    <Grid item xs={6} lg={4} justifyContent="flex-end">
                      <Box sx={containerStyle.fieldContainer}>
                        <Field
                          as={TextField}
                          label={stationNumberTitle}
                          name="stationNumber"
                          size="small"
                          style={containerStyle.textField}
                          helperText={touched.stationNumber && errors.stationNumber}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                ) : null}
                {formDevice.stationName !== null && formDevice.stationName !== undefined ? (
                  <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                    <Grid item xs={5} lg={7}>
                      <Box sx={containerStyle.itemTitle}>{stationNameTitle}</Box>
                      <Box sx={containerStyle.itemDesc}>{stationNameDesc}</Box>
                    </Grid>
                    <Grid item xs={6} lg={4} justifyContent="flex-end">
                      <Box sx={containerStyle.fieldContainer}>
                        <Field
                          as={TextField}
                          label={stationNameTitle}
                          name="stationName"
                          size="small"
                          style={containerStyle.textField}
                          helperText={touched.stationName && errors.stationName}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                ) : null}
                {formDevice.timezone !== null && formDevice.timezone !== undefined ? (
                  <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                    <Grid item xs={5} lg={7}>
                      <Box sx={containerStyle.itemTitle}>{timezoneTitle}</Box>
                      <Box sx={containerStyle.itemDesc}>{timezoneDesc}</Box>
                    </Grid>
                    <Grid item xs={6} lg={4} justifyContent="flex-end">
                      <Box sx={containerStyle.fieldContainer}>
                        <FormControl
                          sx={containerStyle.selectField}
                          size="small"
                          error={touched.timezone && Boolean(errors.timezone)}
                        >
                          <InputLabel id="timezone-label">{timezoneTitle}</InputLabel>
                          <Field
                            as={Select}
                            labelId="timezone-label"
                            label="Timezone"
                            name="timezone"
                            autoWidth={false}
                          >
                            {Object.keys(enumList.timezone).map((key) => {
                              return (
                                <MenuItem key={key} value={key}>
                                  {enumList.timezone[key].value}
                                </MenuItem>
                              );
                            })}
                          </Field>
                        </FormControl>
                      </Box>
                    </Grid>
                  </Grid>
                ) : null}
                {formDevice.timezoneVer2 !== null && formDevice.timezoneVer2 !== undefined ? (
                  <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                    <Grid item xs={5} lg={7}>
                      <Box sx={containerStyle.itemTitle}>{timezoneVer2Title}</Box>
                      <Box sx={containerStyle.itemDesc}>{timezoneVer2Desc}</Box>
                    </Grid>
                    <Grid item xs={6} lg={4} justifyContent="flex-end">
                      <Box sx={containerStyle.fieldContainer}>
                        <FormControl
                          sx={containerStyle.selectField}
                          size="small"
                          error={touched.timezoneVer2 && Boolean(errors.timezoneVer2)}
                        >
                          <InputLabel id="timezone-ver2-label">{timezoneVer2Title}</InputLabel>
                          <Field
                            as={Select}
                            labelId="timezone-ver2-label"
                            label="Timezone Ver2"
                            name="timezoneVer2"
                            autoWidth={false}
                          >
                            {Object.keys(enumList.timezone).map((key) => {
                              return (
                                <MenuItem key={key} value={key}>
                                  {enumList.timezone[key].value}
                                </MenuItem>
                              );
                            })}
                          </Field>
                        </FormControl>
                      </Box>
                    </Grid>
                  </Grid>
                ) : null}
                {formDevice.daylightSavings !== null && formDevice.daylightSavings !== undefined ? (
                  <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                    <Grid item xs={5} lg={7}>
                      <Box sx={containerStyle.itemTitle}>{daylightSavingsTitle}</Box>
                      <Box sx={containerStyle.itemDesc}>{daylightSavingsDesc}</Box>
                    </Grid>
                    <Grid item xs={6} lg={4} justifyContent="flex-end">
                      <Box sx={containerStyle.fieldContainer}>
                        <Field
                          as={Checkbox}
                          label={daylightSavingsTitle}
                          name="daylightSavings"
                          style={containerStyle.toggleField}
                          checked={values.daylightSavings}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                ) : null}
              </Box>
              <Box sx={containerStyle.submitContainer}>
                <Button
                  type="reset"
                  variant="contained"
                  color="primary"
                  disabled={!dirty || isSubmitting || isUpdating}
                  style={containerStyle.submitBarButton}
                >
                  {buttonReset}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!dirty || isSubmitting || isUpdating}
                  style={containerStyle.submitBarButton}
                >
                  {buttonSubmit}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      )}
    </Box>
  );
};

export default StationIdentification;
