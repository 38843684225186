import { IStringDictionary } from 'shared/utils/LocalizationUtils';

const langStrings: IStringDictionary = {};

langStrings['AccountManagerName'] = 'Account Manager Name';
langStrings['AddBranchDialogTitle_AddNewBranch'] = 'Add New Branch';
langStrings['AddBranchDialogTitle_AddNewBranch'] = 'Add New Branch';
langStrings['Address'] = 'Address';
langStrings['AdvancedSettings_AdminId_Desc'] = 'The Admin ID of this device.';
langStrings['AdvancedSettings_AdminId_Title'] = 'Admin ID';
langStrings['AdvancedSettings_AdminPass_Desc'] = 'The Admin Password of this device.';
langStrings['AdvancedSettings_AdminPass_Title'] = 'Admin Password';
langStrings['AdvancedSettings_AudioBufferMaximumPackets_Desc'] = 'Audio Buffer Maximum Packets';
langStrings['AdvancedSettings_AudioBufferMaximumPackets_Title'] = 'Audio Buffer Maximum Packets';
langStrings['AdvancedSettings_AudioBufferStartingPackets_Desc'] = 'Audio Buffer Starting Packets';
langStrings['AdvancedSettings_AudioBufferStartingPackets_Title'] = 'Audio Buffer Starting Packets';
langStrings['AdvancedSettings_AudioEncoder_Desc'] = 'The audio encoder of this device.';
langStrings['AdvancedSettings_AudioEncoder_Title'] = 'Audio Encoder';
langStrings['AdvancedSettings_AudioTosValue_Desc'] = 'Audio TOS Value';
langStrings['AdvancedSettings_AudioTosValue_Title'] = 'Audio TOS Value';
langStrings['AdvancedSettings_ButtonFeedback_Desc'] = 'Button Feedback Tone';
langStrings['AdvancedSettings_ButtonFeedback_Desc'] = 'Button Feedback Tone';
langStrings['AdvancedSettings_ButtonFeedback_Title'] = 'Button Feedback Tone';
langStrings['AdvancedSettings_ButtonFeedback_Title'] = 'Button Feedback Tone';
langStrings['AdvancedSettings_DaylightSavings_Desc'] = 'Toggle to enable or disable automatic daylight savings.';
langStrings['AdvancedSettings_DaylightSavings_Title'] = 'Daylight Savings';
langStrings['AdvancedSettings_Error_UpdateDevice'] = 'Error updating device details';
langStrings['AdvancedSettings_ExternalMicVolume_Desc'] = 'External Microphone Volume';
langStrings['AdvancedSettings_ExternalMicVolume_Desc'] = 'External Microphone Volume';
langStrings['AdvancedSettings_ExternalMicVolume_Title'] = 'External Microphone Volume';
langStrings['AdvancedSettings_ExternalMicVolume_Title'] = 'External Microphone Volume';
langStrings['AdvancedSettings_ExternalSpeakerVolume_Desc'] = 'External Speaker Volume';
langStrings['AdvancedSettings_ExternalSpeakerVolume_Desc'] = 'External Speaker Volume';
langStrings['AdvancedSettings_ExternalSpeakerVolume_Title'] = 'External Speaker Volume';
langStrings['AdvancedSettings_ExternalSpeakerVolume_Title'] = 'External Speaker Volume';
langStrings['AdvancedSettings_HostName_Desc'] = 'The Host Name of this device.';
langStrings['AdvancedSettings_HostName_Title'] = 'Host Name';
langStrings['AdvancedSettings_IpAddressMethod_Desc'] = 'The IP Address Method of this device.';
langStrings['AdvancedSettings_IpAddressMethod_Title'] = 'IP Address Method';
langStrings['AdvancedSettings_IPPhoneVolumeAdjustment_Desc'] = 'VoIP Phone Volume Adjustment';
langStrings['AdvancedSettings_IPPhoneVolumeAdjustment_Title'] = 'VoIP Phone Volume Adjustment';
langStrings['AdvancedSettings_IpV4Address_Desc'] = 'The IPV4 Address of this device.';
langStrings['AdvancedSettings_IpV4Address_Title'] = 'IPV4 Address';
langStrings['AdvancedSettings_IpV4DefaultGateway_Desc'] = 'The IPV4 Default Gateway of this device.';
langStrings['AdvancedSettings_IpV4DefaultGateway_Title'] = 'IPV4 Default Gateway';
langStrings['AdvancedSettings_IpV4MulticastAddress_Desc'] = 'The IPV4 Multicast Address of this device.';
langStrings['AdvancedSettings_IpV4MulticastAddress_Title'] = 'IPV4 Multicast Address';
langStrings['AdvancedSettings_IpV4PrimaryDns_Desc'] = 'The IPV4 Primary DNS of this device.';
langStrings['AdvancedSettings_IpV4PrimaryDns_Title'] = 'IPV4 Primary DNS';
langStrings['AdvancedSettings_IpV4SecondaryDns_Desc'] = 'The IPV4 Secondary DNS of this device.';
langStrings['AdvancedSettings_IpV4SecondaryDns_Title'] = 'IPV4 Secondary DNS';
langStrings['AdvancedSettings_IpV6Address_Desc'] = 'The IPV6 Address of this device.';
langStrings['AdvancedSettings_IpV6Address_Title'] = 'IPV6 Address';
langStrings['AdvancedSettings_IpV6DefaultGateway_Desc'] = 'The IPV6 Default Gateway of this device.';
langStrings['AdvancedSettings_IpV6DefaultGateway_Title'] = 'IPV6 Default Gateway';
langStrings['AdvancedSettings_IpV6MulticastAddress_Desc'] = 'The IPV6 Multicast Address of this device.';
langStrings['AdvancedSettings_IpV6MulticastAddress_Title'] = 'IPV6 Multicast Address';
langStrings['AdvancedSettings_IpV6PrimaryDns_Desc'] = 'The IPV6 Primary DNS of this device.';
langStrings['AdvancedSettings_IpV6PrimaryDns_Title'] = 'IPV6 Primary DNS';
langStrings['AdvancedSettings_IpV6SecondaryDns_Desc'] = 'The IPV6 Secondary DNS of this device.';
langStrings['AdvancedSettings_IpV6SecondaryDns_Title'] = 'IPV6 Secondary DNS';
langStrings['AdvancedSettings_IpVersion_Desc'] = 'The IP Version of this device.';
langStrings['AdvancedSettings_IpVersion_Title'] = 'IP Version';
langStrings['AdvancedSettings_MacAddress_Desc'] = 'The MAC address of this device.';
langStrings['AdvancedSettings_MacAddress_Error_Invalid'] = 'Invalid MAC Address.';
langStrings['AdvancedSettings_MacAddress_Title'] = 'MAC Address';
langStrings['AdvancedSettings_MasterStationVideoBitrate_Desc'] = 'Master Station Video Bitrate Description';
langStrings['AdvancedSettings_MasterStationVideoBitrate_Title'] = 'Master Station Video Bitrate';
langStrings['AdvancedSettings_MasterStationVideoFramerate_Desc'] = 'Master Station Video Framerate Description';
langStrings['AdvancedSettings_MasterStationVideoFramerate_Title'] = 'Master Station Video Framerate';
langStrings['AdvancedSettings_MasterStationVideoIPictureInterval_Desc'] = 'Master Station Video I Picture Interval[H.264/AVC] Description';
langStrings['AdvancedSettings_MasterStationVideoIPictureInterval_Title'] = 'Master Station Video I Picture Interval[H.264/AVC]';
langStrings['AdvancedSettings_OnvifId_Desc'] = 'The Onvif ID of this device.';
langStrings['AdvancedSettings_OnvifId_Title'] = 'Onvif ID';
langStrings['AdvancedSettings_OnvifPass_Desc'] = 'The Onvif Password of this device.';
langStrings['AdvancedSettings_OnvifPass_Title'] = 'Onvif Password';
langStrings['AdvancedSettings_PagingRingtoneVolume_Desc'] = 'Incoming Paging Ringtone Volume';
langStrings['AdvancedSettings_PagingRingtoneVolume_Title'] = 'Incoming Paging Ringtone Volume';
langStrings['AdvancedSettings_ReceiveVolumeHandset_Desc'] = 'Receive and Ringback Tone Volume (Handset)';
langStrings['AdvancedSettings_ReceiveVolumeHandset_Title'] = 'Receive and Ringback Tone Volume (Handset)';
langStrings['AdvancedSettings_ReceiveVolumeSpeaker_Desc'] = 'Receive and Ringback Tone Volume (Speaker)';
langStrings['AdvancedSettings_ReceiveVolumeSpeaker_Title'] = 'Receive and Ringback Tone Volume (Speaker)';
langStrings['AdvancedSettings_RingtoneVolume_Desc'] = 'Incoming Call Ringtone Volume';
langStrings['AdvancedSettings_RingtoneVolume_Title'] = 'Incoming Call Ringtone Volume';
langStrings['AdvancedSettings_RtspId_Desc'] = 'The Rtsp ID of this device.';
langStrings['AdvancedSettings_RtspId_Title'] = 'Rtsp ID';
langStrings['AdvancedSettings_RtspPass_Desc'] = 'The Rtsp Password of this device.';
langStrings['AdvancedSettings_RtspPass_Title'] = 'Rtsp Password';
langStrings['AdvancedSettings_SipTosValue_Desc'] = 'SIP TOS Value';
langStrings['AdvancedSettings_SipTosValue_Title'] = 'SIP TOS Value';
langStrings['AdvancedSettings_StationLocation_Desc'] = 'The location of this device.';
langStrings['AdvancedSettings_StationLocation_Title'] = 'Station Location';
langStrings['AdvancedSettings_StationName_Desc'] = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.';
langStrings['AdvancedSettings_StationName_Title'] = 'Station Name';
langStrings['AdvancedSettings_StationNumber_Desc'] = 'A unique number used to identify this device in your system.';
langStrings['AdvancedSettings_StationNumber_Title'] = 'Station Number';
langStrings['AdvancedSettings_SubnetMask_Desc'] = 'The Subnet Mask of this device.';
langStrings['AdvancedSettings_SubnetMask_Title'] = 'Subnet Mask';
langStrings['AdvancedSettings_Success_UpdateDevice'] = 'The Device Details were successfully updated';
langStrings['AdvancedSettings_Success_UpdateDevice'] = 'The Device Details were successfully updated';
langStrings['AdvancedSettings_SystemExtendMode_Desc'] = 'Toggle to enable or disable system extend mode.';
langStrings['AdvancedSettings_SystemExtendMode_Title'] = 'System Extend Mode';
langStrings['AdvancedSettings_Tab_Audio'] = 'Audio';
langStrings['AdvancedSettings_Tab_IpAddress'] = 'IP Address';
langStrings['AdvancedSettings_Tab_StationIdentification'] = 'Station Identification';
langStrings['AdvancedSettings_Tab_Tooltip_IpAddress'] = 'IP Address, DNS';
langStrings['AdvancedSettings_Tab_Video'] = 'Video';
langStrings['AdvancedSettings_Tab_Volume'] = 'Volume';
langStrings['AdvancedSettings_Timezone_Desc'] = 'The Timezone of this device.';
langStrings['AdvancedSettings_Timezone_Title'] = 'Timezone';
langStrings['AdvancedSettings_TimezoneVer2_Desc'] = 'The Timezone of this device.';
langStrings['AdvancedSettings_TimezoneVer2_Title'] = 'Timezone';
langStrings['AdvancedSettings_TransmitVolumeHandset_Desc'] = 'Microphone Volume (Handset)';
langStrings['AdvancedSettings_TransmitVolumeHandset_Title'] = 'Microphone Volume (Handset)';
langStrings['AdvancedSettings_TransmitVolumeSpeaker_Desc'] = 'Microphone Volume (Speaker)';
langStrings['AdvancedSettings_TransmitVolumeSpeaker_Desc'] = 'Microphone Volume (Speaker)';
langStrings['AdvancedSettings_TransmitVolumeSpeaker_Title'] = 'Microphone Volume (Speaker)';
langStrings['AdvancedSettings_UserId_Desc'] = 'The User ID of this device.';
langStrings['AdvancedSettings_UserId_Title'] = 'User ID';
langStrings['AdvancedSettings_UserPass_Desc'] = 'The User Password of this device.';
langStrings['AdvancedSettings_UserPass_Title'] = 'User Password';
langStrings['AdvancedSettings_VideoDualStream_Desc'] = 'Toggle to enable or disable video dual stream.';
langStrings['AdvancedSettings_VideoDualStream_Title'] = 'Video Dual Stream';
langStrings['AdvancedSettings_VideoEncoder1Bitrate_Desc'] = 'Video Encoder 1 Bitrate Description';
langStrings['AdvancedSettings_VideoEncoder1Bitrate_Title'] = 'Video Encoder 1 Bitrate';
langStrings['AdvancedSettings_VideoEncoder1Framerate_Desc'] = 'Video Encoder 1 Framerate';
langStrings['AdvancedSettings_VideoEncoder1Framerate_Title'] = 'Video Encoder 1 Framerate Description';
langStrings['AdvancedSettings_VideoEncoder1IPictureInterval_Desc'] = 'Video Encoder 1 I Picture Interval[H.264/AVC] Description';
langStrings['AdvancedSettings_VideoEncoder1IPictureInterval_Title'] = 'Video Encoder 1 I Picture Interval[H.264/AVC]';
langStrings['AdvancedSettings_VideoEncoder2Bitrate_Desc'] = 'Video Encoder 2 Bitrate Description';
langStrings['AdvancedSettings_VideoEncoder2Bitrate_Desc'] = 'Video Encoder 2 Bitrate Description';
langStrings['AdvancedSettings_VideoEncoder2Bitrate_Title'] = 'Video Encoder 2 Bitrate';
langStrings['AdvancedSettings_VideoEncoder2Bitrate_Title'] = 'Video Encoder 2 Bitrate';
langStrings['AdvancedSettings_VideoEncoder2Framerate_Desc'] = 'Video Encoder 2 Framerate Description';
langStrings['AdvancedSettings_VideoEncoder2Framerate_Title'] = 'Video Encoder 2 Framerate';
langStrings['AdvancedSettings_VideoEncoder2H264Quality_Desc'] = 'Video Encoder 2 H.264 Quality Description';
langStrings['AdvancedSettings_VideoEncoder2H264Quality_Title'] = 'Video Encoder 2 H.264 Quality';
langStrings['AdvancedSettings_VideoEncoder2IPictureInterval_Desc'] = 'Video Encoder 2 I Picture Interval[H.264/AVC] Description';
langStrings['AdvancedSettings_VideoEncoder2IPictureInterval_Title'] = 'Video Encoder 2 I Picture Interval[H.264/AVC]';
langStrings['AdvancedSettings_VideoEncoder2JpegQuality_Desc'] = 'Video Encoder 2 JPEG Quality Description';
langStrings['AdvancedSettings_VideoEncoder2JpegQuality_Title'] = 'Video Encoder 2 JPEG Quality';
langStrings['AdvancedSettings_VideoEncoder2Method_Desc'] = 'Video Encoder 2 Method Description';
langStrings['AdvancedSettings_VideoEncoder2Method_Title'] = 'Video Encoder 2 Method';
langStrings['AdvancedSettings_VideoEncoder2Resolution_Desc'] = 'Video Encoder 2 Resolution Description';
langStrings['AdvancedSettings_VideoEncoder2Resolution_Title'] = 'Video Encoder 2 Resolution';
langStrings['AdvancedSettings_VideoEncoder2RtpEndPort_Desc'] = 'Video Encoder 2 RTP End Port Description';
langStrings['AdvancedSettings_VideoEncoder2RtpEndPort_Title'] = 'Video Encoder 2 RTP End Port';
langStrings['AdvancedSettings_VideoEncoder2RtpStartPort_Desc'] = 'Video Encoder 2 RTP Start Port Description';
langStrings['AdvancedSettings_VideoEncoder2RtpStartPort_Title'] = 'Video Encoder 2 RTP Start Port';
langStrings['AdvancedSettings_VideoTosValue_Desc'] = 'Video TOS Value';
langStrings['AdvancedSettings_VideoTosValue_Title'] = 'Video TOS Value';
langStrings['AdvancedSettings_VlanId_Desc'] = 'VLAN ID';
langStrings['AdvancedSettings_VlanId_Title'] = 'VLAN ID';
langStrings['AdvancedSettings_VlanPriority_Desc'] = 'VLAN Priority';
langStrings['AdvancedSettings_VlanPriority_Title'] = 'VLAN Priority';
langStrings['AdvancedSettings_VlanSettings_Desc'] = 'Enable VLAN Settings';
langStrings['AdvancedSettings_VlanSettings_Title'] = 'VLAN Settings';
langStrings['BranchInformation_Dialog'] = 'Branch Information';
langStrings['BranchInformation_Dialog'] = 'Branch Information';
langStrings['BranchName'] = 'Branch Name';
langStrings['BranchPhone'] = 'Branch Phone';
langStrings['Button_AddNewBranch_Cancel'] = 'Cancel';
langStrings['Button_AddNewBranch_Cancel'] = 'Cancel';
langStrings['Button_AddNewBranch'] = 'Add New Branch';
langStrings['Button_AddNewBranch'] = 'Add New Branch';
langStrings['Button_BackText'] = 'Back';
langStrings['Button_BranchToolBar_AddBranch'] = 'Add Branch';
langStrings['Button_BranchToolBar_AddBranch'] = 'Add Branch';
langStrings['Button_Cancel_DeleteBranch'] = 'Delete Branch';
langStrings['Button_Cancel_DeleteCompany'] = 'Cancel';
langStrings['Button_CloudAccount_Register'] = 'Register';
langStrings['Button_CloudAccount_Register'] = 'Register';
langStrings['Button_CompaniesToolBar_AddCompany'] = 'Add Company';
langStrings['Button_CompaniesToolBar_AddCompany'] = 'Add Company';
langStrings['Button_CompaniesToolBar_DeleteCompany'] = 'Delete Company';
langStrings['Button_CompaniesToolBar_DeleteCompany'] = 'Delete Company';
langStrings['Button_CookiePolicy'] = 'Cookie Policy';
langStrings['Button_CreateCompany_Cancel'] = 'Cancel';
langStrings['Button_CreateCompany'] = 'Create Company';
langStrings['Button_DeleteBranch'] = 'Delete Branch';
langStrings['Button_DeleteCompany_And_Branch'] = 'Delete Company and Branch';
langStrings['Button_DeleteCompany'] = 'Delete Company';
langStrings['Button_EditBranch_Cancel'] = 'Cancel';
langStrings['Button_EditBranch_Save'] = 'Save';
langStrings['Button_ForgotPassword'] = 'Forgot your password?';
langStrings['Button_ForgotPassword'] = 'Forgot your password?';
langStrings['Button_LogIn'] = 'Log In';
langStrings['Button_LogIn'] = 'Log In';
langStrings['Button_NeedAnAccount'] = 'Need an account?';
langStrings['Button_NeedAnAccount'] = 'Need an account?';
langStrings['Button_Password_ReturnToLogin'] = 'Register here.';
langStrings['Button_Password_ReturnToLogin'] = 'Register here.';
langStrings['Button_PrivacyPolicy'] = 'Privacy Policy';
langStrings['Button_RegisterHere'] = 'Register here.';
langStrings['Button_RegisterHere'] = 'Register here.';
langStrings['Button_Resend'] = 'Didnt receive the code?';
langStrings['Button_Resend'] = 'Resend';
langStrings['Button_Reset'] = 'Reset';
langStrings['Button_SkipForNow'] = 'Skip For Now';
langStrings['Button_Submit'] = 'Submit';
langStrings['Button_TermsConditions'] = 'Terms & Conditions';
langStrings['Button_VerifyAccount'] = 'Verify Account';
langStrings['Button_VerifyAccount'] = 'Verify Account';
langStrings['C2CompanyID_Dialog'] = 'Does the branch have a C2 Company ID? If so, please enter it below to search for the branch and import the data:';
langStrings['C2CompanyID_Dialog'] = 'Does the branch have a C2 Company ID? If so, please enter it below to search for the branch and import the data:';
langStrings['City'] = 'City';
langStrings['Clicking_RegisterDescription'] = "By clicking the Register button, you agree to Aiphone Corporation's";
langStrings['Clicking_RegisterDescription'] = "By clicking the Register button, you agree to Aiphone Corporation's";
langStrings['CloudRegistration_AlreadyHaveAnAccount'] = 'Already have an account?';
langStrings['CloudRegistration_AlreadyHaveAnAccount'] = 'Already have an account?';
langStrings['CloudRegistration_SignIn'] = 'Sign In';
langStrings['CloudRegistration_SignIn'] = 'Sign In';
langStrings['Company_Name'] = 'Company Name';
langStrings['CompanyInformation'] = 'Company Information';
langStrings['Confirm_Password_Title'] = 'Confirm Password';
langStrings['CreateSite_NextButton'] = 'Next';
langStrings['CustomerNumber'] = 'Customer Number';
langStrings['DidNot_Receive_Code'] = "Didn't receive the code?";
langStrings['Didnt_Receive_Code'] = 'Didnt receive the code?';
langStrings['Digit'] = 'digit';
langStrings['EmailVerification_Body'] = 'A 6-digit code has been sent to your registered email address for verification.';
langStrings['EmailVerification_Body'] = 'A 6-digit code has been sent to your registered email address for verification.';
langStrings['Enter_CompanyDetails'] = 'Enter he company details below';
langStrings['Error_Description'] = 'There was an error creating the site. Please try again';
langStrings['Field_Error_Max'] = '{0} can not be more than {1}.';
langStrings['Field_Error_MaxLen'] = '{0} can not be longer than {1} characters.';
langStrings['Field_Error_Min'] = '{0} must be at least {1}.';
langStrings['Field_Error_MinLen'] = '{0} must be at least {1} characters.';
langStrings['Field_Error_NotValid'] = 'Current value is not a valid.';
langStrings['Field_Error_Required'] = '{0} is required.';
langStrings['Field_Error_Unique'] = '{0} must be unique.';
langStrings['Field_Error_Unique'] = '{0} must be unique.';
langStrings['Import_Branch'] = 'Import';
langStrings['ImportBranchData_Title'] = 'Import Branch Data';
langStrings['IpV4Address_Error_Invalid'] = 'Invalid IPV4 Address.';
langStrings['IpV6Address_Error_Invalid'] = 'Invalid IPV6 Address.';
langStrings['LoadingButton_CreateSite'] = 'Create Site';
langStrings['Location'] = 'Location';
langStrings['LowerCase'] = 'lowercase';
langStrings['Message_CantUndo'] = 'This operation cannot be reverted.';
langStrings['Message_DeleteBranch'] = 'Are you sure you want to delete the selected branch?';
langStrings['Message_DeleteCompany'] = 'Are you sure you want to delete the selected company and associated branches?';
langStrings['Message_Note'] = 'Note:';
langStrings['Password_Error_Confirm_Match'] = 'Passwords must match.';
langStrings['Password_Error_MissingItem'] = 'Password must contain at least one {0}.';
langStrings['PasswordCriteria_EightCharacters'] = 'At least 8 Characters';
langStrings['PasswordCriteria_EightCharacters'] = 'At least 8 Characters';
langStrings['PasswordCriteria_OneLowerCaseLetter'] = 'One Lower case Letter';
langStrings['PasswordCriteria_OneLowerCaseLetter'] = 'One Lower case Letter';
langStrings['PasswordCriteria_OneNumber'] = 'One Number';
langStrings['PasswordCriteria_OneNumber'] = 'One Number';
langStrings['PasswordCriteria_OneSpecialCharacter'] = 'One Special Character';
langStrings['PasswordCriteria_OneSpecialCharacter'] = 'One Special Character';
langStrings['PasswordCriteria_OneUpperCaseLetter'] = 'One Upper case Letter';
langStrings['PasswordCriteria_OneUpperCaseLetter'] = 'One Upper case Letter';
langStrings['PasswordCriteria_PasswordMatch'] = 'Passwords Match';
langStrings['PasswordCriteria_PasswordMatch'] = 'Passwords Match';
langStrings['Phone_Number'] = 'Phone Number';
langStrings['PhysicalAddress_2'] = 'Physical Address 2';
langStrings['PhysicalAddress'] = 'Physical Address';
langStrings['PostalCode'] = 'Postal Code';
langStrings['PrivacyPolicy'] = 'Privacy Policy';
langStrings['PrivacyPolicy'] = 'Privacy Policy';
langStrings['Select_Country'] = 'Select a country';
langStrings['Select_State'] = 'Select a state';
langStrings['SelectCompany_Dialog'] = 'Select the Company to add a Branch to:';
langStrings['SelectCompany_Dialog'] = 'Select the Company to add a Branch to:';
langStrings['Site'] = 'Site';
langStrings['SiteInfo_SiteAddress_Title'] = 'Site Address';
langStrings['SiteInfo_SiteCity_Title'] = 'City';
langStrings['SiteInfo_SiteCountry_Title'] = 'Country';
langStrings['SiteInfo_SiteName_Title'] = 'Site Name';
langStrings['SiteInfo_SiteState_Title'] = 'State / Province';
langStrings['SiteInfo_SiteZip_Title'] = 'Zip';
langStrings['SiteSettings_Text'] = 'Site Settings';
langStrings['State'] = 'State';
langStrings['Status'] = 'Status';
langStrings['Status'] = 'Status';
langStrings['TempPassword_Verified'] = 'Temporary password has been verified! Finish your account setup using the form below.';
langStrings['TempPassword_Verififed'] = 'Temporary password has been verified! Finish your account setup using the form below.';
langStrings['Terms_And_Conditions'] = 'Terms & Conditions';
langStrings['Terms_And_Conditions'] = 'Terms & Conditions';
langStrings['Title_Create_Site'] = 'Create Site';
langStrings['Title_CreateSite'] = 'Create Site';
langStrings['Title_EditBranch'] = 'Edit Branch';
langStrings['Title_EmailVerification'] = 'Email Verification';
langStrings['Title_Error'] = 'Error';
langStrings['Update_Success'] = '{0} has been successfully updated.';
langStrings['UpperCase'] = 'uppercase';
langStrings['ZipCode'] = 'Zip Code';

export default langStrings;
