import React from 'react';
import { Container, Paper, Tab } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import DeviceTabContent from 'features/RemoteManagement/Components/DeviceTabContent';

const EntranceStations = () => {
  const [value, setValue] = React.useState('0');
  const deviceListByType = useSelector((state: RootState) => state.devices.DeviceListByType);

  const deviceTabs = [
    {
      deviceType: 'EntranceStation',
      label: `Entrance Stations (${deviceListByType.EntranceStation?.length || 0})`
    }
  ];

  const handleTabChange = (e: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Paper elevation={1}>
          <TabContext value={value}>
            <TabList onChange={handleTabChange}>
              {deviceTabs.map((tab, index) => {
                return <Tab label={tab.label} value={index.toString()} key={tab.deviceType} />;
              })}
            </TabList>
            {deviceTabs.map((tab, index) => {
              return (
                <TabPanel value={index.toString()}>
                  <DeviceTabContent key={index} deviceList={deviceListByType[tab.deviceType]} />
                </TabPanel>
              );
            })}
          </TabContext>
        </Paper>
      </Container>
    </React.Fragment>
  );
};

export default EntranceStations;
