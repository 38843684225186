import { Box, Grid, Typography } from '@mui/material';
import DeviceUnitCard from './DeviceUnitCard';
import { IDevice } from 'store/slices/devicesSlice';

interface Props {
  units: Unit[];
  selectedUnit: Unit;
  devices: string[];
  addDeviceToUnit: (device: IDevice) => void;
  removeDeviceFromUnit: (device: IDevice) => void;
}

interface Unit {
  unitPublicId: string;
  unitNumber: string;
  unitName: string;
  devices: IDevice[];
  unitType: number;
}

const DeviceUnitCardGrid = ({ units, selectedUnit, devices, addDeviceToUnit, removeDeviceFromUnit }: Props) => {
  return (
    <Box sx={{ mt: 2 }}>
      <Grid container spacing={2}>
        {devices.length === 0 ? (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography> No available devices to assigned to this unit </Typography>
          </Grid>
        ) : (
          devices.map((deviceId) => (
            <DeviceUnitCard
              key={deviceId}
              units={units}
              selectedUnit={selectedUnit}
              deviceId={deviceId}
              addDeviceToUnit={addDeviceToUnit}
              removeDeviceFromUnit={removeDeviceFromUnit}
            />
          ))
        )}
      </Grid>
    </Box>
  );
};

export default DeviceUnitCardGrid;
