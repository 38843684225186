/* Guard Button tab stub */
export const GuardButtonLabel = () => {
  return <span>Guard Button</span>;
};

const GuardButton = () => {
  return <span>Guard Button</span>;
};

export default GuardButton;
