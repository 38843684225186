import React, { useState } from 'react';
import Introduction from './Steps/Introduction';
import RegisterGateway from './Steps/RegisterGateway';
import AddDevices from './Steps/AddDevices';
import DeviceBasicInfo from './Steps/DeviceBasicInfo';
import DeviceNetworkInfo from './Steps/DeviceNetworkInfo';
import ApplicationInfo from './Steps/ApplicationInfo';
import AddBuildings from './Steps/AddBuildings';
import AddUnits from './Steps/AddUnits';
import SyncSystemSettings from './Steps/SyncSystemSettings';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import AssociateUnits from './Steps/AssociateUnits';

export enum WizardStep {
  Introduction,
  RegisterGateway,
  AddDevices,
  AddBuildings,
  AddUnits,
  AssociateUnits,
  DeviceBasicInfo,
  DeviceNetworkInfo,
  SyncSystemSettings
}

const MultiTenantSiteWizard: React.FC = () => {
  const siteData = useSelector((state: RootState) => state.site);
  const sitePublicId = siteData.siteInfo.publicId;
  const [currentStep, setCurrentStep] = useState<WizardStep>(WizardStep.Introduction);
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [isChecked3, setIsChecked3] = useState(false);
  const [isChecked4, setIsChecked4] = useState(false);
  const [applicationType, setApplicationType] = useState<string>('');
  const [isRegistered, setIsRegistered] = useState(false);
  const [numberOfBuildings, setNumberOfBuildings] = useState(1);
  const [numberOfUnits, setNumberOfUnits] = useState(0);

  const handleNextStep = () => {
    setCurrentStep((prevStep) => {
      return prevStep + 1;
    });
  };

  const handlePreviousStep = () => {
    setCurrentStep((prevStep) => {
      return prevStep - 1;
    });
  };

  const renderStep = () => {
    switch (currentStep) {
      case WizardStep.Introduction:
        return (
          <Introduction
            isChecked1={isChecked1}
            isChecked2={isChecked2}
            isChecked3={isChecked3}
            isChecked4={isChecked4}
            setIsChecked1={setIsChecked1}
            setIsChecked2={setIsChecked2}
            setIsChecked3={setIsChecked3}
            setIsChecked4={setIsChecked4}
            handleNextStep={handleNextStep}
          />
        );
      case WizardStep.RegisterGateway:
        return (
          <RegisterGateway
            isRegistered={isRegistered}
            setIsRegistered={setIsRegistered}
            handlePreviousStep={handlePreviousStep}
            handleNextStep={handleNextStep}
          />
        );
      case WizardStep.AddDevices:
        return (
          <AddDevices
            isRegistered={isRegistered}
            handlePreviousStep={handlePreviousStep}
            handleNextStep={handleNextStep}
          />
        );
      case WizardStep.DeviceBasicInfo:
        return <DeviceBasicInfo handlePreviousStep={handlePreviousStep} handleNextStep={handleNextStep} />;
      case WizardStep.DeviceNetworkInfo:
        return <DeviceNetworkInfo handlePreviousStep={handlePreviousStep} handleNextStep={handleNextStep} />;
      case WizardStep.AssociateUnits:
        return <AssociateUnits handlePreviousStep={handlePreviousStep} handleNextStep={handleNextStep} />;
      case WizardStep.AddBuildings:
        return (
          <AddBuildings
            numberOfBuildings={numberOfBuildings}
            setNumberOfBuildings={setNumberOfBuildings}
            handlePreviousStep={handlePreviousStep}
            handleNextStep={handleNextStep}
          />
        );
      case WizardStep.AddUnits:
        return (
          <AddUnits
            numberOfUnits={numberOfUnits}
            setNumberOfUnits={setNumberOfUnits}
            handlePreviousStep={handlePreviousStep}
            handleNextStep={handleNextStep}
          />
        );
      case WizardStep.SyncSystemSettings:
        return <SyncSystemSettings />;
      default:
        return null;
    }
  };

  return <div>{renderStep()}</div>;
};

export default MultiTenantSiteWizard;
