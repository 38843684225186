import * as yup from 'yup';

export const loginValidation = yup.object().shape({
  email: yup.string().email('Enter a valid email').required('Email is required'),
  password: yup.string().min(8, 'Password should be a minimum of 8 characters long').required('Password is required')
});

export const registerValidation = yup.object().shape({
  firstName: yup.string().required('This field is required.'),
  lastName: yup.string().required('This field is required.'),
  email: yup
    .string()
    .email('Email is not valid.')
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Please enter a valid email address.'
    )
    .required('This field is required.'),
  phoneNumber: yup
    .string()
    .matches(
      /((^\d{3})|(^\(\d{3}\))){1}((\d{7})|([\s.-]{1}\d{3}[\s.-]{1}\d{4}))$/,
      'Please enter a valid phone number, such as 555-555-5555.'
    )
    .required('This field is required.'),
  companyName: yup.string(),
  countryId: yup.number().required('This field is required.'),
  address: yup.string().required('This field is required.'),
  address2: yup.string(),
  city: yup.string().required('This field is required.'),
  stateId: yup.number().required('This field is required.'),
  zipCode: yup.string().required('This field is required.'),
  password: yup.string().required('This field is required.'),
  passwordConfirm: yup.string().required('This field is required.')
});

export const confirmEmailValidation = yup.object().shape({
  verificationCode: yup.string().required('required')
});

export const createPWDValidation = yup.object().shape({
  password: yup.string().required('required'),
  passwordConfirm: yup.string().required('required')
});

export const resetPWDValidation = yup.object().shape({
  email: yup.string().email('Enter a valid email').required('Email is required')
});
