import { Box, Typography } from '@mui/material';
import { SimBillingSite } from 'features/SimBilling/Types/SimBillingTypes';
import { getAlpha2CodeForCountry } from 'features/SimBilling/Utils/SimBillingUtils';
import { useState, useEffect } from 'react';
import { EnumList, fetchEnumList } from 'shared/utils/EnumUtils';

interface SiteInformationProps {
  siteDetails: SimBillingSite;
}

const SiteInformation = ({ siteDetails }: SiteInformationProps) => {
  const [enumList, setEnumList] = useState<EnumList>({ country: {}, state: {} });

  useEffect(() => {
    fetchEnumList().then((data) => {
      setEnumList(data);
    });
  }, []);

  return (
    <Box>
      <Typography variant="h6" sx={styles.labelsWithMargin}>
        Site Id:
        <Typography variant="body1" display="inline" sx={styles.siteInformationValues}>
          {siteDetails?.AclSiteId || 'N/A'}
        </Typography>
      </Typography>
      <Typography variant="h6" sx={styles.labelsWithMargin}>
        Name:
        <Typography variant="body1" display="inline" sx={styles.siteInformationValues}>
          {siteDetails?.SiteName || 'N/A'}
        </Typography>
      </Typography>
      <Typography variant="h6" sx={styles.labelsWithMargin}>
        Address:
        <Typography variant="body1" display="inline" sx={styles.siteInformationValues}>
          {`${siteDetails.Address1}, ${siteDetails.City}, ${siteDetails.State}, ${
            siteDetails.ZipCode
          }, ${getAlpha2CodeForCountry(siteDetails.CountryCode, enumList.country)}`}
        </Typography>
      </Typography>
    </Box>
  );
};

const styles = {
  labelsWithMargin: {
    mb: 2,
    mt: 2
  },
  siteInformationValues: {
    ml: 1
  }
};

export default SiteInformation;
