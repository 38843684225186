import { Box, Button, Container, Grid, Paper, TextField, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { useCreateAppMutation } from 'services/aiphoneCloud';
import { RootState } from 'store';

interface IAddAppsProps {
  handleNextStep: () => void;
  handlePreviousStep: () => void;
}

const AddApps: React.FC<IAddAppsProps> = ({ handlePreviousStep, handleNextStep }: IAddAppsProps) => {
  const unitList = useSelector((state: RootState) => state.units.UnitList);
  const unitListByType = useSelector((state: RootState) => state.units.UnitListByType);
  const [numberOfApps, setNumberOfApps] = React.useState<number>(0);
  const [createApps] = useCreateAppMutation();

  const handleAddApps = () => {
    let targetUnit;
    if (unitListByType.Residential.length > 0) {
      targetUnit = unitList[unitListByType.Residential[0]];
    } else {
      targetUnit = unitList[unitListByType.Commercial[0]];
    }
    for (let i = 0; i < numberOfApps; i++) {
      const params = {
        appData: {
          stationNumber: `999${i + 1}`,
          stationName: `MobileApp${i + 1}`,
          unitPublicId: targetUnit.publicId
        }
      };
      createApps(params);
    }
    handleNextStep();
  };

  return (
    <Container maxWidth="md">
      <Paper elevation={1} sx={styles.paper}>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box component="img" src="https://placehold.co/500x300" alt="apps" sx={styles.imageContainer} />
            </Grid>
            <Grid item xs={4} sm container>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column'
                }}
              >
                <Box sx={{ mb: 3 }}>
                  <Typography variant="body1">Enter a number of mobile apps to add to the site:</Typography>
                </Box>
                <Box width={'100%'}>
                  <TextField
                    label="Number of Apps"
                    type="number"
                    value={numberOfApps}
                    onChange={(event) => {
                      setNumberOfApps(parseInt(event.target.value));
                      if (parseInt(event.target.value) > 8) {
                        setNumberOfApps(8);
                      }
                    }}
                    fullWidth
                    InputProps={{
                      inputProps: {
                        min: 0,
                        max: 8
                      }
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Box sx={styles.dualButtonContainer}>
        <Button variant="contained" color="primary" onClick={handlePreviousStep}>
          Back
        </Button>

        <Button variant="contained" color="primary" type="submit" onClick={handleAddApps}>
          Continue
        </Button>
      </Box>
    </Container>
  );
};

const styles = {
  imageContainer: {
    width: '100%',
    borderRadius: 1
  },
  dualButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
    flexDirection: 'row',
    marginTop: '1rem'
  },
  paper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    p: 3,
    px: 5,
    mb: 4,
    width: '100%',
    height: '23rem'
  }
};

export default AddApps;
