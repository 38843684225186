/* Syslog Settings tab stub */
export const SyslogSettingsLabel = () => {
  return <span>Syslog Settings</span>;
};

const SyslogSettings = () => {
  return <span>Syslog Settings</span>;
};

export default SyslogSettings;
