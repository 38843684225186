import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from '../index';
import { getSiteById } from 'shared/api/Aws/awsApi';
import { updateSiteJson } from 'features/RemoteManagement/SiteDashboard/utils';

// Define a type for the slice state
interface SiteState {
  siteInfo: ISiteInfo;
  applicationType: string;
  Loading: boolean;
  Error: string | undefined;
  StatusCode: number;
}

// Define the initial state using that type
const initialState: SiteState = {
  siteInfo: {
    awsPropertyId: '',
    cloudOrganizationPublicId: '',
    constructionCompanyName: '',
    constructionEmailAddress: '',
    constructionPhoneNumber: '',
    constructionStaffName: '',
    createdBy: '',
    createdOn: '',
    isWizardCompleted: false,
    lastUpdatedBy: '',
    lastUpdatedOn: '',
    managementCompanyName: '',
    managementEmailAddress: '',
    managementPhoneNumber: '',
    managementStaffName: '',
    multiBuilding: false,
    publicId: '',
    registeredGatewayPublicId: '',
    siteAddress: '',
    siteAddress2: '',
    siteCity: '',
    siteName: '',
    sitePhoneNumber: '',
    stateId: '',
    siteZip: '',
    statusId: 0,
    systemId: '',
    systemPassword: '',
    typeId: 1
  },
  applicationType: '',
  Loading: false,
  Error: undefined,
  StatusCode: 0
};

interface ISiteInfo {
  awsPropertyId: string;
  cloudOrganizationPublicId: string;
  constructionCompanyName: string;
  constructionEmailAddress: string;
  constructionPhoneNumber: string;
  constructionStaffName: string;
  createdBy: string;
  createdOn: string;
  isWizardCompleted: boolean;
  lastUpdatedBy: string;
  lastUpdatedOn: string;
  managementCompanyName: string;
  managementEmailAddress: string;
  managementPhoneNumber: string;
  managementStaffName: string;
  multiBuilding: boolean;
  publicId: string;
  registeredGatewayPublicId: string;
  siteAddress: string;
  siteAddress2: string;
  siteCity: string;
  stateId: string;
  siteName: string;
  sitePhoneNumber: string;
  siteZip: string;
  statusId: number;
  systemId: string;
  systemPassword: string;
  typeId: number;
}

export const fetchSiteData = createAsyncThunk(
  'site/fetchSiteData',
  async ({ sitePublicId }: { sitePublicId: string }, thunkAPI) => {
    try {
      const response = await getSiteById({ sitePublicId: sitePublicId });
      if (response !== undefined) {
        if (response.status !== 200) {
          return thunkAPI.rejectWithValue(response);
        }
        if (response.data.rows.length === 0) {
          return thunkAPI.rejectWithValue(response);
        }
        if (response.data.rows.length > 1) {
          return thunkAPI.rejectWithValue(response);
        }
        if (response.data.rows.length === 1) {
          const siteData = response.data.rows[0];
          return siteData;
        }
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateJsonForSite = createAsyncThunk('site/updateJsonForSite', async () => {
  await updateSiteJson();
});

export const siteSlice = createSlice({
  name: 'siteInfo',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    addSite: (state, action) => {
      state.siteInfo = action.payload;
      state.Loading = false;
    },
    updateSite: (state, action) => {
      state.siteInfo = { ...state.siteInfo, ...action.payload };
    },
    setSiteData: (state, action) => {
      state.siteInfo = action.payload;
      state.Loading = false;
    },
    setSiteLoading: (state, action) => {
      state.Loading = action.payload;
    },
    setIsWizardComplete: (state, action) => {
      state.siteInfo.isWizardCompleted = action.payload;
    },
    addAwsPropertyId: (state, action) => {
      state.siteInfo = { ...state.siteInfo, ...action.payload };
    },
    completeWizard: (state) => {
      state.siteInfo.isWizardCompleted = true;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSiteData.pending, (state) => {
        state.Loading = true;
      })
      .addCase(fetchSiteData.fulfilled, (state, action) => {
        state.siteInfo = action.payload;
        state.Loading = false;
        state.Error = undefined;
      })
      .addCase(fetchSiteData.rejected, (state, action) => {
        state.Loading = false;
        state.siteInfo.publicId = action.meta.arg.sitePublicId;
        state.Error = 'Error fetching site data';
        state.StatusCode = 500;
      })
      // Handle updating site JSON
      .addCase(updateJsonForSite.pending, (state) => {
        state.Loading = true;
      })
      .addCase(updateJsonForSite.fulfilled, (state) => {
        state.Loading = false;
        // Update the site JSON with the data you passed to updateJsonForSite
        // state.siteJson = action.meta.arg.siteJson; // use meta.arg to access original arguments
      })
      .addCase(updateJsonForSite.rejected, (state, action) => {
        state.Loading = false;
        state.Error = action.error.message ?? state.Error;
      });
  }
});

export const { addSite, updateSite, setSiteLoading, setIsWizardComplete, addAwsPropertyId, setSiteData } =
  siteSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getSite = (state: RootState) => {
  return state.site;
};
export const completeWizardSelector = (state: RootState) => state.site.siteInfo.isWizardCompleted;
export const selectSite = (state: RootState) => state.site;
export const siteReducer = siteSlice.reducer;
