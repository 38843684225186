import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  MenuItem,
  Select
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { IDevice } from 'store/slices/devicesSlice';
import { Field, Form, Formik } from 'formik';
import { useCreateAppMutation } from 'services/aiphoneCloud';
import React from 'react';

interface Props {
  isAddMobileDevicesOpen: boolean;
  setIsAddMobileDevicesOpen: (isAddMobileDevicesOpen: boolean) => void;
  selectedUnit: Unit | null;
  units: Unit[];
  setUnits: (units: Unit[]) => void;
}

interface Unit {
  unitPublicId: string;
  unitNumber: string;
  unitName: string;
  unitType: number;
  devices: IDevice[];
  numberOfApps: number;
}

const AddMobileDeviceToUnitDialog = ({
  isAddMobileDevicesOpen,
  setIsAddMobileDevicesOpen,
  selectedUnit,
  units,
  setUnits
}: Props) => {
  const [createApp] = useCreateAppMutation();
  const [numberOfApps, setNumberOfApps] = React.useState<number>(0);

  const handleAddApps = () => {
    for (let i = 0; i < numberOfApps; i++) {
      const params = {
        appData: {
          stationNumber: `999${i + 1}`,
          stationName: `MobileApp${i + 1}`,
          unitPublicId: selectedUnit?.unitPublicId
        }
      };
      createApp(params);
    }
    setIsAddMobileDevicesOpen(false);
  };

  return (
    <Dialog open={isAddMobileDevicesOpen} onClose={() => setIsAddMobileDevicesOpen(false)} maxWidth="md" fullWidth>
      <DialogTitle>Add Mobile Apps to Unit {selectedUnit?.unitNumber}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => setIsAddMobileDevicesOpen(false)}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500]
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <DialogContentText>
          Up to 8 mobile devices can be added to each unit. Mobile devices will incur an extra charge.
        </DialogContentText>
        <DialogContentText>How many mobile devices would you like to add to this unit?</DialogContentText>
        <Box>
          <Select
            label="Number of Mobile Devices"
            name="numberOfApps"
            variant="outlined"
            fullWidth
            value={numberOfApps}
            onChange={(e) => setNumberOfApps(e.target.value)}
          >
            <MenuItem value={0}>0</MenuItem>
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={6}>6</MenuItem>
            <MenuItem value={7}>7</MenuItem>
            <MenuItem value={8}>8</MenuItem>
          </Select>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsAddMobileDevicesOpen(false)}>Close</Button>
        <Button onClick={handleAddApps}>Add Mobile Apps to Unit</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddMobileDeviceToUnitDialog;
