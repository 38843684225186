import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Grid } from '@mui/material';
import InfoScreen from '../../../layouts/InfoScreen';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

export const ProfileSuccessfullyVerified: React.FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <InfoScreen
        title={'Success!'}
        message={
          'Thank you for registering your account on AiphoneCloud! Please log in to access the QuikSpec design tool and extend the features of Aiphone IP intercom systems.'
        }
        iconComponent={<CheckCircleRoundedIcon sx={{ fontSize: '48px', color: 'success.main' }} />}
      />
      <Grid container justifyContent="center">
        <Grid item>
          <Button sx={styles.returnToLogin} onClick={() => navigate('/auth/login')}>
            Return to login
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  returnToLogin: {
    color: '#0071CE',
    fontFamily: "'Roboto Condensed', sans-serif",
    fontWeight: 'bold',
    marginTop: 2
  }
};
