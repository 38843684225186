import { Container, Paper, Tab } from '@mui/material';
import { useState } from 'react';
import UsersDataGrid from './UsersDataGrid';
import RegisteredUsersDataGrid from './RegisteredUsersDataGrid';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import AiphoneUsersDataGrid from './AiphoneUsersDataGrid';
import { useSelector } from 'react-redux';
import { getCurrentUser } from 'store/slices/usersSlice';

const Users = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const currentUser = useSelector(getCurrentUser);
  const hasGlobalRole = currentUser ? currentUser.permissions.global !== null : false;

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <Container maxWidth="xl">
      <Paper sx={styles.usersPanel}>
        {hasGlobalRole && (
          <TabContext value={currentTab.toString()}>
            <TabList onChange={handleTabChange}>
              <Tab label="Branch Users" value="0" />
              <Tab label="Registered Users" value="1" />
              <Tab label="Aiphone Users" value="2" />
            </TabList>
            <TabPanel value="0">
              <UsersDataGrid />
            </TabPanel>
            <TabPanel value="1">
              <RegisteredUsersDataGrid />
            </TabPanel>
            <TabPanel value="2">
              <AiphoneUsersDataGrid />
            </TabPanel>
          </TabContext>
        )}
        {!hasGlobalRole && <UsersDataGrid />}
      </Paper>
    </Container>
  );
};

const styles = {
  devButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    padding: '20px'
  },
  usersPanel: {
    mt: 2
  },
  usersButtonContainer: {
    display: 'flex',
    float: 'right',
    padding: '20px'
  }
};

export default Users;
