import { useDispatch, useSelector } from 'react-redux';
import { getString } from 'shared/utils/LocalizationUtils';
import StringUtils from 'shared/utils/StringUtils';
import { getSelectedDevice, updateSelectedDevice } from 'store/slices/devicesSlice';
import containerStyle from 'shared/styles/advancedSettingContainerStyle';
import { useUpdateDeviceMutation } from 'services/aiphoneCloud';
import * as Yup from 'yup';
import SnackbarAlert from 'shared/components/SnackbarAlert';
import { useState } from 'react';
import { Form, Formik, Field } from 'formik';
import { Box, Button, Checkbox, Grid, MenuItem, Select, TextField } from '@mui/material';
import PasswordField from 'shared/components/PasswordField';

/* ID & Password tab stub */
export const IDPasswordLabel = () => {
  return <span>ID & Password</span>;
};

const IDPassword = () => {
  const dispatch = useDispatch();
  const [updateDevice, { isLoading: isUpdating }] = useUpdateDeviceMutation();
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const selectedDevice = useSelector(getSelectedDevice);
  const digitString = getString('Digit');
  const lowerCaseString = getString('LowerCase');
  const upperCaseString = getString('UpperCase');
  const missingItemError = getString('Password_Error_MissingItem');
  const userIdTitle = getString('AdvancedSettings_UserId_Title');
  const userPassTitle = getString('AdvancedSettings_UserPass_Title');
  const adminIdTitle = getString('AdvancedSettings_AdminId_Title');
  const adminPassTitle = getString('AdvancedSettings_AdminPass_Title');
  const passWordMatchError = getString('Password_Error_Confirm_Match');
  const onvifIdTitle = getString('AdvancedSettings_OnvifId_Title');
  const onvifPassTitle = getString('AdvancedSettings_OnvifPass_Title');
  const rtspIdTitle = getString('AdvancedSettings_RtspId_Title');
  const rtspPassTitle = getString('AdvancedSettings_RtspPass_Title');

  const formDevice = {
    userId: selectedDevice.basicInfo.userId,
    userPass: selectedDevice.basicInfo.userPass,
    userPassConfirm: selectedDevice.basicInfo.userPass,
    adminId: selectedDevice.basicInfo.adminId,
    adminPass: selectedDevice.basicInfo.adminPass,
    adminPassConfirm: selectedDevice.basicInfo.adminPass,
    onvifId: selectedDevice.basicInfo.onvifId,
    onvifPass: selectedDevice.basicInfo.onvifPass,
    onvifPassConfirm: selectedDevice.basicInfo.onvifPass,
    rtspId: selectedDevice.basicInfo.rtspId,
    rtspPass: selectedDevice.basicInfo.rtspPass,
    rtspPassConfirm: selectedDevice.basicInfo.rtspPass
  };

  const getValidationSchema = () => {
    const basicInfoSchema: any = {};

    if (formDevice.userId !== null && formDevice.userId !== undefined) {
      const userIdMaxLen = 15;

      basicInfoSchema.userId = Yup.string().max(
        userIdMaxLen,
        StringUtils.format(getString('Field_Error_MaxLen'), userIdTitle, userIdMaxLen)
      );
    }

    if (formDevice.userPass !== null && formDevice.userPass !== undefined) {
      const userPassMaxLen = 15;

      basicInfoSchema.userPassConfirm = Yup.string()
        .max(userPassMaxLen, StringUtils.format(getString('Field_Error_MaxLen'), userPassTitle, userPassMaxLen))
        .matches(/[0-9]/, StringUtils.format(missingItemError, digitString))
        .matches(/[a-z]/, StringUtils.format(missingItemError, lowerCaseString))
        .matches(/[A-Z]/, StringUtils.format(missingItemError, upperCaseString))
        .oneOf([Yup.ref('userPass')], passWordMatchError);
    }

    if (formDevice.adminId !== null && formDevice.adminId !== undefined) {
      const adminIdMaxLen = 15;
      basicInfoSchema.adminId = Yup.string().max(
        adminIdMaxLen,
        StringUtils.format(getString('Field_Error_MaxLen'), adminIdTitle, adminIdMaxLen)
      );
    }

    if (formDevice.adminPass !== null && formDevice.adminPass !== undefined) {
      const adminPassMaxLen = 15;

      basicInfoSchema.adminPassConfirm = Yup.string()
        .max(adminPassMaxLen, StringUtils.format(getString('Field_Error_MaxLen'), adminPassTitle, adminPassMaxLen))
        .matches(/[0-9]/, StringUtils.format(missingItemError, digitString))
        .matches(/[a-z]/, StringUtils.format(missingItemError, lowerCaseString))
        .matches(/[A-Z]/, StringUtils.format(missingItemError, upperCaseString))
        .oneOf([Yup.ref('adminPass')], passWordMatchError);
    }

    if (formDevice.onvifId !== null && formDevice.onvifId !== undefined) {
      const onvifIdMaxLen = 15;

      basicInfoSchema.onvifId = Yup.string().max(
        onvifIdMaxLen,
        StringUtils.format(getString('Field_Error_MaxLen'), onvifIdTitle, onvifIdMaxLen)
      );
    }

    if (formDevice.onvifPass !== null && formDevice.onvifPass !== undefined) {
      const onvifPassMaxLen = 15;

      basicInfoSchema.onvifPassConfirm = Yup.string()
        .max(onvifPassMaxLen, StringUtils.format(getString('Field_Error_MaxLen'), onvifPassTitle, onvifPassMaxLen))
        .matches(/[0-9]/, StringUtils.format(missingItemError, digitString))
        .matches(/[a-z]/, StringUtils.format(missingItemError, lowerCaseString))
        .matches(/[A-Z]/, StringUtils.format(missingItemError, upperCaseString))
        .oneOf([Yup.ref('onvifPass')], passWordMatchError);
    }

    if (formDevice.rtspId !== null && formDevice.rtspId !== undefined) {
      const rtspIdMaxLen = 15;
      basicInfoSchema.rtspId = Yup.string().max(
        rtspIdMaxLen,
        StringUtils.format(getString('Field_Error_MaxLen'), rtspIdTitle, rtspIdMaxLen)
      );
    }

    if (formDevice.rtspPass !== null && formDevice.rtspPass !== undefined) {
      const rtspPassMaxLen = 15;

      basicInfoSchema.rtspPassConfirm = Yup.string()
        .max(rtspPassMaxLen, StringUtils.format(getString('Field_Error_MaxLen'), rtspPassTitle, rtspPassMaxLen))
        .matches(/[0-9]/, StringUtils.format(missingItemError, digitString))
        .matches(/[a-z]/, StringUtils.format(missingItemError, lowerCaseString))
        .matches(/[A-Z]/, StringUtils.format(missingItemError, upperCaseString))
        .oneOf([Yup.ref('rtspPass')], passWordMatchError);
    }

    return Yup.object(basicInfoSchema);
  };

  const onSubmit = async (values: any, actions: any) => {
    const params = {
      device: {
        publicId: selectedDevice.publicId,
        basicInfo: values
      }
    };

    const newDevice = JSON.parse(JSON.stringify(selectedDevice));

    newDevice.basicInfo = {
      ...newDevice.basicInfo,
      ...values
    };

    updateDevice(params)
      .then((response) => {
        if ('error' in response) {
          throw response.error;
        }

        dispatch(updateSelectedDevice({ device: newDevice }));
        setShowAlert(true);
        actions.resetForm({
          values: values
        });
      })
      .catch(() => {
        setErrorMessage(getString('AdvancedSettings_Error_UpdateDevice'));
      });
  };

  return (
    <Box sx={containerStyle.settingsPageContainer}>
      <SnackbarAlert
        type="error"
        time={10000}
        text={`${errorMessage}`}
        isOpen={!!errorMessage}
        onClose={() => setErrorMessage(null)}
      />
      <SnackbarAlert
        type="success"
        time={3000}
        text={getString('AdvancedSettings_Success_UpdateDevice')}
        isOpen={showAlert}
        onClose={() => setShowAlert(false)}
      />
      <Formik initialValues={formDevice} onSubmit={onSubmit} validationSchema={getValidationSchema()}>
        {({ dirty, touched, errors, isSubmitting }) => (
          <Form style={containerStyle.form}>
            <Box sx={containerStyle.gridContainer}>
              {formDevice.userId !== null && formDevice.userId !== undefined ? (
                <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                  <Grid item xs={5} lg={7}>
                    <Box sx={containerStyle.itemTitle}>{userIdTitle}</Box>
                    <Box sx={containerStyle.itemDesc}>{getString('AdvancedSettings_UserId_Desc')}</Box>
                  </Grid>
                  <Grid item xs={6} lg={4} justifyContent="flex-end">
                    <Box sx={containerStyle.fieldContainer}>
                      <Field
                        as={TextField}
                        label={userIdTitle}
                        name="userId"
                        size="small"
                        style={containerStyle.textField}
                        helperText={touched?.userId && errors?.userId}
                      />
                    </Box>
                  </Grid>
                </Grid>
              ) : null}
              {formDevice.userPass !== null && formDevice.userPass !== undefined ? (
                <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                  <Grid item xs={5} lg={7}>
                    <Box sx={containerStyle.itemTitle}>{userPassTitle}</Box>
                    <Box sx={containerStyle.itemDesc}>{getString('AdvancedSettings_UserPass_Desc')}</Box>
                  </Grid>
                  <Grid item xs={6} lg={4} justifyContent="flex-end">
                    <Box sx={containerStyle.fieldContainer}>
                      <Box>
                        <Field
                          as={PasswordField}
                          label={userPassTitle}
                          name="userPass"
                          size="small"
                          style={containerStyle.textField}
                          helperText={touched.userPass && errors.userPass}
                        />
                      </Box>
                      <Box>
                        <Field
                          as={PasswordField}
                          label={getString('Confirm_Password_Title')}
                          name="userPassConfirm"
                          size="small"
                          style={containerStyle.textField}
                          helperText={touched.userPassConfirm && errors.userPassConfirm}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              ) : null}
              {formDevice.adminId !== null && formDevice.adminId !== undefined ? (
                <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                  <Grid item xs={5} lg={7}>
                    <Box sx={containerStyle.itemTitle}>{adminIdTitle}</Box>
                    <Box sx={containerStyle.itemDesc}>{getString('AdvancedSettings_AdminId_Desc')}</Box>
                  </Grid>
                  <Grid item xs={6} lg={4} justifyContent="flex-end">
                    <Box sx={containerStyle.fieldContainer}>
                      <Field
                        as={TextField}
                        label={adminIdTitle}
                        name="adminId"
                        size="small"
                        style={containerStyle.textField}
                        helperText={touched.adminId && errors.adminId}
                      />
                    </Box>
                  </Grid>
                </Grid>
              ) : null}
              {formDevice.adminPass !== null && formDevice.adminPass !== undefined ? (
                <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                  <Grid item xs={5} lg={7}>
                    <Box sx={containerStyle.itemTitle}>{adminPassTitle}</Box>
                    <Box sx={containerStyle.itemDesc}>{getString('AdvancedSettings_AdminPass_Desc')}</Box>
                  </Grid>
                  <Grid item xs={6} lg={4} justifyContent="flex-end">
                    <Box sx={containerStyle.fieldContainer}>
                      <Box>
                        <Field
                          as={PasswordField}
                          label={adminPassTitle}
                          name="adminPass"
                          size="small"
                          style={containerStyle.textField}
                          helperText={touched.adminPass && errors.adminPass}
                        />
                      </Box>
                      <Box>
                        <Field
                          as={PasswordField}
                          label={getString('Confirm_Password_Title')}
                          name="adminPassConfirm"
                          size="small"
                          style={containerStyle.textField}
                          helperText={touched.adminPassConfirm && errors.adminPassConfirm}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              ) : null}
              {formDevice.onvifId !== null && formDevice.onvifId !== undefined ? (
                <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                  <Grid item xs={5} lg={7}>
                    <Box sx={containerStyle.itemTitle}>{onvifIdTitle}</Box>
                    <Box sx={containerStyle.itemDesc}>{getString('AdvancedSettings_OnvifId_Desc')}</Box>
                  </Grid>
                  <Grid item xs={6} lg={4} justifyContent="flex-end">
                    <Box sx={containerStyle.fieldContainer}>
                      <Field
                        as={TextField}
                        label={onvifIdTitle}
                        name="onvifId"
                        size="small"
                        style={containerStyle.textField}
                        helperText={touched.onvifId && errors.onvifId}
                      />
                    </Box>
                  </Grid>
                </Grid>
              ) : null}
              {formDevice.onvifPass !== null && formDevice.onvifPass !== undefined ? (
                <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                  <Grid item xs={5} lg={7}>
                    <Box sx={containerStyle.itemTitle}>{onvifPassTitle}</Box>
                    <Box sx={containerStyle.itemDesc}>{getString('AdvancedSettings_OnvifPass_Desc')}</Box>
                  </Grid>
                  <Grid item xs={6} lg={4} justifyContent="flex-end">
                    <Box sx={containerStyle.fieldContainer}>
                      <Box>
                        <Field
                          as={PasswordField}
                          label={onvifPassTitle}
                          name="onvifPass"
                          size="small"
                          style={containerStyle.textField}
                          helperText={touched.onvifPass && errors.onvifPass}
                        />
                      </Box>
                      <Box>
                        <Field
                          as={PasswordField}
                          label={getString('Confirm_Password_Title')}
                          name="onvifPassConfirm"
                          size="small"
                          style={containerStyle.textField}
                          helperText={touched.onvifPassConfirm && errors.onvifPassConfirm}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              ) : null}
              {formDevice.rtspId !== null && formDevice.rtspId !== undefined ? (
                <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                  <Grid item xs={5} lg={7}>
                    <Box sx={containerStyle.itemTitle}>{rtspIdTitle}</Box>
                    <Box sx={containerStyle.itemDesc}>{getString('AdvancedSettings_RtspId_Desc')}</Box>
                  </Grid>
                  <Grid item xs={6} lg={4} justifyContent="flex-end">
                    <Box sx={containerStyle.fieldContainer}>
                      <Field
                        as={TextField}
                        label={rtspIdTitle}
                        name="rtspId"
                        size="small"
                        style={containerStyle.textField}
                        helperText={touched.rtspId && errors.rtspId}
                      />
                    </Box>
                  </Grid>
                </Grid>
              ) : null}
              {formDevice.rtspPass !== null && formDevice.rtspPass !== undefined ? (
                <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                  <Grid item xs={5} lg={7}>
                    <Box sx={containerStyle.itemTitle}>{rtspPassTitle}</Box>
                    <Box sx={containerStyle.itemDesc}>{getString('AdvancedSettings_RtspPass_Desc')}</Box>
                  </Grid>
                  <Grid item xs={6} lg={4} justifyContent="flex-end">
                    <Box sx={containerStyle.fieldContainer}>
                      <Box>
                        <Field
                          as={PasswordField}
                          label={rtspPassTitle}
                          name="rtspPass"
                          size="small"
                          style={containerStyle.textField}
                          helperText={touched.rtspPass && errors.rtspPass}
                        />
                      </Box>
                      <Box>
                        <Field
                          as={PasswordField}
                          label={getString('Confirm_Password_Title')}
                          name="rtspPassConfirm"
                          size="small"
                          style={containerStyle.textField}
                          helperText={touched.rtspPassConfirm && errors.rtspPassConfirm}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              ) : null}
            </Box>
            <Box sx={containerStyle.submitContainer}>
              <Button
                type="reset"
                variant="contained"
                color="primary"
                disabled={!dirty || isSubmitting || isUpdating}
                style={containerStyle.submitBarButton}
              >
                {getString('Button_Reset')}
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!dirty || isSubmitting || isUpdating}
                style={containerStyle.submitBarButton}
              >
                {getString('Button_Submit')}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default IDPassword;
