/* currently the qrCode is using dummy data
 * qrCode = 'o@3$AS$I$p{W(Gq)1PHKx-`Q'
 */
import { useSelector } from 'react-redux';
import { Box, Button, Paper } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { RootState } from 'store';
import MobileAppCard from './MobileAppCard';
import { useParams } from 'react-router-dom';

const MobileApps = () => {
  const unitPublicId = useParams().unitid || '';
  const unit = useSelector((state: RootState) => state.units.UnitList[unitPublicId]);
  const unitAppList = useSelector((state: RootState) => state.apps.AppGroup[unitPublicId]);
  const building = useSelector((state: RootState) => state.buildings.BuildingList[unit.buildingPublicId]);
  const appList = useSelector((state: RootState) => state.apps.AppList);

  const openAddAppDialog = () => {
    //App only available to Guard, Residential and Commerical Unit
  };

  const handleDeleteApp = (appId: string) => {
    console.log('app delete', appId);
  };

  return (
    <Paper sx={styles.main}>
      <Box>
        <Button startIcon={<AddIcon />} onClick={openAddAppDialog} variant="contained" color="primary">
          {`Add App`}
        </Button>
      </Box>
      <Box>
        {unitAppList.length > 0 &&
          unitAppList.map((app) => {
            return (
              <MobileAppCard
                id={appList[app].publicId}
                appName={appList[app].stationName}
                appNumber={appList[app].stationNumber}
                buildingId={'01'}
                unitNumber={unit.unitNumber}
                qrCode="o@3$AS$I$p{W(Gq)1PHKx-`Q"
                handleDeleteApp={handleDeleteApp}
              />
            );
          })}
      </Box>
    </Paper>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  main: {
    padding: '20px'
  },
  alert: {
    pb: '20px'
  }
};

export default MobileApps;
