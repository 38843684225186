import { Box, Fab } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet, useLocation, useParams } from 'react-router-dom';
import { AppDispatch, RootState } from 'store';
import { setSiteData, setSiteLoading } from 'store/slices/siteSlice';
import RemoteManagementSideBar from 'layouts/RemoteManagementSideBar';
import DeviceSideBar from 'layouts/DeviceSideBar';
import UnitSideBar from 'layouts/UnitSideBar';
import {
  useGetSiteWithPublicIdQuery,
  useGetDeviceListWithSitePublicIdQuery,
  useGetBuildingsQuery,
  useLazyGetUnitListWithBuildingPublicIdQuery,
  useGetAppWithSiteIdQuery
} from 'services/aiphoneCloud';
import { setSelectedDevice } from 'store/slices/devicesSlice';

const selectSite = (state: RootState) => state.site;

/**
 * There has to be a better way of doing this. This component is loading the site data, device data, building data, and unit data.
 *
 * If anyone has a better way of doing this, please let me know.
 *
 */

const SiteLoader = () => {
  const [, setBuildingPublicId] = useState('');
  const [collapsed] = useState(false);
  const [toggled] = useState(false);
  const location = useLocation();
  const site = useSelector(selectSite);
  const params = useParams();
  const dispatch: AppDispatch = useDispatch();
  const [getUnits] = useLazyGetUnitListWithBuildingPublicIdQuery();
  const { data: siteData, error: siteDataError, isLoading } = useGetSiteWithPublicIdQuery(params.id || '');
  const { error: deviceDataError } = useGetDeviceListWithSitePublicIdQuery(
    {
      sitePublicId: params.id || '',
      //TODO: qty to -1 after 24.6.1 release
      qty: 500,
      page: 0
    },
    {
      skip: !params.id
    }
  );
  const { data: buildingData, isSuccess: buildingDataSuccess } = useGetBuildingsQuery(params.id || '', {
    skip: !params.id
  });
  const { data: appData, isSuccess: appDataSuccess } = useGetAppWithSiteIdQuery(
    {
      sitePublicId: params.id || '',
      page: 0,
      qty: 500
    },
    {
      skip: !params.id
    }
  );

  useEffect(() => {
    if (buildingDataSuccess) {
      const buildingPublicId = Object.keys(buildingData)[0];
      getUnits(buildingPublicId);
      setBuildingPublicId(buildingPublicId);
    }
  }, [buildingDataSuccess, buildingData]);

  useEffect(() => {
    dispatch(setSiteLoading(isLoading));
    if (deviceDataError) {
      console.error('Error fetching devicesBasicInfoList:', deviceDataError);
    }

    if (siteData && !siteDataError) {
      dispatch(setSiteData(siteData));
    }

    if (params.deviceid) {
      dispatch(setSelectedDevice(params.deviceid));
    }
  }, [siteData, siteDataError, isLoading, deviceDataError, dispatch]);

  let SidebarToRender = RemoteManagementSideBar; // Default initialization

  if (/^\/site\/[a-zA-Z0-9-]+\/devices\/[a-zA-Z0-9-]+(\/[a-zA-Z0-9-]+)?$/.test(location.pathname)) {
    SidebarToRender = DeviceSideBar;
  } else if (/^\/site\/[a-zA-Z0-9-]+\/units\/[a-zA-Z0-9-]+(\/[a-zA-Z0-9-]+)?$/.test(location.pathname)) {
    SidebarToRender = UnitSideBar;
  }

  return (
    <>
      <SidebarToRender collapsed={collapsed} toggled={toggled} />
      <Box component={'main'} sx={styles.mainSection}>
        {site.Error && site.StatusCode === 404 ? (
          <Box>404 Site Not Found</Box>
        ) : site.Error ? (
          <Box>Oops! Something went wrong. Try the request again.</Box>
        ) : (
          <>
            <Outlet />
            {!site.siteInfo.isWizardCompleted && !location.pathname.endsWith('/wizard') && (
              <Link to={`${useParams().id}/wizard`} style={styles.link}>
                <Fab variant="extended" color="primary" aria-label="add" sx={styles.fab}>
                  Return to Setup Wizard
                </Fab>
              </Link>
            )}
          </>
        )}
      </Box>
    </>
  );
};

const styles = {
  container: {
    display: 'flex',
    bgcolor: 'neutral.light',
    height: 'calc(100% - 64px)',
    overflow: 'auto'
  },
  mainSection: {
    p: 4,
    width: '100%',
    height: '100%'
  },
  fab: {
    position: 'absolute',
    bottom: 16,
    right: 16,
    zIndex: 1
  },
  link: {
    textDecoration: 'none',
    color: 'white'
  }
};

export default SiteLoader;
