import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { LoadingButton } from '@mui/lab';
import { CA, US } from 'country-flag-icons/react/3x2';
import { Field, Form, Formik } from 'formik';
import { useCreateCompanyMutation } from 'services/aiphoneCloud';
import * as yup from 'yup';
import { EnumList, ICountryValue, IStateValue, fetchEnumList } from 'shared/utils/EnumUtils';
import { getString } from 'shared/utils/LocalizationUtils';
import { useEffect, useState } from 'react';

interface IAddCompanyDialogProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setIsError: (isError: boolean) => void;
  setErrorMessage: (errorMessage: string) => void;
  setIsSuccess: (isSuccess: boolean) => void;
  setSuccessMessage: (successMessage: string) => void;
}

const AddCompanyDialog = ({
  isOpen,
  setIsOpen,
  setIsError,
  setErrorMessage,
  setIsSuccess,
  setSuccessMessage
}: IAddCompanyDialogProps) => {
  const [createCompany, { isLoading: isCompanyLoading }] = useCreateCompanyMutation();
  const [enumList, setEnumList] = useState<EnumList>({ country: {}, state: {} });
  const selectCountryText = getString('Select_Country');
  const selectStateText = getString('Select_State');
  const companyDetails = getString('Enter_CompanyDetails');
  const cancelButton = getString('Button_CreateCompany_Cancel');
  const createCompanyButton = getString('Button_CreateCompany');
  const initialValues = {
    companyName: '',
    countryId: '',
    stateId: '',
    postalCode: ''
  };

  useEffect(() => {
    fetchEnumList().then((data) => {
      setEnumList(data);
    });
  }, []);

  const handleCreateCompany = async (values: typeof initialValues) => {
    const companyData = {
      name: values.companyName,
      stateId: values.stateId,
      postalCode: values.postalCode,
      type: 1,
      statusId: 1,
      dealerCompanyPublicId: null
    };

    createCompany({ companyData })
      .unwrap()
      .then((response) => {
        if (response.error) {
          setIsError(true);
          setErrorMessage(response.error.message);
          return;
        }
        setIsOpen(false);
        setIsSuccess(true);
        setSuccessMessage('Company created successfully');
      })
      .catch((error) => {
        setErrorMessage(JSON.parse(error.data)?.errorDetails || 'Unexpected error');
        setIsError(true);
      });
  };

  const validationSchema = yup.object().shape({
    companyName: yup.string().required('Company Name is required')
  });

  const getStateList = (countryId: string) => {
    const stateList = Object.keys(enumList.state)
      .map((key) => {
        const stateWalker = enumList.state[key] as IStateValue;
        // If the state is not in the selected country, do not add the option
        // Also do not include the unknown option
        if (stateWalker.countryId.toString() !== countryId || stateWalker.value === 'Unknown') {
          return null;
        }

        return (
          <MenuItem key={stateWalker.value} value={key}>
            {stateWalker.value}
          </MenuItem>
        );
      })
      .filter((val) => val !== null);

    if (stateList.length === 0) {
      stateList.push(
        <MenuItem key={selectCountryText} value="" disabled={true}>
          {selectCountryText}
        </MenuItem>
      );
    }

    return stateList;
  };

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      <Grid container spacing={1}>
        <Grid item xs={11}>
          <DialogTitle>{companyDetails}</DialogTitle>
        </Grid>
        <Grid item xs={1}>
          <IconButton color="default" aria-label="cancel" onClick={() => setIsOpen(false)}>
            <CancelIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Formik initialValues={initialValues} onSubmit={handleCreateCompany} validationSchema={validationSchema}>
        {({ handleChange, errors, touched, values, setFieldValue }) => (
          <Form>
            <DialogContent>
              <Box sx={styles.my2}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      label="Company Name"
                      name="companyName"
                      as={TextField}
                      error={errors.companyName && touched.companyName}
                      helperText={errors.companyName && touched.companyName ? errors.companyName : ''}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel id="country-label">{selectCountryText}</InputLabel>
                      <Field
                        name="countryId"
                        as={Select}
                        labelId="country-label"
                        label={selectCountryText}
                        onChange={(e: any) => {
                          handleChange(e);
                          setFieldValue('stateId', '');
                        }}
                        error={errors.countryId && touched.countryId}
                        helperText={errors.countryId && touched.countryId ? errors.countryId : ''}
                      >
                        {Object.keys(enumList.country).map((key) => {
                          const validCountries = ['CA', 'US'];
                          const countryWalker = enumList.country[key] as ICountryValue;

                          if (!validCountries.includes(countryWalker.alpha2Code)) {
                            return null;
                          }

                          const flagComponent: JSX.Element =
                            countryWalker.alpha2Code === 'CA' ? (
                              <CA title={countryWalker.defaultLanguageName} style={styles.flagStyle} />
                            ) : (
                              <US title={countryWalker.defaultLanguageName} style={styles.flagStyle} />
                            );

                          return (
                            <MenuItem key={key} value={key}>
                              <Grid sx={styles.field}>
                                {flagComponent}
                                {countryWalker.value}
                              </Grid>
                            </MenuItem>
                          );
                        })}
                      </Field>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel id="state-label">{selectStateText}</InputLabel>
                      <Field
                        name="stateId"
                        as={Select}
                        labelId="state-label"
                        label={selectStateText}
                        error={errors.stateId && touched.stateId}
                        helperText={errors.stateId && touched.stateId ? errors.stateId : ''}
                      >
                        {getStateList(values.countryId)}
                      </Field>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      fullWidth
                      label="Postal Code"
                      name="postalCode"
                      as={TextField}
                      error={errors.postalCode && touched.postalCode}
                      helperText={errors.postalCode && touched.postalCode ? errors.postalCode : ''}
                    />
                  </Grid>
                </Grid>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={() => setIsOpen(false)} color="primary">
                {cancelButton}
              </Button>
              <LoadingButton
                variant="contained"
                type="submit"
                color="primary"
                loading={isCompanyLoading}
                disabled={isCompanyLoading}
              >
                {createCompanyButton}
              </LoadingButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

const styles = {
  my2: {
    marginY: 2
  },
  field: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  flagStyle: {
    width: '30px',
    minWidth: '30px',
    height: '20px',
    paddingRight: '10px'
  }
};

export default AddCompanyDialog;
