/* Building Page tab stub */
export const BuildingPageLabel = () => {
  return <span>Building Page</span>;
};

const BuildingPage = () => {
  return <span>Building Page</span>;
};

export default BuildingPage;
