/* Incoming Call tab stub */
export const IncomingCallLabel = () => {
  return <span>Incoming Call</span>;
};

const IncomingCall = () => {
  return <span>Incoming Call</span>;
};

export default IncomingCall;
