import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Tab,
  Tabs
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeviceUnitCardGrid from '../components/DeviceUnitCardGrid';
import { IDevice, IDeviceTypes } from 'store/slices/devicesSlice';
import { DeviceCategories } from 'features/RemoteManagement/Types';
import { useState } from 'react';

interface Props {
  isAddDevicesOpen: boolean;
  setIsAddDevicesOpen: (isAddDevicesOpen: boolean) => void;
  selectedUnit: Unit | null;
  units: Unit[];
  setUnits: (units: Unit[]) => void;
  deviceGroups: IDeviceTypes;
}

interface Unit {
  unitPublicId: string;
  unitNumber: string;
  unitName: string;
  unitType: number;
  devices: IDevice[];
}

const AddDeviceToUnitDialog = ({
  isAddDevicesOpen,
  setIsAddDevicesOpen,
  selectedUnit,
  units,
  setUnits,
  deviceGroups
}: Props) => {
  const [hideAssignedDevices, setHideAssignedDevices] = useState(false);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  let assignedIndex = 0;

  const handleTabChange = (e: any, tabIndex: number) => {
    setCurrentTabIndex(tabIndex);
  };

  const addDeviceToUnit = (device: IDevice) => {
    const updatedUnits = [...units];
    updatedUnits.forEach((unit) => {
      if (unit.unitNumber === selectedUnit!.unitNumber) {
        unit.devices.push(device);
      }
    });
    setUnits(updatedUnits);
  };

  const removeDeviceFromUnit = (device: IDevice) => {
    const updatedUnits = [...units];
    updatedUnits.forEach((unit) => {
      if (unit.unitNumber === selectedUnit!.unitNumber) {
        unit.devices = unit.devices.filter((d) => d.basicInfo.macAddress !== device.basicInfo.macAddress);
      }
    });
    setUnits(updatedUnits);
  };

  return (
    <Dialog open={isAddDevicesOpen} onClose={() => setIsAddDevicesOpen(false)} maxWidth="xl" fullWidth>
      <DialogTitle>Add Devices to Unit {selectedUnit?.unitNumber}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => setIsAddDevicesOpen(false)}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500]
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <DialogContentText></DialogContentText>
        <Box sx={{ float: 'right' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={hideAssignedDevices}
                onChange={(event) => setHideAssignedDevices(event.target.checked)}
              />
            }
            label="Hide devices assigned to other Units"
          />
        </Box>

        <Tabs value={currentTabIndex} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
          {DeviceCategories.map((category, index) => {
            const deviceCount = deviceGroups[category.deviceGroup].length;
            if (!category.allowedUnitType.includes(selectedUnit?.unitType ?? 0)) {
              return null;
            }
            if (deviceCount === 0) {
              return null;
            }
            DeviceCategories[index].assignedIndex = assignedIndex;
            assignedIndex++;
            return (
              <Tab
                key={index}
                label={deviceCount > 0 ? `${category.deviceCategory} (${deviceCount})` : category.deviceCategory}
              />
            );
          })}
        </Tabs>
        {DeviceCategories.map((category) => {
          const deviceCount = deviceGroups[category.deviceGroup].length;

          if (
            currentTabIndex === category.assignedIndex &&
            deviceCount !== 0 &&
            category.allowedUnitType.includes(selectedUnit?.unitType ?? 0)
          ) {
            const filteredDevices = hideAssignedDevices
              ? deviceGroups[category.deviceGroup].filter(
                  (devicePublicId) =>
                    !units.some((unit) => {
                      if (unit.unitNumber === selectedUnit?.unitNumber) {
                        return false;
                      }
                      return unit.devices.some((d) => d.publicId.includes(devicePublicId));
                    })
                )
              : deviceGroups[category.deviceGroup];

            return (
              <DeviceUnitCardGrid
                units={units}
                selectedUnit={selectedUnit!}
                devices={filteredDevices}
                addDeviceToUnit={addDeviceToUnit}
                removeDeviceFromUnit={removeDeviceFromUnit}
              />
            );
          }
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsAddDevicesOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddDeviceToUnitDialog;
