import {
  Box,
  Typography,
  Checkbox,
  Button,
  FormGroup,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  Paper
} from '@mui/material';
import { Container } from '@mui/system';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useUpdateSiteMutation } from 'services/aiphoneCloud';
import { RootState } from 'store';
import { setSiteLoading, setIsWizardComplete } from 'store/slices/siteSlice';
interface Props {
  isChecked1: boolean;
  isChecked2: boolean;
  isChecked3: boolean;
  isChecked4: boolean;
  setIsChecked1: (isChecked: boolean) => void;
  setIsChecked2: (isChecked: boolean) => void;
  setIsChecked3: (isChecked: boolean) => void;
  setIsChecked4: (isChecked: boolean) => void;
  handleNextStep: () => void;
}

function Introduction({
  isChecked1,
  isChecked2,
  isChecked3,
  isChecked4,
  setIsChecked1,
  setIsChecked2,
  setIsChecked3,
  setIsChecked4,
  handleNextStep
}: Props) {
  const [isSkipDialogOpen, setIsSkipDialogOpen] = useState(false);
  const site = useSelector((state: RootState) => state.site);
  const [updateSite] = useUpdateSiteMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCheckboxChange = (checkboxNumber: number) => {
    switch (checkboxNumber) {
      case 1:
        setIsChecked1(!isChecked1);
        break;
      case 2:
        setIsChecked2(!isChecked2);
        break;
      case 3:
        setIsChecked3(!isChecked3);
        break;
      case 4:
        setIsChecked4(!isChecked4);
        break;
      default:
        break;
    }
  };

  const handleWizardSkip = async () => {
    const siteData = {
      ...site.siteInfo,
      isWizardCompleted: true
    };
    dispatch(setSiteLoading(true));
    try {
      updateSite({ siteData, sitePublicId: site.siteInfo.publicId }).then((result) => {
        if ('error' in result) {
          throw new Error('Error updating site');
        } else {
          dispatch(setSiteLoading(false));
          dispatch(setIsWizardComplete(true));
          navigate(`/site/${site.siteInfo.publicId}/siteinfo`);
        }
      });
    } catch (error) {
      console.error('Error updating site data:', error);
    }
  };

  const isButtonEnabled = isChecked1 && isChecked2 && isChecked3 && isChecked4;

  const strings = {
    title: 'New Site Wizard',
    body1:
      'Welcome to the Remote Management new site wizard! Before we get started, please refer to the checklist below to ensure you’re able to get your site up and running as quickly as possible.',
    check1: 'Your intercoms are connected to the same network as your IXGW-GW gateway.',
    check2: 'You have notated the station type and MAC address of each station.',
    check3:
      'You have a list of station IP addresses (if not using DHCP) and station names to be given to each station.',
    check4: 'I have read and understand the requirements for adding a new site to my account.',
    continueButtonText: 'Continue to Site Wizard',
    skipDialogHeader: 'Are you sure you want to skip the wizard?',
    skipDialogBody: 'Your system will not be fully functional until you complete the wizard and sync your devices.',
    cancelButtonText: 'Cancel',
    skipButtonText: 'Skip wizard'
  };

  return (
    <Container maxWidth="md">
      <Box>
        <Box sx={styles.centerContent}>
          <Typography variant="h4" sx={{ mb: 3, color: 'primary.main', fontWeight: 'bold' }}>
            {strings.title}
          </Typography>
          <Typography variant="body1" style={{ marginBottom: 20 }}>
            {strings.body1}
          </Typography>
        </Box>
        <Box sx={styles.centerContent}>
          <Box>
            <Paper sx={styles.checkboxContainer}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={isChecked1} onChange={() => handleCheckboxChange(1)} />}
                  label={strings.check1}
                  style={{ marginBottom: 10 }}
                />
                <FormControlLabel
                  control={<Checkbox checked={isChecked2} onChange={() => handleCheckboxChange(2)} />}
                  label={strings.check2}
                  style={{ marginBottom: 10 }}
                />
                <FormControlLabel
                  control={<Checkbox checked={isChecked3} onChange={() => handleCheckboxChange(3)} />}
                  label={strings.check3}
                  style={{ marginBottom: 10 }}
                />
                <FormControlLabel
                  control={<Checkbox checked={isChecked4} onChange={() => handleCheckboxChange(4)} />}
                  label={strings.check4}
                  style={{ marginBottom: 10 }}
                />
              </FormGroup>
            </Paper>
          </Box>
          <Button disabled={!isButtonEnabled} variant="contained" onClick={handleNextStep}>
            Continue to Site Wizard
          </Button>
          <Button onClick={() => setIsSkipDialogOpen(true)}>
            <Typography variant="caption">Skip wizard</Typography>
          </Button>
        </Box>
      </Box>
      <Dialog
        open={isSkipDialogOpen}
        onClose={() => setIsSkipDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{strings.skipDialogHeader}</DialogTitle>
        <DialogContent>
          {site.Loading ? (
            <Box sx={styles.centerContent}>
              <DialogContentText>Saving Site Data...</DialogContentText>
              <CircularProgress />
            </Box>
          ) : (
            <DialogContentText>{strings.skipDialogBody}</DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsSkipDialogOpen(false)}>{strings.cancelButtonText}</Button>
          <Button onClick={handleWizardSkip}>{strings.skipButtonText}</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  dialogContainer: {
    width: '100%',
    padding: '4rem'
  },
  marginBottom: {
    marginBottom: 10
  },
  dualButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    marginTop: 10
  },
  checkboxContainer: {
    p: 3,
    px: 5,
    mb: 4,
    width: '100%'
  }
};

export default Introduction;
