import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from 'shared/constantAwsApi';
import { getDeviceModelNumberFromModelType } from 'shared/utils/helperFunctions';
import { IDevice } from 'store/slices/devicesSlice';

interface Props {
  device: IDevice;
}

const DeviceCard = ({ device }: Props) => {
  const imageUrl = `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/icons/${getDeviceModelNumberFromModelType(
    device.basicInfo.deviceModel,
    device.basicInfo.deviceType
  )}.png`;

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography gutterBottom variant="h5" component="div">
              {device.basicInfo.macAddress}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <strong>Model Number: </strong>
              {getDeviceModelNumberFromModelType(device.basicInfo.deviceModel, device.basicInfo.deviceType)}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <strong>Station Name: </strong>
              {device.basicInfo.stationName}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <strong>Station Number: </strong>
              {device.basicInfo.stationNumber}
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Box component="img" src={imageUrl} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DeviceCard;
