import { Box, Button, Container, Grid, Paper, TextField, Typography } from '@mui/material';
import useConfigureWizardCommUnits from 'features/RemoteManagement/Hooks/useConfigureWizardCommUnits';
import useConfigureWizardMultiUnits from 'features/RemoteManagement/Hooks/useConfigureWizardMultiUnits';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useCreateUnitsWizardMutation } from 'services/aiphoneCloud';
import { RootState } from 'store';
import { selectBuildings } from 'store/slices/buildingsSlice';
import { selectDeviceTypes } from 'store/slices/devicesSlice';

interface Props {
  numberOfUnits: number;
  setNumberOfUnits: React.Dispatch<React.SetStateAction<number>>;
  handlePreviousStep: () => void;
  handleNextStep: () => void;
}

const strings = {
  title: 'Add Apartments/Tenant Units',
  body1:
    'Enter a number of apartment/tenant units to add to your site. Once you have entered the number of units, click continue to add the units to your site. Adding more units will be available after the completion of the setup wizard.',
  body2: 'Examples of these units include but are not limited to apartments, offices, hotel rooms, and more.',
  backButtonText: 'Back',
  continueButtonText: 'Continue'
};

const renderAddUnitsEntry = () => {
  return (
    <Box sx={styles.centerContent}>
      <Typography variant="h4" sx={{ mb: 3, color: 'primary.main', fontWeight: 'bold' }}>
        {strings.title}
      </Typography>
    </Box>
  );
};

const AddUnits = ({ numberOfUnits, setNumberOfUnits, handlePreviousStep, handleNextStep }: Props) => {
  const unitList = useSelector((state: RootState) => state.units.UnitList);
  const siteType = useSelector((state: RootState) => state.site.siteInfo.typeId);
  const devices = useSelector(selectDeviceTypes);
  const buildingList = useSelector(selectBuildings);
  const buildingPublicId = Object.keys(buildingList)[0];
  console.log(buildingPublicId);
  const configuringSystem = useRef(false);
  const [createUnitsWizard, { isUninitialized }] = useCreateUnitsWizardMutation();

  const handleAddUnits = async () => {
    const isConfiguringSystem = configuringSystem.current;

    const configureSystem = async () => {
      if (Object.keys(unitList).length === 0) {
        let params;
        if (siteType === 1) {
          params = await useConfigureWizardCommUnits(devices, buildingPublicId);
        } else {
          params = await useConfigureWizardMultiUnits(devices, buildingPublicId, numberOfUnits);
        }
        await createUnitsWizard(params);
        handleNextStep();
      } else {
        handleNextStep();
      }
    };

    if (isUninitialized) {
      configuringSystem.current = true;
      if (!isConfiguringSystem) {
        configureSystem();
      }
    }
  };

  if (siteType === 1) {
    handleAddUnits();
    return null;
  }

  return (
    <Container maxWidth="md">
      {renderAddUnitsEntry()}
      <Paper sx={styles.paper}>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box component="img" src="/Unit_500x300.jpg" alt="unit in building" sx={styles.imageContainer} />
            </Grid>
            <Grid item xs={4} sm container>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column'
                }}
              >
                <Box sx={{ mb: 3 }}>
                  <Typography variant="body1" sx={{ mb: 2 }}>
                    {strings.body1}
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 2 }}>
                    {strings.body2}
                  </Typography>
                </Box>
                <Box width={'100%'}>
                  <TextField
                    label="Number of Units"
                    type="number"
                    value={numberOfUnits}
                    onChange={(event) => setNumberOfUnits(parseInt(event.target.value))}
                    fullWidth
                    InputProps={{
                      inputProps: {
                        min: 1,
                        max: 9999
                      }
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Box sx={styles.dualButtonContainer}>
        <Button variant="contained" color="primary" onClick={handlePreviousStep}>
          {strings.backButtonText}
        </Button>

        <Button variant="contained" color="primary" type="submit" onClick={handleAddUnits}>
          {strings.continueButtonText}
        </Button>
      </Box>
    </Container>
  );
};

export default AddUnits;

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  dualButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
    flexDirection: 'row',
    marginTop: '1rem'
  },
  paper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    p: 3,
    px: 5,
    mb: 4,
    width: '100%',
    height: '23rem'
  },
  imageContainer: {
    width: '100%',
    borderRadius: 1
  }
};
