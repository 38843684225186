import React, { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowSelectionModel,
  GridRowsProp,
  GridToolbarContainer
} from '@mui/x-data-grid';
import DeleteUserDialog from './Dialogs/DeleteUserDialog';
import EditUserDialog from './Dialogs/EditUserDialog';
import { CloudUser } from 'store/slices/usersSlice';
import { useGetCompaniesQuery, useGetUsersWithBranchRolesQuery } from 'services/aiphoneCloud';
import { Add } from '@mui/icons-material';
import InviteUserDialog from './Dialogs/InviteUserDialog';
import SnackbarAlert from 'shared/components/SnackbarAlert';
import EditIcon from '@mui/icons-material/Edit';
import LockResetIcon from '@mui/icons-material/LockReset';
import ResetPasswordDialog from './Dialogs/ResetPasswordDialog';
import { useSelector } from 'react-redux';
import { getCurrentUser } from 'store/slices/usersSlice';
import { UserInfo } from 'shared/utils/UserUtils';
import { useGetUsersByBranchPublicIdQuery } from 'services/aiphoneCloud';
import { RootState } from 'store';

interface IGetUsersResponse {
  userList: { [key: string]: CloudUser };
  perPage: number;
  pageNumber: number;
  totalPages: number;
  totalUsers: number;
}

const UsersDataGrid = () => {
  const defaultPageSize = 50;

  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
  const [isInviteUserDialogOpen, setIsInviteUserDialogOpen] = useState(false);
  const [isEditUserDialogOpen, setIsEditUserDialogOpen] = useState(false);
  const [isDeleteUserDialogOpen, setIsDeleteUserDialogOpen] = useState(false);
  const [isResetPasswordDialogOpen, setIsResetPasswordDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<CloudUser>();
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [usersList, setUsersList] = useState<{ [key: string]: CloudUser }>({});
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [rowCount, setRowCount] = useState(0);

  const hasAdminRole = new UserInfo().isAdmin();
  const hasAiphoneRole = new UserInfo().isAiphone();
  const hasManagerRole = new UserInfo().isBranchManager();
  const currentUser: CloudUser | null = useSelector(getCurrentUser);

  let branchName = '';
  const firstBranchId = useSelector((state: RootState) => state.branches.Branches[0]);
  let companyName = '';
  let branchRole = '';

  if (currentUser?.permissions.branch) {
    branchName = currentUser.permissions.branch[firstBranchId].branchName;
    companyName = currentUser.permissions.branch[firstBranchId].companyName;
    branchRole = currentUser.permissions.branch[firstBranchId].roleList[0].roleName;
  }

  const fetchData = () => {
    return hasAiphoneRole
      ? useGetUsersWithBranchRolesQuery({
          qty: pageSize.toString(),
          page: page.toString()
        })
      : useGetUsersByBranchPublicIdQuery(
          {
            qty: pageSize.toString(),
            page: page.toString(),
            publicId: firstBranchId
          },
          { skip: !firstBranchId }
        );
  };

  /*Fetch users*/
  const { data, isFetching, error, refetch } = fetchData();
  const usersData: IGetUsersResponse = data as IGetUsersResponse;

  /*Fetch companies*/
  const { data: companies, isFetching: isCompaniesFetching } = useGetCompaniesQuery(
    {
      qty: '-1',
      page: '0'
    },
    { skip: !hasAiphoneRole }
  );
  const companyList = companies ? companies.companyList : {};

  /*HOOK Components*/
  useEffect(() => {
    if ((usersData || error) && isSuccess) {
      refetch();
    }
  }, [page, pageSize, isSuccess]);

  useEffect(() => {
    if (usersData?.userList) {
      setUsersList(usersData.userList);
      setRowCount(usersData.totalUsers);
    } else if (usersData) {
      setUsersList({});
    }
  }, [usersData]);
  useEffect(() => {
    if (error) {
      const errorData = JSON.parse((error as { data: string }).data);
      const errorCode = errorData.errorCode;
      if (errorCode === 'A0001') {
        setErrorMessage('Unauthorized to fetch users, please contact your administrator.');
      } else {
        setErrorMessage('Failed to fetch users');
      }
      setIsError(true);
    }
  }, [error]);

  /*Prep content for DataGrid*/
  const computeRows = (): GridRowsProp => {
    return Object.entries(usersList).map(([key, value]: [string, CloudUser]) => {
      return {
        id: key,
        firstName: value.firstName,
        lastName: value.lastName,
        email: value.email,
        role: value.permissions.branch[Object.keys(value.permissions.branch)[0]]?.roleList[0].roleName,
        companyName: value.permissions.branch[Object.keys(value.permissions.branch)[0]].companyName,
        branchName: value.permissions.branch[Object.keys(value.permissions.branch)[0]].branchName
      };
    });
  };

  const computeColumns = (): GridColDef[] => {
    return [
      { field: 'companyName', type: 'string', headerName: 'Company Name', flex: 4 },
      { field: 'branchName', type: 'string', headerName: 'Branch Name', flex: 4 },
      { field: 'firstName', type: 'string', headerName: 'First Name', flex: 2 },
      { field: 'lastName', type: 'string', headerName: 'Last Name', flex: 2 },
      { field: 'role', type: 'string', headerName: 'Role', flex: 2 },
      { field: 'email', type: 'string', headerName: 'Email', flex: 4 }
    ];
  };
  const UsersToolBar = () => {
    return (
      <GridToolbarContainer>
        {(hasAdminRole || hasAiphoneRole || hasManagerRole) && (
          <Box sx={styles.w100}>
            <Button onClick={() => setIsInviteUserDialogOpen(true)} disabled={isFetching} startIcon={<Add />}>
              Invite User
            </Button>
            {/* TODO: Implement hard delete user
            {hasAdminRole && (
              <Button
                onClick={() => setIsDeleteUserDialogOpen(true)}
                disabled={isFetching || selectionModel.length !== 1}
                startIcon={<DeleteForeverIcon />}
              >
                Delete User
              </Button>
            )} */}
          </Box>
        )}
      </GridToolbarContainer>
    );
  };

  const rows: GridRowsProp = !isFetching ? computeRows() : [];
  const columns: GridColDef[] = computeColumns();

  if (hasAdminRole || hasManagerRole) {
    columns.push({
      field: 'actions',
      type: 'actions',
      flex: 1,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit User"
          showInMenu
          onClick={() => {
            setIsEditUserDialogOpen(true);
            setSelectedUser(usersList[params.row.id]);
          }}
        />,
        <GridActionsCellItem
          icon={<LockResetIcon />}
          label="Reset Password"
          showInMenu
          onClick={() => {
            setIsResetPasswordDialogOpen(true);
            setSelectedUser(usersList[params.row.id]);
          }}
        />
      ]
    });
    // Aiphone Sales and Tech can see only Reset Password
  } else if (hasAiphoneRole) {
    columns.push({
      field: 'actions',
      type: 'actions',
      flex: 1,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<LockResetIcon />}
          label="Reset Password"
          showInMenu
          onClick={() => {
            setIsResetPasswordDialogOpen(true);
            setSelectedUser(usersList[params.row.id]);
          }}
        />
      ]
    });
  }

  return (
    <>
      <Box sx={styles.w100}>
        <DataGrid
          rows={rows}
          columns={columns}
          loading={isFetching}
          checkboxSelection
          disableRowSelectionOnClick
          pageSizeOptions={[25, 50, 100]}
          paginationModel={{ page: page, pageSize: pageSize }}
          onPaginationModelChange={(model) => {
            setPage(model.page);
            setPageSize(model.pageSize);
          }}
          slots={{ toolbar: UsersToolBar }}
          rowSelectionModel={selectionModel}
          onRowSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          rowCount={rowCount}
          paginationMode="server"
          pagination
          autoHeight
        />
      </Box>
      <InviteUserDialog
        isOpen={isInviteUserDialogOpen}
        setIsOpen={setIsInviteUserDialogOpen}
        setErrorMessage={setErrorMessage}
        setSuccessMessage={setSuccessMessage}
        setIsError={setIsError}
        setIsSuccess={setIsSuccess}
        companies={companyList}
        adminBranchName={branchName}
        adminBranchId={firstBranchId}
        adminCompanyName={companyName}
      />
      <DeleteUserDialog
        selectionModel={selectionModel}
        isOpen={isDeleteUserDialogOpen}
        setIsOpen={setIsDeleteUserDialogOpen}
        setErrorMessage={setErrorMessage}
        setIsError={setIsError}
        setSuccessMessage={setSuccessMessage}
        setIsSuccess={setIsSuccess}
      />
      <EditUserDialog
        isOpen={isEditUserDialogOpen}
        setIsOpen={setIsEditUserDialogOpen}
        selectedUser={selectedUser}
        setErrorMessage={setErrorMessage}
        setSuccessMessage={setSuccessMessage}
        setIsError={setIsError}
        setIsSuccess={setIsSuccess}
      />
      <ResetPasswordDialog
        isOpen={isResetPasswordDialogOpen}
        setIsOpen={setIsResetPasswordDialogOpen}
        selectedUser={selectedUser?.publicId}
        setErrorMessage={setErrorMessage}
        setIsError={setIsError}
        setSuccessMessage={setSuccessMessage}
        setIsSuccess={setIsSuccess}
        resetUserType="Branch"
      />
      <SnackbarAlert type="error" time={6000} text={errorMessage} isOpen={isError} onClose={() => setIsError(false)} />
      <SnackbarAlert
        type="success"
        time={6000}
        text={successMessage}
        isOpen={isSuccess}
        onClose={() => {
          setIsSuccess(false);
        }}
      />
    </>
  );
};

const styles = {
  w100: {
    width: '100%'
  }
};

export default UsersDataGrid;
