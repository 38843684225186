import { Add } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowSelectionModel,
  GridRowsProp,
  GridToolbarContainer
} from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useGetCompaniesQuery } from 'services/aiphoneCloud';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SnackbarAlert from 'shared/components/SnackbarAlert';
import AddCompanyDialog from '../UiParts/AddCompanyDialog';
import DeleteCompanyDialog from '../UiParts/DeleteCompanyDialog';
import SettingsIcon from '@mui/icons-material/Settings';
import EditCompanyDialog, { Company } from '../UiParts/EditCompanyDialog';
import { useSelector } from 'react-redux';
import { getCurrentUser } from 'store/slices/usersSlice';
import { EnumList, fetchEnumList } from 'shared/utils/EnumUtils';
import { getString } from 'shared/utils/LocalizationUtils';

export type CompanyResponse = {
  companyList: {
    [key: string]: Company;
  };
  perPage: number;
  pageNumber: number;
  totalPages: number;
  totalCompanies: number;
};

export type CompanyRecord = {
  publicId: string;
  name: string;
  stateId: number;
  postalCode: string;
  companyTypeId: number;
  dealerCompanyPublicId: string;
  createdOn: string;
  lastUpdatedOn: string;
  createdBy: string;
  lastUpdatedBy: string;
};

const CompaniesDataGrid = () => {
  const defaultPageSize = 50;

  const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([]);
  const [selectedCompany, setSelectedCompany] = React.useState<Company>({
    id: '',
    name: '',
    stateId: 0,
    postalCode: '',
    companyTypeId: 0,
    dealerCompanyPublicId: ''
  });
  const [isCreateCompanyDialogOpen, setisCreateCompanyDialogOpen] = React.useState(false);
  const [isDeleteCompanyDialogOpen, setisDeleteCompanyDialogOpen] = React.useState(false);
  const [isEditCompanyDialogOpen, setisEditCompanyDialogOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isError, setIsError] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('');
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [companiesList, setCompaniesList] = React.useState<{ [key: string]: CompanyRecord }>({});
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(defaultPageSize);
  const [rowCount, setRowCount] = React.useState(0);
  const [enumList, setEnumList] = useState<EnumList>({ country: {}, state: {} });
  const [fetchingEnums, setFetchingEnums] = useState(true);
  const addCompany = getString('Button_CompaniesToolBar_AddCompany');
  const deleteCompany = getString('Button_CompaniesToolBar_DeleteCompany');

  const currentUser = useSelector(getCurrentUser);
  const hasGlobalAdminRole = currentUser ? currentUser.permissions.global.role.roleName === 'Aiphone Admin' : false;

  const fetchData = () => {
    return useGetCompaniesQuery({
      qty: '-1',
      page: page.toString()
    });
  };

  /*Fetch companies*/
  const { data, isFetching, error, refetch } = fetchData();
  const companyData: { [key: string]: CompanyRecord } = data ? data.companyList : {};

  useEffect(() => {
    if ((data || error) && isSuccess) {
      refetch();
    }
  }, [page, pageSize, isSuccess]);

  useEffect(() => {
    if (data?.companyList) {
      setCompaniesList(companyData);
      setRowCount(data.totalCompanies);
    } else if (companyData) {
      setCompaniesList({});
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      const errorData = JSON.parse((error as { data: string }).data);
      const errorCode = errorData.errorCode;
      if (errorCode === 'A0001') {
        setErrorMessage('Unauthorized to fetch users, please contact your administrator.');
      } else {
        setErrorMessage('Failed to fetch users');
      }
      setIsError(true);
    }
  }, [error]);

  useEffect(() => {
    fetchEnumList().then((data) => {
      setEnumList(data);
      setFetchingEnums(false);
    });
  }, []);

  const computeRows = (): GridRowsProp => {
    return Object.entries(companiesList).map(([key, company]) => ({
      id: key,
      name: company.name,
      state: enumList.state[company.stateId].value,
      stateId: company.stateId,
      postalCode: company.postalCode,
      companyTypeId: company.companyTypeId,
      dealerCompanyPublicId: company.dealerCompanyPublicId
    }));
  };

  const computeColumns = (): GridColDef[] => {
    return [
      { field: 'name', headerName: 'Company Name', width: 200, flex: 1 },
      { field: 'state', headerName: 'State', width: 90, flex: 1 },
      { field: 'postalCode', headerName: 'Zip Code', width: 90, flex: 1 },
      {
        field: 'actions',
        type: 'actions',
        flex: 1,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<SettingsIcon />}
            label="Edit"
            onClick={() => {
              setisEditCompanyDialogOpen(true);
              setSelectedCompany(params.row);
            }}
          />
        ]
      }
    ];
  };

  const CompaniesToolBar = () => {
    return (
      <GridToolbarContainer>
        <Box sx={styles.w100}>
          <Button onClick={() => setisCreateCompanyDialogOpen(true)} disabled={isFetching} startIcon={<Add />}>
            {addCompany}
          </Button>
          {hasGlobalAdminRole && (
            <Button
              onClick={() => setisDeleteCompanyDialogOpen(true)}
              disabled={isFetching || selectionModel.length !== 1}
              startIcon={<DeleteForeverIcon />}
            >
              {deleteCompany}
            </Button>
          )}
        </Box>
      </GridToolbarContainer>
    );
  };

  const rows: GridRowsProp = !isFetching && !fetchingEnums ? computeRows() : [];
  const columns: GridColDef[] = computeColumns();

  return (
    <>
      <DataGrid
        rows={rows}
        columns={columns}
        loading={isFetching}
        checkboxSelection
        disableRowSelectionOnClick
        pageSizeOptions={[25, 50, 100]}
        paginationModel={{ page: page, pageSize: pageSize }}
        onPaginationModelChange={(model) => {
          setPage(model.page);
          setPageSize(model.pageSize);
        }}
        slots={{ toolbar: CompaniesToolBar }}
        rowSelectionModel={selectionModel}
        onRowSelectionModelChange={(newSelectionModel) => {
          setSelectionModel(newSelectionModel);
        }}
        rowCount={rowCount}
        paginationMode="server"
        pagination
        autoHeight
      />
      <AddCompanyDialog
        isOpen={isCreateCompanyDialogOpen}
        setIsOpen={setisCreateCompanyDialogOpen}
        setIsError={setIsError}
        setErrorMessage={setErrorMessage}
        setIsSuccess={setIsSuccess}
        setSuccessMessage={setSuccessMessage}
      />
      <EditCompanyDialog
        isOpen={isEditCompanyDialogOpen}
        setIsOpen={setisEditCompanyDialogOpen}
        company={selectedCompany}
        setIsError={setIsError}
        setErrorMessage={setErrorMessage}
        setIsSuccess={setIsSuccess}
        setSuccessMessage={setSuccessMessage}
      />
      <DeleteCompanyDialog
        isOpen={isDeleteCompanyDialogOpen}
        setIsOpen={setisDeleteCompanyDialogOpen}
        selectionModel={selectionModel}
        setIsError={setIsError}
        setErrorMessage={setErrorMessage}
        setIsSuccess={setIsSuccess}
        setSuccessMessage={setSuccessMessage}
      />
      <SnackbarAlert type="error" time={6000} text={errorMessage} isOpen={isError} onClose={() => setIsError(false)} />
      <SnackbarAlert
        type="success"
        time={6000}
        text={successMessage}
        isOpen={isSuccess}
        onClose={() => {
          setIsSuccess(false);
        }}
      />
    </>
  );
};

const styles = {
  w100: {
    width: '100%'
  }
};

export default CompaniesDataGrid;
