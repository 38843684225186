import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from '@mui/material';

//TODO -------------------------------------------------------------------
// support for multilingual using libraries such as react-intl or i18next.
import strings from 'features/SimBilling/Types/en.json';
//------------------------------------------------------------------------

interface ConfirmDialogProps {
  isOpen: boolean;
  title: string;
  content: string;
  onClose: () => void;
  onConfirm: () => Promise<void>;
  isLoading?: boolean;
  loadingMessage?: string;
  message?: string;
  messageType?: 'success' | 'error' | 'warning' | 'info';
}
import { Alert } from '@mui/material'; // Import Alert component

const ConfirmDialog = ({
  isOpen,
  title,
  content,
  onClose,
  onConfirm,
  isLoading,
  loadingMessage,
  message,
  messageType
}: ConfirmDialogProps) => {
  const { confirmDialog } = strings;
  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth={true}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {isLoading ? (
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <CircularProgress />
            <Typography sx={styles.topMargin}>{loadingMessage}</Typography>
          </Box>
        ) : message ? (
          <Alert severity={messageType}>{message}</Alert>
        ) : (
          <DialogContentText>{content}</DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        {!isLoading && (
          <>
            <Button onClick={onClose}>{confirmDialog.cancel}</Button>
            {!message && (
              <Button onClick={onConfirm} color="primary">
                {confirmDialog.confirm}
              </Button>
            )}
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

const styles = {
  topMargin: { mt: 2 }
};

export default ConfirmDialog;
