import React, { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Switch,
  Typography
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { LoadingButton } from '@mui/lab';
import { Form, Formik } from 'formik';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { useAddUserRoleMutation, useGetBranchesWithCompanyPublicIdQuery } from 'services/aiphoneCloud';
import RoleDescriptions from '../RoleDescriptions';
import { remoteManagementEmail } from 'shared/api/Aws/RemoteManagementApi';
import { ISelectedUser } from '../RegisteredUsersDataGrid';
import { isApiError } from '../../../shared/api/ApiError';
import CompanySelect from '../shared/components/CompanySelect';
import BranchSelect from '../shared/components/BranchSelect';
import RoleSelect from '../shared/components/RoleSelect';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { findContact, updateContact } from '../../../shared/api/Aws/C2Api.ts';

export interface ICompanyList {
  [key: string]: ICompany;
}
export interface ICompany {
  companyTypeId: number;
  createdOn: string;
  dealerCompanyPublicId: string;
  name: string;
  lastUpdatedOn: string;
  publicId: string;
  stateId: number;
  postalCode: string;
}
export interface IBranch {
  publicId: string;
  address: string;
  address2: string | null;
  address3: string | null;
  city: string;
  branchName: string;
  c2CompanyId: string | null;
  aiphoneCloudAccountNumber: string | null;
  phoneNumber: string | null;
  stateId: number;
  postalCode: string;
  isDutyFree: boolean;
  sitePublicIds: string[];
  company: IBranchCompany;
}
export interface IRole {
  publicId: string;
  roleName: string;
}
interface IBranchCompany {
  name: string;
  stateId: number;
  publicId: string;
  postalCode: string;
}

interface IEditRegisteredUserDialogProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  selectedUser: ISelectedUser;
  companies: ICompanyList;
  isFetching: boolean;
  setErrorMessage: (message: string) => void;
  setIsError: (isError: boolean) => void;
  setSuccessMessage: (message: string) => void;
  setIsSuccess: (isSuccess: boolean) => void;
}

interface IFormValues {
  company: {
    publicId: string;
    dealerCompanyPublicId: string | null;
    name: string;
    stateId: number | null;
    postalCode: string;
    companyTypeId: number | null;
    createdOn: string;
    lastUpdatedOn: string;
  };
  branch: string;
  role: string;
}

interface ICreateRoleRequestDTO {
  selectedUser: ISelectedUser;
  values: IFormValues;
  isAiphoneEmployee: boolean;
}

interface ICommonEditUserDTO {
  selectedUser: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
  };
  values: IFormValues;
  isAiphoneEmployee: boolean;
  roleName: string;
  branchName: string;
}

const initialFormValues = {
  company: {
    publicId: '',
    dealerCompanyPublicId: null,
    name: '',
    stateId: null,
    postalCode: '',
    companyTypeId: null,
    createdOn: '',
    lastUpdatedOn: ''
  },
  branch: '',
  role: ''
};

const isCustomError = (error: unknown): error is { message: string } => {
  return typeof error === 'object' && error !== null && 'message' in error;
};

const EditRegisteredUserDialog = ({
  isOpen,
  setIsOpen,
  selectedUser,
  companies,
  setErrorMessage,
  setIsError,
  setSuccessMessage,
  setIsSuccess
}: IEditRegisteredUserDialogProps) => {
  const [company, setCompany] = React.useState('');
  const [branch, setBranch] = React.useState('');
  const [role, setRole] = React.useState('');
  const [roleName, setRoleName] = React.useState('');
  const [branchName, setBranchName] = React.useState('');
  const [buttonDisable, setButtonDisable] = React.useState(true);
  const [isAiphoneEmployee, setIsAiphoneEmployee] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const { data: branches, isFetching: isBranchesFetching } = useGetBranchesWithCompanyPublicIdQuery(company, {
    skip: !company
  });

  const branchRoles = useSelector((state: RootState) => state.users.availableRoles?.branch);
  const globalRoles = useSelector((state: RootState) => state.users.availableRoles?.global);
  const [addRole] = useAddUserRoleMutation();

  useEffect(() => {
    checkFields();
  }, [isAiphoneEmployee, role, company, branch]);

  const checkFields = () => {
    const shouldEnableButton = (isAiphoneEmployee && role) || (role && company && branch);
    setButtonDisable(!shouldEnableButton);
  };

  const resetStateValues = (): void => {
    setCompany('');
    setRole('');
    setBranch('');
    setRoleName('');
  };

  const handleCloseClick = () => {
    setIsOpen(false);
    resetStateValues();
    setIsAiphoneEmployee(false);
    setButtonDisable(true);
    setIsLoading(false);
  };

  const createEmailBody = (payload: ICommonEditUserDTO) => {
    const { selectedUser, values, isAiphoneEmployee, roleName, branchName } = payload;

    if (isAiphoneEmployee) {
      return {
        userPublicId: selectedUser.id,
        userName: `${selectedUser.firstName} ${selectedUser.lastName}`,
        userEmail: selectedUser.email,
        companyName: 'Aiphone Corporation',
        role: roleName
      };
    } else {
      return {
        userPublicId: selectedUser.id,
        userName: `${selectedUser.firstName} ${selectedUser.lastName}`,
        userEmail: selectedUser.email,
        companyName: values.company.name,
        branchName: branchName,
        role: roleName
      };
    }
  };

  const createRoleRequest = ({ selectedUser, values, isAiphoneEmployee }: ICreateRoleRequestDTO) => ({
    userPublicId: selectedUser.id,
    rolePublicId: values.role,
    sitePublicId: null,
    branchPublicId: isAiphoneEmployee ? null : values.branch
  });

  const isValidEdit = ({ selectedUser, values, isAiphoneEmployee }: Partial<ICommonEditUserDTO>) => {
    const selectedUserIsDefined = selectedUser?.id;
    const roleIsDefined = values?.role?.length;
    const branchIsDefined = values?.branch?.length;
    const isNotAiphoneEmployee = !isAiphoneEmployee;

    return !!(selectedUserIsDefined || roleIsDefined || (isNotAiphoneEmployee && branchIsDefined));
  };

  const handleAiphoneRole = (rolePublicId: string) => {
    const AiphoneRoleName = globalRoles?.filter((role) => role.publicId === rolePublicId)[0]?.roleName;
    setRole(rolePublicId);
    switch (AiphoneRoleName) {
      case 'Aiphone Admin':
        setRoleName('AiphoneAdmin');
        break;
      case 'Aiphone Sales':
        setRoleName('AiphoneSales');
        break;
      case 'Aiphone Tech':
        setRoleName('AiphoneTech');
        break;
      default:
        setRoleName('InvalidRole');
    }
  };

  const updateBranchIdInC2 = async (selectedBranchId: string): Promise<void> => {
    const selectedUserEmail: string = selectedUser.email;
    const branchesList: IBranch[] = Object.values(branches);
    const detailedBranchInto: IBranch | null =
      branchesList.find((item: IBranch) => item.publicId === selectedBranchId) || null;

    /*Make API request to get selected contact from C2*/
    const contactFromC2 = await findContact({ email: selectedUserEmail });

    if (!detailedBranchInto || !contactFromC2) {
      throw new Error('Error during updating the C2 Contact information.');
    }

    const payload = {
      id: contactFromC2.data.contactId,
      person: {
        companyid: detailedBranchInto.c2CompanyId
      }
    };

    /*Make API request to update contact in C2*/
    await updateContact(payload);
  };

  const handleEditUser = async (values: IFormValues) => {
    if (!isValidEdit({ selectedUser, values, isAiphoneEmployee })) {
      setErrorMessage('Please select all fields');
      setButtonDisable(true);
      return;
    }

    setIsLoading(true);
    const roleRequest = createRoleRequest({ selectedUser, values, isAiphoneEmployee });
    const emailBody = createEmailBody({ selectedUser, values, isAiphoneEmployee, roleName, branchName });

    try {
      await remoteManagementEmail(emailBody);
      await addRole(roleRequest).unwrap();
      await updateBranchIdInC2(values.branch);
      setIsSuccess(true);
      setSuccessMessage('User promoted successfully');
    } catch (error: unknown | FetchBaseQueryError) {
      let defaultErrorMessage = 'Error sending email.';

      if (isApiError(error)) {
        defaultErrorMessage = `${error}`;
      }
      if (isCustomError(error)) {
        defaultErrorMessage = error.message;
      }

      setErrorMessage(defaultErrorMessage);
      setIsError(true);
    } finally {
      setIsLoading(false);
      setIsOpen(false);
      resetStateValues();
    }
  };

  return (
    <Dialog open={isOpen} onClose={() => handleCloseClick()} aria-labelledby="" maxWidth="sm" fullWidth>
      <Grid container spacing={1}>
        <Grid item xs={11}>
          <DialogTitle>{isAiphoneEmployee ? 'Promote To Aiphone User' : 'Promote To Branch User'}</DialogTitle>
        </Grid>
        <Grid item xs={1}>
          <IconButton color="default" aria-label="cancel" onClick={() => handleCloseClick()}>
            <CancelIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Formik
        initialValues={initialFormValues}
        onSubmit={async (values, { setSubmitting }) => {
          await handleEditUser(values);
          setSubmitting(false);
        }}
      >
        {(props) => (
          <Form>
            <DialogContent>
              <Box>
                <Grid sx={styles.topBarContainer}>
                  <Grid item xs={8}>
                    <Typography variant="body2" color="initial">
                      Is this user an Aiphone employee?
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Switch
                      checked={isAiphoneEmployee}
                      onChange={(e) => {
                        resetStateValues();
                        setButtonDisable(true);
                        setIsAiphoneEmployee(e.target.checked);
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              {isAiphoneEmployee ? (
                <>
                  <Box>
                    <Typography variant="body1" color="initial">
                      Select a role to promote this user to:
                    </Typography>
                    <Box sx={{ my: 2 }}>
                      <RoleSelect
                        handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          const selectedValue = event.target.value;
                          props.handleChange(event);
                          handleAiphoneRole(selectedValue);
                          setRole(selectedValue);
                          checkFields();
                        }}
                        roles={globalRoles || []}
                      />
                    </Box>
                  </Box>
                </>
              ) : (
                <>
                  <Box>
                    <Typography variant="body1" color="initial">
                      Select a company, branch, and role to promote a user to a higher level.
                    </Typography>
                  </Box>
                  <Box>
                    <Box sx={styles.my2}>
                      <Typography variant="h6" color="initial">
                        Branch Information
                      </Typography>
                    </Box>

                    <Grid container spacing={2}>
                      <CompanySelect
                        companies={companies}
                        handleChange={async (event: React.ChangeEvent<HTMLInputElement>, value: ICompany) => {
                          await props.setFieldValue('company', value);
                          setCompany(value.publicId);
                          checkFields();
                        }}
                      />

                      <BranchSelect
                        isBranchesFetching={isBranchesFetching}
                        isCompanySelected={!!company}
                        branches={branches}
                        handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          const selectedValue = event.target.value;
                          props.handleChange(event);
                          const selectedBranch = branches[selectedValue];
                          if (selectedBranch) {
                            setBranchName(selectedBranch.branchName);
                            setBranch(selectedBranch.publicId);
                            checkFields();
                          }
                        }}
                      />

                      <RoleSelect
                        roles={branchRoles || []}
                        handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          const selectedValue = event.target.value;
                          props.handleChange(event);
                          setRole(selectedValue);
                          const selectedRole = branchRoles?.find((role) => role.publicId === selectedValue);
                          if (selectedRole) {
                            setRoleName(selectedRole.roleName);
                          }
                          checkFields();
                        }}
                      />
                    </Grid>
                  </Box>
                </>
              )}
              {/* Box to display each role permission */}
              <RoleDescriptions roleName={roleName} />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleCloseClick()} variant="contained">
                Cancel
              </Button>
              <LoadingButton type="submit" variant="contained" loading={isLoading} disabled={buttonDisable}>
                Promote User
              </LoadingButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

const styles = {
  mt2: {
    marginTop: 2
  },
  my2: {
    marginY: 2
  },
  topBarContainer: {
    border: 1,
    borderColor: 'lightgray',
    borderRadius: 1,
    bgcolor: '#dcdcdc',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 1,
    paddingX: 1
  }
};

export default EditRegisteredUserDialog;
