/* Direct Station Call List tab stub */
export const DirectStationCallListLabel = () => {
  return <span>Direct Station Call List</span>;
};

const DirectStationCallList = () => {
  return <span>Direct Station Call List</span>;
};

export default DirectStationCallList;
