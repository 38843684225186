import {
  Box,
  Button,
  Container,
  Tab,
  Tabs,
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
  Tooltip,
  CircularProgress
} from '@mui/material';
import { DeviceCategories } from 'features/RemoteManagement/Types';
import React, { useState, useEffect } from 'react';
import { RootState } from 'store';
import { useSelector, useDispatch } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import { addSpaceBeforeCapitalLetters, getNextIpAddress } from 'shared/utils/helperFunctions';
import DeviceNetworkInfoTabContent from './components/DeviceNetworkInfoTabContent';
import { setDeviceNetworkSettings } from 'store/slices/devicesSlice';
import { fetchGatewayCommand } from 'shared/rmGateway/gwCommandProcessor';
import { gwCommand } from 'shared/rmGateway/gwCommand';
import { useHandleGatewayCommandMutation } from 'services/aiphoneCloud';
interface Props {
  handlePreviousStep: () => void;
  handleNextStep: () => void;
}

const strings = {
  title: 'Device Network Info',
  dhcpTitle: 'DHCP Network Configuration',
  body1:
    'The following devices have been added to your site. Please verify that the information is correct and make adjustments as needed.',
  dhcpInfoBody: 'All the devices will automatically assigned IP address via DHCP. ',
  associatedBody: 'All the devices are associated with this site. Taking you to the next step...',
  notAssociatedBody: 'All the devices are not associated with this site. Please try it again',
  readyToAssociate:
    'The expected setup time is roughly 30 seconds per device. Are you ready to connect all these devices to this site?',
  backButtonText: 'Back',
  continueButtonText: 'Continue'
};

const renderDeviceNetworkInfoEntry = () => {
  return (
    <Box sx={{ mt: 3 }}>
      <Box sx={styles.centerContent}>
        <Typography variant="h4" sx={{ mb: 3, color: 'primary.main', fontWeight: 'bold' }}>
          {strings.title}
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          {strings.body1}
        </Typography>
      </Box>
    </Box>
  );
};

const DeviceNetworkInfo = ({ handlePreviousStep, handleNextStep }: Props) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [dhcpDialogOpen, setDhcpDialogOpen] = useState(false);
  const [ipVersion, setIpVersion] = useState('static');
  const [message, setMessage] = useState('start');
  const site = useSelector((state: RootState) => state.site);
  const gateway = useSelector((state: RootState) => state.devices.DeviceList[site.siteInfo.registeredGatewayPublicId]);
  const devices = useSelector((state: RootState) => state.devices);
  const deviceListByType = devices.DeviceListByType;
  const [handleGatewayCommand, { isError: gwCommandError, isLoading: gwLoading }] = useHandleGatewayCommandMutation();

  const dispatch = useDispatch();
  let assignedIndex = 0;

  const handleTabChange = (e: any, tabIndex: number) => {
    setCurrentTabIndex(tabIndex);
  };

  const openDialog = async () => {
    handleDisplayMessage('start');
    setIpVersion('dhcp');
    setDhcpDialogOpen(true);
  };

  const handleClose = () => {
    handleDisplayMessage('start');
    setDhcpDialogOpen(false);
    setIpVersion('static');
  };

  const handleDisplayMessage = (associatedStatus: string) => {
    switch (associatedStatus) {
      case 'associated':
        setMessage(strings.associatedBody);
        break;
      case 'notAssociated':
        setMessage(strings.notAssociatedBody);
        break;
      case 'start':
        setMessage(strings.readyToAssociate);
        break;
      default:
        setMessage('');
    }
  };
  const processBatchAssociationResponse = (batchAssociationResponse) => {
    batchAssociationResponse.forEach((gwDevice) => {
      const devicePublicId = Object.keys(devices.DeviceList).find(
        (key) => devices.DeviceList[key].basicInfo.macAddress === gwDevice.mac_addr
      );
      if (devicePublicId) {
        const selectedDevice = devices.DeviceList[devicePublicId];
        const updatedDevice = {
          ...selectedDevice,
          networkSettings: {
            ...selectedDevice.networkSettings,
            ipVersion: '2',
            ipAddressMethod: '2',
            ipV4Address: gwDevice.ip_addr,
            subnetMask: gwDevice.ip_subnet,
            ipV4DefaultGateway: gwDevice.ip_gateway,
            dns: '8.8.8.8'
          }
        };
        dispatch(setDeviceNetworkSettings(updatedDevice));
      }
      handleDisplayMessage('associated');
      handleClose();
    });
  };
  // This function will fetch the payload for the DHCP association
  // and send it to the gateway
  const associateWithDhcp = async (): Promise<void> => {
    const awsPropertyId = site?.siteInfo?.awsPropertyId;
    const gwMacAddress = gateway?.basicInfo?.macAddress;
    const isGatewayFirstSync = gateway?.lastSyncedOn === null;
    const gwId = isGatewayFirstSync ? 'admin' : gateway?.basicInfo?.adminId;
    const gwPassword = isGatewayFirstSync ? 'admin' : gateway?.basicInfo?.adminPass;

    try {
      const ioTPayload = fetchGatewayCommand(
        'sendCommand',
        gwCommand.ASSOCIATE,
        { awsPropertyId, gwMacAddress, gwId, gwPassword },
        devices.DeviceList,
        'dhcp'
      );
      await handleGatewayCommand(ioTPayload).unwrap();
      const fetchPayload = fetchGatewayCommand(
        'fetchResult',
        gwCommand.ASSOCIATE,
        { awsPropertyId, gwMacAddress, gwId, gwPassword },
        devices.DeviceList,
        'dhcp'
      );
      const fetchResponse = await handleGatewayCommand(fetchPayload).unwrap();

      processBatchAssociationResponse(fetchResponse);
    } catch (error) {
      handleDisplayMessage('notAssociated');
    }
  };
  /**
   * This function will take the categoryDevices array and update the device network settings in the redux store for each device with default network settings:
   * IP Address: gateway.networkSettings?.ipV4Address ?? '192.168.1.10'
   * Subnet Mask: gateway.networkSettings?.subnetMask ?? '255.255.255.0'
   * Default Gateway: gateway.networkSettings?.ipV4DefaultGateway ?? '192.168.1.1'
   * DNS: gateway.networkSettings?.ipV4PrimaryDns ?? '8.8.8.8'
   *
   * For each of the devices the function will increment the IP Address by 1 based on the previous device's IP Address, but starting with the gateway's IP Address, and not exceeding 254.
   *
   * This function will only be called once when the component is mounted.
   *
   */
  // TODO: for IXGW-GW, fetch the Network setting from previous step
  // TODO: check the gateway ip, subnet, default gateway and dns is the same as the previous step (register gateway)

  const initializeDeviceNetworkSettings = () => {
    const defaultIpV4Address = '192.168.1.10';
    let gatewayIpAddress = defaultIpV4Address;
    let currentIpAddress: string;

    if (gateway) {
      gatewayIpAddress = gateway.networkSettings?.ipV4Address ?? defaultIpV4Address;
    }
    currentIpAddress = gatewayIpAddress;

    Object.entries(devices.DeviceList).forEach(([, device]) => {
      // Skip the gateway device. By default the gateway device have the DHCP ip address set up
      if (device.basicInfo.macAddress === gateway?.basicInfo?.macAddress) {
        return;
      }
      const nextIpAddress = getNextIpAddress(currentIpAddress);
      const updatedDevice = {
        ...device,
        networkSettings: {
          ...device.networkSettings,
          ipV4Address: nextIpAddress,
          subnetMask: gateway?.networkSettings?.subnetMask ?? '255.255.255.0',
          ipV4DefaultGateway: gateway?.networkSettings?.ipV4DefaultGateway ?? '192.168.1.1',
          ipV4PrimaryDns: gateway?.networkSettings?.ipV4PrimaryDns ?? '8.8.8.8'
        }
      };

      dispatch(setDeviceNetworkSettings(updatedDevice));
      currentIpAddress = nextIpAddress;
    });
  };

  useEffect(() => {
    initializeDeviceNetworkSettings();
  }, []);

  return (
    <Container maxWidth="md">
      {renderDeviceNetworkInfoEntry()}
      <Grid>
        <FormControlLabel
          control={
            <Checkbox checked={ipVersion === 'static'} onChange={() => setIpVersion('static')} color="primary" />
          }
          label={
            <Tooltip title="Choose this option if you want to manually assign network settings">
              <span>Static IP Configuration</span>
            </Tooltip>
          }
        />
        <FormControlLabel
          control={<Checkbox checked={ipVersion === 'dhcp'} onClick={openDialog} color="primary" />}
          label={
            <Tooltip title="Choose this option for automatic network settings assignment">
              <span>DHCP Configuration</span>
            </Tooltip>
          }
        />
      </Grid>
      <Tabs value={currentTabIndex} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
        {Object.keys(deviceListByType).map((deviceType, index) => {
          const deviceCount = deviceListByType[deviceType].length;
          if (deviceCount === 0) {
            return null;
          }
          DeviceCategories[index].assignedIndex = assignedIndex;
          assignedIndex++;
          return (
            <Tab
              key={index}
              label={deviceCount > 0 ? `${addSpaceBeforeCapitalLetters(deviceType)} (${deviceCount})` : deviceType}
            />
          );
        })}
      </Tabs>
      {ipVersion === 'dhcp' ? (
        <Dialog open={dhcpDialogOpen} onClose={handleClose} fullWidth maxWidth="sm">
          <Box sx={styles.centerContent}>
            <Typography variant="h6" sx={styles.dialogTitle}>
              {message}
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}></Typography>

            <Button type="submit" color="primary" disabled={gwLoading} onClick={associateWithDhcp}>
              {gwLoading ? <CircularProgress size="25px" sx={styles.loading} /> : 'Start Association'}
            </Button>
            <Button type="submit" color="primary" onClick={handleClose}>
              cancel
            </Button>
          </Box>
        </Dialog>
      ) : (
        DeviceCategories.map((category) => {
          const deviceCount = deviceListByType[category.deviceGroup].length;

          if (currentTabIndex === category.assignedIndex && deviceCount) {
            return (
              <DeviceNetworkInfoTabContent
                category={category.deviceGroup}
                ipVersion={ipVersion}
                key={category.assignedIndex}
              />
            );
          }
        })
      )}
      <Box sx={styles.dualButtonContainer}>
        <Button variant="contained" color="primary" onClick={handlePreviousStep}>
          {strings.backButtonText}
        </Button>

        <Button variant="contained" color="primary" type="submit" onClick={handleNextStep}>
          {strings.continueButtonText}
        </Button>
      </Box>
    </Container>
  );
};

export default DeviceNetworkInfo;

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '2rem'
  },
  dialogTitle: {
    mb: 3,
    color: 'primary.main',
    fontWeight: 'bold'
  },
  dualButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
    flexDirection: 'row',
    marginTop: '1rem'
  },
  backdrop: {
    color: '#fff',
    zIndex: 'theme.zIndex.drawer'
  },
  loading: {
    color: 'white'
  }
};
