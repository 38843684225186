import { updateJsonForSite } from 'shared/api/Aws/awsApi';
import { store } from 'store';

export const updateSiteDevices = async (publicId: string, devices: any, existingSiteJson: any) => {
  existingSiteJson.Devices = devices;
  const apiQueryparams = {
    pk: publicId,
    lastUpdated: new Date().toUTCString(),
    siteJsonData: existingSiteJson
  };
  await updateJsonForSite(apiQueryparams);
};

export const updateSiteJson = async () => {
  const siteStore = store.getState();
};

export const getDevicesWithStartingIndex = (devices: any, startingIndex: number) => {
  return devices
    .filter((device: any) => device.StationNumber.toString().startsWith(startingIndex?.toString()))
    .sort((a, b) => {
      return a.StationNumber - b.StationNumber;
    });
};
