import { useState } from 'react';
import { Container, Paper, Tab } from '@mui/material';
import SnackbarAlert from 'shared/components/SnackbarAlert';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import CompaniesDataGrid from '../Datagrids/CompaniesDataGrid';
import BranchesDataGrid from '../Datagrids/BranchesDataGrid';

const DealerCompanies = () => {
  const [currentTab, setCurrentTab] = useState('1');
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  };

  return (
    <>
      <Container maxWidth="xl">
        <Paper>
          <TabContext value={currentTab}>
            <TabList onChange={handleTabChange}>
              <Tab label="Companies" value="1" />
              <Tab label="Branches" value="2" />
            </TabList>
            <TabPanel value="1">
              <CompaniesDataGrid />
            </TabPanel>
            <TabPanel value="2">
              <BranchesDataGrid />
            </TabPanel>
          </TabContext>
        </Paper>
      </Container>
      <SnackbarAlert
        type={isError ? 'error' : 'success'}
        time={3000}
        text={errorMessage}
        onClose={() => {
          setErrorMessage('');
          setIsError(false);
        }}
        isOpen={isError}
      />
    </>
  );
};

export default DealerCompanies;
