import { ReactNode, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, CardMedia } from '@mui/material';
import Divider from '@mui/material/Divider';
import {
  Dashboard as DashboardIcon,
  Build as BuildIcon,
  InfoOutlined as InfoOutlinedIcon,
  Wifi as WifiIcon,
  CallMade as CallMadeIcon,
  Input as InputIcon,
  Lock as LockIcon,
  VolumeUp as VolumeUpIcon,
  Send as SendIcon,
  Pages as PagesIcon,
  IntegrationInstructions as IntegrationIcon,
  Engineering as EngineeringIcon,
  Elevator as ElevatorIcon,
  Devices as DevicesIcon
} from '@mui/icons-material';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { useTheme, Grid } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { SiteContext } from 'context/SiteContext';
import { getDeviceCategoryFromDeviceTypeModel, getDeviceModelNumberFromModelType } from 'shared/utils/helperFunctions';
import { DeviceCategory } from 'features/RemoteManagement/Types';
import { getSelectedDevice } from 'store/slices/devicesSlice';
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from 'shared/constantAwsApi';

function DeviceSideBar(props: any) {
  const theme = useTheme();
  const location = useLocation();
  const { siteName } = useContext(SiteContext);
  const locationArray = location.pathname.split('/');
  const stationId = locationArray[4];
  const publicId = locationArray[2];
  const selectedDevice = useSelector(getSelectedDevice)?.basicInfo;
  if (!selectedDevice) return <Box>Error Loading Device</Box>;
  const deviceCategory = getDeviceCategoryFromDeviceTypeModel(selectedDevice?.deviceType, selectedDevice?.deviceModel);

  const imageUrl = `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/icons/${getDeviceModelNumberFromModelType(
    selectedDevice.deviceModel,
    selectedDevice.deviceType
  )}.png`;

  const handleAllDevicesClick = () => {
    //TODO: dispatch action to clear selected device
  };

  interface DeviceMenu {
    name: string;
    icon: ReactNode;
  }
  const allMenuItems = {
    StationInfo: { name: 'Station Info', icon: <InfoOutlinedIcon color="primary" /> },
    NetworkInfo: { name: 'Network Info', icon: <WifiIcon color="primary" /> },
    SecuritySettings: { name: 'Security Settings', icon: <LockIcon color="primary" /> },
    CallSettings: { name: 'Call Settings', icon: <CallMadeIcon color="primary" /> },
    SoundSettings: { name: 'Sound Settings', icon: <VolumeUpIcon color="primary" /> },
    InputOutput: { name: 'Input Output', icon: <InputIcon color="primary" /> },
    Integrations: { name: 'Integrations', icon: <IntegrationIcon color="primary" /> },
    Maintenance: { name: 'Maintenance', icon: <EngineeringIcon color="primary" /> },
    Transfer: { name: 'Transfer', icon: <SendIcon color="primary" /> },
    Paging: { name: 'Paging', icon: <PagesIcon color="primary" /> },
    LiftControl: { name: 'Lift Control', icon: <ElevatorIcon color="primary" /> }
  };

  let menuItems: DeviceMenu[] = [];
  switch (deviceCategory) {
    case DeviceCategory.VideoDoorStation:
    case DeviceCategory.AudioDoorStation:
    case DeviceCategory.EntranceStation:
    case DeviceCategory.EmergencyStation:
    case DeviceCategory.TenantStation:
      menuItems = [
        allMenuItems.StationInfo,
        allMenuItems.NetworkInfo,
        allMenuItems.SecuritySettings,
        allMenuItems.CallSettings,
        allMenuItems.SoundSettings,
        allMenuItems.InputOutput,
        allMenuItems.Integrations,
        allMenuItems.Maintenance
      ];
      break;
    case DeviceCategory.GuardStation:
    case DeviceCategory.MasterStation:
    case DeviceCategory.SubMasterStation:
      menuItems = [
        allMenuItems.StationInfo,
        allMenuItems.NetworkInfo,
        allMenuItems.SecuritySettings,
        allMenuItems.CallSettings,
        allMenuItems.SoundSettings,
        allMenuItems.InputOutput,
        allMenuItems.Paging,
        allMenuItems.Integrations,
        allMenuItems.Transfer,
        allMenuItems.Maintenance
      ];
      break;
    case DeviceCategory.NetworkAdapter:
      menuItems = [
        allMenuItems.StationInfo,
        allMenuItems.NetworkInfo,
        allMenuItems.SecuritySettings,
        allMenuItems.InputOutput,
        allMenuItems.Integrations,
        allMenuItems.Maintenance
      ];
      break;
    case DeviceCategory.LiftControl:
      menuItems = [
        allMenuItems.StationInfo,
        allMenuItems.NetworkInfo,
        allMenuItems.SecuritySettings,
        allMenuItems.Integrations,
        allMenuItems.LiftControl,
        allMenuItems.Maintenance
      ];
      break;
  }

  return (
    <Sidebar
      style={styles.sidebar as any}
      breakPoint="md"
      backgroundColor={theme.palette.neutral.light}
      collapsed={props.collapsed}
      toggled={props.toggled}
    >
      <Link style={styles.siteLink} to={`/site/${publicId}/siteinfo`}>
        <Box sx={styles.siteBadge}>
          <Typography sx={styles.siteName}>{siteName}</Typography>
          {/* TODO: Conditionally render progress bar only if steps not 100%, will have to reference user's site data pulled from cloud when site is loaded */}
          <Grid container spacing={1} direction="column" justifyContent="center">
            <Grid xs={12} item>
              <Grid sx={styles.device}>
                <Grid>
                  <CardMedia component="img" image={imageUrl} alt={'Device Image'} sx={styles.deviceImage} />
                </Grid>
                <Typography sx={styles.siteName}>{selectedDevice?.macAddress}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Link>
      <Menu
        menuItemStyles={{
          button: ({ active }) => {
            return {
              backgroundColor: active ? '#c9e7ff' : undefined,
              color: active ? '#0071CE' : undefined,
              '&:hover': {
                backgroundColor: active ? '#c9e7ff' : '#e2f2ff'
              }
            };
          }
        }}
      >
        {menuItems.map((item, index) => (
          <MenuItem
            key={index.toString()}
            active={
              location.pathname ===
              `/site/${publicId}/devices/${stationId}/${item.name.toLowerCase().replace(/ /g, '')}`
            }
            component={
              <Link to={`/site/${publicId}/devices/${stationId}/${item.name.toLowerCase().replace(/ /g, '')}`} />
            }
            icon={item.icon}
          >
            <Typography variant="body2">{item.name}</Typography>
          </MenuItem>
        ))}
        <MenuItem
          active={location.pathname === '/remotemanagement'}
          component={<Link to={`/site/${publicId}/`} />}
          onClick={handleAllDevicesClick}
          icon={<DevicesIcon color="primary" />}
        >
          <Typography variant="body2">Return to Site</Typography>
        </MenuItem>
      </Menu>
      <Menu
        menuItemStyles={{
          button: ({ active }) => {
            return {
              backgroundColor: active ? theme.palette.neutral.medium : undefined
            };
          }
        }}
        rootStyles={styles.secondMenu}
      >
        <Divider />
        <MenuItem active={location.pathname === '/'} component={<Link to="/" />} icon={<DashboardIcon />}>
          <Typography style={styles.overflowHidden} variant="body2">
            Home
          </Typography>
        </MenuItem>
        <MenuItem
          active={location.pathname === '/remotemanagement'}
          component={<Link to="/remotemanagement" />}
          icon={<BuildIcon />}
        >
          <Typography style={styles.overflowHidden} variant="body2">
            Remote Management
          </Typography>
        </MenuItem>
      </Menu>
    </Sidebar>
  );
}

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  sidebar: {
    height: 'calc(100%-64px)',
    position: 'sticky',
    top: '0',
    left: '0'
  },
  siteLink: {
    textDecoration: 'none'
  },
  siteBadge: {
    border: '2px solid #D7D7D7',
    boxShadow: '.5px .5px .5px .5px rgba(0,0,0,0.2)',
    borderRadius: '2%',
    marginX: '.5rem',
    marginY: '.5rem',
    '&:hover': {
      backgroundColor: '#f3f3f3'
    }
  },
  siteName: {
    color: '#003366',
    textDecoration: 'none',
    fontSize: '1.25rem',
    fontWeight: '700',
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'block',
    marginY: '.5rem',
    paddingX: '.5rem'
  },
  progressBar: {
    backgroundColor: '#ffd5b1 ',
    '& .MuiLinearProgress-bar': {
      backgroundColor: '#FF7500'
    },
    padding: '.30rem',
    marginLeft: '.5rem',
    marginRight: '.5rem'
  },
  progressBarText: {
    textAlign: 'center',
    color: '#003366'
  },
  overflowHidden: {
    overflow: 'hidden'
  },
  secondMenu: {
    position: 'absolute',
    bottom: '0',
    '@media (max-height: 800px)': {
      position: 'relative'
    }
  },
  device: {
    display: 'flex',
    flexdirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    color: '#6D6E71',
    fontSize: '10px'
  },
  deviceImage: {
    objectFit: 'contain',
    height: '40px',
    width: '40px'
  },
  deviceType: {
    display: 'flex',
    justifyContent: 'center'
  }
};

export default DeviceSideBar;
