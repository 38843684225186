import React, { useState } from 'react';
import RequestReset from './RequestReset';

const ResetPassword: React.FC = () => {
  return (
    <div>
      <RequestReset />
    </div>
  );
};

export default ResetPassword;
