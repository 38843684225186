import React, { useEffect, useState } from 'react';
import { Box, Checkbox, Grid } from '@mui/material';
import theme from 'data/theme';
import { QuikspecCategory } from 'features/QuikSpec/Types/QuikSpecTypes';
import useQuantityChange from '../../Hooks/useQuantityChange';
import RadioButton from './QuikspecRadioButton';

interface Props {
  categories: QuikspecCategory[];
}

//TODO: Does this prop need to be passed into this component?
const QuikspecCategoryTwoWireQuestions = ({ categories }: Props) => {
  const [selectedRadioItem] = useState<string>('');
  const { directChangeQuantity, directGetQuantity } = useQuantityChange(selectedRadioItem, false, 1);

  const [doorMounting, setDoorMounting] = useState<string>('');
  useEffect(() => {
    if (!doorMounting) {
      if (directGetQuantity('DOOR_FLUSH_MOUNT') > 0) {
        setDoorMounting('Flush Mount');
      } else {
        setDoorMounting('Surface Mount');
      }
    } else {
      if (doorMounting === 'Surface Mount') {
        directChangeQuantity('DOOR_FLUSH_MOUNT', '0');
      } else {
        directChangeQuantity('DOOR_FLUSH_MOUNT', '1');
      }
    }
  }, [doorMounting]);

  const [vandalCheckBox, setVandalCheckBox] = useState<boolean>(false);
  const handleVandalCheckBoxChange = () => {
    if (vandalCheckBox) {
      setVandalCheckBox(false);
      directChangeQuantity('VANDAL', '0');
    } else {
      setVandalCheckBox(true);
      directChangeQuantity('VANDAL', '1');
    }
  };
  useEffect(() => {
    if (!vandalCheckBox) {
      if (directGetQuantity('VANDAL') > 0) {
        return setVandalCheckBox(true);
      }
    }
  }, [setVandalCheckBox]);

  const [numberOfMaster, setNumberOfMaster] = useState<string>('');
  useEffect(() => {
    if (!numberOfMaster) {
      if (directGetQuantity('NUMBER_OF_MASTERS') === 2) {
        setNumberOfMaster('2');
      } else {
        setNumberOfMaster('1');
        directChangeQuantity('NUMBER_OF_MASTERS', '1');
      }
    } else {
      if (numberOfMaster === '1') {
        directChangeQuantity('NUMBER_OF_MASTERS', '1');
      } else {
        directChangeQuantity('NUMBER_OF_MASTERS', '2');
      }
    }
  }, [numberOfMaster]);

  const [masterMounting, setMasterMounting] = useState<string>('');
  useEffect(() => {
    if (!masterMounting) {
      if (directGetQuantity('MASTER_DESK_MOUNT') > 0) {
        setMasterMounting('Counter/Desk Mount');
      } else {
        setMasterMounting('Wall Mount');
      }
    } else {
      if (masterMounting === 'Wall Mount') {
        directChangeQuantity('MASTER_DESK_MOUNT', '0');
      } else {
        directChangeQuantity('MASTER_DESK_MOUNT', '1');
      }
    }
  }, [masterMounting]);

  const [doorReleaseCheckBox, setDoorReleaseCheckBox] = useState<boolean>(false);
  const handleDoorReleaseCheckBoxChange = () => {
    if (doorReleaseCheckBox) {
      setDoorReleaseCheckBox(false);
      directChangeQuantity('DOOR_RELEASE', '0');
    } else {
      setDoorReleaseCheckBox(true);
      directChangeQuantity('DOOR_RELEASE', '1');
    }
  };
  useEffect(() => {
    if (!doorReleaseCheckBox) {
      if (directGetQuantity('DOOR_RELEASE') > 0) {
        return setDoorReleaseCheckBox(true);
      }
    }
  }, [setDoorReleaseCheckBox]);

  const [externalSignalingCheckBox, setExternalSignalingCheckBox] = useState<boolean>(false);
  const handleExternalSignalingCheckBoxChange = () => {
    if (externalSignalingCheckBox) {
      setExternalSignalingCheckBox(false);
      directChangeQuantity('EXTERNAL_SIGNAL', '0');
    } else {
      setExternalSignalingCheckBox(true);
      directChangeQuantity('EXTERNAL_SIGNAL', '1');
    }
  };
  useEffect(() => {
    if (!externalSignalingCheckBox) {
      if (directGetQuantity('EXTERNAL_SIGNAL') > 0) {
        return setExternalSignalingCheckBox(true);
      }
    }
  }, [setExternalSignalingCheckBox]);

  return (
    <Box sx={styles.mainStationsBox}>
      <Grid container justifyContent="center" flexDirection={'column'} sx={styles.mainStationsTabGrid}>
        <Grid item xs={12} sx={styles.categoryHeader}>
          Door/Gate Station
        </Grid>
        <Grid container paddingTop={1}>
          <Grid item xs={6}>
            Will the door/gate station be surface or flush mounted?
          </Grid>
          <Grid item xs={6}>
            <Grid container direction="column">
              <Grid item>
                <RadioButton
                  value="Surface Mount"
                  label="Surface Mount"
                  checked={'Surface Mount' === doorMounting}
                  onChange={() => setDoorMounting('Surface Mount')}
                />
              </Grid>
              <Grid item>
                <RadioButton
                  value="Flush Mount"
                  label="Flush Mount"
                  checked={'Flush Mount' === doorMounting}
                  onChange={() => setDoorMounting('Flush Mount')}
                />
              </Grid>
              <Grid item>
                <Checkbox
                  checked={vandalCheckBox}
                  value="test"
                  onChange={() => handleVandalCheckBoxChange()}
                  sx={styles.orgCheckBox}
                />
                Vandal proof
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={styles.categoryHeader} marginTop={1}>
          Master Station(s)
        </Grid>
        <Grid container paddingTop={1}>
          <Grid item xs={6}>
            How many master stations are required?
          </Grid>
          <Grid item xs={6}>
            <Grid container direction="column">
              <Grid item>
                <RadioButton
                  value="1"
                  label="1"
                  checked={'1' === numberOfMaster}
                  onChange={() => setNumberOfMaster('1')}
                />
              </Grid>
              <Grid item>
                <RadioButton
                  value="2"
                  label="2"
                  checked={'2' === numberOfMaster}
                  onChange={() => setNumberOfMaster('2')}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container paddingTop={1}>
          <Grid item xs={6}>
            Wall or counter/desk mount?
          </Grid>
          <Grid item xs={6}>
            <Grid container direction="column">
              <Grid item>
                <RadioButton
                  value="Wall Mount"
                  label="Wall Mount"
                  checked={'Wall Mount' === masterMounting}
                  onChange={() => setMasterMounting('Wall Mount')}
                />
              </Grid>
              <Grid item>
                <RadioButton
                  value="Counter/Desk Mount"
                  label="Counter/Desk Mount"
                  checked={'Counter/Desk Mount' === masterMounting}
                  onChange={() => setMasterMounting('Counter/Desk Mount')}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={styles.categoryHeader} marginTop={1}>
          Optional Features
        </Grid>
        <Grid container paddingTop={1}>
          <Grid item xs={6}>
            Check all that are required.
          </Grid>
          <Grid item xs={6}>
            <Grid container direction="column">
              <Grid item>
                <Checkbox
                  checked={doorReleaseCheckBox}
                  onChange={() => handleDoorReleaseCheckBoxChange()}
                  sx={styles.orgCheckBox}
                />
                Door Release
              </Grid>
              <Grid item>
                <Checkbox
                  checked={externalSignalingCheckBox}
                  onChange={() => handleExternalSignalingCheckBoxChange()}
                  sx={styles.orgCheckBox}
                />
                External Signaling
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  mainStationsBox: {
    my: 4,
    boxShadow: '0 4px 16px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff',
    borderRadius: '8px',
    overflow: 'hidden'
  },
  mainStationsTabGrid: {
    my: 2,
    width: '95%',
    mx: 'auto'
  },
  mainStationsTab: {
    flexGrow: 1,
    flexBasis: 0,
    borderRadius: '0',
    '&:first-of-type': {
      borderTopLeftRadius: '7px',
      borderBottomLeftRadius: '7px'
    },
    '&:last-of-type': {
      borderTopRightRadius: '7px',
      borderBottomRightRadius: '7px'
    },
    fontWeight: 'bold',
    color: 'rgba(0, 0, 0, 0.87)',
    backgroundColor: '#f0f0f0',
    '&.Mui-selected': {
      color: '#ffffff',
      backgroundColor: theme.palette.primary.main
    },
    '&:hover': {
      backgroundColor: '#d0d0d0',
      opacity: 1
    }
  },
  orgCheckBox: {
    position: 'relative',
    left: -10
  },
  categoryHeader: {
    color: 'white.main',
    background: theme.palette.secondary.main,
    textAlign: 'center'
  }
};

export default QuikspecCategoryTwoWireQuestions;
