export const CallDestinationLabel = () => {
  return (
    <>
      <span>Call Destination</span>
    </>
  );
};

const CallDestination = () => {
  return <>Needs Refactor</>;
};

export default CallDestination;
