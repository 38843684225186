import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { GridValidRowModel } from '@mui/x-data-grid';
import AddDeviceManuallyDataGrid from 'features/RemoteManagement/NewSiteWizard/Steps/Datagrids/AddDeviceManuallyDataGrid';
import StationSearchDataGrid from 'features/RemoteManagement/NewSiteWizard/Steps/Datagrids/StationSearchDataGrid';
import { SearchDevice } from 'features/RemoteManagement/Types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useCreateDeviceMutation } from 'services/aiphoneCloud';
import { getDeviceModelFromString, getDeviceTypeIntFromSearch } from 'shared/utils/helperFunctions';
import { RootState } from 'store';

interface Props {
  isAddDevicesWizardOpen: boolean;
  setIsAddDevicesWizardOpen: (isAddDevicesWizardOpen: boolean) => void;
  sitePublicId: string;
  setShouldFetchDevices: (shouldFetchDevices: boolean) => void;
  restrictedMacAddresses: string[];
  setRestrictedMacAddresses: (restrictedMacAddresses: string[]) => void;
}

const AddDevicesWizard = ({
  isAddDevicesWizardOpen,
  setIsAddDevicesWizardOpen,
  sitePublicId,
  setShouldFetchDevices,
  restrictedMacAddresses,
  setRestrictedMacAddresses
}: Props) => {
  const [searchSelectedDevices, setSearchSelectedDevices] = useState<SearchDevice[]>([]);
  const [manualSelectedDevices, setManualSelectedDevices] = useState<GridValidRowModel[]>([]);
  const [, setErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [isAddingManually, setIsAddingManually] = useState(false);
  const [createDevice] = useCreateDeviceMutation();
  const devices = useSelector((state: RootState) => state.devices);

  useEffect(() => {
    Object.entries(devices.DeviceList).forEach(([, device]) => {
      if (device.basicInfo.macAddress) {
        if (!restrictedMacAddresses.includes(device.basicInfo.macAddress)) {
          restrictedMacAddresses.push(device.basicInfo.macAddress);
        }
      }
    });
  }, [devices.DeviceList]);

  const handleAddDevices = async () => {
    const devicesToAdd = [...searchSelectedDevices, ...manualSelectedDevices]
      .map((device) => {
        const deviceType = getDeviceTypeIntFromSearch(device.device_type);
        const deviceModel = getDeviceModelFromString(device.model_number);
        const deviceMacAddress = device.mac_addr;
        const deviceStationNumber = device.station_number || '';
        const deviceStationName = device.station_name || '';
        if (deviceType) {
          return {
            deviceType: deviceType,
            deviceModel: deviceModel,
            macAddress: deviceMacAddress,
            stationNumber: deviceStationNumber,
            stationName: deviceStationName
          };
        }
        return null;
      })
      .filter((device) => device !== null);

    const params = {
      sitePublicId: sitePublicId,
      devices: devicesToAdd
    };
    try {
      setIsLoading(true);
      const response = await createDevice(params);
      if (response.status === 200) {
        setShouldFetchDevices(true);
        setIsAddDevicesWizardOpen(false);
      } else {
        setErrorMessage(response.data.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error adding devices:', error);
      // TODO: Add error handling
    }
  };

  return (
    <Dialog open={isAddDevicesWizardOpen} maxWidth="xl" fullWidth>
      <DialogTitle>Add Devices Wizard</DialogTitle>
      <DialogContent>
        {!isAddingManually ? (
          <StationSearchDataGrid
            searchSelectedDevices={searchSelectedDevices}
            setSearchSelectedDevices={setSearchSelectedDevices}
            setIsAddingManually={setIsAddingManually}
            restrictedMacAddresses={restrictedMacAddresses}
            setRestrictedMacAddresses={setRestrictedMacAddresses}
          />
        ) : (
          <AddDeviceManuallyDataGrid
            manualSelectedDevices={manualSelectedDevices}
            setManualSelectedDevices={setManualSelectedDevices}
            restrictedMacAddresses={restrictedMacAddresses}
          />
        )}
      </DialogContent>
      <DialogActions>
        {!isAddingManually ? (
          <Button onClick={() => setIsAddingManually(true)} disabled={isLoading}>
            Add Manually
          </Button>
        ) : (
          <Button onClick={() => setIsAddingManually(false)} disabled={isLoading}>
            Search
          </Button>
        )}
        <Button onClick={() => setIsAddDevicesWizardOpen(false)}>Cancel</Button>
        <Button onClick={handleAddDevices} disabled={isLoading}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddDevicesWizard;
