import {
  Alert,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useState, useEffect } from 'react';
import Login from 'shared/api/Acl/Login';
import Spinner from 'features/SimBilling/Components/UiParts/Spinner';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

/* This component is used for both SIM Billing and Remote Management */
interface Props {
  // Both
  setUsernameAndToken?: (userName: string, token: string) => void;
  // Remote Management
  handleClose?: (event: React.SyntheticEvent) => void;
  setToken?: React.Dispatch<React.SetStateAction<string>>;
  setIsActivationDialogOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoginDialogOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setTab?: (x: string) => void;
  // SIM Billing
  apiContext?: {
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    errorMessage: string;
    setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  };
}

interface FormValues {
  adminId: string;
  aclPassword: string;
}

const validationSchema = yup.object().shape({
  adminId: yup.string().required('required'),
  aclPassword: yup.string().required('required')
});

const LoginPanel = ({
  handleClose,
  setToken,
  setUsernameAndToken,
  setTab,
  setIsActivationDialogOpen,
  setIsLoginDialogOpen,
  apiContext
}: Props) => {
  const [alert, setAlert] = useState(false);
  const [error, setError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [alertContent, setAlertContent] = useState('');

  useEffect(() => {
    setIsSubmitting(apiContext?.loading || false);
    if (apiContext?.errorMessage) {
      setError(true);
      setAlert(true);
      setAlertContent(apiContext?.errorMessage);
    }
  }, [apiContext]);

  const handleLogin = async (values: FormValues) => {
    if (apiContext) {
      apiContext.setLoading(true);
      setError(false);
    }
    setAlert(false);
    setIsSubmitting(true);
    try {
      const { idToken, username } = await Login.getToken(values.adminId, values.aclPassword);
      setError(false);
      if (apiContext === undefined) setIsSubmitting(false);
      if (setUsernameAndToken) {
        await setUsernameAndToken(username, idToken);
      }
      if (setToken) {
        setToken(idToken);
      }
      if (apiContext === undefined) {
        setError(false);
        setAlert(true);
        setAlertContent('Login successful! This window will close in 3 seconds...');
      }

      if (setTab) {
        setTab('4');
      }
    } catch (e: any) {
      setIsSubmitting(false);
      setError(true);
      setAlert(true);
      const errorType = e.message.split(':')[0] || e.message;
      switch (errorType) {
        case 'UserNotConfirmedException':
          if (setIsLoginDialogOpen) setIsLoginDialogOpen(false);
          if (setIsActivationDialogOpen) setIsActivationDialogOpen(true);
          break;
        // TODO: Add more error cases
        default:
          break;
      }

      const actualMessage = e.message.split(': ')[1] || e.message;
      setAlertContent(actualMessage);
    }
  };
  const isMarkdownContent = (content: string) => /\[.*\]\(.*\)/.test(content);

  const handleAlertContent = () => {
    if (isMarkdownContent(alertContent)) {
      return <ReactMarkdown remarkPlugins={[remarkGfm]}>{alertContent}</ReactMarkdown>;
    } else {
      return alertContent;
    }
  };

  return (
    <>
      {isSubmitting && apiContext && <Spinner />}
      <DialogTitle>IXG Cloud Server Login</DialogTitle>
      <Formik
        initialValues={{ adminId: '', aclPassword: '' }}
        onSubmit={handleLogin}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, errors, touched }) => (
          <Form>
            <DialogContent>
              {alert ? (
                <Alert severity={error ? 'error' : 'success'} sx={styles.alert}>
                  {handleAlertContent()}
                </Alert>
              ) : (
                <></>
              )}
              <DialogContentText>
                Please enter your IXG Cloud Server ID or Email Address and Password to continue with site creation.
              </DialogContentText>

              <Grid container sx={styles.marginTop} direction={'column'} spacing={1}>
                <Grid item>
                  <Field
                    as={TextField}
                    fullWidth
                    label="IXG Cloud Server ID or Email Address"
                    name="adminId"
                    margin="dense"
                    sx={styles.inputField}
                    helperText={touched.adminId && errors.adminId}
                    errors={touched.adminId && errors.adminId}
                  />
                </Grid>
                <Grid item>
                  <Field
                    as={TextField}
                    fullWidth
                    label="Password"
                    type="password"
                    name="aclPassword"
                    margin="dense"
                    sx={styles.inputField}
                    helperText={touched.aclPassword && errors.aclPassword}
                    errors={touched.aclPassword && errors.aclPassword}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              {apiContext === undefined ? (
                <Button variant="contained" onClick={handleClose}>
                  Cancel
                </Button>
              ) : (
                <></>
              )}
              <Button variant="contained" type="submit" disabled={isSubmitting}>
                {isSubmitting && apiContext === undefined ? (
                  <CircularProgress size="25px" sx={{ color: 'white' }} />
                ) : (
                  'Login'
                )}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </>
  );
};

/** @type {import('@mui/material').SxProps} */
const styles = {
  alert: {
    mb: 2,
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px'
  },
  marginTop: {
    mt: 2
  },
  button: {
    backgroundColor: '#d24800',
    color: 'white',
    borderRadius: '5px',
    margin: '5px',
    '&:hover': {
      backgroundColor: '#f85000',
      color: 'white'
    }
  },
  inputField: {
    marginBottom: 1,
    width: '100%',
    '& .MuiInputLabel-root': {
      color: 'grey',
      '&.Mui-focused': {
        color: 'black'
      }
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey'
      },
      '&:hover fieldset': {
        borderColor: '#003366'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#0071ce'
      }
    },
    '&.MuiFormHelperText-root': {
      color: '#d32f2f'
    }
  }
};

export default LoginPanel;
