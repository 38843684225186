import { Box, Container, Grid, Typography, TextField, Paper, Button } from '@mui/material';
import React from 'react';

interface Props {
  numberOfBuildings: number;
  setNumberOfBuildings: React.Dispatch<React.SetStateAction<number>>;
  handlePreviousStep: () => void;
  handleNextStep: () => void;
}

const strings = {
  title: 'Add Buildings',
  body1:
    'Enter a number of buildings to add to your site. Once you have entered the number of buildings, click continue to add the buildings to your site. You cannot adjust the number of buildings beyond this point.',
  backButtonText: 'Back',
  continueButtonText: 'Continue'
};

const renderAddBuildingsEntry = () => {
  return (
    <Box sx={styles.centerContent}>
      <Typography variant="h4" sx={{ mb: 3, color: 'primary.main', fontWeight: 'bold' }}>
        {strings.title}
      </Typography>
    </Box>
  );
};

const AddBuildings = ({ numberOfBuildings, setNumberOfBuildings, handlePreviousStep, handleNextStep }: Props) => {
  const handleAddBuildings = () => {
    handleNextStep();
  };

  return (
    <Container maxWidth="md">
      {renderAddBuildingsEntry()}
      <Paper sx={styles.paper}>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box component="img" src="/Buildings_500x300.jpg" alt="apartment building" sx={styles.imageContainer} />
            </Grid>
            <Grid item xs={4} sm container>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column'
                }}
              >
                <Box sx={{ mb: 3 }}>
                  <Typography variant="body1">{strings.body1}</Typography>
                </Box>
                <Box width={'100%'}>
                  <TextField
                    label="Number of Buildings"
                    type="number"
                    value={numberOfBuildings}
                    onChange={(event) => setNumberOfBuildings(parseInt(event.target.value))}
                    fullWidth
                    InputProps={{
                      inputProps: {
                        min: 1,
                        max: 99
                      }
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Box sx={styles.dualButtonContainer}>
        <Button variant="contained" color="primary" onClick={handlePreviousStep}>
          {strings.backButtonText}
        </Button>

        <Button variant="contained" color="primary" type="submit" onClick={handleAddBuildings}>
          {strings.continueButtonText}
        </Button>
      </Box>
    </Container>
  );
};

export default AddBuildings;

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  dualButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
    flexDirection: 'row',
    marginTop: '1rem'
  },
  paper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    p: 3,
    px: 5,
    mb: 4,
    width: '100%',
    height: '23rem'
  },
  imageContainer: {
    width: '100%',
    borderRadius: 1
  }
};
