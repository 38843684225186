/* Call Origination tab stub */
export const CallOriginationLabel = () => {
  return <span>Call Origination</span>;
};

const CallOrigination = () => {
  return <span>Call Origination</span>;
};

export default CallOrigination;
