import React from 'react';
import { Alert as MUIAlert, IconButton, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface AlertProps {
  type: 'success' | 'error' | 'warning' | 'info';
  time: number;
  text: string;
  isOpen: boolean;
  onClose: () => void;
}

const SnackbarAlert: React.FC<AlertProps> = ({ type, time, text, isOpen, onClose }) => {
  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={time}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <MUIAlert
        severity={type}
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={onClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      >
        {text}
      </MUIAlert>
    </Snackbar>
  );
};

export default SnackbarAlert;
