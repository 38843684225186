import { Card } from '@mui/material';
import { useState, useEffect } from 'react';
import { ErrorType, ErrorTypeValues } from 'shared/api/ApiError';
import LoginPanel from 'features/RemoteManagement/SiteDashboard/Billing/components/MobileService/LoginPanel';
import Dashboard from 'features/SimBilling/Components/Entrypoints/Home/Children/DashBoard';
import Spinner from 'features/SimBilling/Components/UiParts/Spinner';
import { useAclAuth, useReduxAclUser, useProcessAclUser } from 'features/SimBilling/Hooks';
import useRefetchData from 'features/Dashboard/Hooks/useRefetchData';

const SimBilling = () => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isUserDataFetched, setIsUserDataFetched] = useState<boolean>(false);
  const [shouldNavigate, setShouldNavigate] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const apiContext = {
    loading,
    setLoading,
    errorMessage,
    setErrorMessage
  };

  /**If, for some reason, the user and organization info isn't in the Redux store, we will refetch it and save it again.*/
  useRefetchData();

  const { aclToken, aclUserName } = useAclAuth();
  const { user, error } = useReduxAclUser();
  const { initialize, processUserData } = useProcessAclUser();

  const fetchAndUpsertACLUser = async (userName: string, token: string) => {
    await initialize();
    setIsUserDataFetched(true);
    localStorage.setItem('acltoken', token);
    localStorage.setItem('aclUserName', userName);
    await processUserData(userName, token);
  };

  const syncCurrentAclUser = async () => {
    if (aclUserName && aclToken) {
      initialize();
      await processUserData(aclUserName, aclToken);
    } else {
      setShouldNavigate(true);
    }
  };

  useEffect(() => {
    const isValidErrorKey = (key: any): key is keyof typeof ErrorTypeValues => {
      return key in ErrorTypeValues;
    };

    const errorMessage =
      error && isValidErrorKey(error)
        ? ErrorTypeValues[error].message1
        : ErrorTypeValues[ErrorType.UNEXPECTED].message1;

    if (error) {
      setErrorMessage(errorMessage);
      setLoading(false);
      setShouldNavigate(true);
    }
  }, [error]);

  if (shouldNavigate) {
    return <LoginPanel setUsernameAndToken={fetchAndUpsertACLUser} apiContext={apiContext} />;
  }

  return (
    <Card>
      {aclToken && aclUserName ? (
        user ? (
          <Dashboard />
        ) : isUserDataFetched ? (
          <LoginPanel setUsernameAndToken={fetchAndUpsertACLUser} apiContext={apiContext} />
        ) : (
          <Spinner onMount={syncCurrentAclUser} />
        )
      ) : (
        <LoginPanel setUsernameAndToken={fetchAndUpsertACLUser} apiContext={apiContext} />
      )}
    </Card>
  );
};

export default SimBilling;
