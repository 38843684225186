import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableRow, Typography, Box } from '@mui/material';
import theme from 'data/theme';
import { FormValuesWires } from 'features/QuikSpec/Types/QuikspecFormTypes';

interface Props {
  wireInfoArray: FormValuesWires[];
}

const WireInfoTable = ({ wireInfoArray }: Props) => {
  return (
    <TableContainer component={Box}>
      <Table>
        <TableBody>
          {/* Header Row */}
          <TableRow>
            <TableCell align="center" sx={styles.headerCell}>
              <Typography variant="body2">
                <strong>System</strong>
              </Typography>
            </TableCell>
            <TableCell align="center" sx={styles.headerCell}>
              <Typography variant="body2">
                <strong>Wire #</strong>
              </Typography>
            </TableCell>
            <TableCell sx={styles.headerCell}>
              <Typography variant="body2">
                <strong>Description</strong>
              </Typography>
            </TableCell>
            <TableCell align="center" sx={styles.headerCell}>
              <Typography variant="body2">
                <strong>Max. Distance</strong>
              </Typography>
            </TableCell>
            <TableCell align="center" sx={styles.headerCell}>
              <Typography variant="body2">
                <strong>Jacket/Nom. O.D.</strong>
              </Typography>
            </TableCell>
            <TableCell align="center" sx={styles.headerCell}>
              <Typography variant="body2">
                <strong>Capacitance/Loop Res.</strong>
              </Typography>
            </TableCell>
          </TableRow>

          {/* Data Rows */}
          {wireInfoArray.map((wireInfo, index) => (
            <TableRow key={index}>
              <TableCell align="center">
                <Typography variant="body2">{wireInfo.system}</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2">{wireInfo.wire}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{wireInfo.description}</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2">{wireInfo.maxDistance}</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2">{wireInfo.jacketNomOD}</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2">{wireInfo.capacitanceLoopRes}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const styles = {
  headerCell: {
    backgroundColor: theme.palette.primary.dark,
    color: 'white.main'
  }
};
export default WireInfoTable;
