import { Box, Button, Container } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDeleteSiteMutation } from 'services/aiphoneCloud';
import { RootState } from 'store';

const SiteSettings = () => {
  const publicId = useSelector((state: RootState) => state.site.siteInfo.publicId);
  const navigate = useNavigate();
  const [deleteSite] = useDeleteSiteMutation();

  const handleDeleteSite = () => {
    console.log('Site Deleted');
    deleteSite(publicId).then(() => {
      navigate('/remotemanagement');
    });
  };

  return (
    <Container maxWidth="xl">
      <Box>
        <Button variant="contained" onClick={handleDeleteSite}>
          Delete Site
        </Button>
      </Box>
    </Container>
  );
};

export default SiteSettings;
