import React from 'react';
import InfoScreen from '../../../layouts/InfoScreen';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { getString } from 'shared/utils/LocalizationUtils';

export const PwdSuccessfullyChanged: React.FC = () => {
  const navigate = useNavigate();
  const returnToLogin = getString('Button_Password_ReturnToLogin');
  return (
    <InfoScreen
      title={'Success!'}
      message={'Your password was updated!'}
      iconComponent={<CheckCircleRoundedIcon sx={{ fontSize: '48px', color: 'success.main' }} />}
      button={<Button onClick={() => navigate('/auth/login')}>{returnToLogin}</Button>}
    />
  );
};
