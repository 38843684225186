/* Firmware Update tab stub */
export const FirmwareUpdateLabel = () => {
  return <span>Firmware Update</span>;
};

const FirmwareUpdate = () => {
  return <span>Firmware Update</span>;
};

export default FirmwareUpdate;
