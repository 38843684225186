import { Box, Button, Container, Tab, Tabs, Typography } from '@mui/material';
import { memo, useState } from 'react';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { DeviceCategories } from 'features/RemoteManagement/Types';
import { addSpaceBeforeCapitalLetters } from 'shared/utils/helperFunctions';
import DeviceBasicInfoTabContent from './components/DeviceBasicInfoTabContent';

interface Props {
  handlePreviousStep: () => void;
  handleNextStep: () => void;
}

const strings = {
  title: 'Device Basic Info',
  body1:
    'The following devices have been added to your site. Please verify that the information is correct and make adjustments as needed.',
  backButtonText: 'Back',
  continueButtonText: 'Continue'
};

function DeviceBasicInfo({ handlePreviousStep, handleNextStep }: Props) {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const devices = useSelector((state: RootState) => state.devices);
  const deviceListByType = devices.DeviceListByType;

  const handleTabChange = (e: any, tabIndex: number) => {
    setCurrentTabIndex(tabIndex);
  };

  let assignedIndex = 0;

  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 3 }}>
        <Box sx={styles.centerContent}>
          <Typography variant="h4" sx={{ mb: 3, color: 'primary.main', fontWeight: 'bold' }}>
            {strings.title}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            {strings.body1}
          </Typography>
        </Box>
      </Box>
      <Tabs value={currentTabIndex} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
        {Object.keys(deviceListByType).map((deviceType, index) => {
          const deviceCount = deviceListByType[deviceType].length;
          if (deviceCount === 0) {
            return null;
          }
          DeviceCategories[index].assignedIndex = assignedIndex;
          assignedIndex++;
          return (
            <Tab
              key={index}
              label={deviceCount > 0 ? `${addSpaceBeforeCapitalLetters(deviceType)}s (${deviceCount})` : deviceType}
            />
          );
        })}
      </Tabs>
      {DeviceCategories.map((category) => {
        const deviceCount = deviceListByType[category.deviceGroup].length;
        if (currentTabIndex === category.assignedIndex && deviceCount !== 0) {
          return <DeviceBasicInfoTabContent category={category.deviceGroup} key={category.deviceGroup} />;
        }
      })}
      <Box sx={styles.dualButtonContainer}>
        <Button variant="contained" color="primary" onClick={handlePreviousStep}>
          {strings.backButtonText}
        </Button>

        <Button variant="contained" color="primary" type="submit" onClick={handleNextStep}>
          {strings.continueButtonText}
        </Button>
      </Box>
    </Container>
  );
}

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  dualButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
    flexDirection: 'row',
    marginTop: '1rem'
  }
};

export default memo(DeviceBasicInfo);
