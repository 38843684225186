import { FC } from 'react';
import containerStyle from '../../../../../../shared/styles/advancedSettingContainerStyle';
import { Grid } from '@mui/material';
import { getString } from '../../../../../../shared/utils/LocalizationUtils';
import { useField, useFormikContext } from 'formik';
import { CustomSlider } from '../CustomSlider';
import { IVolumeProps } from '../../Volume';

const volumeConfig = {
  volumeType: 'volumeIncomingHandset',
  volumeTitle: 'AdvancedSettings_ReceiveVolumeHandset_Title',
  volumeDesc: 'AdvancedSettings_ReceiveVolumeHandset_Desc'
};

export const VolumeIncomingHandset: FC<IVolumeProps> = ({ defaultValue }) => {
  const { setFieldValue } = useFormikContext();
  const { volumeType, volumeTitle, volumeDesc } = volumeConfig;

  /*Static data*/
  const receiveVolumeHandsetTitle = getString(volumeTitle);
  const receiveVolumeHandsetDesc = getString(volumeDesc);

  /*Validation*/
  const [, volumeIncomingHandsetMeta] = useField(volumeType);

  /*Handlers*/
  const handleChange = async (value: number): Promise<void> => {
    await setFieldValue(volumeType, value);
  };

  return (
    <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
      <Grid item xs={6} lg={7}>
        <div style={containerStyle.itemTitle}>{receiveVolumeHandsetTitle}</div>
        <div style={containerStyle.itemDesc}>{receiveVolumeHandsetDesc}</div>
      </Grid>
      <Grid item xs={6} lg={4} justifyContent="flex-end">
        <CustomSlider
          label={receiveVolumeHandsetTitle}
          name={volumeType}
          min={1}
          max={10}
          step={1}
          onChange={handleChange}
          defaultValue={defaultValue}
          helperText={!!(volumeIncomingHandsetMeta.touched && volumeIncomingHandsetMeta.error)}
          styles={containerStyle.sliderBar}
        />
      </Grid>
    </Grid>
  );
};
