/* CSR tab stub */
export const CSRLabel = () => {
  return <span>CSR</span>;
};

const CSR = () => {
  return <span>We will update Aiphone Cloud for CSR soon!</span>;
};

export default CSR;
