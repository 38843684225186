/* Communication tab stub */
import { Tooltip } from '@mui/material';

export const CommunicationLabel = () => {
  return (
    <Tooltip title="Communication, Communication after Door Release">
      <span>Communication</span>
    </Tooltip>
  );
};

const Communication = () => {
  return <span>Communication</span>;
};

export default Communication;
