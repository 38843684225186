import { DeviceCategories, DeviceType } from 'features/RemoteManagement/Types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'store';
import { updateJsonForSite } from 'store/slices/siteSlice';
import { EnumList, IDeviceModelValue, fetchLocalEnumList } from './EnumUtils';

const selectTenants = (state: RootState) => state.tenants;
const selectUsers = (state: RootState) => state.users;
const selectUnits = (state: RootState) => state.units;
const selectDevices = (state: RootState) => state.devices;

export const saveSite = () => {
  const tenants = useSelector(selectTenants);
  const users = useSelector(selectUsers);
  const units = useSelector(selectUnits);
  const devices = useSelector(selectDevices);
  const publicId = useParams().id;
  const dispatch = useDispatch();

  const siteJsonData = {
    Devices: devices.Devices,
    Units: units.Units,
    Users: users.Users,
    Tenants: tenants.Tenants
  };

  dispatch(updateJsonForSite({ publicId: publicId, siteJson: siteJsonData }));
};

export const getMaxStationNumbersMap = (devices: any): { [key: string]: number } => {
  const maxStations: { [key: string]: number } = {};
  if (devices) {
    devices.forEach((device: any) => {
      const firstDigit = device.StationNumber.toString()[0];
      if (!maxStations[firstDigit]) {
        maxStations[firstDigit] = device.StationNumber;
      } else {
        maxStations[firstDigit] = Math.max(maxStations[firstDigit], device.StationNumber);
      }
    });
  }
  return maxStations;
};

export const findDeviceTypeForDevice = (device): DeviceType | null => {
  for (const category of DeviceCategories) {
    if (category.deviceList.includes(device.DeviceType)) {
      return category;
    }
  }
  return null;
};

export const getDeviceCategoryFromDeviceTypeModel = (deviceType: number, deviceModel: number): string | null => {
  const deviceModelCategoryMap: { [key: number]: { [key: number]: string } } = {
    4: {
      1: 'Master Station',
      2: 'Master Station',
      3: 'Master Station',
      4: 'Master Station',
      5: 'Master Station',
      6: 'Master Station'
    },
    5: {
      1: 'Sub Master Station',
      2: 'Sub Master Station'
    },
    6: {
      1: 'Network Adapter',
      2: 'Network Adapter'
    },
    8: {
      1: 'Video Door Station',
      2: 'Video Door Station',
      3: 'Video Door Station',
      4: 'Video Door Station',
      5: 'Video Door Station',
      6: 'Video Door Station',
      7: 'Video Door Station',
      8: 'Emergency Station',
      9: 'Emergency Station'
    },
    9: {
      1: 'Audio Door Station',
      2: 'Emergency Station',
      3: 'Emergency Station'
    },
    10: {
      1: 'Audio Door Station'
    },
    11: {
      1: 'Video Door Station'
    },
    12: {
      1: 'Video Door Station'
    },
    14: {
      1: 'Tenant Station',
      2: 'Tenant Station'
    },
    15: {
      1: 'Entrance Station'
    },
    16: {
      1: 'Guard Station'
    },
    17: {
      1: 'Lift Control',
      2: 'Lift Control'
    },
    18: {
      1: 'Network Adapter'
    },
    20: {
      1: 'Video Door Station'
    }
  };

  return deviceModelCategoryMap[deviceType]?.[deviceModel] || null;
};

export const getDeviceTypeIntFromSearch = (deviceType: string): number | null => {
  const enumList: EnumList = fetchLocalEnumList();

  return (
    parseInt(
      Object.keys(enumList.deviceType).find(
        (key) => (enumList.deviceType[key] as IDeviceModelValue).value === deviceType
      ) || ''
    ) || null
  );
};

export const getDeviceTypeStringFromInt = (deviceType: number): string | null => {
  const enumList: EnumList = fetchLocalEnumList();

  return enumList.deviceType[deviceType]?.value || null;
};

export const getDeviceModelFromString = (deviceModel: string): number | null => {
  const enumList: EnumList = fetchLocalEnumList();

  const modelKey =
    Object.keys(enumList.deviceModel).find(
      (key) => (enumList.deviceModel[key] as IDeviceModelValue).value === deviceModel
    ) || '';

  return (enumList.deviceModel[modelKey] as IDeviceModelValue)?.modelNumber || null;
};

/**
 * Returns the device model number based on the device model and device type.
 *
 * @param deviceModel Enum value of the device model.
 * @param deviceType Enum value of the device type. [See Device Types](https://github.com/AiphoneCorporation/remoteProgramming-json/blob/main/README.md)
 * @returns String representation of the device model number.
 *
 */
export const getDeviceModelNumberFromModelType = (deviceModel: number, deviceType: number): string | null => {
  const enumList: EnumList = fetchLocalEnumList();

  const modelKey =
    Object.keys(enumList.deviceModel).find(
      (key) =>
        (enumList.deviceModel[key] as IDeviceModelValue).modelNumber === deviceModel &&
        (enumList.deviceModel[key] as IDeviceModelValue).deviceType === deviceType
    ) || '';

  return enumList.deviceModel[modelKey]?.value || null;
};

export const addSpaceBeforeCapitalLetters = (str: string) => {
  return str.replace(/([A-Z])/g, ' $1').trim();
};

export const getNextIpAddress = (ipAddress: string) => {
  const ipAddressParts = ipAddress.split('.');
  if (ipAddressParts.length === 4) {
    ipAddressParts[3] = (parseInt(ipAddressParts[3]) + 1).toString();
    return ipAddressParts.join('.');
  }
  return '';
};
