import { Box, Grid, Skeleton, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import {
  Dashboard as DashboardIcon,
  Apartment as ApartmentIcon,
  Receipt as ReceiptIcon,
  SimCard as SimCardIcon,
  Handyman as HandymanIcon,
  AddBusiness as AddBusinessIcon,
  SupportAgent as SupportAgentIcon,
  Assignment as AssignmentIcon
} from '@mui/icons-material';
import GroupIcon from '@mui/icons-material/Group';
import Divider from '@mui/material/Divider';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link, useLocation } from 'react-router-dom';
import { IBranchPermissions, getCurrentUser } from 'store/slices/usersSlice';
import { useSelector } from 'react-redux';
import { hasNonAssociateAccessInBranch } from 'features/Dashboard/Home';

interface SideBarMenuItemsProps {
  hasGlobalRole: boolean;
  hasBranchRole: boolean;
  registeredUser: boolean;
  isPrductionAccount: boolean;
  userPermissions: any;
}

const SideBarMenuItems = ({
  hasGlobalRole,
  registeredUser,
  isPrductionAccount,
  userPermissions: permissions
}: SideBarMenuItemsProps) => {
  const branchesPermissions: IBranchPermissions[] = permissions.branch ? Object.values(permissions.branch) : [];
  const hasNonAssociateBranchRole = branchesPermissions.some((branch) => hasNonAssociateAccessInBranch(branch));
  const canAccessSimBilling = !registeredUser && (hasGlobalRole || hasNonAssociateBranchRole);
  const canAccessUsers = !registeredUser && !hasGlobalRole && hasNonAssociateBranchRole;
  return (
    <>
      <MenuItem active={location.pathname === '/'} component={<Link to="/" />} icon={<DashboardIcon />}>
        <Typography variant="body2">Dashboard</Typography>
      </MenuItem>
      {hasGlobalRole && (
        <SubMenu
          label="Admin Tools"
          icon={<HandymanIcon />}
          rootStyles={{
            '&:hover': {
              backgroundColor: '#e2f2ff'
            },
            '.ps-submenu-content': {
              width: 'auto'
            },
            margin: 0,
            fontFamily: 'Roboto',
            fontWeight: 400,
            fontSize: '0.875rem',
            lineHeight: 1.43,
            letterSpacing: '0.01071em'
          }}
        >
          <MenuItem active={location.pathname === '/users'} component={<Link to="/users" />} icon={<GroupIcon />}>
            <Typography variant="body2">Manage Users</Typography>
          </MenuItem>
          <MenuItem
            active={location.pathname === '/dealercompanies'}
            component={<Link to="/dealercompanies" />}
            icon={<AddBusinessIcon />}
          >
            <Typography variant="body2">Manage Organizations</Typography>
          </MenuItem>
        </SubMenu>
      )}
      {canAccessUsers && (
        <MenuItem active={location.pathname === '/users'} component={<Link to="/users" />} icon={<GroupIcon />}>
          <Typography variant="body2">Users</Typography>
        </MenuItem>
      )}
      <MenuItem active={location.pathname === '/quikspec'} component={<Link to="/quikspec" />} icon={<ReceiptIcon />}>
        <Typography variant="body2">QuikSpec</Typography>
      </MenuItem>
      {canAccessSimBilling && (
        <MenuItem
          active={location.pathname === '/simbilling'}
          component={<Link to="/simbilling" />}
          icon={<SimCardIcon />}
        >
          <Typography variant="body2">SIM Billing</Typography>
        </MenuItem>
      )}

      {/* Hide 'REMOTE MANAGEMENT' in production until feature release */}
      {!(isPrductionAccount || registeredUser) && (
        <MenuItem
          active={location.pathname === '/remotemanagement'}
          component={<Link to="/remotemanagement" />}
          icon={<ApartmentIcon />}
        >
          <Typography variant="body2">Remote Management</Typography>
        </MenuItem>
      )}
    </>
  );
};

const SideBarSkeleton = () => {
  return (
    <Box>
      <Grid container spacing={0.25}>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={50} width="100%" animation="wave" />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={50} width="100%" animation="wave" />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={50} width="100%" animation="wave" />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={50} width="100%" animation="wave" />
        </Grid>
      </Grid>
    </Box>
  );
};

function SideBar(props: any) {
  const theme = useTheme();
  const location = useLocation();
  const currentUser = useSelector(getCurrentUser);
  const hasGlobalRole = currentUser ? currentUser.permissions.global !== null : false;
  const branchUserRole = currentUser ? currentUser.permissions.branch !== null : false;
  const registeredUser = currentUser
    ? currentUser.permissions.global === null && currentUser.permissions.branch === null
    : true;
  const currentUrl = window.location.href;
  const isPrductionAccount = !/localhost|beta|alpha/.test(currentUrl);

  return (
    <Sidebar
      style={{
        height: 'calc(100%-64px)',
        position: 'sticky',
        top: '0',
        left: '0',
        zIndex: 1
      }}
      backgroundColor={theme.palette.neutral.light}
      collapsed={props.collapsed}
      toggled={props.toggled}
      collapsedWidth={useMediaQuery(() => theme.breakpoints.up('sm')) ? '80px' : '0px'}
    >
      <Menu
        menuItemStyles={{
          button: ({ active }) => {
            return {
              backgroundColor: active ? '#c9e7ff' : undefined,
              color: active ? '#0071CE' : undefined,
              '&:hover': {
                backgroundColor: active ? '#c9e7ff' : '#e2f2ff'
              }
            };
          }
        }}
      >
        {currentUser ? (
          <SideBarMenuItems
            hasGlobalRole={hasGlobalRole}
            hasBranchRole={branchUserRole}
            registeredUser={registeredUser}
            isPrductionAccount={isPrductionAccount}
            userPermissions={currentUser.permissions}
          />
        ) : (
          <SideBarSkeleton />
        )}
      </Menu>
      <Menu
        menuItemStyles={{
          button: ({ active }) => {
            return {
              backgroundColor: active ? theme.palette.neutral.medium : null,
              width: '100%'
            };
          }
        }}
        rootStyles={styles.secondMenu}
      >
        <Divider />
        <MenuItem
          active={location.pathname === '/support'}
          component={<Link to="/support" />}
          icon={<SupportAgentIcon />}
        >
          <Typography style={styles.overflowHidden} variant="body2">
            Support
          </Typography>
        </MenuItem>
        <MenuItem active={location.pathname === '/legal'} component={<Link to="/legal" />} icon={<AssignmentIcon />}>
          <Typography style={styles.overflowHidden} variant="body2">
            Legal Documents
          </Typography>
        </MenuItem>
      </Menu>
    </Sidebar>
  );
}
/** @type {import('@mui/material'.SxProps)} */
const styles = {
  overflowHidden: {
    overflow: 'hidden'
  },
  secondMenu: {
    position: 'absolute',
    bottom: '0',
    width: '100%',
    '@media (max-height: 700px)': {
      position: 'relative'
    }
  }
};

export default SideBar;
