/* Address Book tab stub */
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { findDeviceTypeForDevice } from 'shared/utils/helperFunctions';
import { DeviceCategory } from 'features/RemoteManagement/Types';

export const AddressBookLabel = () => {
  const deviceCategory = findDeviceTypeForDevice(
    useSelector((state: RootState) => state.devices.SelectedDevice)
  )?.deviceCategory;

  return (
    <span>
      {deviceCategory === DeviceCategory.MasterStation ||
      deviceCategory === DeviceCategory.TenantStation ||
      deviceCategory === DeviceCategory.GuardStation
        ? 'Address Book + Network Camera List'
        : 'Address Book'}
    </span>
  );
};

const AddressBook = () => {
  const deviceCategory = findDeviceTypeForDevice(
    useSelector((state: RootState) => state.devices.SelectedDevice)
  )?.deviceCategory;

  return (
    <span>
      {deviceCategory === DeviceCategory.MasterStation ||
      deviceCategory === DeviceCategory.TenantStation ||
      deviceCategory === DeviceCategory.GuardStation
        ? 'Address Book + Network Camera List'
        : 'Address Book'}
    </span>
  );
};

export default AddressBook;
