/* Time tab stub */
import { Tooltip } from '@mui/material';

export const TimeLabel = () => {
  return (
    <Tooltip title="Time, NTP">
      <span>Time</span>
    </Tooltip>
  );
};

const Time = () => {
  return <span>Time</span>;
};

export default Time;
