/* All Page tab stub */
export const AllPageLabel = () => {
  return <span>All Page</span>;
};

const AllPage = () => {
  return <span>All Page</span>;
};

export default AllPage;
