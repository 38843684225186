import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateDeviceMutation } from 'services/aiphoneCloud';
import { getDeviceModelNumberFromModelType } from 'shared/utils/helperFunctions';
import { RootState } from 'store';
import { IDevice, setDeviceBasicInfo } from 'store/slices/devicesSlice';
import * as yup from 'yup';

interface Props {
  category: string;
}

const dialogText = {
  title: 'Edit Basic Info',
  body: 'Edit the station name and station number for the selected device.'
};

const DeviceBasicInfoTabContent = ({ category }: Props) => {
  const dispatch = useDispatch();
  const [selectedDevice, setSelectedDevice] = useState<IDevice | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const devices = useSelector((state: RootState) => state.devices);
  const categoryDevices = devices.DeviceListByType[category];
  const [updateDevice, { isLoading: isUpdating }] = useUpdateDeviceMutation();

  const initialValues = {
    stationName: '',
    stationNumber: ''
  };

  const validationSchema = yup.object().shape({
    stationName: yup
      .string()
      .required('Station Name is required')
      .max(24, 'Station name must be less than 24 characters'),
    stationNumber: yup
      .string()
      .required('Station Number is required')
      .test('isUnique', 'Station number already exists', (value) => {
        return !Object.values(devices.DeviceList).some((device) => {
          return device.basicInfo.stationNumber === value;
        });
      })
      .test('isNumber', 'Station number must be a number', (value) => {
        const regex = /^[0-9]*$/;
        return regex.test(value);
      })
  });

  /**
   * This function is called when the user clicks the edit button on a device card. It sets the selected device and opens the dialog.
   *
   * @param device
   * @param step
   */
  const handleDeviceEdit = (device: IDevice) => {
    setSelectedDevice(device);
    setOpenDialog(true);
  };

  const handleSaveDeviceBasicInfo = async (values: { stationName: string; stationNumber: string }) => {
    if (selectedDevice) {
      // Adding the adminId and adminPass to the basicInfo object to ensure the config file is generated with the correct credentials for existing site
      const updatedDevice = {
        device: {
          sitePublicId: selectedDevice.sitePublicId,
          publicId: selectedDevice.publicId,
          basicInfo: {
            ...selectedDevice.basicInfo,
            stationName: values.stationName,
            stationNumber: values.stationNumber
          }
        }
      };

      try {
        await updateDevice(updatedDevice);
        dispatch(
          setDeviceBasicInfo({
            device: { publicId: selectedDevice.publicId, basicInfo: updatedDevice.device.basicInfo }
          })
        );
      } catch (error) {
        //TODO: Handle Error
      }
      setOpenDialog(false);
    }
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Grid container spacing={2}>
        {categoryDevices.map((deviceId) => {
          const device = devices.DeviceList[deviceId];
          const modelNumber = getDeviceModelNumberFromModelType(
            device.basicInfo.deviceModel,
            device.basicInfo.deviceType
          );
          return (
            <Grid item xs={4} key={device.publicId}>
              <Card sx={{ position: 'relative' }} key={device.publicId}>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {device.basicInfo.macAddress}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <strong>Model Number: </strong>
                    <br />
                    {modelNumber}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <strong>Station Name: </strong>
                    <br />
                    {device.basicInfo.stationName || 'Nothing Assigned'}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <strong>Station Number: </strong>
                    <br />
                    {device.basicInfo.stationNumber || 'Nothing Assigned'}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button onClick={() => handleDeviceEdit(device)}>Edit Station Info</Button>
                </CardActions>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSaveDeviceBasicInfo}>
          {({ errors, touched }) => {
            return (
              <Form>
                <DialogTitle>{dialogText.title}</DialogTitle>
                <DialogContent>
                  <DialogContentText>{dialogText.body}</DialogContentText>
                  <Field
                    name="stationName"
                    label="Station Name"
                    as={TextField}
                    fullWidth
                    error={touched.stationName && Boolean(errors.stationName)}
                    helperText={touched.stationName && errors.stationName}
                  />
                  <Field
                    name="stationNumber"
                    label="Station Number"
                    as={TextField}
                    fullWidth
                    error={touched.stationNumber && Boolean(errors.stationNumber)}
                    helperText={touched.stationNumber && errors.stationNumber}
                  />
                </DialogContent>
                <DialogActions>
                  <Button type="submit">Save Changes</Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    </Box>
  );
};

export default DeviceBasicInfoTabContent;
