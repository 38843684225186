/**
 * This is only for the manual testing to check the connection with the Gateway directly without creating a site
 * will remove for the production
 */
import { useState } from 'react';
import { Grid, TextField, Button, Card, Typography, CardContent, CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, updateSite } from 'store';
import CheckIcon from '@mui/icons-material/Check';
import { fetchGatewayCommand } from 'shared/rmGateway/gwCommandProcessor';
import { useHandleGatewayCommandMutation } from 'services/aiphoneCloud';
import { RootState, addGatewayInfo } from 'store';
import { gwCommand } from 'shared/rmGateway/gwCommand';

const GatewayTest = () => {
  const dispatch: AppDispatch = useDispatch();
  // const [submitted, setSubmitted] = useState(false);
  const [unregister, setUnregister] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [awsPropertyId, setAwsPropertyId] = useState(() => '');
  const [gwId, setGwId] = useState(() => '');
  const [gwPassword, setGwPassword] = useState(() => '');
  const [gwIpAddress, setGwIpAddress] = useState(() => '');
  const [gwMacAddress, setGwMacAddress] = useState(() => '');
  const [deviceId, setDeviceId] = useState(() => '');
  const [devicePassword, setDevicePassword] = useState(() => '');
  const [deviceIpAddress, setDeviceIpAddress] = useState(() => '');
  const [deviceMacAddress, setDeviceMacAddress] = useState(() => '');
  const [deviceStationNumber, setDeviceStationNumber] = useState(() => '');
  const [deviceConfigFileUrl, setDeviceConfigFileUrl] = useState(() => '');
  const [firmwareUrl, setFirmwareUrl] = useState(() => '');
  const [register, setRegister] = useState(false);
  const [search, setSearch] = useState(false);
  let gwStorage: any = {};
  const [handleGatewayCommand, { isError: gwCommandError, isLoading: gwLoading }] = useHandleGatewayCommandMutation();
  const gatewayInfo = useSelector((state: RootState) => state.gateway?.gateway || null);
  // submit the acl site id to the store - will remove for prod
  const unregisterGW = async () => {
    // setLoading(true);
    gwStorage = {
      awsPropertyId: awsPropertyId.toString(),
      gwMacAddress: gwMacAddress.toString(),
      gwId: gwId.toString(),
      gwPassword: gwPassword.toString()
    };
    localStorage.setItem('gwStorage', JSON.stringify(gwStorage));
    dispatch(addGatewayInfo(gwStorage));
    try {
      const ioTPayload = fetchGatewayCommand(
        'sendCommand',
        gwCommand.UNREGISTER,
        { awsPropertyId, gwMacAddress, gwId, gwPassword },
        null,
        null
      );
      await handleGatewayCommand(ioTPayload).unwrap();
      const fetchPayload = fetchGatewayCommand(
        'fetchResult',
        gwCommand.UNREGISTER,
        { awsPropertyId, gwMacAddress, gwId, gwPassword },
        null,
        null
      );
      await handleGatewayCommand(fetchPayload).unwrap();
    } catch (error) {
      console.error('Error unregistering gateway:', error);
    }
    // setLoading(false);
  };

  const registerGW = async () => {
    // setLoading(true);
    gwStorage = {
      awsPropertyId: awsPropertyId.toString(),
      gwId: gwId.toString(),
      gwPassword: gwPassword.toString(),
      gwMacAddress: gwMacAddress.toString()
    };
    dispatch(addGatewayInfo(gwStorage));
    localStorage.setItem('gwStorage', JSON.stringify(gwStorage));
    try {
      const ioTPayload = fetchGatewayCommand(
        'sendCommand',
        gwCommand.REGISTER,
        { awsPropertyId, gwMacAddress, gwId, gwPassword },
        null,
        null
      );
      await handleGatewayCommand(ioTPayload).unwrap();
      const fetchPayload = fetchGatewayCommand(
        'fetchResult',
        gwCommand.REGISTER,
        { awsPropertyId, gwMacAddress, gwId, gwPassword },
        null,
        null
      );
      await handleGatewayCommand(fetchPayload).unwrap();
    } catch (error) {
      console.error('Error Registering Gateway:', error);
    }
    // setLoading(false);
  };

  const searchStations = async () => {
    // setLoading(true);
    gwStorage = {
      awsPropertyId: awsPropertyId.toString(),
      gwId: gwId.toString(),
      gwPassword: gwPassword.toString(),
      gwMacAddress: gwMacAddress.toString()
    };
    dispatch(addGatewayInfo(gwStorage));
    localStorage.setItem('gwStorage', JSON.stringify(gwStorage));
    try {
      const ioTPayload = fetchGatewayCommand(
        'sendCommand',
        gwCommand.STATION_SEARCH,
        { awsPropertyId, gwMacAddress, gwId, gwPassword },
        null,
        null
      );
      await handleGatewayCommand(ioTPayload).unwrap();
      const fetchPayload = fetchGatewayCommand(
        'fetchResult',
        gwCommand.STATION_SEARCH,
        { awsPropertyId, gwMacAddress, gwId, gwPassword },
        null,
        null
      );
      await handleGatewayCommand(fetchPayload).unwrap();
    } catch (error) {
      console.error('Error Registering Gateway:', error);
    }
    // setLoading(false);
  };

  const syncStation = async () => {
    dispatch(updateSite(awsPropertyId));
    gwStorage = {
      awsPropertyId: awsPropertyId.toString(),
      gwId: gwId.toString(),
      gwPassword: gwPassword.toString(),
      gwIpAddress: gwIpAddress.toString(),
      gwMacAddress: gwMacAddress.toString(),
      deviceId: deviceId.toString(),
      devicePassword: devicePassword.toString(),
      deviceIpAddress: deviceIpAddress.toString(),
      deviceMacAddress: deviceMacAddress.toString(),
      deviceStationNumber: deviceStationNumber.toString(),
      deviceConfigFileUrl: deviceConfigFileUrl.toString(),
      firmwareUrl: firmwareUrl.toString()
    };
    localStorage.setItem('gwStorage', JSON.stringify(gwStorage));
    try {
      const ioTPayload = fetchGatewayCommand(
        'sendCommand',
        gwCommand.SYNC,
        { awsPropertyId, gwMacAddress, gwId, gwPassword },
        { deviceMacAddress, deviceStationNumber, deviceIpAddress, deviceId, devicePassword, deviceConfigFileUrl },
        null
      );
      await handleGatewayCommand(ioTPayload).unwrap();
      const fetchPayload = fetchGatewayCommand(
        'fetchResult',
        gwCommand.SYNC,
        { awsPropertyId, gwMacAddress, gwId, gwPassword },
        { deviceMacAddress, deviceStationNumber, deviceIpAddress, deviceId, devicePassword, deviceConfigFileUrl },
        null
      );
      await handleGatewayCommand(fetchPayload).unwrap();
    } catch (error) {
      console.error('Error Registering Gateway:', error);
    }
    // setLoading(false);
  };

  return (
    <>
      <Typography variant="h6" color="initial">
        Gateway Manual Testing
      </Typography>
      <Grid sx={styles.info}>
        <CardContent sx={{ display: 'flex', flexDirection: 'column', margin: '5px' }}>
          <TextField
            id="outlined-basic"
            label="Site ID"
            name="aclSiteId"
            size="small"
            style={styles.textFld}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAwsPropertyId(e.target.value)}
          />
          <TextField
            id="outlined-basic"
            label="Gateway Mac Address"
            name="gwMacAddress"
            size="small"
            style={styles.textFld}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setGwMacAddress(e.target.value)}
          />
          <TextField
            id="outlined-basic"
            label="Gateway id"
            name="gwId"
            size="small"
            style={styles.textFld}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setGwId(e.target.value)}
          />
          <TextField
            id="outlined-basic"
            label="Gateway password"
            name="gwPassword"
            size="small"
            style={styles.textFld}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setGwPassword(e.target.value)}
          />
          <Button variant="contained" sx={styles.testButton} onClick={registerGW}>
            {register ? <CheckIcon /> : gwLoading ? <CircularProgress /> : 'Register GW'}
          </Button>

          <Button variant="contained" sx={styles.testButton} onClick={searchStations}>
            {search ? <CheckIcon /> : gwLoading ? <CircularProgress /> : 'Search Stations'}
          </Button>

          <TextField
            id="outlined-basic"
            label="Gateway Ip Address"
            name="gwIpAddress"
            size="small"
            style={styles.textFld}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setGwIpAddress(e.target.value)}
          />
          <TextField
            id="outlined-basic"
            label="Device id"
            name="deviceId"
            size="small"
            style={styles.textFld}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDeviceId(e.target.value)}
          />
          <TextField
            id="outlined-basic"
            label="Device password"
            name="devicePassword"
            size="small"
            style={styles.textFld}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDevicePassword(e.target.value)}
          />
          <TextField
            id="outlined-basic"
            label="Device Ip Address"
            name="deviceIpAddress"
            size="small"
            style={styles.textFld}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDeviceIpAddress(e.target.value)}
          />
          <TextField
            id="outlined-basic"
            label="Device Mac Address"
            name="deviceMacAddress"
            size="small"
            style={styles.textFld}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDeviceMacAddress(e.target.value)}
          />
          <TextField
            id="outlined-basic"
            label="Device Station Number"
            name="deviceStationNumber"
            size="small"
            style={styles.textFld}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDeviceStationNumber(e.target.value)}
          />
          <TextField
            id="outlined-basic"
            label="Config File URL"
            name="configFileUrl"
            size="small"
            style={styles.textFld}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDeviceConfigFileUrl(e.target.value)}
          />
          <Button variant="contained" sx={styles.testButton} onClick={syncStation}>
            {gwLoading ? <CircularProgress /> : 'SYNC ONE STATION'}
          </Button>

          <Card sx={styles.card}>
            <Typography variant="h6" color="initial">
              Unregister Gateway
            </Typography>

            <CardContent>
              <TextField
                id="outlined-basic"
                label="Site ID"
                name="siteId"
                size="small"
                style={styles.textFld}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAwsPropertyId(e.target.value)}
              />
              <TextField
                id="outlined-basic"
                label="Gateway Mac Address"
                name="gwMacAddress"
                size="small"
                style={styles.textFld}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setGwMacAddress(e.target.value)}
              />
              <TextField
                id="outlined-basic"
                label="Gateway id"
                name="gwId"
                size="small"
                style={styles.textFld}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setGwId(e.target.value)}
              />
              <TextField
                id="outlined-basic"
                label="Gateway password"
                name="gwPassword"
                size="small"
                style={styles.textFld}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setGwPassword(e.target.value)}
              />
              {gwCommandError && <Typography variant="body1">Error unregistering gateway</Typography>}
              <Button variant="contained" sx={styles.testButton} onClick={unregisterGW}>
                {unregister ? <CheckIcon /> : gwLoading ? <CircularProgress /> : 'Unregistered'}
              </Button>
            </CardContent>
          </Card>
        </CardContent>
      </Grid>
      {/* Temporary adding the test site ID manually, WILL REMOVE FOR PROD */}
    </>
  );
};

const styles = {
  info: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    margin: '12px 1px 0px 1px'
  },
  infoValue: {
    display: 'flex',
    margin: '5px'
  },
  textFld: {
    width: '200px',
    margin: '5px 5px 0px 0px'
  },
  langItemContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  langIconElem: {
    margin: '0px 5px 0px 0px',
    width: '30px'
  },
  submitButton: {
    fontSize: '1rem',
    fontWeight: '700',
    letterSpacing: '1px',
    borderRadius: '0.25 rem',
    margin: '12px 5px 0px 5px',
    width: '200px',
    '&:hover': {
      backgroundColor: '#f85000'
    },
    color: 'white',
    marginBottom: '5px'
  },
  //WILL REMOVE THIS FOR PROD
  testButton: {
    fontSize: '1rem',
    fontWeight: '700',
    letterSpacing: '1px',
    borderRadius: '0.25 rem',
    margin: '12px 5px 0px 5px',
    '&:hover': {
      backgroundColor: '#f85000'
    },
    color: 'white',
    marginBottom: '5px'
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '5px'
  }
};

export default GatewayTest;
