import { FC } from 'react';
import containerStyle from '../../../../../../shared/styles/advancedSettingContainerStyle';
import { Grid } from '@mui/material';
import { getString } from '../../../../../../shared/utils/LocalizationUtils';
import { useField, useFormikContext } from 'formik';
import { CustomSlider } from '../CustomSlider';
import { IVolumeProps } from '../../Volume';

const volumeConfig = {
  volumeType: 'volumeIncomingSpeaker',
  volumeTitle: 'AdvancedSettings_ReceiveVolumeSpeaker_Title',
  volumeDesc: 'AdvancedSettings_ReceiveVolumeSpeaker_Desc'
};

export const VolumeIncomingSpeaker: FC<IVolumeProps> = ({ defaultValue }) => {
  const { setFieldValue } = useFormikContext();
  const { volumeType, volumeTitle, volumeDesc } = volumeConfig;

  /*Static data*/
  const receiveVolumeSpeakerTitle = getString(volumeTitle);
  const receiveVolumeSpeakerDesc = getString(volumeDesc);

  /*Validation*/
  const [, volumeIncomingSpeakerMeta] = useField(volumeType);

  /*Handlers*/
  const handleChange = async (value: number): Promise<void> => {
    await setFieldValue(volumeType, value);
  };

  return (
    <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
      <Grid item xs={6} lg={7}>
        <div style={containerStyle.itemTitle}>{receiveVolumeSpeakerTitle}</div>
        <div style={containerStyle.itemDesc}>{receiveVolumeSpeakerDesc}</div>
      </Grid>
      <Grid item xs={6} lg={4} justifyContent="flex-end">
        <CustomSlider
          label={receiveVolumeSpeakerTitle}
          name={volumeType}
          min={1}
          max={10}
          step={1}
          onChange={handleChange}
          helperText={!!(volumeIncomingSpeakerMeta.touched && volumeIncomingSpeakerMeta.error)}
          defaultValue={defaultValue}
          styles={containerStyle.sliderBar}
        />
      </Grid>
    </Grid>
  );
};
