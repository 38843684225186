import { useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

interface SpinnerProps {
  onMount?: () => void; // Optional callback prop when the component mounts
}

const Spinner = ({ onMount }: SpinnerProps) => {
  useEffect(() => {
    if (onMount) {
      onMount(); // Call the onMount callback if it exists
    }
    // Add any cleanup actions to be performed when the component unmounts here
    // return () => {
    //   // Cleanup actions
    // };
  }, [onMount]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        top: '64px',
        left: 0,
        width: '100vw',
        height: `calc(100vh - 64px)`,
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        zIndex: 1500
      }}
    >
      <CircularProgress size={70} /> {/* Increase the size of the spinner */}
    </Box>
  );
};

export default Spinner;
