/* Relay Output IO tab stub */
export const RelayOutputIOLabel = () => {
  return <span>Relay Output</span>;
};

const RelayOutputIO = () => {
  return <span>Relay Output</span>;
};

export default RelayOutputIO;
