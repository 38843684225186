import { Alert, AlertTitle, Box, Card, CardContent, LinearProgress, Tab } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import ixg from '../../assets/images/ixg.png';
import { Site } from './Types';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { SiteContext } from 'context/SiteContext';
import { useContext, useState } from 'react';
import { TabContext, TabList } from '@mui/lab';
import { updateSite } from 'store';
import { useDispatch } from 'react-redux';

interface Props {
  isFetching: boolean;
  mySites: Site[] | [];
}
const Sites = ({ isFetching, mySites }: Props) => {
  const [tabIndex, setTabIndex] = useState('1');
  const navigate = useNavigate();
  const { setSiteName, setPublicId } = useContext(SiteContext);
  const dispatch = useDispatch();

  const mySitesError = false;

  const handleTabChange = (event: any, newValue: string) => {
    setTabIndex(newValue);
  };

  const openSiteInfo = (params: any) => {
    dispatch(
      updateSite({
        publicId: params.row.id,
        siteName: params.row.siteName
      })
    );
    setSiteName(params.row.siteName);
    setPublicId(params.row.publicId);
    navigate(`/site/${params.id}/siteinfo`);
  };

  const convertToLocalTimezone = (utcTimestamp: any) => {
    return moment.utc(utcTimestamp).local().format('MM/DD/YYYY h:mm A');
  };

  const mySitesRecords = mySites
    ? Object.entries(mySites).map(([key, value]) => ({
        image: ixg,
        id: key,
        siteName: value.siteName,
        lastUpdated: convertToLocalTimezone(value.lastUpdatedOn)
      }))
    : [];

  const renderDataGrid = (rows: any, error: boolean) => (
    <Box>
      {error ? (
        <Alert severity="error" sx={styles.error}>
          <AlertTitle>Error</AlertTitle>
          There was an error Loading the sites. Please refresh to try again
        </Alert>
      ) : (
        <DataGrid
          initialState={{
            sorting: {
              sortModel: [{ field: 'lastUpdated', sort: 'desc' }]
            }
          }}
          onRowClick={openSiteInfo}
          columns={[
            {
              field: 'image',
              headerName: 'Image',
              width: 150,
              renderCell: (params) => <img src={params.value} alt="IXG" />
            },
            { field: 'siteName', headerName: 'Site Name', width: 200 },
            { field: 'lastUpdated', headerName: 'Last Updated', width: 300, sortingOrder: ['asc'] }
          ]}
          slots={{ loadingOverlay: LinearProgress }}
          rows={rows}
          loading={isFetching}
          autoHeight
        />
      )}
    </Box>
  );

  return (
    <Card>
      <CardContent>
        <TabContext value={tabIndex}>
          <Box>
            <TabList onChange={handleTabChange} aria-label="Device tab setting">
              <Tab label="My Sites" value="1" />
              <Tab label="My organization sites" value="2" disabled />
            </TabList>
          </Box>
          {tabIndex === '1' && renderDataGrid(mySitesRecords, mySitesError)}
        </TabContext>
      </CardContent>
    </Card>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  featuredAppContainer: {
    width: '100%',
    position: 'relative',
    padding: '20px'
  },
  row: {
    '&:hover': {
      background: 'lightgray'
    }
  },
  container: {
    display: 'flex',
    bgcolor: 'neutral.light',
    height: 'calc(100% - 64px)'
  },
  mainSection: {
    p: 4,
    width: '100%',
    height: '100%',
    overflow: 'auto'
  },
  error: {
    mb: 2
  },
  dataGridContainer: {
    height: 400,
    width: '100%'
  }
};

export default Sites;
