import { Alert, Typography, Box } from '@mui/material';

const SubscriptionDetailsInfo = () => {
  const isEasternDaylightTime = () => {
    const currentDate = new Date();
    const januaryDate = new Date(currentDate.getFullYear(), 0, 1);
    const julyDate = new Date(currentDate.getFullYear(), 6, 1);
    const standardOffset = Math.max(januaryDate.getTimezoneOffset(), julyDate.getTimezoneOffset());
    const currentOffset = currentDate.getTimezoneOffset();
    return currentOffset < standardOffset;
  };
  return (
    <Alert severity="info" sx={styles.subscriptionDetail}>
      <Typography variant="h6" color="primary" gutterBottom>
        Subscription Details
      </Typography>
      <Box component="ul" sx={styles.subscriptionListItem}>
        <li>
          <Typography variant="body2" component="span">
            - Due to system restrictions, we cannot accept new subscriptions from{' '}
            {isEasternDaylightTime() ? '8:00' : '7:00'} PM {isEasternDaylightTime() ? 'EDT' : 'EST'} on the 17th to{' '}
            {isEasternDaylightTime() ? '7:59 ' : '6:59 '} PM {isEasternDaylightTime() ? 'EDT' : 'EST'} on the 18th of
            each month. Please subscribe outside these hours.
          </Typography>
        </li>
        <li>
          <Typography variant="body2" component="span">
            - Data used beyond subscription limits will be automatically purchased in 0.5GB increments. Each 0.5GB of
            additional data costs $10.
          </Typography>
        </li>
        <li>
          <Typography variant="body2" component="span">
            - Subscription cycles are billed on the 18th of every month.
          </Typography>
        </li>
        <li>
          <Typography variant="body2" component="span">
            - Data usage information is refreshed from the carrier every 6 hours.
          </Typography>
        </li>
        <li>
          <Typography variant="body2" component="span">
            - Data usage is reset on the 18th of every month.
          </Typography>
        </li>
        <li>
          <Typography variant="body2" component="span">
            - Registering for a new subscription or making changes to an existing one is processed immediately, with
            charges adjusted for the remaining days in the current billing cycle.
          </Typography>
        </li>
      </Box>
    </Alert>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  subscriptionDetail: {
    my: 2,
    border: '1px solid #007BFF',
    background: '#f0f8ff'
  },
  subscriptionListItem: {
    listStyle: 'none',
    padding: 0,
    margin: 0
  }
};

export default SubscriptionDetailsInfo;
