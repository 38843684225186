import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Grid,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Container } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { updateSite as updateSiteInStore } from 'store';
import { updateSite } from 'shared/api/Aws/awsApi';
import { RootState } from 'store';
import {
  useCreateDeviceMutation,
  useGetDeviceWithPublicIdQuery,
  useUpdateDeviceMutation,
  useHandleGatewayCommandMutation
} from 'services/aiphoneCloud';
import { fetchGatewayCommand } from 'shared/rmGateway/gwCommandProcessor';
import { gwCommand } from 'shared/rmGateway/gwCommand';
import Countdown from 'shared/utils/CountDown';
import { RegisterGatewayForm } from './components/RegisterGatewayForm';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import SnackbarAlert from '../../../../shared/components/SnackbarAlert';
import { isApiError } from '../../../../shared/api/ApiError';
import { BasicInfoDevice } from '../../Types';

interface IRegisterGatewayProps {
  isRegistered: boolean;
  setIsRegistered: (isRegistered: boolean) => void;
  handlePreviousStep: () => void;
  handleNextStep: () => void;
}

interface IHandleGatewayCommandRes {
  data?: {
    payload: {
      dns_primary: string;
      dns_secondary: string;
      ip_addr: string;
      ip_subnet: string;
      ip_gateway: string;
    }[];
  }[];
  error?: FetchBaseQueryError | SerializedError;
}
interface ICreateDeviceRes {
  data?: {
    macAddress: string;
    publicId: string;
  }[];
  error?: FetchBaseQueryError | SerializedError;
}
export interface IRegisterGatewayWithCloud {
  macAddress: string;
}
export interface IDefaultGatewayInfo {
  gwMacAddress: string | undefined;
  gwId: string;
  gwPassword: string;
}
interface IGateway {
  ac_device_get_with_public_id?: BasicInfoDevice;
}

const isCustomError = (error: unknown): error is { message: string } => {
  return typeof error === 'object' && error !== null && 'message' in error;
};

const RegisterGateway: FC<IRegisterGatewayProps> = ({
  isRegistered,
  setIsRegistered,
  handlePreviousStep,
  handleNextStep
}) => {
  const dispatch = useDispatch();

  /*Operations with root state*/
  const awsPropertyId = useSelector((state: RootState) => state.site?.siteInfo?.awsPropertyId) || '0';
  const site = useSelector((state: RootState) => state.site);
  const sitePublicId = useSelector((state: RootState) => state.site?.siteInfo?.publicId);

  /*Operations with local state*/
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isSkipDialogOpen, setIsSkipDialogOpen] = useState(false);
  const [gatewayPublicId, setGatewayPublicId] = useState('');
  const [primaryDns, setPrimaryDns] = useState('');
  const [secondaryDns, setSecondaryDns] = useState('');
  const [gatewayIpAddress, setGatewayIpAddress] = useState('');
  const [gatewaySubnetMask, setGatewaySubnetMask] = useState('');
  const [gatewayDefaultGateway, setGatewayDefaultGateway] = useState('');

  /*API requests*/
  const [updateDevice] = useUpdateDeviceMutation();
  const [createDevice] = useCreateDeviceMutation();
  const [handleGatewayCommand] = useHandleGatewayCommandMutation();
  const { data: gateway } = useGetDeviceWithPublicIdQuery(gatewayPublicId, {
    skip: !gatewayPublicId
  });

  /*Helpers*/
  const strings = {
    title: 'Register Gateway',
    body1:
      'In order for your intercoms to be managed remotely, you must first register your IXGW-GW gateway with the Aiphone cloud service. Please follow the steps below to complete this process. If you have any questions, please contact Aiphone Technical Support at 1-800-692-0200.',
    step1Title: '1. Connect the IXGW-GW gateway to your network.',
    step1Body:
      'Connect the IXGW-GW gateway to your network using an Ethernet cable. The gateway will automatically obtain an IP address from your DHCP server. If you are not using DHCP, you will need to manually assign an IP address to the gateway.',
    step2Title:
      '2. Register the gateway with the Aiphone cloud service by entering the MAC address of the gateway below.',
    step2Body: 'The MAC address can be found on a yellow label on the bottom of the gateway.',
    backButtonText: 'Back',
    skipButtonText: 'Skip',
    continueButtonText: 'Continue',
    registeringGateway: 'Registering gateway...',
    gatewayRegistered: 'Gateway registered successfully!',
    defaultErrorMessage: 'Error registering gateway.'
  };
  const getDefaultGatewayInfo = (gateway: IGateway): IDefaultGatewayInfo => {
    return {
      gwMacAddress: gateway.ac_device_get_with_public_id?.basicInfo?.macAddress,
      gwId: gateway.ac_device_get_with_public_id?.basicInfo?.adminId || 'admin',
      gwPassword: gateway.ac_device_get_with_public_id?.basicInfo?.adminPass || 'admin'
    };
  };

  useEffect(() => {
    if (gateway) {
      // step#5 - update the device with the network settings
      try {
        const updatedDeviceParams = {
          device: {
            publicId: gatewayPublicId,
            sitePublicId: sitePublicId,
            networkSettings: {
              ...gateway.ac_device_get_with_public_id?.networkSettings,
              ipV4Address: gatewayIpAddress || '',
              subnetMask: gatewaySubnetMask || '',
              ipV4DefaultGateway: gatewayDefaultGateway || '',
              ipV4PrimaryDns: primaryDns,
              ipV4SecondaryDns: secondaryDns
            }
          }
        };
        updateDevice(updatedDeviceParams);
        setIsLoading(false);
        setIsRegistered(true);
      } catch (error) {
        setIsLoading(false);
        setErrorMessage(strings.defaultErrorMessage);
      }
    }
  }, [gateway]);

  const registerGatewayWithCloud = async (values: IRegisterGatewayWithCloud) => {
    const gwMacAddress = values.macAddress;
    let ioTPayload, fetchPayload, fetchResponse;
    setIsLoading(true);

    try {
      // Step 1: register Gateway through MQTT, once registered, search the stations and stored in Redux store
      ioTPayload = fetchGatewayCommand('sendCommand', gwCommand.REGISTER, { awsPropertyId, gwMacAddress }, null, null);
      await handleGatewayCommand(ioTPayload).unwrap();

      fetchPayload = fetchGatewayCommand(
        'fetchResult',
        gwCommand.REGISTER,
        { awsPropertyId, gwMacAddress },
        null,
        null
      );
      fetchResponse = await handleGatewayCommand(fetchPayload).unwrap();
      if (fetchResponse.error) {
        let defaultErrorMessage = strings.defaultErrorMessage;
        if ('status' in fetchResponse.error && fetchResponse.error.status === 404) {
          defaultErrorMessage = 'Gateway did not respond.';
        }
        throw { message: defaultErrorMessage };
      }
      if (!fetchResponse || fetchResponse.length === 0) {
        throw { message: strings.defaultErrorMessage };
      }

      // Once the register is successful, fetch the gateway details

      const registerGWResponse = fetchResponse.payload[0];
      setPrimaryDns(registerGWResponse?.dns_primary.toString());
      setSecondaryDns(registerGWResponse?.dns_secondary.toString());
      setGatewayIpAddress(registerGWResponse?.ip_addr.toString());
      setGatewaySubnetMask(registerGWResponse?.ip_subnet.toString());
      setGatewayDefaultGateway(registerGWResponse?.ip_gateway.toString());

      ioTPayload = fetchGatewayCommand(
        'sendCommand',
        gwCommand.STATION_SEARCH,
        { awsPropertyId, gwMacAddress },
        null,
        null
      );
      await handleGatewayCommand(ioTPayload).unwrap();

      // fetch response from dynamoDB
      fetchPayload = fetchGatewayCommand(
        'fetchResult',
        gwCommand.STATION_SEARCH,
        { awsPropertyId, gwMacAddress },
        null,
        null
      );
      fetchResponse = await handleGatewayCommand(fetchPayload).unwrap();

      const gatewayInfo = fetchResponse.payload.find((device) => device.mac_addr === gwMacAddress);
      const gwFirmwareVersion = gatewayInfo.fw_ver;
      const gwStationNumber = gatewayInfo.station_number;
      const gwStationName = gatewayInfo.station_name;

      const deviceCreationPayload = {
        sitePublicId: sitePublicId,
        devices: [
          {
            deviceType: 18,
            deviceModel: 1,
            macAddress: gwMacAddress,
            stationNumber: gwStationNumber || '013001',
            stationName: gwStationName || 'Default Gateway',
            firmwareVersion: gwFirmwareVersion || ''
          }
        ]
      };

      // Only create the device for gateway if it is not already registered
      const needsNewDevice =
        site.siteInfo?.registeredGatewayPublicId === '' || site.siteInfo?.registeredGatewayPublicId === null;
      if (needsNewDevice) {
        const response: ICreateDeviceRes = await createDevice(deviceCreationPayload);

        if (!response?.data || response?.error) {
          throw { message: 'Failed to add new gateway device in the database' };
        }
        setGatewayPublicId(response.data[0].publicId);

        // step 3 - update the site with the registeredGatewayPublicId
        dispatch(updateSiteInStore({ registeredGatewayPublicId: response?.data[0]?.publicId }));
        const updateSitePayload = {
          siteData: {
            ...site.siteInfo,
            awsPropertyId: site.siteInfo?.awsPropertyId || '',
            registeredGatewayPublicId: response.data[0].publicId
          },
          sitePublicId: sitePublicId
        };
        dispatch(updateSiteInStore(updateSitePayload));
        await updateSite(updateSitePayload);
      } else {
        setIsLoading(false);
        setIsRegistered(true);
      }
    } catch (error) {
      let defaultErrorMessage = strings.defaultErrorMessage;

      if (isApiError(error)) {
        defaultErrorMessage = `${error}`;
      }
      if (isCustomError(error)) {
        defaultErrorMessage = error.message;
      }

      setErrorMessage(defaultErrorMessage);
      setIsLoading(false);
      setIsRegistered(false);
    }
  };

  return (
    <Container maxWidth="md">
      <SnackbarAlert
        type="error"
        time={7000}
        text={`${errorMessage}`}
        isOpen={!!errorMessage}
        onClose={() => setErrorMessage(null)}
      />
      <Grid container spacing={2}></Grid>
      <Box>
        <Box sx={styles.centerContent}>
          <Typography variant="h4" sx={{ mb: 3, color: 'primary.main', fontWeight: 'bold' }}>
            {strings.title}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            {strings.body1}
          </Typography>
        </Box>
        <Box>
          <Typography variant="h6" sx={{ mb: 1 }}>
            {strings.step1Title}
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            {strings.step1Body}
          </Typography>
          <Typography variant="h6" sx={{ mb: 1 }}>
            {strings.step2Title}
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            {strings.step2Body}
          </Typography>
        </Box>
        <Box sx={styles.centerContent}>
          <Paper sx={styles.registerOptions}>
            {isRegistered ? (
              <Box sx={styles.centerContent}>
                <Typography variant="h6" sx={{ mb: 4 }}>
                  {strings.gatewayRegistered}
                </Typography>
                <Countdown onComplete={() => handleNextStep()} />
                <CheckCircleIcon sx={{ fontSize: '8rem', color: 'primary.main' }} />
              </Box>
            ) : (
              <RegisterGatewayForm
                onSubmitCallback={registerGatewayWithCloud}
                awsPropertyId={`${awsPropertyId}`}
                isLoading={isLoading}
              />
            )}
          </Paper>
        </Box>
        <Box sx={styles.dualButtonContainer}>
          <Button variant="contained" color="primary" onClick={handlePreviousStep}>
            {strings.backButtonText}
          </Button>
          <Button color="primary" onClick={() => setIsSkipDialogOpen(true)}>
            {strings.skipButtonText}
          </Button>
          <Button variant="contained" color="primary" type="submit" disabled={!isRegistered} onClick={handleNextStep}>
            {strings.continueButtonText}
          </Button>
        </Box>
      </Box>
      <Dialog
        open={isSkipDialogOpen}
        onClose={() => setIsSkipDialogOpen(false)}
        aria-labelledby={'Skip gateway registration'}
      >
        <DialogTitle>Skip gateway registration?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            If you skip gateway registration, you will not be able to manage your intercoms remotely. Are you sure you
            want to skip?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsSkipDialogOpen(false)}>Cancel</Button>
          <Button onClick={() => handleNextStep()}>Skip Gateway Registration</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: 1
  },
  dualButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    height: '100%',
    marginTop: 1
  },
  gwImage: {
    height: '25%',
    width: '25%',
    marginTop: 1
  },
  iconButton: {
    position: 'relative',
    top: -25,
    left: 0
  },
  qrScannerButton: {
    fontSize: '7rem',
    color: 'primary.main',
    '&:hover': {
      color: 'primary.dark'
    }
  },
  separator: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  registerOptions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    p: 3,
    px: 5,
    mb: 4,
    width: '100%',
    height: '23rem'
  }
};

export default RegisterGateway;
