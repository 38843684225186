import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  Step,
  StepButton,
  Stepper,
  TextField,
  Typography
} from '@mui/material';
import { CA, US } from 'country-flag-icons/react/3x2';
import ApartmentIcon from '@mui/icons-material/Apartment';
import BusinessIcon from '@mui/icons-material/Business';
import CancelIcon from '@mui/icons-material/Cancel';
import { ErrorMessage, Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import React from 'react';
import { EnumList, ICountryValue, IStateValue, fetchEnumList } from 'shared/utils/EnumUtils';
import { getString } from 'shared/utils/LocalizationUtils';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, resetGatewayState, updateGWId, updateSite } from 'store';
import { fetchAclSiteId } from 'shared/utils/fetchAclSiteId';
import { useCreateSiteMutation } from 'services/aiphoneCloud';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';

interface INewSiteDialog {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IFormValues {
  siteName: string | undefined;
  siteAddress: string;
  siteAddress2: string;
  siteCity: string;
  stateId: string;
  siteZip: string;
  countryId: string;
  typeId: number;
}

const initialValues: IFormValues = {
  siteName: '',
  siteAddress: '',
  siteAddress2: '',
  siteCity: '',
  stateId: '',
  siteZip: '',
  countryId: '',
  typeId: 1
};

const validationSchema = Yup.object().shape({
  siteName: Yup.string().required('Required')
});

interface SiteInformationProps {
  formikProps: FormikProps<IFormValues>;
}

const steps = ['Site Information', 'Site Type', 'Confirmation'];

enum Steps {
  SiteInformation,
  SiteType,
  Confirmation
}

//TODO: Move this to a utility file
const getStateList = (enumList: EnumList, countryId: string) => {
  const selectCountryText = getString('Select_Country');
  const stateList = Object.keys(enumList.state)
    .map((key) => {
      const stateWalker = enumList.state[key] as IStateValue;
      // If the state is not in the selected country, do not add the option
      // Also do not include the unknown option
      if (stateWalker.countryId.toString() !== countryId || stateWalker.value === 'Unknown') {
        return null;
      }
      return (
        <MenuItem key={stateWalker.value} value={key}>
          {stateWalker.value}
        </MenuItem>
      );
    })
    .filter((val) => val !== null);

  if (stateList.length === 0) {
    stateList.push(
      <MenuItem key={selectCountryText} value="" disabled={true}>
        {selectCountryText}
      </MenuItem>
    );
  }

  return stateList;
};

const SiteInformation: React.FC<SiteInformationProps> = ({ formikProps }) => {
  const [enumList, setEnumList] = React.useState<EnumList>({ country: {}, state: {} });
  const [, setFetchingEnums] = React.useState(true);
  const selectCountryText = getString('Select_Country');
  const selectStateText = getString('Select_State');

  const { values, handleChange, setFieldValue, errors, touched } = formikProps;

  React.useEffect(() => {
    const fetchEnums = async () => {
      const data = await fetchEnumList();
      setEnumList(data);
      setFetchingEnums(false);
    };

    fetchEnums();
  }, []);

  return (
    <Box>
      <Box sx={styles.mb2}>
        <Typography variant="h5">Site Information</Typography>
        <Typography variant="body1">Enter the site information below.</Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid></Grid>
        <Grid item xs={12}>
          <Field
            name="siteName"
            required
            as={TextField}
            label="Site Name"
            fullWidth
            size="small"
            helperText={touched.siteName && errors.siteName}
            error={touched.siteName && !!errors.siteName}
          />
        </Grid>
        <Grid item xs={12}>
          <Field name="siteAddress" as={TextField} label="Site Address" fullWidth size="small" />
        </Grid>
        <Grid item xs={12}>
          <Field name="siteAddress2" as={TextField} label="Site Address 2" fullWidth size="small" />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <FormControl sx={styles.inputField} size="small" error={touched.countryId && Boolean(errors.countryId)}>
            <InputLabel id="country-label">{selectCountryText}</InputLabel>
            <Field
              name="countryId"
              id="countryId"
              as={Select}
              labelId="country-label"
              label={selectCountryText}
              onChange={(e: any) => {
                handleChange(e);
                setFieldValue('stateId', '');
              }}
            >
              {Object.keys(enumList.country).map((key) => {
                const validCountries = ['CA', 'US'];
                const countryWalker = enumList.country[key] as ICountryValue;

                if (!validCountries.includes(countryWalker.alpha2Code)) {
                  return null;
                }

                const flagComponent: JSX.Element =
                  countryWalker.alpha2Code === 'CA' ? (
                    <CA title={countryWalker.defaultLanguageName} style={styles.flagStyle} />
                  ) : (
                    <US title={countryWalker.defaultLanguageName} style={styles.flagStyle} />
                  );

                return (
                  <MenuItem key={key} value={key}>
                    <Grid sx={styles.field}>
                      {flagComponent}
                      {countryWalker.value}
                    </Grid>
                  </MenuItem>
                );
              })}
            </Field>
            <Box sx={styles.errorMessage}>
              <ErrorMessage name="country" />
            </Box>
          </FormControl>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <FormControl sx={styles.inputField} size="small" error={touched.stateId && Boolean(errors.stateId)}>
            <InputLabel id="state-label">{selectStateText}</InputLabel>
            <Field name="stateId" id="stateId" as={Select} labelId="state-label" label={selectStateText}>
              {getStateList(enumList, values.countryId)}
            </Field>
            <Box sx={styles.errorMessage}>
              <ErrorMessage name="state" />
            </Box>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Field name="siteCity" as={TextField} label="Site City" fullWidth size="small" />
        </Grid>
        <Grid item xs={6}>
          <Field name="siteZip" as={TextField} label="Zip Code" fullWidth size="small" />
        </Grid>
      </Grid>
    </Box>
  );
};

const SiteType: React.FC<SiteInformationProps> = ({ formikProps }) => {
  const { values, handleChange, setFieldValue } = formikProps;

  const styles = {
    dividerContainer: {
      display: 'flex',
      justifyContent: 'center',
      height: '100%'
    },
    selectionContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'column',
      gap: '1rem'
    },
    iconButton: {
      color: 'primary.main',
      fontSize: '5rem'
    },
    iconButtonSelected: {
      color: 'secondary.main',
      fontSize: '5rem'
    },
    typeSelection: {
      border: '2px solid #e0e0e0'
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} container>
          <Grid item xs={4}>
            <Box sx={styles.selectionContainer}>
              <Radio
                checked={values.typeId === 2}
                value={1}
                name="typeId"
                icon={<ApartmentIcon sx={styles.iconButton} />}
                checkedIcon={<ApartmentIcon sx={styles.iconButtonSelected} />}
                onChange={(e) => {
                  handleChange(e);
                  setFieldValue('typeId', 2);
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6" color={values.typeId === 2 ? 'secondary' : 'primary'}>
              Multi-Tenant
            </Typography>
            <Typography variant="body1">
              Designed for apartments and multi-unit commercial spaces. <br />
              Arrange your system into Units to add devices, mobile apps, and tenants.
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={4}>
            <Box sx={styles.selectionContainer}>
              <Radio
                checked={values.typeId === 1}
                value={2}
                name="typeId"
                icon={<BusinessIcon sx={styles.iconButton} />}
                checkedIcon={<BusinessIcon sx={styles.iconButtonSelected} />}
                onChange={(e) => {
                  handleChange(e);
                  setFieldValue('typeId', 1);
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="h6" color={values.typeId === 1 ? 'secondary' : 'primary'}>
              Commercial
            </Typography>
            <Typography variant="body1">
              Designed for a single commercial or residential space. <br />
              Start with any number of devices and up to 8 IXG mobile apps.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const Confirmation = () => {
  return (
    <Box>
      <Typography variant="h5">Confirmation Screen</Typography>
      <Typography variant="body1">
        Please review the information below and click "Create Site" to complete the process.
      </Typography>
      <Divider />
      <Typography variant="h4">Work in Progress</Typography>
    </Box>
  );
};

const NewSiteDialog = ({ isOpen, setIsOpen }: INewSiteDialog) => {
  const [activeStep, setActiveStep] = React.useState(Steps.SiteInformation);
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});
  const [, setErrorMessage] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [createSite, { isLoading: isCreating }] = useCreateSiteMutation();
  const branchData = useSelector((state: RootState) => state.branches.currentBranch);
  const userData = useSelector((state: RootState) => state.users.currentUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted() ? steps.findIndex((step, i) => !(i in completed)) : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const handleClose = () => {
    setIsOpen(false);
    handleReset();
  };

  const prepareGateway = async () => {
    setIsLoading(true);
    dispatch(resetGatewayState());
    try {
      const aclSiteId = await fetchAclSiteId();
      dispatch(updateGWId(aclSiteId));
      return aclSiteId;
    } catch (error) {
      console.error('An error occurred:', error);
      setErrorMessage('Error fetching site ID. Please try again');
      setIsLoading(false);
      return;
    }
  };

  const handleSubmit = async (values: IFormValues, { setSubmitting }: FormikHelpers<any>) => {
    const awsPropertyId = await prepareGateway();
    const createSitePayload = {
      siteData: {
        siteName: values.siteName,
        siteAddress: values.siteAddress || '',
        siteAddress2: values.siteAddress2 || '',
        siteCity: values.siteCity || '',
        stateId: values.stateId || branchData?.stateId,
        siteZip: values.siteZip || '',
        sitePhoneNumber: null,
        multiBuilding: false,
        constructionCompanyName: branchData?.branchName,
        constructionStaffName: userData?.firstName + ' ' + userData?.lastName,
        constructionEmailAddress: userData?.email,
        constructionPhoneNumber: branchData?.phoneNumber,
        managementCompanyName: null,
        managementStaffName: null,
        managementEmailAddress: null,
        managementPhoneNumber: null,
        awsPropertyId: awsPropertyId,
        systemId: null,
        systemPassword: null,
        statusId: 1,
        branchPublicId: branchData?.publicId,
        typeId: values.typeId
      }
    };

    dispatch(updateSite(createSitePayload));
    createSite(createSitePayload)
      .then((result) => {
        setSubmitting(false);
        setIsLoading(false);
        if ('error' in result) {
          throw new Error('Error creating site');
        } else if (result.data) {
          navigate(`/site/${result.data}/wizard`);
        } else {
          throw new Error('Site ID not found in response');
        }
      })
      .catch((error) => {
        console.error('An error occurred:', error);
        setSubmitting(false);
        setIsLoading(false);
        setErrorMessage('Error creating site');
      });
  };

  const renderStep = (step: Steps, formikProps: FormikProps<IFormValues>) => {
    switch (step) {
      case Steps.SiteInformation:
        return <SiteInformation formikProps={formikProps} />;
      case Steps.SiteType:
        return <SiteType formikProps={formikProps} />;
      case Steps.Confirmation:
        return <Confirmation />;
      default:
        return <Box>Site Created</Box>;
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
      <Box sx={styles.newSiteDialogContainer}>
        <Grid container spacing={1}>
          <Grid item xs={11}></Grid>
          <Grid item xs={1}>
            <IconButton color="default" aria-label="cancel" onClick={() => setIsOpen(false)}>
              <CancelIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
          {(formikProps) => (
            <Form>
              <DialogContent>
                <Box>
                  <Stepper activeStep={activeStep} nonLinear>
                    {steps.map((label, index) => {
                      return (
                        <Step key={label} completed={completed[index]}>
                          <StepButton color="inherit" onClick={handleStep(index)}>
                            {label}
                          </StepButton>
                        </Step>
                      );
                    })}
                  </Stepper>
                </Box>
                <Box sx={styles.stepContainer}>{renderStep(activeStep, formikProps)}</Box>
              </DialogContent>
              <DialogActions>
                {activeStep === Steps.Confirmation ? (
                  <React.Fragment>
                    <Button onClick={handleBack} sx={{ mr: 1 }}>
                      Back
                    </Button>
                    <LoadingButton
                      variant="contained"
                      color="primary"
                      type="button"
                      loading={isLoading || isCreating}
                      onClick={() => {
                        if (formikProps.isValid) formikProps.handleSubmit();
                      }}
                    >
                      Create Site
                    </LoadingButton>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Button disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      type={'button'}
                      disabled={!formikProps.isValid || formikProps.values.siteName === ''}
                      onClick={() => {
                        handleComplete();
                      }}
                    >
                      Next
                    </Button>
                  </React.Fragment>
                )}
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Box>
    </Dialog>
  );
};

const styles = {
  newSiteDialogContainer: {
    width: '100%',
    height: '100%'
  },
  stepContainer: {
    padding: '16px'
  },
  mb2: {
    marginBottom: '16px'
  },
  inputField: {
    marginBottom: 1,
    width: '100%',
    '& .MuiInputBase-input': {
      backgroundColor: '#ffffff'
    },
    '&.MuiFormHelperText-root': {
      color: 'red'
    },
    '& .MuiInputLabel-root': {
      color: 'red',
      '&.Mui-focused': {
        color: 'black'
      }
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey'
      },
      '&:hover fieldset': {
        borderColor: '#003366'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#0071ce'
      }
    }
  },
  field: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  flagStyle: {
    width: '33px',
    minWidth: '33px',
    height: '22px',
    paddingRight: '10px'
  },
  errorMessage: {
    color: '#d32f2f',
    fontSize: '0.75rem',
    margin: 0
  }
};

export default NewSiteDialog;
