/* Absent Transfer tab stub */
export const AbsentTransferLabel = () => {
  return <span>Absent Transfer</span>;
};

const AbsentTransfer = () => {
  return <span>Absent Transfer</span>;
};

export default AbsentTransfer;
